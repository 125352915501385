import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { Input } from "@progress/kendo-react-inputs";
import { derender, getInitialState, render } from "@selas/state-management";
import { Confirm, EntityEditor, IAddEntityScreenProps, IEditEntityScreenProps, ManageableField } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";

import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import apiService from "@selas/api-communication/dist/apiService";
import { IAction } from "@selas/models";
import { cloneDeep, map } from "lodash";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";
import Endpoint from "../../../services/api/endpoint";
import { taskSubjectReducer } from "../../../state/reducers";
import sessionTemplateFollowUpTaskReducer from "../../../state/reducers/sessionTemplateFollowUpTaskReducer";
import { ICheckListItem, ISessionTemplateFollowUpTask, ITaskSubject } from "../../../utils/types/models";
import CheckListItemEditor from "../masterdata/taskSubject/checkListItemEditor";

import styles from "../masterdata/taskSubject/editor.module.scss";

interface IProps {
	sessionTemplateId: number;
}

type PropsBase = IAddEntityScreenProps<ISessionTemplateFollowUpTask> | IEditEntityScreenProps<ISessionTemplateFollowUpTask>;

const SessionTemplateTaskEditor: React.FC<PropsBase & IProps> = (props: PropsBase & IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: ReduxDispatch = useDispatch();

	const initialSessionTemplate: ISessionTemplateFollowUpTask = {
		id: (props as IEditEntityScreenProps<ISessionTemplateFollowUpTask>).recordId || 0,
		subject: "",
		checkList: [],
		sessionTemplateId: props.sessionTemplateId
	};

	const [sessionTemplateFollowUpTask, setSessionTemplateFollowUpTask] = useState<ISessionTemplateFollowUpTask>(initialSessionTemplate);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();
	const [currentTaskSubject, setCurrentTaskSubject] = useState<ITaskSubject>();
	const [sessionTemplateFollowUpTaskState, sessionTemplateFollowUpTaskDispatch] = useReducer(sessionTemplateFollowUpTaskReducer, getInitialState<ISessionTemplateFollowUpTask>());
	const [taskSubjectState, taskSUbjectDispatch] = useReducer(taskSubjectReducer, getInitialState<ITaskSubject>());

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let searchEndpoint: Endpoint;
		let dispatch: Dispatch<IAction>;
		switch (event.target.name) {
			case "subject":
				searchEndpoint = Endpoint.TaskSubjects;
				dispatch = taskSUbjectDispatch;
				break;
		}
		apiService.callApi(dispatch, searchEndpoint, "GET", { search: event.filter.value });
	}

	useEffect(() => {
		if (sessionTemplateFollowUpTaskState.entity) {
			setSessionTemplateFollowUpTask(sessionTemplateFollowUpTaskState.entity);
			if (sessionTemplateFollowUpTask.id > 0) {
				const taskSubject: ITaskSubject = {
					name: sessionTemplateFollowUpTaskState.entity.subject,
					description: "",
					checkList: sessionTemplateFollowUpTaskState.entity.checkList,
					active: true,
					id: 0
				};
				setCurrentTaskSubject(taskSubject);
			}
		}
	}, [sessionTemplateFollowUpTaskState.entity, sessionTemplateFollowUpTask.id]);

	function setTaskSubject(taskSubject: ITaskSubject): void {
		const task: ISessionTemplateFollowUpTask = cloneDeep(sessionTemplateFollowUpTask);
		if (taskSubject.checkList) {
			task.checkList = taskSubject.checkList;
		}
		task.subject = taskSubject.name;
		setSessionTemplateFollowUpTask(task);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(sessionTemplateFollowUpTask.subject)) {
			messages.push(t("fill_in_required_field", { field: t("subject").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: ISessionTemplateFollowUpTask): void {
		if (record && !close) {
			setSessionTemplateFollowUpTask(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function addItem(): void {
		const key: string = newKey("addItem");
		reduxDispatch(
			render(key, CheckListItemEditor, {
				onClose: (title?: string) => {
					if (title) {
						const newTaskSubject: ISessionTemplateFollowUpTask = cloneDeep(sessionTemplateFollowUpTask);
						newTaskSubject.checkList.push({
							title,
							finished: false
						});
						setSessionTemplateFollowUpTask(newTaskSubject);
						setDataChanged(true);
					}
					reduxDispatch(derender(key));
				}
			})
		);
	}

	function updateItem(index: number): void {
		const taskListEditorKey: string = newKey("editChecklistItemConfirm");
		const checkListIstItem: ICheckListItem = sessionTemplateFollowUpTask.checkList[index];
		reduxDispatch(
			render(taskListEditorKey, CheckListItemEditor, {
				initialTitle: checkListIstItem.title,
				onClose: (title: string) => {
					if (!isNullOrEmpty(title)) {
						const newTaskSubject: ISessionTemplateFollowUpTask = cloneDeep(sessionTemplateFollowUpTask);
						newTaskSubject.checkList.splice(index, 1, { title, finished: checkListIstItem.finished });
						setSessionTemplateFollowUpTask(newTaskSubject);
						setDataChanged(true);
					}
					reduxDispatch(derender(taskListEditorKey));
				}
			})
		);
	}

	function deleteItem(index: number): void {
		const confirmKey: string = newKey("deleteItem");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm"),
				children: t("confirm_content", { action: t("remove") }),
				onConfirm: () => {
					const newTaskSubject: ISessionTemplateFollowUpTask = cloneDeep(sessionTemplateFollowUpTask);
					newTaskSubject.checkList.splice(index, 1);
					setSessionTemplateFollowUpTask(newTaskSubject);
					setDataChanged(true);
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ISessionTemplateFollowUpTask>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={sessionTemplateFollowUpTask}
			endpoint={Endpoint.SessionTemplateFollowUpTasks}
			entityState={sessionTemplateFollowUpTaskState}
			extraUrlParams={{ sessionTemplateId: props.sessionTemplateId }}
			entityType={t("sessionTemplateFollowUpTask")}
			dispatch={sessionTemplateFollowUpTaskDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={sessionTemplateFollowUpTask.subject}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="k-form-field">
					<ManageableField
						fieldLabel={t("subject") + " *"}
						recordId={undefined}
						addScreen={{ screen: null, isAllowed: false }}
						editScreen={{ screen: null, isAllowed: false }}
						setEntity={(taskSubject: ITaskSubject) => {
							setCurrentTaskSubject(taskSubject);
							setTaskSubject(taskSubject);
						}}
						readOnly={readonly}
					>
						<ComboBox
							name="subject"
							className="hiddenTrigger"
							data={taskSubjectState.entities}
							loading={taskSubjectState.areEntitiesLoading}
							value={currentTaskSubject}
							filterable
							dataItemKey="id"
							textField="name"
							onFilterChange={onFilterChange}
							onChange={(event: ComboBoxChangeEvent) => setCurrentTaskSubject(event.value)}
							onClose={(event: ComboBoxCloseEvent) => {
								if (event.target.value) {
									setTaskSubject(event.target.value);
								}
							}}
							allowCustom
							clearButton
							suggest
							required
							disabled={readonly}
						/>
					</ManageableField>
				</div>
				<div className="k-form-field" style={{ position: "relative", height: "200px", marginBottom: "40px" }}>
					<span className="manageable-field-label">
						{t("checkList")} <i className="las la-plus smallAction" onClick={() => addItem()} />
					</span>
					<div className={styles.itemsListingContainer}>
						{map(sessionTemplateFollowUpTask.checkList, (item: ICheckListItem, index: number) => (
							<div key={`checkListItem_${index}`} className={styles.itemPreviewRow}>
								<div className={`manageable-field-label ${styles.itemPreviewDescription}`}>
									<i className="las la-pencil-alt" style={{ marginRight: "10px" }} onClick={() => updateItem(index)} />
									<i className="las la-trash" style={{ marginRight: "10px" }} onClick={() => deleteItem(index)} />
									{item.title}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</EntityEditor>
	);
};

export default SessionTemplateTaskEditor;
