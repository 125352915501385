import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, GridPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import style from "../../../../../assets/editor.module.scss";
import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { Permission } from "../../../../../utils/enums";
import NoteEditor from "../../../followup/note";

interface IProps {
	groupId: number;
}

const GroupNotes: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.GroupNotesList}
			listUrlArguments={{ groupId: props.groupId }}
			endpoint={Endpoint.GroupNotes}
			addScreen={{
				screen: NoteEditor,
				isAllowed: hasPermission(Permission.GroupsUpdate),
				extraProps: { groupId: props.groupId }
			}}
			editScreen={{
				screen: NoteEditor,
				isAllowed: hasPermission(Permission.GroupsUpdate),
				extraProps: { groupId: props.groupId }
			}}
			delete={{ isAllowed: hasPermission(Permission.GroupsUpdate), urlArguments: { groupId: props.groupId } }}
			localLoader
		>
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="createdDate" title={t("createdDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="content" title={t("note")} />
		</GridPanel>
	);
};

export default GroupNotes;
