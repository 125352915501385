import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { invoicingMomentReducer } from "../../../state/reducers";
import { IInvoicingMoment } from "../../../utils/types/models";

const InvoicingMomentEditor: React.FC<IAddEntityScreenProps<IInvoicingMoment> | IEditEntityScreenProps<IInvoicingMoment>> = (
	props: IAddEntityScreenProps<IInvoicingMoment> | IEditEntityScreenProps<IInvoicingMoment>
) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(invoicingMomentReducer, getInitialState<IInvoicingMoment>());
	const initialInvoicingMoment: IInvoicingMoment = {
		id: (props as IEditEntityScreenProps<IInvoicingMoment>).recordId || 0,
		name: "",
		active: true
	};
	const [invoicingMoment, setInvoicingMoment] = useState<IInvoicingMoment>(initialInvoicingMoment);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setInvoicingMoment(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent): void {
		setInvoicingMoment(handleChange(invoicingMoment, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(invoicingMoment.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IInvoicingMoment): void {
		if (record && !close) {
			setInvoicingMoment(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IInvoicingMoment>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={invoicingMoment}
			endpoint={Endpoint.InvoicingMoments}
			entityState={state}
			entityType={t("invoicingMoment")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={invoicingMoment.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={invoicingMoment.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={invoicingMoment.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default InvoicingMomentEditor;
