import React, { useEffect, useReducer, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, hideLoader, render, showLoader } from "@selas/state-management";
import { Confirm, PendingSavePrompt, usePreventWindowUnload } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import chunk from "lodash/chunk";
import keys from "lodash/keys";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialSettingState } from "../../../state";
import { updateSetting } from "../../../state/actions/settingActions";
import { settingReducer } from "../../../state/reducers";
import { Permission } from "../../../utils/enums";
import { ISetting } from "../../../utils/types/models";
import SettingEditor from "./settingEditor";

import style from "./settings.module.scss";

const Settings: React.FC = () => {
	const { t } = useTranslation();
	const [settingsChanged, setSettingsChanged] = useState(false);
	const [state, dispatch] = useReducer(settingReducer, initialSettingState);
	const reduxDispatch: Dispatch = useDispatch();
	const apiService: ApiCommunicator = useApiService();
	usePreventWindowUnload(settingsChanged);

	useEffect(() => {
		apiService.callApi(dispatch, Endpoint.Settings, "GET");
	}, [apiService]);

	useEffect(() => {
		if (state.settingsLoading || state.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [state.settingsLoading, state.isUpdating, reduxDispatch]);

	function onChange(settingsKey: string, setting: ISetting): void {
		dispatch(updateSetting(settingsKey, setting));
		setSettingsChanged(true);
	}

	function reset(): void {
		const confirmKey: string = newKey("confirm");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title", { action: t("reset").toLocaleLowerCase() }),
				onConfirm: () => {
					reduxDispatch(derender(confirmKey));
					apiService.callApi(dispatch, Endpoint.Settings, "GET");
					setSettingsChanged(false);
				},
				onDecline: () => reduxDispatch(derender(confirmKey)),
				children: t("confirm_reset")
			})
		);
	}

	function save(setNavigationAllowed?: (navigationAllowed: boolean) => void): void {
		if (setNavigationAllowed) {
			apiService.callApi(dispatch, Endpoint.Settings, "PUT", null, state.settings, null, (success: boolean) => {
				if (success) {
					setNavigationAllowed(true);
				}
			});
		} else {
			apiService.callApi(dispatch, Endpoint.Settings, "PUT", null, state.settings, null, (success: boolean) => {
				if (success) {
					setSettingsChanged(false);
				}
			});
		}
	}

	const readOnly: boolean = !hasPermission(Permission.SettingsUpdate);

	return (
		<div className={"container " + style.settings}>
			<PendingSavePrompt when={settingsChanged} title={t("pending_changes")} onConfirm={save}>
				{t("ask_save")}
			</PendingSavePrompt>
			<div className={"row " + style.settingsTitle}>
				<div className="col">
					<h1>{t("settings")}</h1>
				</div>
			</div>
			{map(chunk(keys(state.settings), 2), (settingKeys: string[], index: number) => (
				<div className="row" key={"setting-row-" + index} style={{ paddingBottom: "10px" }}>
					{map(settingKeys, (settingKey: string) => (
						<React.Fragment key={settingKey}>
							<div className="col-2">{t(settingKey)}:</div>
							<div className="col-4" key={settingKey}>
								<SettingEditor setting={state.settings[settingKey]} onChange={(setting: ISetting) => onChange(settingKey, setting)} readOnly={readOnly} />
							</div>
						</React.Fragment>
					))}
				</div>
			))}
			{!readOnly && (
				<div className={style.buttonRow + " row justify-content-end"}>
					<Button onClick={reset} disabled={!settingsChanged}>
						{t("reset")}
					</Button>
					<Button primary onClick={() => save()} disabled={!settingsChanged}>
						{t("save")}
					</Button>
				</div>
			)}
			<div className={style.settingsFooter} />
		</div>
	);
};

export default Settings;
