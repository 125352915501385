import React, { Dispatch, Ref, SetStateAction, useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, ManageableMultiSelect, Tab, TabPanel, TextArea, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { facilityReducer, topicReducer } from "../../../state/reducers";
import sessionTemplateReducer from "../../../state/reducers/sessionTemplateReducer";
import { Permission } from "../../../utils/enums";
import { IFacility, ISessionTemplate, ISessionTemplateFacility, ISessionTemplateTopic, ITopic } from "../../../utils/types/models";
import TopicEditor from "../masterdata/topic";
import FacilityEditor from "../resources/facility";
import SessionTemplateFollowUpTasks from "./sessionTemplateFollowUpTask";

const SessionTemplateEditor: React.FC<IAddEntityScreenProps<ISessionTemplate> | IEditEntityScreenProps<ISessionTemplate>> = (
	props: IAddEntityScreenProps<ISessionTemplate> | IEditEntityScreenProps<ISessionTemplate>
) => {
	const { t } = useTranslation();
	const firstField: Ref<Input> = useRef();
	const apiService: ApiCommunicator = useApiService();

	const initialSession: ISessionTemplate = {
		id: (props as IEditEntityScreenProps<ISessionTemplate>).recordId || 0,
		name: "",
		description: "",
		isOnSite: false,
		isCollective: true,
		maximumParticipantCount: 0,
		facilities: [],
		topics: [],
		active: true,
		followUpTasks: []
	};

	const [sessionTemplate, setSessionTemplate] = useState<ISessionTemplate>(initialSession);
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [sessionTemplateState, sessionTemplateDispatch] = useReducer(sessionTemplateReducer, getInitialState<ISessionTemplate>());
	const [facilityState, facilityDispatch] = useReducer(facilityReducer, getInitialState<IFacility>());
	const [topicState, topicDispatch] = useReducer(topicReducer, getInitialState<ITopic>());

	useEffect(() => {
		if (sessionTemplateState.entity) {
			setSessionTemplate(sessionTemplateState.entity);
		}
	}, [sessionTemplateState.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent): void {
		setSessionTemplate(handleChange(sessionTemplate, event));
		setDataChanged(true);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const newSessionTemplate: ISessionTemplate = cloneDeep(sessionTemplate);

		if (event.value) {
			if (event.target.name === "facilities") {
				newSessionTemplate.facilities = [];
				for (const facility of event.value) {
					newSessionTemplate.facilities.push({
						sessionTemplateId: newSessionTemplate.id,
						facilityId: facility.id,
						facility
					});
				}
			} else if (event.target.name === "topics") {
				newSessionTemplate.topics = [];
				for (const topic of event.value) {
					newSessionTemplate.topics.push({
						sessionTemplateId: newSessionTemplate.id,
						topicId: topic.id,
						topic
					});
				}
			}
			updateSession(newSessionTemplate);
		}
	}

	function addOrUpdateFacility(facility: IFacility): void {
		const newSessionTemplate: ISessionTemplate = cloneDeep(sessionTemplate);
		const existingSessionFacility: ISessionTemplateFacility = find(newSessionTemplate.facilities, { facilityId: facility.id });
		if (existingSessionFacility) {
			existingSessionFacility.facility = facility;
		} else {
			newSessionTemplate.facilities.push({
				sessionTemplateId: newSessionTemplate.id,
				facilityId: facility.id,
				facility
			});
		}
		updateSession(newSessionTemplate);
	}

	function addOrUpdateTopic(topic: ITopic): void {
		const newSessionTemplate: ISessionTemplate = cloneDeep(sessionTemplate);
		const existingSessionTopic: ISessionTemplateTopic = find(newSessionTemplate.topics, { topicId: topic.id });
		if (existingSessionTopic) {
			existingSessionTopic.topic = topic;
		} else {
			newSessionTemplate.topics.push({
				sessionTemplateId: newSessionTemplate.id,
				topicId: topic.id,
				topic
			});
		}
		updateSession(newSessionTemplate);
	}

	function updateSession(newSessionTemplate: SetStateAction<ISessionTemplate>): void {
		setSessionTemplate(newSessionTemplate);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "facilities":
				dispatch = facilityDispatch;
				filterEndpoint = Endpoint.Facilities;
				break;
			case "topics":
				dispatch = topicDispatch;
				filterEndpoint = Endpoint.Topics;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(sessionTemplate.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: ISessionTemplate): void {
		if (record && !close) {
			setSessionTemplate(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ISessionTemplate>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={sessionTemplate}
			endpoint={Endpoint.SessionTemplates}
			entityState={sessionTemplateState}
			entityType={t("sessionTemplate")}
			dispatch={sessionTemplateDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={sessionTemplate.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")} *</span>
									<Input ref={firstField} name="name" value={sessionTemplate.name} onChange={onChange} required disabled={readonly} />
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("maximumParticipantCount")}</span>
									<NumericTextBox name="maximumParticipantCount" value={sessionTemplate.maximumParticipantCount} onChange={onChange} format="n0" min={0} disabled={readonly} />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("isOnSite")}</div>
									<YesNoSwitch name="isOnSite" checked={sessionTemplate.isOnSite} onChange={onChange} disabled={readonly} />
								</div>
							</div>
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("isCollective")}</div>
									<YesNoSwitch name="isCollective" checked={sessionTemplate.isCollective} onChange={onChange} disabled={readonly} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("description")}</span>
									<TextArea name="description" value={sessionTemplate.description} onChange={onChange} disabled={readonly} />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("facilities")}
									addScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesAdd) }}
									editScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesUpdate) }}
									setEntity={addOrUpdateFacility}
									name="facilities"
									data={facilityState.entities}
									textField="name"
									dataItemKey="id"
									value={map(sessionTemplate.facilities, "facility")}
									loading={facilityState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									autoClose={false}
									filterable
									onFilterChange={onFilterChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("topics")}
									addScreen={{ screen: TopicEditor, isAllowed: hasPermission(Permission.TopicsAdd) }}
									editScreen={{ screen: TopicEditor, isAllowed: hasPermission(Permission.TopicsUpdate) }}
									setEntity={addOrUpdateTopic}
									name="topics"
									data={topicState.entities}
									textField="name"
									dataItemKey="id"
									value={map(sessionTemplate.topics, "topic")}
									loading={topicState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									autoClose={false}
									filterable
									onFilterChange={onFilterChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
						</div>
						{!props.hideActive && (
							<div className="k-form-field">
								<div>{t("active")}</div>
								<YesNoSwitch name="active" checked={sessionTemplate.active} onChange={onChange} disabled={readonly} />
							</div>
						)}
					</div>
				</Tab>
				{sessionTemplate.id > 0 && (
					<Tab reactKey="sessionTemplateTasks" label={t("tasks")}>
						<SessionTemplateFollowUpTasks sessionTemplateId={sessionTemplate.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default SessionTemplateEditor;
