import React, { Dispatch, ReactElement, useEffect, useReducer, useRef, useState } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState, render } from "@selas/state-management";
import {
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel,
	TextArea
} from "@selas/ui-components";
import { getDate, isNullOrEmpty, newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import { isNull } from "lodash";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialOpportunityState, initialProjectState, initialUserState } from "../../../state";
import {
	companyContactReducer,
	companyReducer,
	contactReducer,
	governmentServiceReducer,
	opportunityReducer,
	partnerContactReducer,
	partnerReducer,
	projectGroupReducer,
	projectReducer,
	userReducer
} from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Permission, ProjectStatus } from "../../../utils/enums";
import { IProjectProps } from "../../../utils/props";
import { ICompany, IContact, IGovernmentService, IPartner, IProject, IProjectGroup, IUser } from "../../../utils/types/models";
import { statusDropdownItemRender } from "../../global/statusDropdownItemRender";
import { getProjectStatusColor } from "../../pages/work/projects";
import CompanyEditor from "../customer/company";
import ContactEditor from "../customer/contact";
import PartnerEditor from "../customer/partner";
import DatePickerForDateOnly from "../datepicker/datePicker";
import GovernmentServiceEditor from "../masterdata/governmentService";
import ProjectGroupEditor from "../masterdata/projectGroup";
import RequestMail from "../simpleEmailSend/requestMail";
import Groups from "./grids/groups";
import ProjectEmails from "./grids/projectEmails";
import ProjectFollowUpTasks from "./grids/projectFollowUpTasks";
import ProjectNotes from "./grids/projectNotes";
import ProjectParticipants from "./grids/projectParticipants";
import ProjectServices from "./grids/projectServices";
import Sessions from "./grids/sessions";

const ProjectEditor: React.FC<IAddEntityScreenProps<IProject> | IEditEntityScreenProps<IProject>> = (props: IAddEntityScreenProps<IProject> | IEditEntityScreenProps<IProject>) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	const initialProject: IProject = {
		id: (props as IEditEntityScreenProps<IProject>).recordId || 0,
		title: "",
		description: "",
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		spread: 0,
		customerPONumber: "",
		status: ProjectStatus.New,
		projectServices: [],
		projectParticipants: [],
		groups: [],
		sessions: []
	};

	const [project, setProject] = useState<IProject>(initialProject);
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const [contactState, contactDispatch] = useReducer(contactReducer, getInitialState<IContact>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [companyContactState, companyContactDispatch] = useReducer(companyContactReducer, getInitialState<IContact>());
	const [partnerContactState, partnerContactDispatch] = useReducer(partnerContactReducer, getInitialState<IContact>());
	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [opportunityState, opportunityDispatch] = useReducer(opportunityReducer, initialOpportunityState);
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const [governmentServiceState, governmentServiceDispatch] = useReducer(governmentServiceReducer, getInitialState<IGovernmentService>());
	const [projectGroupState, projectGroupDispatch] = useReducer(projectGroupReducer, getInitialState<IProjectGroup>());

	const firstField: React.RefObject<ComboBox> = useRef<ComboBox>();
	const apiService: ApiCommunicator = useApiService();

	const readonly: boolean = (props as IEditEntityScreenProps<IProject>).readonly;

	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (projectState.entity) {
			setProject(projectState.entity);
		}
	}, [projectState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.ProjectsAdd, Permission.ProjectsUpdate)) {
			apiService.callApi(governmentServiceDispatch, Endpoint.GovernmentServices, "GET");
			apiService.callApi(projectGroupDispatch, Endpoint.ProjectGroups, "GET");
		}
	}, [apiService]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		let extraUrlParameters: Record<string, unknown>;
		switch (event.target.name) {
			case "contactId":
				dispatch = contactDispatch;
				filterEndpoint = Endpoint.Contacts;
				break;
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "partnerId":
				dispatch = partnerDispatch;
				filterEndpoint = Endpoint.Partners;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
			case "opportunityId":
				dispatch = opportunityDispatch;
				filterEndpoint = Endpoint.Opportunities;
				break;
			case "companyContactId":
				dispatch = companyContactDispatch;
				extraUrlParameters = { companyId: project.companyId };
				filterEndpoint = Endpoint.CompanyContacts;
				break;
			case "partnerContactId":
				dispatch = partnerContactDispatch;
				extraUrlParameters = { partnerId: project.partnerId };
				filterEndpoint = Endpoint.PartnerContacts;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value, ...extraUrlParameters });
	}

	function onChange(event: ComboBoxCloseEvent | InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent): void {
		let newProject: IProject = handleChange(project, event);
		if (event.target.name === "companyId") {
			if (newProject.company?.partnerId) {
				newProject = setEntity(newProject, newProject.company.partner, "partner", "partnerId");
			}
		} else if (event.target.name === "opportunityId") {
			if (newProject.opportunity?.contactId) {
				newProject = setEntity(newProject, newProject.contact, "contact", "contactId");
			}
			if (newProject.opportunity?.contact?.partnerId) {
				newProject = setEntity(newProject, newProject.contact.partner, "partner", "partnerId");
			}
			if (newProject.opportunity?.contact?.companyId) {
				newProject = setEntity(newProject, newProject.contact.company, "company", "companyId");
			}
		}
		updateProject(newProject);
	}

	function updateProject(newProject: IProject): void {
		setProject(newProject);
		setDataChanged(true);
	}

	function onActionButtonClicked(close: boolean, record: IProject): void {
		if (record && !close) {
			setProject(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IProject, idField: keyof IProject): void {
		let newProject: IProject = setEntity(project, entity, field, idField);
		if (field === "partner" && !entity) {
			newProject = setEntity(newProject, null, "partnerContact", "partnerContactId");
		}
		if (field === "company" && !entity) {
			newProject = setEntity(newProject, null, "companyContact", "companyContactId");
		}
		updateProject(newProject);
	}

	function renderProjectStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getProjectStatusColor);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!project.responsibleUserId) {
			messages.push(t("fill_in_required_field", { field: t("projectResponsible").toLowerCase() }));
		}
		if (isNullOrEmpty(project.title)) {
			messages.push(t("fill_in_required_field", { field: t("title").toLowerCase() }));
		}
		if (!project.status) {
			messages.push(t("fill_in_required_field", { field: t("projectStatus").toLowerCase() }));
		}
		if (!isNull(project.letterOfIntent) && project.letterOfIntent < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("letterOfIntent").toLowerCase() }));
		}

		return messages;
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: IProjectProps = {
			projectId: project.id
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						// console.log("email");
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	return (
		<EntityEditor
			width="70%"
			record={project}
			endpoint={Endpoint.Projects}
			entityState={projectState}
			entityType={t("project")}
			dispatch={projectDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={project.title}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						{project.id > 0 && (
							<div className="k-form-field" style={{ padding: "5px 0px 20px 0px" }}>
								<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
									{t("sendEmail")}
								</StandardButton>
							</div>
						)}
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("contact")}
									recordId={project.contactId}
									addScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsAdd) }}
									editScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsUpdate) }}
									setEntity={(contact: IContact) => setChild(contact, "contact", "contactId")}
									readOnly={readonly}
								>
									<SearchBox
										name="contactId"
										entities={contactState.entities}
										isLoading={contactState.areEntitiesLoading}
										entityId={project.contactId}
										entity={project.contact}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "contact", "contactId")}
										disabled={readonly}
										myRef={firstField}
									/>
								</ManageableField>
								<div className="contactInformation">
									{project.contact && (
										<>
											{project.contact.address.street} {project.contact.address.number}
											<br />
											{project.contact.address.postalCode} {project.contact.address.municipality}
											<br />
											{!isNullOrEmpty(project.contact.phoneNumber) && (
												<>
													{project.contact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(project.contact.phoneNumber) && <a href={"mailto:" + project.contact.email}>{project.contact.email}</a>}
										</>
									)}
								</div>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("company")}
									recordId={project.companyId}
									addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
									editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
									setEntity={(company: ICompany) => setChild(company, "company", "companyId")}
									readOnly={readonly}
								>
									<SearchBox
										name="companyId"
										entities={companyState.entities}
										isLoading={companyState.areEntitiesLoading}
										entityId={project.companyId}
										entity={project.company}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "company", "companyId")}
										disabled={readonly}
									/>
								</ManageableField>
								<div className="contactInformation">
									{project.company && (
										<>
											{!isNullOrEmpty(project.company.vatNumber) && (
												<>
													{project.company.vatNumber}
													<br />
												</>
											)}
											{project.company.address.street} {project.company.address.number}
											<br />
											{project.company.address.postalCode} {project.company.address.municipality}
										</>
									)}
								</div>
							</div>
							<div className="col">
								<div className="companyContact">
									<>
										<div className="k-form-field">
											<span>{t("companyContact")}</span>
											<SearchBox
												name="companyContactId"
												entities={companyContactState.entities}
												isLoading={companyContactState.areEntitiesLoading}
												entityId={project.companyContactId}
												entity={project.companyContact}
												textField="fullName"
												onFilterChange={onFilterChange}
												onClose={onChange}
												onClear={() => setChild(null, "companyContact", "companyContactId")}
												disabled={readonly || project.company === undefined || !hasPermission(Permission.ContactsRead, Permission.ContactsUpdate)}
											/>
										</div>
									</>
								</div>
								<div className="contactInformation">
									{project.companyContact && (
										<>
											{!isNullOrEmpty(project.companyContact.phoneNumber) && (
												<>
													{t("phoneNumber")}: {project.companyContact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(project.companyContact.mobilePhoneNumber) && (
												<>
													{t("mobilePhoneNumber")}: {project.companyContact.mobilePhoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(project.companyContact.email) && <a href={"mailto:" + project.companyContact.email}>{project.companyContact.email}</a>}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="row justify-content-end">
							<div className="col-4">
								<ManageableField
									fieldLabel={t("partner")}
									recordId={project.partnerId}
									addScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
									editScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
									setEntity={(partner: IPartner) => setChild(partner, "partner", "partnerId")}
									readOnly={readonly}
								>
									<SearchBox
										name="partnerId"
										entities={partnerState.entities}
										isLoading={partnerState.areEntitiesLoading}
										entityId={project.partnerId}
										entity={project.partner}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "partner", "partnerId")}
										disabled={readonly}
									/>
								</ManageableField>
								<div className="contactInformation">
									{project.partner && (
										<>
											{!isNullOrEmpty(project.partner.vatNumber) && (
												<>
													{project.partner.vatNumber}
													<br />
												</>
											)}
											{project.partner.address.street} {project.partner.address.number}
											<br />
											{project.partner.address.postalCode} {project.partner.address.municipality}
										</>
									)}
								</div>
							</div>
							<div className="col-4">
								<div className="partnerContact">
									<div className="k-form-field">
										<span>{t("partnerContact")}</span>
										<SearchBox
											name="partnerContactId"
											entities={partnerContactState.entities}
											isLoading={partnerContactState.areEntitiesLoading}
											entityId={project.partnerContactId}
											entity={project.partnerContact}
											textField="fullName"
											onFilterChange={onFilterChange}
											onClose={onChange}
											onClear={() => setChild(null, "partnerContact", "partnerContactId")}
											disabled={readonly || project.partner === undefined || !hasPermission(Permission.ContactsRead, Permission.ContactsUpdate)}
										/>
									</div>
								</div>
								<div className="contactInformation">
									{project.partnerContact && (
										<>
											{!isNullOrEmpty(project.partnerContact.phoneNumber) && (
												<>
													{t("phoneNumber")}: {project.partnerContact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(project.partnerContact.mobilePhoneNumber) && (
												<>
													{t("mobilePhoneNumber")}: {project.partnerContact.mobilePhoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(project.partnerContact.email) && <a href={"mailto:" + project.partnerContact.email}>{project.partnerContact.email}</a>}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("title")} *</span>
									<Input name="title" value={project.title} onChange={onChange} disabled={readonly} required />
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("spread")}</span>
									<NumericTextBox name="spread" value={project.spread} onChange={onChange} format="n0" min={0} disabled={readonly} />
								</label>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("projectResponsible")} *</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={project.responsibleUserId}
										entity={project.responsibleUser}
										required
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "responsibleUser", "responsibleUserId")}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("description")}</span>
									<TextArea name="description" value={project.description} onChange={onChange} style={{ height: "200px" }} disabled={readonly} />
								</label>
							</div>
							<div className="col">
								<div className="row">
									<div className="col">
										<div className="k-form-field">
											<span>{t("projectStatus")} *</span>
											<EnumDropDownList
												name="status"
												value={project.status}
												onChange={onChange}
												enum={ProjectStatus}
												itemRender={renderProjectStatus}
												required
												disabled={readonly}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="k-form-field">
											<span>{t("opportunity")}</span>
											<SearchBox
												name="opportunityId"
												entities={opportunityState.entities}
												isLoading={opportunityState.areEntitiesLoading}
												entityId={project.opportunityId}
												entity={project.opportunity}
												textField="title"
												onFilterChange={onFilterChange}
												onClose={onChange}
												onClear={() => setChild(null, "opportunity", "opportunityId")}
												disabled={readonly}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="k-form-field">
											<DatePickerForDateOnly
												label={"letterOfIntent"}
												name="letterOfIntent"
												value={getDate(project.letterOfIntent)}
												formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
												onChange={onChange}
												disabled={readonly}
												format={"yyyy-MM-dd"}
											/>
										</div>
									</div>
									<div className="col">
										<label className="k-form-field">
											<span>{t("customerPONumber")}</span>
											<Input name="customerPONumber" value={project.customerPONumber} onChange={onChange} disabled={readonly} />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<ManageableField
											fieldLabel={t("projectGroup")}
											recordId={project.projectGroupId}
											addScreen={{ screen: ProjectGroupEditor, isAllowed: hasPermission(Permission.ProjectGroupsAdd) }}
											editScreen={{ screen: ProjectGroupEditor, isAllowed: hasPermission(Permission.ProjectGroupsUpdate) }}
											setEntity={(projectGroup: IProjectGroup) => setChild(projectGroup, "projectGroup", "projectGroupId")}
											readOnly={readonly}
										>
											<DropDownList
												name="projectGroupId"
												loading={projectGroupState.areEntitiesLoading}
												data={projectGroupState.entities}
												value={project.projectGroup}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												disabled={readonly}
											/>
										</ManageableField>
									</div>
									<div className="col">
										<ManageableField
											fieldLabel={t("reportableGovernmentService")}
											recordId={project.reportableGovernmentServiceId}
											addScreen={{ screen: GovernmentServiceEditor, isAllowed: hasPermission(Permission.GovernmentServicesAdd) }}
											editScreen={{ screen: GovernmentServiceEditor, isAllowed: hasPermission(Permission.GovernmentServicesUpdate) }}
											setEntity={(type: IGovernmentService) => setChild(type, "reportableGovernmentService", "reportableGovernmentServiceId")}
											readOnly={readonly}
										>
											<DropDownList
												name="reportableGovernmentServiceId"
												loading={governmentServiceState.areEntitiesLoading}
												data={governmentServiceState.entities}
												value={project.reportableGovernmentService}
												dataItemKey="id"
												textField="name"
												onChange={onChange}
												disabled={readonly}
											/>
										</ManageableField>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Tab>
				{project.id && (
					<Tab reactKey="tasks" label={t("tasks")}>
						<ProjectFollowUpTasks projectId={project.id} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="services" label={t("services")}>
						<ProjectServices projectId={project.id} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="groups" label={t("groups")}>
						<Groups projectId={project.id} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="participants" label={t("participants")}>
						<ProjectParticipants projectId={project.id} contact={project.contact} companyId={project.companyId} statusChange={true} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="notes" label={t("notes")}>
						<ProjectNotes projectId={project.id} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="sessions" label={t("sessions")}>
						<Sessions projectId={project.id} />
					</Tab>
				)}
				{project.id && (
					<Tab reactKey="emails" label={t("emails")}>
						<ProjectEmails projectId={project.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default ProjectEditor;
