import React, { useCallback, useEffect, useReducer, useState } from "react";

import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridSelectionChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, render } from "@selas/state-management";
import { commandCell, customCell, enumFilterCell, Loader, translatedCell } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import groupParticipantsReducer from "../../../state/reducers/groupParticipantsReducer";
import { IApplicationState } from "../../../store";
import { Permission, ProjectParticipantStatus } from "../../../utils/enums";
import { IGroupParticipantBase, IProjectParticipant, IUser } from "../../../utils/types/models";
import ProjectParticipantEditor from "../../editors/project/projectParticipant";
import { colorCell, colorCellClassName } from "../../global/colorCell";
import { getProjectParticipantStatusColor } from "./participants";
import { addFieldInfo, getHoursColor } from "./services";

interface IProps {
	groupId: number;
	setParticipant?: (id: number) => void;
}

const GroupParticipantsDetail: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();
	const [gridState, setGridState] = useState<State>({
		skip: 0,
		take: 25
	});
	const [groupParticipantsState, groupParticipantsDispatch] = useReducer(groupParticipantsReducer, getInitialState<IGroupParticipantBase>());
	const apiService: ApiCommunicator = useApiService();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	const refreshGrid: () => void = useCallback(() => {
		if (props.groupId !== 0) {
			apiService.callApi(groupParticipantsDispatch, Endpoint.ProjectParticipantsDetailByGroup, "POST", { groupId: props.groupId }, gridState);
		}
	}, [apiService, gridState, props.groupId]);

	useEffect(() => {
		refreshGrid();
	}, [refreshGrid]);

	useEffect(() => {
		if (!groupParticipantsState.isUpdating && groupParticipantsState.updatedEntity) {
			refreshGrid();
		}
	}, [groupParticipantsState.isUpdating, groupParticipantsState.updatedEntity, refreshGrid]);

	function editParticipant(groupParticip: IGroupParticipantBase): void {
		const editParticipantKey: string = newKey("editParticipant");
		reduxDispatch(
			render(editParticipantKey, ProjectParticipantEditor, {
				recordId: groupParticip.id,
				projectId: groupParticip.projectId,
				readonly: !(hasPermission(Permission.ProjectParticipantsUpdate) && (hasPermission(Permission.ProjectParticipantsAdministrator) || groupParticip.responsibleUserId === currentUser.id)),
				statusChange: true,
				actionButtonClicked: (close: boolean, record: IProjectParticipant) => {
					if (close) {
						reduxDispatch(derender(editParticipantKey));
					}
					if (record) {
						refreshGrid();
					}
				}
			})
		);
	}

	return (
		<>
			{(groupParticipantsState.isListLoading || groupParticipantsState.isUpdating) && <Loader />}
			<Grid
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
				data={groupParticipantsState.list}
				total={groupParticipantsState.totalCount}
				{...gridState}
				filterable
				sortable
				style={{ height: "450px" }}
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				resizable={false}
				onRowClick={(event: GridSelectionChangeEvent) => {
					if (event.dataItem) {
						props.setParticipant(event.dataItem.id);
					}
				}}
			>
				<GridToolbar>
					<div className="toolbarButtonContainer d-flex w-100 align-items-center">
						<i className="refreshButton las la-sync" onClick={refreshGrid} />
					</div>
				</GridToolbar>
				<GridColumn
					field="status"
					title={t("projectParticipantStatus")}
					cell={customCell(colorCell(true, translatedCell(), getProjectParticipantStatusColor), colorCellClassName)}
					filterCell={enumFilterCell(ProjectParticipantStatus)}
				/>
				<GridColumn field="fullName" title={t("fullName")} />
				{/* <GridColumn field="projectTitle" title={t("project")} /> */}
				{/* <GridColumn field="groupName" title={t("group")} /> */}
				<GridColumn field="individualHours" title={t("individualHours")} cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} />
				<GridColumn field="collectiveHours" title={t("collectiveHours")} cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} />
				<GridColumn field="responsibleUserFullName" title={t("projectParticipantResponsible")} />
				<GridColumn
					title={t("actions")}
					width="60px"
					filterable={false}
					editable={false}
					sortable={false}
					groupable={false}
					headerClassName="notSortable"
					cell={customCell(
						commandCell([
							{
								tooltip: t("editParticipant"),
								iconClass: "las la-pencil-alt",
								recordAction: editParticipant,
								showCommand: (dataItem: IGroupParticipantBase) =>
									hasPermission(Permission.ProjectParticipantsUpdate) && (hasPermission(Permission.ProjectParticipantsAdministrator) || dataItem.responsibleUserId === currentUser.id)
							}
						])
					)}
				/>
			</Grid>
		</>
	);
};

export default GroupParticipantsDetail;
