import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";

import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { IAction } from "@selas/models";
import { getInitialState, IEntityState } from "@selas/state-management";
import { EntityEditor, EnumDropDownList, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, TextArea } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../services/api/endpoint";
import { followUpTaskEmailReducer, projectParticipantEmailReducer } from "../../../state/reducers";
import groupEmailReducer from "../../../state/reducers/groupEmailReducer";
import opportunityEmailReducer from "../../../state/reducers/opportunityEmailReducer";
import projectEmailReducer from "../../../state/reducers/projectEmailReducer";
import sessionEmailReducer from "../../../state/reducers/sessionEmailReducer";
import { IApplicationState } from "../../../store";
import { emailRegex } from "../../../utils";
import { EmailImportance } from "../../../utils/enums";
import { IGroupProps, IOpportunityProps, IProjectProps, ISessionProps, isGroupProps, isOpportunityProps, isProjectProps, isSessionProps } from "../../../utils/props";
import { IEmail, IFollowUpTaskEmail, IGroupEmail, IOpportunityEmail, IProjectEmail, IProjectParticipantEmail, ISessionEmail, IUser } from "../../../utils/types/models";

interface ITaskProps {
	taskId: number;
}

interface IProjectParticipantProps {
	projectParticipantId: number;
}

type SpecificProps = ITaskProps | IProjectParticipantProps | IProjectProps | IGroupProps | ISessionProps | IOpportunityProps;

type BaseProps = IAddEntityScreenProps<IEmail> | IEditEntityScreenProps<IEmail>;

function isTaskProps(toBeDetirmined: SpecificProps): toBeDetirmined is ITaskProps {
	return (toBeDetirmined as ITaskProps).taskId !== undefined;
}

function isProjectParticipantProps(toBeDetirmined: SpecificProps): toBeDetirmined is IProjectParticipantProps {
	return (toBeDetirmined as IProjectParticipantProps).projectParticipantId !== undefined;
}

function getInitialEmail(props: BaseProps & SpecificProps, currentUser: IUser): IEmail {
	const email: IEmail = {
		id: (props as IEditEntityScreenProps<IEmail>).recordId || 0,
		creatorId: currentUser.id,
		creator: currentUser,
		from: "",
		to: "",
		importance: EmailImportance.Normal,
		subject: "",
		body: ""
	};
	if (isTaskProps(props)) {
		(email as IFollowUpTaskEmail).taskId = props.taskId;
	} else if (isProjectParticipantProps(props)) {
		(email as IProjectParticipantEmail).projectParticipantId = props.projectParticipantId;
	} else if (isGroupProps(props)) {
		(email as IGroupEmail).groupId = props.groupId;
	} else if (isSessionProps(props)) {
		(email as ISessionEmail).sessionId = props.sessionId;
	} else if (isOpportunityProps(props)) {
		(email as IOpportunityEmail).opportunityId = props.opportunityId;
	} else if (isProjectProps(props)) {
		(email as IProjectEmail).projectId = props.projectId;
	}
	return email;
}

const EmailEditor: React.FC<BaseProps & SpecificProps> = (props: BaseProps & SpecificProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const [email, setEmail] = useState<IEmail>(getInitialEmail(props, currentUser));
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef<Input>();

	let reducer: Reducer<IEntityState<IEmail>, IAction>;
	let initialState: IEntityState<IEmail>;
	if (isTaskProps(props)) {
		reducer = (followUpTaskEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	} else if (isProjectParticipantProps(props)) {
		reducer = (projectParticipantEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	} else if (isGroupProps(props)) {
		reducer = (groupEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	} else if (isSessionProps(props)) {
		reducer = (sessionEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	} else if (isOpportunityProps(props)) {
		reducer = (opportunityEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	} else if (isProjectProps(props)) {
		reducer = (projectEmailReducer as unknown) as Reducer<IEntityState<IEmail>, IAction>;
		initialState = getInitialState<IEmail>();
	}

	const [emailState, emailDispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (emailState.entity) {
			setEmail(emailState.entity);
		}
	}, [emailState.entity]);

	function onChange(event: InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent): void {
		setEmail(handleChange(email, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(email.from)) {
			messages.push(t("fill_in_required_field", { field: t("from").toLowerCase() }));
		}
		if (!isNullOrEmpty(email.from) && !email.from.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("from").toLowerCase(), example: "email@test.com" }));
		}
		if (isNullOrEmpty(email.to)) {
			messages.push(t("fill_in_required_field", { field: t("to").toLowerCase() }));
		}
		if (!isNullOrEmpty(email.to) && !email.to.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("to").toLowerCase(), example: "email@test.com" }));
		}
		if (!isNullOrEmpty(email.cc) && !email.cc.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("cc").toLowerCase(), example: "email@test.com" }));
		}
		if (!isNullOrEmpty(email.bcc) && !email.bcc.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("bcc").toLowerCase(), example: "email@test.com" }));
		}
		if (isNullOrEmpty(email.importance)) {
			messages.push(t("fill_in_required_field", { field: t("body").toLowerCase() }));
		}
		if (isNullOrEmpty(email.body)) {
			messages.push(t("fill_in_required_field", { field: t("body").toLowerCase() }));
		}
		if (isNullOrEmpty(email.subject)) {
			messages.push(t("fill_in_required_field", { field: t("subject").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IEmail): void {
		if (record && !close) {
			setEmail(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IEmail>).readonly;
	let endpoint: Endpoint;
	let extraParams: Record<string, unknown>;

	if (isTaskProps(props)) {
		endpoint = Endpoint.FollowUpTaskEmails;
		extraParams = { taskId: props.taskId };
	} else if (isProjectParticipantProps(props)) {
		endpoint = Endpoint.ProjectParticipantEmails;
		extraParams = { projectParticipantId: props.projectParticipantId };
	} else if (isGroupProps(props)) {
		endpoint = Endpoint.GroupEmails;
		extraParams = { groupId: props.groupId };
	} else if (isSessionProps(props)) {
		endpoint = Endpoint.SessionEmails;
		extraParams = { sessionId: props.sessionId };
	} else if (isOpportunityProps(props)) {
		endpoint = Endpoint.OpportunityEmails;
		extraParams = { opportunityId: props.opportunityId };
	} else if (isProjectProps(props)) {
		endpoint = Endpoint.ProjectEmails;
		extraParams = { projectId: props.projectId };
	}

	return (
		<EntityEditor
			width="70%"
			record={email}
			endpoint={endpoint}
			extraUrlParams={extraParams}
			entityState={emailState}
			entityType={t("email")}
			dispatch={emailDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName=""
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("creator")}</span>
							<Input value={email.creator?.fullName} disabled />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("from")} *</span>
							<Input name="from" value={email.from} ref={firstField} type="email" onChange={onChange} required disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("to")} *</span>
							<Input name="to" value={email.to} type="email" onChange={onChange} required disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("cc")}</span>
							<Input name="cc" value={email.cc} type="email" onChange={onChange} disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("bcc")}</span>
							<Input name="bcc" value={email.bcc} type="email" onChange={onChange} disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("subject")} *</span>
							<Input name="subject" value={email.subject} onChange={onChange} required disabled={readonly} />
						</label>
					</div>
					<div className="col-3">
						<div className="k-form-field">
							<span>{t("importance")} *</span>
							<EnumDropDownList name="importance" enum={EmailImportance} value={email.importance} onChange={onChange} required disabled={readonly} />
						</div>
					</div>
				</div>
				<label className="k-form-field">
					<span>{t("body")} *</span>
					<TextArea name="body" value={email.body} onChange={onChange} required disabled={readonly} style={{ height: "300px" }} />
				</label>
			</div>
		</EntityEditor>
	);
};

export default EmailEditor;
