import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import DocumentTemplates from "./documentTemplates";
import EmailTemplates from "./emailTemplates";
import GovernmentServices from "./governmentServices";
import InvoicingMoments from "./invoicingMoments";
import JointCommittees from "./jointCommittees";
import NacebelCodes from "./nacebelCodes";
import OpportunityOrigins from "./opportunityOrigins";
import ProjectGroups from "./projectGroups";
import Sectors from "./sectors";
import TaskSubjects from "./taskSubjects";

const Masterdata: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("masterdata")}>
			<Sectors reactKey="sectors" label={t("sectors")} url="/sectors" tileIcon="las la-industry" isAllowed={hasPermission(Permission.SectorsRead)} />
			<JointCommittees reactKey="jointCommittees" label={t("jointCommittees")} url="/jointCommittees" tileIcon="las la-users" isAllowed={hasPermission(Permission.JointCommitteesRead)} />
			<InvoicingMoments
				reactKey="invoicingMoments"
				label={t("invoicingMoments")}
				url="/invoicingMoments"
				tileIcon="las la-file-invoice-dollar"
				isAllowed={hasPermission(Permission.FacilitiesRead)}
			/>
			<NacebelCodes reactKey="nacebelCodes" label={t("nacebelCodes")} url="/nacebelCodes" tileIcon="las la-th-list" isAllowed={hasPermission(Permission.NacebelCodesRead)} />
			<DocumentTemplates
				reactKey="documentTemplates"
				label={t("documentTemplates")}
				url="/documentTemplates"
				tileIcon="las la-file-alt"
				isAllowed={hasPermission(Permission.DocumentTemplatesRead)}
			/>
			<TaskSubjects reactKey="taskSubjects" label={t("taskSubjects")} url="/taskSubjects" tileIcon="las la-tasks" isAllowed={hasPermission(Permission.TaskSubjectsRead)} />
			<EmailTemplates reactKey="emailTemplates" label={t("emailTemplates")} url="/emailTemplates" tileIcon="las la-envelope-open-text" isAllowed={hasPermission(Permission.EmailTemplatesRead)} />
			<GovernmentServices
				reactKey="governmentServices"
				label={t("governmentServices")}
				url="/governmentservices"
				tileIcon="las la-landmark"
				isAllowed={hasPermission(Permission.GovernmentServicesRead)}
			/>
			<ProjectGroups reactKey="projectGroups" label={t("projectGroups")} url="/projectgroups" tileIcon="las la-folder" isAllowed={hasPermission(Permission.ProjectGroupsRead)} />
			<OpportunityOrigins
				reactKey="opportunityOrigins"
				label={t("opportunityOrigins")}
				url="/opportunityOrigins"
				tileIcon="las la-seedling"
				isAllowed={hasPermission(Permission.OpportunityOriginsRead)}
			/>
		</RoutedTabPanel>
	);
};

export default Masterdata;
