import React, { useEffect, useReducer } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, render } from "@selas/state-management";
import { checkBoxCell, customCell, GridPanel, StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialSettingState } from "../../../../state";
import { settingReducer } from "../../../../state/reducers";
import { Permission } from "../../../../utils/enums";
import { colorCellClassName, occupationColorCell } from "../../../global/colorCell";
import GroupEditor from "../group";
import DistributeParticipantsScreen from "../group/distributeParticipantsScreen";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
}

const Groups: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();

	const [settingOrangeState, settingOrangeDispatch] = useReducer(settingReducer, initialSettingState);
	const [settingRedState, settingRedDispatch] = useReducer(settingReducer, initialSettingState);
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		apiService.callApi(settingOrangeDispatch, Endpoint.SettingsByKey, "GET", { key: "GroupOccupationOrange" });
		apiService.callApi(settingRedDispatch, Endpoint.SettingsByKey, "GET", { key: "GroupOccupationRed" });
	}, [apiService]);

	function onDistributeParticipants(): void {
		const key: string = newKey("drag_to_group_screen");
		reduxDispatch(
			render(key, DistributeParticipantsScreen, {
				projectId: props.projectId,
				onClose: () => reduxDispatch(derender(key))
			})
		);
	}

	let extraToolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.GroupsUpdate) && hasPermission(Permission.ProjectsUpdate)) {
		extraToolbarButtons = [
			<StandardButton key="distributeParticipants" primary onClick={onDistributeParticipants}>
				{t("distributeParticipants")}
			</StandardButton>
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.GroupsList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.Groups}
			addScreen={{ screen: GroupEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			editScreen={{ screen: GroupEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			extraToolbarButtons={extraToolbarButtons}
			localLoader
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="code" title={t("groupCode")} />
			<GridColumn field="responsibleUser.fullName" title={t("groupResponsible")} />
			<GridColumn
				field="occupationPercentage"
				title={t("occupation")}
				filter="numeric"
				cell={customCell(occupationColorCell(true, settingOrangeState.setting?.value, settingRedState.setting?.value), colorCellClassName)}
			/>
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default Groups;
