import translationEN from "./en";
import translationFR from "./fr";
import translationNL from "./nl";
import ITranslation from "./translation";

export interface ILanguageConfiguration {
	code: string;
	translationKey: string;
	editButtonWidth: number;
	removeButtonWidth: number;
	translations: ITranslation;
}
export const languages: ILanguageConfiguration[] = [
	{
		code: "en",
		translationKey: "en",
		editButtonWidth: 70,
		removeButtonWidth: 100,
		translations: translationEN
	},
	{
		code: "nl",
		translationKey: "nl",
		editButtonWidth: 100,
		removeButtonWidth: 120,
		translations: translationNL
	},
	{
		code: "fr",
		translationKey: "fr",
		editButtonWidth: 100,
		removeButtonWidth: 120,
		translations: translationFR
	}
];
