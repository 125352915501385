import React, { useEffect, useReducer, useState } from "react";

import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import ApiCommunicator, { useApiService } from "@selas/api-communication";

import Endpoint from "../../../../services/api/endpoint";
import { initialUserState } from "../../../../state";
import { userReducer } from "../../../../state/reducers";
import { IUser } from "../../../../utils/types/models";

interface Props {
	setSelectedUsers: (users: IUser[]) => void;
}

export default function UserFilter({ setSelectedUsers }: Props): JSX.Element {
	const [usersFilter, setUsersFilter] = useState([]);
	const [usersState, usersDispatch] = useReducer(userReducer, initialUserState);
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		apiService.callApi(usersDispatch, Endpoint.AllActiveUsers, "GET");
	}, [apiService]);

	useEffect(() => {
		if (!usersState.allActiveUsersLoading && usersState.allActiveUsers) {
			setSelectedUsers(usersState.allActiveUsers);
		}
	}, [usersState.allActiveUsersLoading, usersState.allActiveUsers, setSelectedUsers]);

	function onChange(event: MultiSelectChangeEvent): void {
		const newUsers: IUser[] = [];
		for (const user of event.value) {
			newUsers.push(user);
		}
		setUsersFilter(newUsers);
		if (newUsers.length === 0) {
			setSelectedUsers(usersState.allActiveUsers);
		} else {
			setSelectedUsers(newUsers);
		}
	}

	function onFilterChange(event: MultiSelectFilterChangeEvent): void {
		apiService.callApi(usersDispatch, Endpoint.Users, "GET", { search: event.filter.value });
	}

	return (
		<MultiSelect
			name="users"
			loading={usersState.areEntitiesLoading}
			data={usersState.entities}
			value={usersFilter}
			dataItemKey="id"
			textField="fullName"
			autoClose={false}
			onChange={onChange}
			filterable
			onFilterChange={onFilterChange}
		/>
	);
}
