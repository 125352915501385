import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useReducer, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { SearchBox, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import { IPlanningChild } from ".";
import Endpoint from "../../../../services/api/endpoint";
import { initialSessionState, initialUserState } from "../../../../state";
import { sessionReducer, userReducer } from "../../../../state/reducers";
import { ISession, IUser } from "../../../../utils/types/models";
import { useDebouncedState } from "../../../../utils/useDebouncedState";
import { SessionCard } from "./sessionCard";

import { ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import style from "./planning.module.scss";

interface Props {
	redLimit: string;
	orangeLimit: string;
	openSession: (session: ISession, readOnly: boolean) => void;
	showLoader: (key: string) => void;
	hideLoader: (key: string) => void;
}

function Sessions({ redLimit, orangeLimit, openSession, showLoader, hideLoader }: Props, ref: Ref<IPlanningChild>): JSX.Element {
	const { t } = useTranslation();
	const [sessionNameFilter, setSessionNameFilter] = useDebouncedState("", 500);
	const [responsibleFilter, setResponsibleFilter] = useState<IUser>();
	const [showAll, setShowAll] = useState(false);
	const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
	const apiService: ApiCommunicator = useApiService();
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);

	const refresh: () => void = useCallback(() => {
		apiService.callApi(sessionDispatch, Endpoint.OpenSessionsList, "GET", {
			allSessions: showAll,
			sessionName: isNullOrEmpty(sessionNameFilter) ? null : sessionNameFilter,
			coachId: responsibleFilter?.id
		});
	}, [apiService, responsibleFilter, sessionNameFilter, showAll]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	useEffect(() => {
		const key: string = "sessions";
		if (sessionState.areEntitiesLoading) {
			showLoader(key);
		} else {
			hideLoader(key);
		}
	}, [hideLoader, sessionState.areEntitiesLoading, showLoader]);

	useImperativeHandle(
		ref,
		() => ({
			refresh
		}),
		[refresh]
	);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(userDispatch, Endpoint.Users, "GET", { search: event.filter.value });
	}

	return (
		<div className="d-flex flex-column">
			<div className={style.header}>
				<div className="d-flex">{t("sessions")}</div>
			</div>
			<div className={`${style.grid} px-1`}>
				<div className="d-flex" style={{ marginTop: "5px" }}>
					<div style={{ marginLeft: "5px", marginTop: "5px" }}>{t("Toon alle sessies")}</div>
					<div className="flex-grow-1" />
					<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
				</div>
				<div className="mt-2 mx-2">
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("sessionName")}</span>
								<Input className="w-100" defaultValue={sessionNameFilter} onChange={(event: InputChangeEvent) => setSessionNameFilter(event.value)} />
							</label>
						</div>
					</div>
					<div className="row mt-1">
						<div className="col">
							<div className="k-form-field">
								<span>{t("coach")}</span>
								<SearchBox
									name="users"
									entities={userState.entities}
									isLoading={userState.areEntitiesLoading}
									entityId={responsibleFilter?.id}
									entity={responsibleFilter}
									textField="fullName"
									onFilterChange={onFilterChange}
									onClose={(event) => setResponsibleFilter(event.target.value)}
									onClear={() => setResponsibleFilter(null)}
								/>
							</div>
						</div>
					</div>
				</div>
				{sessionState.entities.map((session: ISession) => (
					<SessionCard key={session.id} session={session} redLimit={redLimit} orangeLimit={orangeLimit} openSession={openSession} listCard />
				))}
				{isEmpty(sessionState.entities) && <div style={{ padding: "10px" }}>{t("grid.noRecords")}</div>}
			</div>
		</div>
	);
}

export default forwardRef(Sessions);
