import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "react";
import { initialUserState, IUserState } from "..";

import Endpoint from "../../services/api/endpoint";
import { IUser } from "../../utils/types/models";

const userReducer: Reducer<IUserState, IAction> = (state: IUserState = initialUserState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.AllActiveUsers:
			state = {
				...state,
				allActiveUsersLoading: true,
				allActiveUsersError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.AllActiveUsers: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					allActiveUsers: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.AllActiveUsers: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				allActiveUsersError: !!payload.error.message
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.AllActiveUsers:
			state = {
				...state,
				allActiveUsersLoading: false,
				allActiveUsersError: null
			};
			break;
	}
	return state;
};

export default createEntityReducer<IUser, IUserState>(Endpoint.Users, Endpoint.UsersList, userReducer);
