import { Reducer } from "react";

import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import Endpoint from "../../services/api/endpoint";
import { IProjectService } from "../../utils/types/models";

const projectServiceReducer: Reducer<IEntityState<IProjectService>, IAction> = (state: IEntityState<IProjectService> = getInitialState<IProjectService>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.ProjectServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: true,
				addedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProjectServicesImportFromInvoicingGroup: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				addedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.ProjectServicesImportFromInvoicingGroup: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.ProjectServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: false
			};
			break;
	}
	return state;
};

export default createEntityReducer<IProjectService>(Endpoint.ProjectServices, Endpoint.ProjectServicesList, projectServiceReducer);
