import { Reducer } from "react";

import { IAction } from "@selas/models";
import { getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import Endpoint from "../../services/api/endpoint";
import { IProjectParticipant } from "../../utils/types/models";

const projectParticipantDistributeReducer: Reducer<IEntityState<IProjectParticipant>, IAction> = (
	state: IEntityState<IProjectParticipant> = getInitialState<IProjectParticipant>(),
	action: IAction
) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.ProjectParticipantNotAssignedToGroup:
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.ProjectParticipantNotAssignedToGroup: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				listLoadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProjectParticipantNotAssignedToGroup: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					list: payload.response.data.data
				};
			}
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.ProjectParticipantNotAssignedToGroup: {
			state = {
				...state,
				isListLoading: false
			};

			break;
		}
		case "REQUEST_GET_" + Endpoint.ProjectParticipantsByGroupList: {
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.ProjectParticipantsByGroupList: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				listLoadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProjectParticipantsByGroupList: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					list: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.ProjectParticipantsByGroupList: {
			state = {
				...state,
				isListLoading: false
			};
			break;
		}

		default:
			return state;
	}
	return state;
};

export default projectParticipantDistributeReducer;
