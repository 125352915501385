import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridColumn } from "@progress/kendo-react-grid";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import {
	checkBoxCell,
	customCell,
	EntityEditor,
	GridPanel,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	ManageableMultiSelect,
	notify,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel,
	TextArea,
	YesNoSwitch
} from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialCompanyWebState } from "../../../state";
import { companyGroupReducer, companyReducer, jointCommitteeReducer, nacebelCodeReducer, partnerReducer } from "../../../state/reducers";
import companyWebReducer from "../../../state/reducers/companyWebReducer";
import { Permission } from "../../../utils/enums";
import { IAddress, ICompany, ICompanyGroup, ICompanyJointCommittee, ICompanyNacebelCode, ICompanyWeb, IContact, IJointCommittee, INacebelCode, IPartner } from "../../../utils/types/models";
import AddressEdit from "../../global/addressEdit";
import { heatCell } from "../../global/heat";
import JointCommitteeEditor from "../masterdata/jointCommittee";
import NacebelCodeEditor from "../masterdata/nacebelCode";
import CompanyGroupEditor from "./companyGroup";
import ContactEditor from "./contact";
import PartnerEditor from "./partner";

type Props = IAddEntityScreenProps<ICompany> | IEditEntityScreenProps<ICompany>;

const CompanyEditor: React.FC<IAddEntityScreenProps<ICompany> | IEditEntityScreenProps<ICompany>> = (props: Props) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	const [companyWebState, companyWebDispatch] = useReducer(companyWebReducer, initialCompanyWebState);

	const initialCompany: ICompany = {
		id: (props as IEditEntityScreenProps<ICompany>).recordId || 0,
		name: "",
		groupId: null,
		group: null,
		partnerId: null,
		partner: null,
		vatNumber: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		extra: "",
		nacebelCodes: [],
		jointCommittees: [],
		contacts: [],
		active: true
	};

	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [companyGroupState, companyGroupDispatch] = useReducer(companyGroupReducer, getInitialState<ICompanyGroup>());
	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [nacebelCodeState, nacebelCodeDispatch] = useReducer(nacebelCodeReducer, getInitialState<INacebelCode>());
	const [jointCommitteeState, jointCommitteeDispatch] = useReducer(jointCommitteeReducer, getInitialState<IJointCommittee>());

	const [company, setCompany] = useState<ICompany>(initialCompany);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (companyState.entity) {
			setCompany(companyState.entity);
		}
	}, [companyState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.CompaniesAdd, Permission.CompaniesUpdate)) {
			apiService.callApi(companyGroupDispatch, Endpoint.CompanyGroups, "GET");
			apiService.callApi(partnerDispatch, Endpoint.Partners, "GET");
			apiService.callApi(nacebelCodeDispatch, Endpoint.NacebelCodes, "GET", { search: "" });
			apiService.callApi(jointCommitteeDispatch, Endpoint.JointCommittees, "GET", { search: "" });
		}
	}, [apiService]);

	useEffect(() => {
		if (companyWebState.data) {
			const data: ICompanyWeb = companyWebState.data;
			const companyRecord: ICompany = cloneDeep(company);

			companyRecord.name = data.name;
			companyRecord.address.street = data.address.street;
			companyRecord.address.number = data.address.number;
			companyRecord.address.postalCode = data.address.postalCode;
			companyRecord.address.municipality = data.address.municipality;
			companyRecord.address.country = data.address.country;
			companyRecord.nacebelCodes = data.nacebelCodes.map((code: ICompanyNacebelCode) => {
				code.companyId = company.id;
				return code;
			});
			companyRecord.jointCommittees = data.jointCommittees.map((committee: ICompanyJointCommittee) => {
				committee.companyId = company.id;
				return committee;
			});

			updateCompany(companyRecord);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyWebState.data]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | ComboBoxCloseEvent): void {
		const newCompany: ICompany = handleChange(company, event);
		updateCompany(newCompany);
	}

	function addMutliSelectItem(item: INacebelCode | IJointCommittee, type: "nacebelCode" | "jointCommittee"): void {
		const companyRecord: ICompany = cloneDeep(company);
		let joinObject: ICompanyNacebelCode | ICompanyJointCommittee;
		let joinArray: ICompanyNacebelCode[] | ICompanyJointCommittee[];
		let idField: "nacebelCodeId" | "jointCommitteeId";
		switch (type) {
			case "nacebelCode":
				joinArray = companyRecord.nacebelCodes;
				idField = "nacebelCodeId";
				break;
			case "jointCommittee":
				joinArray = companyRecord.jointCommittees;
				idField = "jointCommitteeId";
				break;
		}
		joinObject = find(joinArray, { [idField]: item.id }) as ICompanyNacebelCode | ICompanyJointCommittee;
		if (joinObject) {
			switch (type) {
				case "nacebelCode":
					(joinObject as ICompanyNacebelCode).nacebelCode = item;
					break;
				case "jointCommittee":
					(joinObject as ICompanyJointCommittee).jointCommittee = item;
					break;
			}
		} else {
			switch (type) {
				case "nacebelCode":
					joinObject = {
						companyId: companyRecord.id,
						nacebelCodeId: item.id,
						nacebelCode: item
					};
					break;
				case "jointCommittee":
					joinObject = {
						companyId: companyRecord.id,
						jointCommitteeId: item.id,
						jointCommittee: item
					};
					break;
			}
			joinArray.push(joinObject as ICompanyNacebelCode & ICompanyJointCommittee);
		}
		updateCompany(companyRecord);
	}

	function updateCompany(newCompany: ICompany): void {
		setCompany(newCompany);
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newCompany: ICompany = cloneDeep(company);
		newCompany.address = address;
		updateCompany(newCompany);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "groupId":
				dispatch = companyGroupDispatch;
				filterEndpoint = Endpoint.CompanyGroups;
				break;
			case "partnerId":
				dispatch = partnerDispatch;
				filterEndpoint = Endpoint.Partners;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function setChild<T extends IEntity>(entity: T, field: keyof ICompany, idField: keyof ICompany): void {
		const newCompany: ICompany = setEntity(company, entity, field, idField);
		updateCompany(newCompany);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const companyRecord: ICompany = cloneDeep(company);

		if (event.value) {
			if (event.target.name === "nacebelCodes") {
				companyRecord.nacebelCodes = [];
				for (const code of event.value) {
					companyRecord.nacebelCodes.push({ companyId: companyRecord.id, nacebelCodeId: code.id, nacebelCode: code });
				}
			} else if (event.target.name === "jointCommittee") {
				companyRecord.jointCommittees = [];
				for (const committee of event.value) {
					companyRecord.jointCommittees.push({ companyId: companyRecord.id, jointCommitteeId: committee.id, jointCommittee: committee });
				}
			}
			setDataChanged(true);
		}

		setCompany(companyRecord);
	}

	function onMultiSelectFilterChange(event: MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let endpoint: Endpoint;
		switch (event.target.name) {
			case "nacebelCodes":
				dispatch = nacebelCodeDispatch;
				endpoint = Endpoint.NacebelCodes;
				break;
			case "jointCommittee":
				dispatch = jointCommitteeDispatch;
				endpoint = Endpoint.JointCommittees;
				break;
		}
		apiService.callApi(dispatch, endpoint, "GET", { search: event.filter.value });
	}

	function onActionButtonClicked(close: boolean, record: ICompany): void {
		if (record && !close) {
			setCompany(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(company.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(company.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(company.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(company.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(company.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(company.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		return messages;
	}

	function getVatInfo(): void {
		const vatNumber: string = company.vatNumber.replace(/[^0-9]/g, "");

		if (vatNumber.length > 8 && vatNumber.length < 11) {
			apiService.callApi(companyWebDispatch, Endpoint.CompanyWeb, "GET", { vatnumber: vatNumber });
		} else {
			reduxDispatch(notify(t("vatNumber"), t("vatNumberFormat"), "success"));
		}
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ICompany>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={company}
			endpoint={Endpoint.Companies}
			entityState={companyState}
			entityType={t("company")}
			dispatch={companyDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={company.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="details" label={t("details")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")} *</span>
									<Input name="name" ref={firstField} value={company.name} onChange={onChange} disabled={readonly} required />
								</label>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("partner")}
									recordId={company.partnerId}
									addScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
									editScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersUpdate) }}
									setEntity={(partner: IPartner) => setChild(partner, "partner", "partnerId")}
									readOnly={readonly}
								>
									<SearchBox
										name="partnerId"
										entities={partnerState.entities}
										isLoading={partnerState.areEntitiesLoading}
										entityId={company.partnerId}
										entity={company.partner}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "partner", "partnerId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									addScreen={{ screen: CompanyGroupEditor, isAllowed: hasPermission(Permission.CompanyGroupsAdd) }}
									editScreen={{ screen: CompanyGroupEditor, isAllowed: hasPermission(Permission.CompanyGroupsUpdate) }}
									fieldLabel={t("group")}
									recordId={company.groupId}
									setEntity={(record: ICompanyGroup) => setChild(record, "group", "groupId")}
									readOnly={readonly}
								>
									<SearchBox
										name="groupId"
										entities={companyGroupState.entities}
										entityId={company.groupId}
										entity={company.group}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "group", "groupId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col d-flex">
								<div className="flex-grow-1" style={{ marginRight: "10px" }}>
									<label className="k-form-field">
										<span>{t("vatNumber")}</span>
										<Input name="vatNumber" value={company.vatNumber} onChange={onChange} disabled={readonly} />
									</label>
								</div>
								<div className="d-flex justify-content-end align-items-end">
									<StandardButton onClick={() => getVatInfo()} type="button" primary disabled={!company.vatNumber} iconClass="las la-search" />
								</div>
							</div>
						</div>
						<AddressEdit address={company.address} addressField="address" onChange={onChange} setAddress={setAddress} required numberRequired={true} readonly={readonly} />
						<label className="k-form-field">
							<span>{t("extra")}</span>
							<TextArea name="extra" value={company.extra} onChange={onChange} disabled={readonly} />
						</label>
						<ManageableMultiSelect
							fieldLabel={t("nacebelCodes")}
							addScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesAdd) }}
							editScreen={{ screen: NacebelCodeEditor, isAllowed: hasPermission(Permission.NacebelCodesUpdate) }}
							setEntity={(record: INacebelCode) => addMutliSelectItem(record, "nacebelCode")}
							name="nacebelCodes"
							data={nacebelCodeState.entities}
							textField="name"
							dataItemKey="id"
							value={map(company.nacebelCodes, "nacebelCode")}
							filterable
							loading={nacebelCodeState.areEntitiesLoading}
							onFilterChange={onMultiSelectFilterChange}
							onChange={onMultiSelectChange}
							disabled={readonly}
							readOnly={readonly}
						/>
						<ManageableMultiSelect
							fieldLabel={t("jointCommittees")}
							addScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesAdd) }}
							editScreen={{ screen: JointCommitteeEditor, isAllowed: hasPermission(Permission.JointCommitteesUpdate) }}
							setEntity={(record: IJointCommittee) => addMutliSelectItem(record, "jointCommittee")}
							name="jointCommittee"
							data={jointCommitteeState.entities}
							textField="name"
							dataItemKey="id"
							value={map(company.jointCommittees, "jointCommittee")}
							filterable
							loading={jointCommitteeState.areEntitiesLoading}
							onFilterChange={onMultiSelectFilterChange}
							onChange={onMultiSelectChange}
							disabled={readonly}
							readOnly={readonly}
						/>
						{!props.hideActive && (
							<div className="k-form-field">
								<div>{t("active")}</div>
								<YesNoSwitch name="active" checked={company.active} onChange={onChange} disabled={readonly} />
							</div>
						)}
					</div>
				</Tab>
				{company.id && hasPermission(Permission.ContactsRead) && (
					<Tab reactKey="contacts" label={t("contacts")}>
						<div className="row">
							<GridPanel
								style={{ height: "500px" }}
								listEndpoint={Endpoint.CompanyContactsList}
								listUrlArguments={{ companyId: company.id }}
								endpoint={Endpoint.CompanyContacts}
								addScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsAdd), extraProps: { companyId: company.id, allowedToEditCompany: false } }}
								editScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsUpdate), extraProps: { companyId: company.id, allowedToEditCompany: false } }}
								delete={{ urlArguments: { companyId: company.id }, isAllowed: hasPermission(Permission.ContactsDelete) }}
								filter={{
									logic: "and",
									filters: [
										{
											field: "active",
											operator: "eq",
											value: true
										}
									]
								}}
								localLoader
							>
								<GridColumn field="fullName" title={t("name")} />
								<GridColumn field="companyHeat" title={t("companyHeat")} cell={customCell(heatCell<IContact>("companyId"))} filter="numeric" />
								<GridColumn field="type.name" title={t("contactType")} />
								<GridColumn field="statute.name" title={t("statute")} />
								<GridColumn field="partner.name" title={t("partner")} />
								<GridColumn field="partnerHeat" title={t("partnerHeat")} cell={customCell(heatCell<IContact>("partnerId"))} filter="numeric" />
								<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
							</GridPanel>
						</div>
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default CompanyEditor;
