import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import ErrorLogViewer from "../../editors/support/errorLogViewer";

const ErrorLog: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel listEndpoint={Endpoint.ErrorLogList} endpoint={undefined} editScreen={{ screen: ErrorLogViewer, isAllowed: true }} delete={false} sort={[{ field: "createdBy", dir: "desc" }]}>
			<GridColumn field="id" title={t("errorLogId")} filter="numeric" />
			<GridColumn field="createdDate" title={t("date")} filter="date" cell={customCell(dateCell("yyyy-MM-dd"))} />
			<GridColumn field="createdBy" title={t("user")} />
			<GridColumn field="source" title={t("source")} />
		</GridPanel>
	);
};

export default ErrorLog;
