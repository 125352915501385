import React, { MutableRefObject, useCallback, useEffect, useReducer, useRef, useState } from "react";

import { ApiCommunicator, useApiService } from "@selas/api-communication";
import { derender, hideLoader as hideGlobalLoader, render, showLoader as showGlobalLoader } from "@selas/state-management";
import { IRoutedTabProps } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import without from "lodash/without";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { initialSettingState } from "../../../../state";
import { settingReducer } from "../../../../state/reducers";
import { ISession } from "../../../../utils/types/models";
import SessionEditor from "../../../editors/project/session";
import Sessions from "./sessions";
import Weekplanning from "./weekplanning";

export interface IPlanningChild {
	refresh: () => void;
}

const Planning: React.FC<IRoutedTabProps> = () => {
	const [loaderKeys, setLoaderKeys] = useState<string[]>([]);
	const [settingOrangeState, settingOrangeDispatch] = useReducer(settingReducer, initialSettingState);
	const [settingRedState, settingRedDispatch] = useReducer(settingReducer, initialSettingState);
	const apiService: ApiCommunicator = useApiService();
	const weekplanningRef: MutableRefObject<IPlanningChild> = useRef<IPlanningChild>();
	const sessionsRef: MutableRefObject<IPlanningChild> = useRef<IPlanningChild>();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		apiService.callApi(settingOrangeDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationOrange" });
		apiService.callApi(settingRedDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationRed" });
	}, [apiService]);

	useEffect(() => {
		if (loaderKeys.length > 0) {
			reduxDispatch(showGlobalLoader());
		} else {
			reduxDispatch(hideGlobalLoader());
		}
	}, [loaderKeys.length, reduxDispatch]);

	const showLoader: (key: string) => void = useCallback((key: string) => {
		setLoaderKeys((prev: string[]) => {
			if (!prev.includes(key)) {
				return [...prev, key];
			}
			return prev;
		});
	}, []);

	const hideLoader: (key: string) => void = useCallback((key: string) => {
		setLoaderKeys((prev: string[]) => {
			if (prev.includes(key)) {
				return without(prev, key);
			}
			return prev;
		});
	}, []);

	function openSession(session: ISession, readOnly: boolean): void {
		const screenKey: string = newKey("sessionScreen");

		reduxDispatch(
			render(screenKey, SessionEditor, {
				recordId: session.id,
				readOnly,
				actionButtonClicked: (close: boolean) => {
					if (close) {
						reduxDispatch(derender(screenKey));
						weekplanningRef.current.refresh();
						sessionsRef.current.refresh();
					}
				},
				projectId: session.projectId
			})
		);
	}

	return (
		<div className="d-flex flex-row w-100">
			<Sessions
				ref={sessionsRef}
				orangeLimit={settingOrangeState.setting?.value}
				redLimit={settingRedState.setting?.value}
				openSession={openSession}
				showLoader={showLoader}
				hideLoader={hideLoader}
			/>
			<Weekplanning
				ref={weekplanningRef}
				orangeLimit={settingOrangeState.setting?.value}
				redLimit={settingRedState.setting?.value}
				openSession={openSession}
				showLoader={showLoader}
				hideLoader={hideLoader}
			/>
		</div>
	);
};

export default Planning;
