import React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { Form, StandardButton, SubmitButton, YesNoSwitch } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

interface IProps {
	onClose: () => void;
	onSave: (deleteInGroups: boolean, deleteInSessions: boolean, deleteInOtherProjects: boolean) => void;
}

const ParticipantStatusEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	let deleteInGroups: boolean = false;
	let deleteInSessions: boolean = false;
	let deleteInOtherProjects: boolean = false;

	function save(): void {
		props.onSave(deleteInGroups, deleteInSessions, deleteInOtherProjects);
	}

	function onChange(event: SwitchChangeEvent): void {
		switch (event.target.props.name) {
			case "deleteInGroups":
				deleteInGroups = event.value;
				break;
			case "deleteInSessions":
				deleteInSessions = event.value;
				break;
			case "deleteInOtherProjects":
				deleteInOtherProjects = event.value;
				break;
		}
	}

	return (
		<Form>
			<Dialog title={t("participantDelete")} width="70%" autoFocus onClose={props.onClose}>
				<div className="k-form">
					<div className="row" style={{ margin: "20px" }}>
						<div className="col">
							<div className="k-form-field">
								<label style={{ margin: "20px" }}>{t("participantDeleteInGroups")}</label>
								<YesNoSwitch name="deleteInGroups" onChange={onChange} value={deleteInGroups} />
							</div>
						</div>
					</div>
					<div className="row" style={{ margin: "20px" }}>
						<div className="col">
							<div className="k-form-field">
								<label style={{ margin: "20px" }}>{t("participantDeleteInSessions")}</label>
								<YesNoSwitch name="deleteInSessions" onChange={onChange} value={deleteInSessions} />
							</div>
						</div>
					</div>
					<div className="row" style={{ margin: "20px" }}>
						<div className="col">
							<div className="k-form-field">
								<label style={{ margin: "20px" }}>{t("participantDeleteInOtherProjects")}</label>
								<YesNoSwitch name="deleteInOtherProjects" onChange={onChange} value={deleteInOtherProjects} />
							</div>
						</div>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={props.onClose}>{t("cancel")}</StandardButton>
					<SubmitButton primary onClick={() => save()}>
						{t("save")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
		</Form>
	);
};

export default ParticipantStatusEditor;
