import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "react";

import Endpoint from "../../services/api/endpoint";
import { ICatalogueService } from "../../utils/types/models";

const catalogueServiceReducer: Reducer<IEntityState<ICatalogueService>, IAction> = (state: IEntityState<ICatalogueService> = getInitialState<ICatalogueService>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_PUT_" + Endpoint.CatalogueServicesUpdatePriority:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_SUCCESS_PUT_" + Endpoint.CatalogueServicesUpdatePriority: {
			const payload: { response: AxiosResponse<ICatalogueService> } = action.payload as { response: AxiosResponse<ICatalogueService> };
			state = {
				...state,
				updatedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_PUT_" + Endpoint.CatalogueServicesUpdatePriority: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				updateError: payload.error
			};

			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.CatalogueServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false
			};

			break;
		case "CLEAR_PUT_" + Endpoint.CatalogueServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false,
				updatedEntity: null,
				updateError: null
			};
			break;
	}
	return state;
};

export default createEntityReducer<ICatalogueService>(Endpoint.CatalogueServices, Endpoint.CatalogueServicesList, catalogueServiceReducer);
