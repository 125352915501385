import React, { Dispatch, ReactElement, useEffect, useReducer, useRef, useState } from "react";

import { DateTimePicker, DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
	ComboBox,
	ComboBoxCloseEvent,
	ComboBoxFilterChangeEvent,
	DropDownList,
	DropDownListChangeEvent,
	ListItemProps,
	MultiSelectChangeEvent,
	MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { derender, getInitialState, render } from "@selas/state-management";
import {
	Confirm,
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	Loader,
	ManageableField,
	ManageableMultiSelect,
	notify,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel,
	TextArea,
	YesNoSwitch
} from "@selas/ui-components";
import { getDate, isNullOrEmpty, newKey } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import map from "lodash/map";
import omit from "lodash/omit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Redux from "redux";
import { v4 as uuidv4 } from "uuid";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialSessionState, initialUserState } from "../../../../state";
import { facilityReducer, groupReducer, locationReducer, sessionFollowUpTaskReducer, sessionReducer, topicReducer, userReducer } from "../../../../state/reducers";
import sessionTemplateReducer from "../../../../state/reducers/sessionTemplateReducer";
import { IApplicationState } from "../../../../store";
import { uriRegex } from "../../../../utils";
import { FollowUpTaskStatus, Permission, SessionStatus } from "../../../../utils/enums";
import { ISessionProps } from "../../../../utils/props";
import {
	IFacility,
	IGroup,
	ILocation,
	ISession,
	ISessionFacility,
	ISessionFollowUpTask,
	ISessionTemplate,
	ISessionTemplateFacility,
	ISessionTemplateFollowUpTask,
	ISessionTemplateTopic,
	ISessionTopic,
	ITopic,
	IUser
} from "../../../../utils/types/models";
import { statusDropdownItemRender } from "../../../global/statusDropdownItemRender";
import { getSessionStatusColor } from "../../../pages/work/sessions";
import SessionTemplateEditor from "../../catalogue/sessionTemplate";
import TopicEditor from "../../masterdata/topic";
import FacilityEditor from "../../resources/facility";
import LocationEditor from "../../resources/location";
import RequestMail from "../../simpleEmailSend/requestMail";
import GroupEditorContent from "../group/editorContent";
import SelectSessionParticipant from "../selectSessionParticipant";
import SessionEmails from "./grids/sessionEmails";
import SessionFollowUpTasks from "./grids/sessionFollowUpTasks";
import SessionNotes from "./grids/sessionNotes";
import SessionParticipants from "./grids/sessionParticipants";

interface IProps {
	projectId: number;
	groupId?: number;
	readOnly?: boolean;
}

const SessionEditor: React.FC<(IAddEntityScreenProps<ISession> | IEditEntityScreenProps<ISession>) & IProps> = (
	props: (IAddEntityScreenProps<ISession> | IEditEntityScreenProps<ISession>) & IProps
) => {
	const { t } = useTranslation();
	const [sessionTemplate, setSessionTemplate] = useState<ISessionTemplate>();
	const [sessionTemplateSession, sessionTemplateDispatch] = useReducer(sessionTemplateReducer, getInitialState<ISessionTemplate>());
	const firstField: React.RefObject<ComboBox> = useRef<ComboBox>();
	const apiService: ApiCommunicator = useApiService();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [timestamp, setTimestamp] = useState("");
	const [sessionfollowUpTasksState, sessionFollowUpTasksDispatch] = useReducer(sessionFollowUpTaskReducer, getInitialState<ISessionFollowUpTask>());
	const [template, setTemplate] = useState(false);

	const initialSession: ISession = {
		id: (props as IEditEntityScreenProps<ISession>).recordId || 0,
		projectId: props.projectId,
		groupId: props.groupId || null,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		name: "",
		description: "",
		isCollective: true,
		isOnSite: false,
		status: SessionStatus.New,
		maximumParticipantCount: 0,
		surveyLink: "",
		facilities: [],
		topics: [],
		sessionParticipants: [],
		sendSurveyOnClose: true
	};

	const [session, setSession] = useState<ISession>(initialSession);
	const [sessionAfterCustomUpdate, setSessionAfterCustomUpdate] = useState<ISession>();
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
	const [locationState, locationDispatch] = useReducer(locationReducer, getInitialState<ILocation>());
	const [facilityState, facilityDispatch] = useReducer(facilityReducer, getInitialState<IFacility>());
	const [allFacilitiesState, allFacilitiesDispatch] = useReducer(facilityReducer, getInitialState<IFacility>());
	const [topicState, topicDispatch] = useReducer(topicReducer, getInitialState<ITopic>());
	const [allTopicsState, allTopicsDispatch] = useReducer(topicReducer, getInitialState<ITopic>());
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const [groupState, groupDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [sessionFollowUpTasks, setSessionFollowUpTasks] = useState([]);

	const reduxDispatch: Redux.Dispatch = useDispatch();

	useEffect(() => {
		if (hasPermission(Permission.ProjectsUpdate, Permission.SessionsUpdate)) {
			apiService.callApi(groupDispatch, Endpoint.Groups, "GET", { projectId: props.projectId });
			apiService.callApi(allFacilitiesDispatch, Endpoint.Facilities, "GET", { search: "" });
			apiService.callApi(allTopicsDispatch, Endpoint.Topics, "GET", { search: "" });
		}
	}, [apiService, props.projectId]);

	useEffect(() => {
		if (!sessionfollowUpTasksState.isUpdating && sessionfollowUpTasksState.updatedEntity) {
			props.actionButtonClicked(false, sessionAfterCustomUpdate);
		}
	}, [props, sessionAfterCustomUpdate, sessionfollowUpTasksState.isUpdating, sessionfollowUpTasksState.updatedEntity]);

	useEffect(() => {
		if (sessionState.entity) {
			setSession(sessionState.entity);
			setTimestamp(sessionState.entity.timestamp);
		}
	}, [sessionState.entity]);

	useEffect(() => {
		if (groupState.entities && groupState.entities.length === 1 && !session.groupId) {
			setSession((oldSession: ISession) => ({
				...oldSession,
				groupId: groupState.entities[0].id
			}));
		}
	}, [groupState.entities, session.groupId]);

	function onChange(
		event: InputChangeEvent | SwitchChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent | DateTimePickerChangeEvent
	): void {
		session.timestamp = timestamp;
		const newSession: ISession = handleChange(session, event);
		if (event.target.name === "isCollective" && event.target.value === false) {
			const selectParticipantKey: string = newKey("selectParticipant");
			reduxDispatch(
				render(selectParticipantKey, SelectSessionParticipant, {
					projectId: props.projectId,
					sessionId: session.id,
					onSave: (sessionParticipantId: number) => {
						apiService.callApi(sessionDispatch, Endpoint.SessionsSetToIndividual, "PUT", { projectId: props.projectId, id: session.id }, { session: newSession, sessionParticipantId });
						reduxDispatch(derender(selectParticipantKey));
					},
					onCancel: () => reduxDispatch(derender(selectParticipantKey))
				})
			);
		} else {
			if (event.target.name === "plannedStart" && event.target.value) {
				newSession.plannedStop = event.target.value;
				newSession.plannedStop?.setHours(0);
				newSession.plannedStop?.setMinutes(0);
				newSession.plannedStop?.setSeconds(0);
			}
			setSession(newSession);
			setDataChanged(true);
		}
	}

	function fillInSessionTemplateValues(newSessionTemplate: ISessionTemplate): void {
		setTemplate(true);
		const newSession: ISession = {
			...newSessionTemplate,
			facilities: map(newSessionTemplate.facilities, (facility: ISessionTemplateFacility) => ({
				sessionId: session.id,
				facilityId: facility.facilityId,
				facility: facility.facility
			})),
			topics: map(newSessionTemplate.topics, (topic: ISessionTemplateTopic) => ({
				sessionId: session.id,
				topicId: topic.topicId,
				topic: topic.topic
			})),
			id: session.id,
			projectId: session.projectId,
			project: session.project,
			responsibleUserId: session.responsibleUserId,
			responsibleUser: session.responsibleUser,
			groupId: session.groupId,
			locationId: session.locationId,
			location: session.location,
			plannedStart: session.plannedStart,
			plannedStop: session.plannedStop,
			actualStart: session.actualStart,
			actualStop: session.actualStop,
			sessionParticipants: session.sessionParticipants,
			status: session.status,
			zoomSessionId: session.zoomSessionId,
			timestamp: session.timestamp,
			createdBy: session.createdBy,
			createdDate: session.createdDate,
			sessionInvite: session.sessionInvite,
			surveyLink: session.surveyLink,
			sendSurveyOnClose: true
		};
		if (session.groupId === null) {
			newSession.groupId = groupState.entities[0].id;
		}
		const sessionFollowUpTasksToAdd: ISessionFollowUpTask[] = [];

		newSessionTemplate.followUpTasks?.forEach((templateTask: ISessionTemplateFollowUpTask) => {
			const sessionFollowUpTask: ISessionFollowUpTask = {
				sessionId: session.id,
				projectId: session.projectId,
				outlookMeetingId: session.sessionInvite?.outlookMeetingId,
				status: FollowUpTaskStatus.New,
				subject: templateTask.subject,
				checkList: templateTask.checkList,
				id: 0,
				creator: currentUser,
				creatorId: currentUser.id
			};
			sessionFollowUpTasksToAdd.push(sessionFollowUpTask);
		});
		setSessionFollowUpTasks(sessionFollowUpTasksToAdd);
		if (session.id === 0) {
			apiService.callApi(sessionDispatch, Endpoint.Sessions, "POST", { projectId: props.projectId }, newSession);
		} else {
			apiService.callApi(sessionDispatch, Endpoint.Sessions, "PUT", { projectId: props.projectId, id: session.id }, newSession);
		}
		updateSession(newSession);
	}

	function onSessionTemplateChange(newSessionTemplate: ISessionTemplate): void {
		if (isEmpty(groupState.entities) && isNull(session.groupId)) {
			reduxDispatch(
				notify(
					t("information"),
					<>
						<React.Fragment key={"noGroup"}>
							{t("noGroupForSession")}
							<br />
						</React.Fragment>
					</>,
					"success"
				)
			);
			setSessionTemplate(null);
		} else {
			setSessionTemplate(newSessionTemplate);
			if (newSessionTemplate) {
				const propertiesToIgore: (keyof ISession)[] = [
					"projectId",
					"project",
					"responsibleUserId",
					"responsibleUser",
					"groupId",
					"plannedStart",
					"plannedStop",
					"actualStart",
					"actualStop",
					"sessionParticipants",
					"status",
					"zoomSessionId"
				];
				if (isEqual(omit(session, propertiesToIgore), omit(initialSession, propertiesToIgore))) {
					fillInSessionTemplateValues(newSessionTemplate);
				} else {
					const confirmKey: string = newKey("Confirm");
					reduxDispatch(
						render(confirmKey, Confirm, {
							children: t("confirm_overwrite_session_template"),
							onConfirm: () => {
								reduxDispatch(derender(confirmKey));
								fillInSessionTemplateValues(newSessionTemplate);
							},
							onDecline: () => reduxDispatch(derender(confirmKey))
						})
					);
				}
			}
		}
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const newSession: ISession = cloneDeep(session);
		if (event.value) {
			if (event.target.name === "facilities") {
				newSession.facilities = [];
				for (const facility of event.value) {
					newSession.facilities.push({
						sessionId: newSession.id,
						facilityId: facility.id,
						facility
					});
				}
			} else if (event.target.name === "topics") {
				newSession.topics = [];
				for (const topic of event.value) {
					newSession.topics.push({
						sessionId: newSession.id,
						topicId: topic.id,
						topic
					});
				}
			}
			updateSession(newSession);
		}
	}

	function addOrUpdateFacility(facility: IFacility): void {
		const newSession: ISession = cloneDeep(session);
		const existingSessionFacility: ISessionFacility = find(newSession.facilities, { facilityId: facility.id });
		if (existingSessionFacility) {
			existingSessionFacility.facility = facility;
		} else {
			newSession.facilities.push({
				sessionId: newSession.id,
				facilityId: facility.id,
				facility
			});
		}
		updateSession(newSession);
	}

	function addOrUpdateTopic(topic: ITopic): void {
		const newSession: ISession = cloneDeep(session);
		const existingSessionTopic: ISessionTopic = find(newSession.topics, { topicId: topic.id });
		if (existingSessionTopic) {
			existingSessionTopic.topic = topic;
		} else {
			newSession.topics.push({
				sessionId: newSession.id,
				topicId: topic.id,
				topic
			});
		}
		updateSession(newSession);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof ISession, idField: keyof ISession): void {
		const newSession: ISession = setEntity(session, entity, field, idField);
		updateSession(newSession);
	}

	function generateSurveyLink(): void {
		const newSession: ISession = cloneDeep(session);
		newSession.surveyLink = window.location.origin + "/public/#/sessionfeedback/" + uuidv4();
		updateSession(newSession);
	}

	function generateLinkButtonClicked(): void {
		if (!isNullOrEmpty(session.surveyLink)) {
			const confirmKey: string = newKey("confirmOverwrite");
			reduxDispatch(
				render(confirmKey, Confirm, {
					children: t("confirm_overwrite_field", { field: t("surveyLink").toLowerCase() }),
					onConfirm: () => {
						generateSurveyLink();
						reduxDispatch(derender(confirmKey));
					},
					onDecline: () => reduxDispatch(derender(confirmKey))
				})
			);
		} else {
			generateSurveyLink();
		}
	}

	function updateSession(newSession: ISession): void {
		newSession.timestamp = timestamp;
		setSession(newSession);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "sessionTemplate":
				dispatch = sessionTemplateDispatch;
				filterEndpoint = Endpoint.SessionTemplates;
				break;
			case "locationId":
				dispatch = locationDispatch;
				filterEndpoint = Endpoint.Locations;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
			case "facilities":
				dispatch = facilityDispatch;
				filterEndpoint = Endpoint.Facilities;
				break;
			case "topics":
				dispatch = topicDispatch;
				filterEndpoint = Endpoint.Topics;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(session.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!session.responsibleUserId) {
			messages.push(t("fill_in_required_field", { field: t("coach").toLowerCase() }));
		}
		if (!session.groupId) {
			messages.push(t("fill_in_required_field", { field: t("group").toLowerCase() }));
		}
		if (!isNullOrEmpty(session.surveyLink) && !session.surveyLink.match(uriRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("surveyLink").toLowerCase(), example: "https://www.survey.com" }));
		}
		if (session.plannedStop && session.plannedStart && getDate(session.plannedStop) < getDate(session.plannedStart)) {
			messages.push(t("fill_in_correct_planned_hours", { begin: t("plannedStart").toLowerCase(), end: t("plannedStop").toLowerCase() }));
		}
		if (session.actualStop && session.actualStart && getDate(session.actualStop) < getDate(session.actualStart)) {
			messages.push(t("fill_in_correct_planned_hours", { begin: t("actualStart").toLowerCase(), end: t("actualStop").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: ISession): void {
		if (record && !close) {
			setTimestamp(record.timestamp);
			setSession(record);
			if (record.facilities) {
				record.facilities.forEach((f: ISessionFacility) => addOrUpdateFacility(allFacilitiesState.entities.find((fac: IFacility) => fac.id === f.facilityId)));
			}
			if (record.topics) {
				record.topics.forEach((st: ISessionTopic) => addOrUpdateTopic(allTopicsState.entities.find((top: ITopic) => top.id === st.topicId)));
			}
			if (template) {
				addTasksToSessionFromTemplate(record.id, sessionFollowUpTasks);
				setSessionAfterCustomUpdate(record);
			}
			setDataChanged(false);
			record.timestamp = timestamp;
		}
		if (!template) {
			props.actionButtonClicked(close, record);
		}
	}

	function addTasksToSessionFromTemplate(sessionId: number, sessionFollowUpTasksToSend: ISessionFollowUpTask[]): void {
		apiService.callApi(sessionFollowUpTasksDispatch, Endpoint.SessionFollowUpTasksPostMultiple, "PUT", { projectId: props.projectId, sessionId }, sessionFollowUpTasksToSend);
		setTemplate(false);
	}

	function renderSessionStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getSessionStatusColor);
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: ISessionProps = {
			sessionId: session.id,
			projectId: props.projectId,
			outlookMeetingId: session.sessionInvite?.outlookMeetingId
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						// console.log("email");
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	// const readonly: boolean = (props as IEditEntityScreenProps<ISession>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={session}
			endpoint={Endpoint.Sessions}
			extraUrlParams={{ projectId: props.projectId }}
			entityState={sessionState}
			entityType={t("session")}
			dispatch={sessionDispatch}
			dataChanged={dataChanged}
			readonly={props.readOnly}
			recordName={session.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			{(sessionState.isEntityLoading || sessionState.isAdding || sessionState.isUpdating || sessionfollowUpTasksState.isUpdating) && <Loader />}
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						{hasPermission(Permission.ProjectsUpdate, Permission.SessionsUpdate) && session.id > 0 && !props.readOnly && (
							<div className="k-form-field" style={{ padding: "5px 0px 20px 0px" }}>
								<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
									{t("sendEmail")}
								</StandardButton>
							</div>
						)}
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("sessionTemplate")}
									recordId={sessionTemplate?.id}
									addScreen={{ screen: SessionTemplateEditor, isAllowed: hasPermission(Permission.SessionTemplatesAdd) }}
									editScreen={{ screen: SessionTemplateEditor, isAllowed: hasPermission(Permission.SessionTemplatesUpdate) }}
									setEntity={(newSessionTemplate: ISessionTemplate) => onSessionTemplateChange(newSessionTemplate)}
									readOnly={props.readOnly}
								>
									<SearchBox
										name="sessionTemplate"
										entities={sessionTemplateSession.entities}
										isLoading={sessionTemplateSession.areEntitiesLoading}
										entityId={sessionTemplate?.id}
										entity={sessionTemplate}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={(event: ComboBoxCloseEvent) => onSessionTemplateChange(event.target.value)}
										onClear={() => setSessionTemplate(undefined)}
										disabled={props.readOnly}
										myRef={firstField}
									/>
								</ManageableField>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")} *</span>
									<Input name="name" value={session.name} onChange={onChange} required disabled={props.readOnly} />
								</label>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("sessionStatus")} *</span>
									<EnumDropDownList
										name="status"
										value={session.status}
										onChange={onChange}
										enum={SessionStatus}
										itemRender={renderSessionStatus}
										required
										disabled={props.readOnly}
									/>
								</div>
							</div>
							<div className="col-2">
								<div className="k-form-field">
									<div>{t("sendSurveyOnClose")}</div>
									<YesNoSwitch name="sendSurveyOnClose" checked={session.sendSurveyOnClose} onChange={onChange} disabled={props.readOnly} />
								</div>
							</div>
							<div className="col-4">
								<div className="k-form-field">
									<span>{t("coach")} *</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={session.responsibleUserId}
										entity={session.responsibleUser}
										required
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "responsibleUser", "responsibleUserId")}
										disabled={props.readOnly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("location")}
									recordId={session.locationId}
									addScreen={{ screen: LocationEditor, isAllowed: hasPermission(Permission.LocationsAdd) }}
									editScreen={{ screen: LocationEditor, isAllowed: hasPermission(Permission.LocationsUpdate) }}
									setEntity={(location: ILocation) => setChild(location, "location", "locationId")}
									readOnly={props.readOnly}
								>
									<SearchBox
										name="locationId"
										entities={locationState.entities}
										isLoading={locationState.areEntitiesLoading}
										entityId={session.locationId}
										entity={session.location}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "location", "locationId")}
										disabled={props.readOnly}
									/>
								</ManageableField>
							</div>
							{!props.groupId && !session.id && (
								<div className="col">
									<div className="k-form-field">
										<span>{t("group")} *</span>
										<DropDownList
											name="groupId"
											loading={groupState.areEntitiesLoading}
											data={groupState.entities}
											value={find(groupState.entities, { id: session.groupId })}
											dataItemKey="id"
											textField="name"
											onChange={onChange}
											disabled={props.readOnly}
											required
										/>
									</div>
								</div>
							)}

							<div className="col">
								<label className="k-form-field">
									<span>{t("zoomSessionId")}</span>
									<Input name="zoomSessionId" value={session.zoomSessionId} onChange={onChange} disabled={props.readOnly} />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("maximumParticipantCount")}</span>
									<NumericTextBox name="maximumParticipantCount" value={session.maximumParticipantCount} onChange={onChange} format="n0" min={0} disabled={props.readOnly} />
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("surveyLink")}</span>
									<div className="d-flex">
										<Input name="surveyLink" value={session.surveyLink} onChange={onChange} pattern={uriRegex.source} disabled={props.readOnly} />
										<StandardButton
											title={t("generateLink")}
											primary
											iconClass="las la-link"
											style={{ marginLeft: "10px" }}
											onClick={generateLinkButtonClicked}
											disabled={props.readOnly}
										/>
									</div>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("description")}</span>
									<TextArea name="description" value={session.description} onChange={onChange} disabled={props.readOnly} />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("facilities")}
									addScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesAdd) }}
									editScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesUpdate) }}
									setEntity={addOrUpdateFacility}
									name="facilities"
									data={facilityState.entities}
									textField="name"
									dataItemKey="id"
									value={map(session.facilities, "facility")}
									loading={facilityState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									autoClose={false}
									filterable
									onFilterChange={onFilterChange}
									disabled={props.readOnly}
									readOnly={props.readOnly}
								/>
							</div>
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("topics")}
									addScreen={{ screen: TopicEditor, isAllowed: hasPermission(Permission.TopicsAdd) }}
									editScreen={{ screen: TopicEditor, isAllowed: hasPermission(Permission.TopicsUpdate) }}
									setEntity={addOrUpdateTopic}
									name="topics"
									data={topicState.entities}
									textField="name"
									dataItemKey="id"
									value={map(session.topics, "topic")}
									loading={topicState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									autoClose={false}
									filterable
									onFilterChange={onFilterChange}
									disabled={props.readOnly}
									readOnly={props.readOnly}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("plannedStart")}</span>
									<DateTimePicker name="plannedStart" value={getDate(session.plannedStart)} onChange={onChange} format={"yyyy-MM-dd HH:mm"} disabled={props.readOnly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("plannedStop")}</span>
									<DateTimePicker name="plannedStop" value={getDate(session.plannedStop)} onChange={onChange} format={"yyyy-MM-dd HH:mm"} disabled={props.readOnly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("actualStart")}</span>
									<DateTimePicker name="actualStart" value={getDate(session.actualStart)} onChange={onChange} format={"yyyy-MM-dd HH:mm"} disabled={props.readOnly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("actualStop")}</span>
									<DateTimePicker name="actualStop" value={getDate(session.actualStop)} onChange={onChange} format={"yyyy-MM-dd HH:mm"} disabled={props.readOnly} />
								</div>
							</div>
						</div>
						{session.id > 0 && (
							<div className="k-form-field">
								<div>{t("isCollective")}</div>
								<YesNoSwitch name="isCollective" checked={session.isCollective} onChange={onChange} disabled={props.readOnly} />
							</div>
						)}
						<div className="k-form-field">
							<div>{t("isOnSite")}</div>
							<YesNoSwitch name="isOnSite" checked={session.isOnSite} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
				</Tab>
				{session.groupId && find(groupState.entities, { id: session.groupId }) && (
					<Tab reactKey="group" label={t("groupInfo")}>
						<div className="k-form">
							<GroupEditorContent group={find(groupState.entities, { id: session.groupId })} readonly={true} />
						</div>
					</Tab>
				)}
				{session.id > 0 && (
					<Tab reactKey="participants" label={t("participants")}>
						<SessionParticipants projectId={props.projectId} sessionId={session.id} sessionState={sessionState} sessionDispatch={sessionDispatch} readOnly={props.readOnly} />
					</Tab>
				)}
				{session.id > 0 && (
					<Tab reactKey="tasks" label={t("tasks")}>
						<SessionFollowUpTasks
							projectId={props.projectId}
							sessionId={session.id}
							outlookMeetingId={session.sessionInvite?.outlookMeetingId}
							sessionFollowUpTasksState={sessionfollowUpTasksState}
							sessionFollowUpTasksDispatch={sessionFollowUpTasksDispatch}
							readOnly={props.readOnly}
						/>
					</Tab>
				)}
				{session.id > 0 && (
					<Tab reactKey="notes" label={t("notes")}>
						<SessionNotes sessionId={session.id} readOnly={props.readOnly} />
					</Tab>
				)}
				{session.id > 0 && (
					<Tab reactKey="emails" label={t("emails")}>
						<SessionEmails projectId={props.projectId} sessionId={session.id} outlookMeetingId={session.sessionInvite?.outlookMeetingId} readOnly={props.readOnly} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default SessionEditor;
