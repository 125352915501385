import React, { useState } from "react";

import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Form, notify, StandardButton, SubmitButton } from "@selas/ui-components";
import { isNull, map } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface IProps {
	onClose: () => void;
	onSave: (invoiceNumber: string, invoiceDate: Date) => void;
}

const InvoiceEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [invoiceDate, setInvoiceDate] = useState(null);

	function save(): void {
		if (getErrorMessages()) {
			props.onSave(invoiceNumber, invoiceDate);
		}
	}

	function onChange(event: InputChangeEvent | DatePickerChangeEvent): void {
		switch (event.target.props.name) {
			case "invoiceNumber":
				setInvoiceNumber(event.value as string);
				break;
			case "invoiceDate":
				setInvoiceDate(event.value as Date);
				break;
		}
	}

	function getErrorMessages(): boolean {
		const messages: string[] = [];
		if (invoiceNumber === "") {
			messages.push(t("fill_in_required_field", { field: t("invoicingNumber").toLowerCase() }));
		}
		if (!invoiceDate) {
			messages.push(t("fill_in_required_field", { field: t("invoicingDate").toLowerCase() }));
		}
		if (!isNull(invoiceDate) && invoiceDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("invoicingDate").toLowerCase() }));
		}
		if (messages.length > 0) {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(messages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
			return false;
		}
		return true;
	}

	return (
		<Form>
			<Dialog title={t("invoicingDetails")} width="70%" autoFocus onClose={props.onClose}>
				<div className="k-form">
					<div className="row">
						<div className="col">
							<div className="k-form-field">
								<label>{t("invoicingNumber")} *</label>
								<Input name="invoiceNumber" onChange={onChange} required />
							</div>
						</div>
						<div className="col">
							<div className="k-form-field">
								<label>{t("invoicingDate")} *</label>
								<DatePicker name="invoiceDate" format={"yyyy-MM-dd"} onChange={onChange} required />
							</div>
						</div>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={props.onClose}>{t("cancel")}</StandardButton>
					<SubmitButton primary onClick={() => save()}>
						{t("save")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
		</Form>
	);
};

export default InvoiceEditor;
