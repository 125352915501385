import { Reducer } from "react";

import { IAction } from "@selas/models";
import { createEntityReducer, IEntityState } from "@selas/state-management";

import Endpoint from "../../services/api/endpoint";
import { IProjectParticipant } from "../../utils/types/models";

const generalProjectParticipantReducer: Reducer<IEntityState<IProjectParticipant>, IAction> = createEntityReducer<IProjectParticipant>(
	Endpoint.GeneralProjectParticipants,
	Endpoint.GeneralProjectParticipantsList
);

export default createEntityReducer<IProjectParticipant>(Endpoint.ProjectParticipants, Endpoint.ProjectParticipantsList, generalProjectParticipantReducer);
