import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import round from "lodash/round";

import { Color } from "../../../utils/enums";

import styles from "./colorCell.module.scss";

type cellContentsFunction = (props: GridCellProps) => React.ReactNode;
type getColorFunction = (props: GridCellProps) => Color;
type classNameFunction = (props: GridCellProps) => string;

export function colorCell(showContent: boolean = false, getContent?: cellContentsFunction, getColor?: getColorFunction): cellContentsFunction {
	function ColorCell(props: GridCellProps): React.ReactNode {
		let colorClass: string;
		const color: Color = getColor ? getColor(props) : props.dataItem[props.field];
		switch (color) {
			case Color.Green:
				colorClass = "las la-thumbs-up greenStatus";
				break;
			case Color.Orange:
				colorClass = "las la-hand-point-right orangeStatus";
				break;
			case Color.Red:
				colorClass = "las la-thumbs-down redStatus";
				break;
			case Color.Blue:
				colorClass = "las la-external-link-alt blueStatus";
				break;
			case Color.Grey:
				colorClass = "las la-clock greyStatus";
				break;
			default:
				colorClass = "";
				break;
		}

		return (
			<>
				<i className={`${colorClass} ${showContent && styles.colorStatus}`} />
				{showContent && (getContent ? getContent(props) : props.dataItem[props.field])}
			</>
		);
	}

	return ColorCell;
}

export function occupationColorCell(showContent: boolean = false, settingOrange: string, settingRed: string): cellContentsFunction {
	function OccupationColorCell(props: GridCellProps): React.ReactNode {
		let colorClass: string = "las la-check greenStatus";

		const limitOrange: number = parseFloat(settingOrange);
		const limitRed: number = parseFloat(settingRed);

		if (limitOrange === 0 || limitRed === 0) {
			colorClass = "las la-question greyStatus";
			return (
				<>
					<i className={`${colorClass} ${showContent && styles.colorStatus}`} />
					{showContent && "- %"}
				</>
			);
		}

		if (props.dataItem[props.field] < limitOrange) {
			colorClass = "las la-check greenStatus";
		} else if (props.dataItem[props.field] >= limitOrange && props.dataItem[props.field] < limitRed) {
			colorClass = "las la-exclamation orangeStatus";
		} else if (props.dataItem[props.field] >= limitRed) {
			colorClass = "las la-ban redStatus";
		}

		if (props.dataItem[props.field] === undefined) {
			colorClass = "las la-question greyStatus";
		}

		return (
			<>
				<i className={`${colorClass} ${showContent && styles.colorStatus}`} />
				{showContent && (props.dataItem[props.field] !== undefined ? round(props.dataItem[props.field], 2) : "-") + " %"}
			</>
		);
	}

	return OccupationColorCell;
}

export const colorCellClassName: classNameFunction = () => "d-flex align-items-center";
