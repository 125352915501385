import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import { IContact } from "../../../utils/types/models";
import ContactEditor from "../../editors/customer/contact";
import { heatCell } from "../../global/heat";

const Contacts: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.ContactsList}
			endpoint={Endpoint.Contacts}
			addScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsAdd), extraProps: { allowedToEditCompany: true, allowedToEditPartner: true } }}
			editScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsUpdate), extraProps: { allowedToEditCompany: true, allowedToEditPartner: true } }}
			delete={{ isAllowed: hasPermission(Permission.ContactsDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="fullName" title={t("name")} />
			<GridColumn field="type.name" title={t("contactType")} />
			<GridColumn field="statute.name" title={t("statute")} />
			<GridColumn field="company.name" title={t("company")} />
			<GridColumn field="companyHeat" title={t("companyHeat")} cell={customCell(heatCell<IContact>("companyId"))} filter="numeric" />
			<GridColumn field="partner.name" title={t("partner")} />
			<GridColumn field="partnerHeat" title={t("partnerHeat")} cell={customCell(heatCell<IContact>("partnerId"))} filter="numeric" />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default Contacts;
