import React from "react";

import { DatePicker, DatePickerProps } from "@progress/kendo-react-dateinputs";
import { getDate } from "@selas/utils";
import { useTranslation } from "react-i18next";

interface IProps {
	label: string;
}

const DatePickerForDateOnly: React.FC<DatePickerProps & IProps> = (props: DatePickerProps & IProps) => {
	const { t } = useTranslation();

	function convertDate(d: Date | string): Date {
		if (typeof d === "string") {
			const birthDate: Date = new Date(d);
			birthDate.setHours(birthDate.getHours() + 10);
			return birthDate;
		}
		return getDate(d);
	}

	return (
		<>
			<span>{`${t(props.label)} ${props.required ? " *" : ""}`}</span>
			<DatePicker {...props} value={convertDate(props.value)} />
		</>
	);
};

export default DatePickerForDateOnly;
