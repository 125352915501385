import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, render } from "@selas/state-management";
import { EntityEditor, IAddEntityScreenProps, IEditEntityScreenProps, notify, SearchBox, StandardButton, Tab, TabPanel, TranslatedSwitch } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import { Button } from "@progress/kendo-react-buttons";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import Endpoint from "../../../../services/api/endpoint";
import { groupReducer, projectParticipantReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { GroupType, Permission } from "../../../../utils/enums";
import { IGroup, IGroupProjectParticipant, IProjectParticipant, IUser } from "../../../../utils/types/models";
import GroupEditorContent from "./editorContent";
import GroupFollowUpTasks from "./grids/groupFollowUpTasks";
import GroupSessions from "./grids/groupSessions";

import { hasPermission } from "../../../../services/authentication";
import { IGroupProps } from "../../../../utils/props";
import RequestMail from "../../simpleEmailSend/requestMail";
import GroupEmails from "./grids/groupEmails";
import ImportFromOtherGroup from "./importFromOtherGroup";

import style from "../../../../assets/editor.module.scss";
import GroupNotes from "./grids/groupNotes";

interface IProps {
	projectId: number;
}

const GroupEditor: React.FC<(IAddEntityScreenProps<IGroup> | IEditEntityScreenProps<IGroup>) & IProps> = (props: (IAddEntityScreenProps<IGroup> | IEditEntityScreenProps<IGroup>) & IProps) => {
	const { t } = useTranslation();
	const firstField: React.MutableRefObject<Input> = useRef();
	const apiService: ApiCommunicator = useApiService();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ReduxDispatch = useDispatch();

	const initialGroup: IGroup = {
		id: (props as IEditEntityScreenProps<IGroup>).recordId || 0,
		projectId: props.projectId,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		name: "",
		description: "",
		code: "",
		maximumParticipantCount: 0,
		type: GroupType.Travvant,
		sessions: [],
		projectParticipants: [],
		active: true
	};

	const [group, setGroup] = useState<IGroup>(initialGroup);
	const [dataChanged, setDataChanged] = useState<boolean>(false);

	const [groupState, groupDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [projectParticipantsState, projectParticipantsDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());
	const [otherParticipantsState, otherParticipantsDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());

	useEffect(() => {
		apiService.callApi(projectParticipantsDispatch, Endpoint.ProjectParticipantsList, "POST", { projectId: props.projectId }, {});
	}, [apiService, props.projectId]);

	useEffect(() => {
		if (groupState.entity) {
			setGroup(groupState.entity);
		}
	}, [groupState.entity]);

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(group.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!group.responsibleUserId) {
			messages.push(t("fill_in_required_field", { field: t("groupResponsible").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IGroup): void {
		if (record && !close) {
			setGroup(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function hasEntity<T>(entities: T[], match: (entity: T) => boolean): boolean {
		if (entities) {
			const matchedParticipant: T = entities.find(match);
			return matchedParticipant ? true : false;
		}
		return false;
	}

	function hasParticipant(participantId: number): boolean {
		return hasEntity<IGroupProjectParticipant>(group.projectParticipants, (projPart: IGroupProjectParticipant) => projPart.projectParticipantId === participantId);
	}

	function onChangeParticipant(event: SwitchChangeEvent): void {
		const groupRecord: IGroup = cloneDeep(group);
		const participantId: number = parseInt(event.target.name, 0);
		if (event.value) {
			groupRecord.projectParticipants.push({ projectParticipantId: participantId, groupId: groupRecord.id });
			setDataChanged(true);
		} else {
			const matchedParticipant: IGroupProjectParticipant = groupRecord.projectParticipants.find((gp: IGroupProjectParticipant) => gp.projectParticipantId === participantId);
			if (matchedParticipant) {
				groupRecord.projectParticipants.splice(groupRecord.projectParticipants.indexOf(matchedParticipant), 1);
				setDataChanged(true);
			}
		}
		setGroup(groupRecord);
	}

	function addOtherParticipant(event: ComboBoxCloseEvent): void {
		const groupRecord: IGroup = cloneDeep(group);
		if (event.target.value) {
			if (find(groupRecord.projectParticipants, ["projectParticipantId", event.target.value.id]) === undefined) {
				groupRecord.projectParticipants.push({ projectParticipantId: event.target.value.id, projectParticipant: event.target.value, groupId: groupRecord.id });
				updateGroup(groupRecord);
			}
		}
	}

	function onRemove(p: IGroupProjectParticipant): void {
		const groupRecord: IGroup = cloneDeep(group);
		const indexOfP: number = groupRecord.projectParticipants.findIndex((gpp: IGroupProjectParticipant) => {
			return gpp.projectParticipantId === p.projectParticipantId;
		});
		groupRecord.projectParticipants.splice(indexOfP, 1);
		updateGroup(groupRecord);
	}

	function updateGroup(newGroup: IGroup): void {
		setGroup(newGroup);
		setDataChanged(true);
	}

	function participantItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, `${itemProps.dataItem.participant.fullName} ${itemProps.dataItem.project ? "(" + itemProps.dataItem.project.title + ")" : ""}`);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(otherParticipantsDispatch, Endpoint.GeneralProjectParticipants, "GET", { search: event.filter.value, projectId: undefined });
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: IGroupProps = {
			groupId: group.id,
			projectId: props.projectId
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						// console.log("email");
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	function importFromOtherGroup(): void {
		const key: string = newKey("importFromOtherGroup");
		reduxDispatch(
			render(key, ImportFromOtherGroup, {
				onClose: (participants?: IProjectParticipant[]) => {
					if (!isEmpty(participants)) {
						const newGroup: IGroup = cloneDeep(group);
						forEach(participants, (newPart: IProjectParticipant) => {
							newGroup.projectParticipants.push({ projectParticipantId: newPart.id, projectParticipant: newPart, groupId: newGroup.id });
						});
						setGroup(newGroup);
						setDataChanged(true);
						reduxDispatch(notify(t("reminder"), t("inviteReminderMessageGroup"), "success"));
					}
					reduxDispatch(derender(key));
				},
				group,
				projectId: props.projectId
			})
		);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IGroup>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={group}
			endpoint={Endpoint.Groups}
			extraUrlParams={{ projectId: props.projectId }}
			entityState={groupState}
			entityType={t("group")}
			dispatch={groupDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={group.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						{hasPermission(Permission.ProjectsUpdate, Permission.GroupsUpdate) && group.id > 0 && (
							<div className="k-form-field" style={{ padding: "5px 0px 20px 0px" }}>
								<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
									{t("sendEmail")}
								</StandardButton>
							</div>
						)}
						<GroupEditorContent group={group} firstField={firstField} updateGroup={updateGroup} readonly={readonly} />
					</div>
				</Tab>
				{group.id > 0 && (
					<Tab reactKey="participants" label={t("participants")}>
						<div className="k-form">
							<div className="row" style={{ marginBottom: "10px" }}>
								<div className="col">
									<div className="d-flex" style={{ fontWeight: "bold" }}>
										{t("project")}
									</div>
								</div>
								<div className="col">
									<div className="row">
										<div className="col" style={{ fontWeight: "bold" }}>
											{t("otherParticipants")}
										</div>
										<div className="col">
											<SearchBox
												name="otherParticipantId"
												entities={filter(otherParticipantsState.entities, (op: IProjectParticipant) => {
													return op.projectId !== props.projectId;
												})}
												isLoading={otherParticipantsState.areEntitiesLoading}
												entityId={null}
												entity={null}
												textField="participant.fullName"
												itemRender={participantItem}
												onFilterChange={onFilterChange}
												onClose={addOtherParticipant}
												onClear={() => {
													return;
												}}
											/>
										</div>
										<div className="col-1" style={{ paddingLeft: "0px" }}>
											<Button
												look="flat"
												iconClass="las la-users"
												style={{ fontSize: "20px" }}
												title={t("importFromGroup")}
												onClick={(event: React.MouseEvent<HTMLButtonElement>) => importFromOtherGroup()}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									{projectParticipantsState.list.length !== 0 &&
										projectParticipantsState.list.map((participant: IProjectParticipant) => (
											<div className="k-form-field" key={participant.id}>
												<span className={hasParticipant(participant.id) ? style.checkedRow : ""} style={{ marginRight: "10px" }}>
													{participant.participant.fullName}
												</span>
												<TranslatedSwitch name={participant.id.toString()} onChange={onChangeParticipant} checked={hasParticipant(participant.id)} disabled={readonly} />
											</div>
										))}
									{projectParticipantsState.list.length === 0 && <div>{t("noProjectParticipantsFound")}</div>}
								</div>
								<div className="col">
									{group.projectParticipants.map((participant: IGroupProjectParticipant) => {
										if (find(projectParticipantsState.list, ["id", participant.projectParticipantId]) === undefined) {
											return (
												<div key={participant.projectParticipantId.toString()} className="row" style={{ marginBottom: "10px" }}>
													<div className="k-chip k-chip-filled">
														<span className="k-selected-icon-wrapper" />
														<span className="k-chip-content">
															<span className="k-chip-label">
																{participant.projectParticipant?.participant.fullName + " - " + participant.projectParticipant?.project?.title}
															</span>
														</span>
														<span className="k-remove-icon">
															<span className="k-icon k-i-close-circle" onClick={() => onRemove(participant)} />
														</span>
													</div>
												</div>
											);
										}
										return null;
									})}
								</div>
							</div>
						</div>
					</Tab>
				)}
				{group.id > 0 && (
					<Tab reactKey="sessions" label={t("sessions")}>
						<GroupSessions projectId={props.projectId} groupId={group.id} />
					</Tab>
				)}
				{group.id > 0 && (
					<Tab reactKey="tasks" label={t("tasks")}>
						<GroupFollowUpTasks projectId={props.projectId} groupId={group.id} />
					</Tab>
				)}
				{group.id > 0 && (
					<Tab reactKey="notes" label={t("notes")}>
						<GroupNotes groupId={group.id} />
					</Tab>
				)}
				{group.id > 0 && (
					<Tab reactKey="emails" label={t("emails")}>
						<GroupEmails projectId={props.projectId} groupId={group.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default GroupEditor;
