import React from "react";

import { newKey } from "@selas/utils";
import { AxiosError } from "axios";
import i18next from "i18next";
import forIn from "lodash/forIn";
import map from "lodash/map";
import { show } from "react-notification-system-redux";

import store from "../store";

export default function handleError(error: AxiosError): void {
	const { response } = error;
	let autoDismiss: number = 7;
	if (response) {
		const title: string = i18next.t("information");
		let content: string | JSX.Element = i18next.t("errors.server_error", { statuscode: response.status });
		switch (response.status) {
			case 400:
				if (response.data.errors) {
					autoDismiss = 14;
					const errors: JSX.Element[] = [];
					forIn(response.data.errors, (value: string[], key: string) => {
						errors.push(
							<li key={key}>
								{i18next.t(key)}:
								<ul>
									{map(value, (validationError: string) => (
										<li key={newKey("error")}>{validationError}</li>
									))}
								</ul>
							</li>
						);
					});
					content = (
						<React.Fragment>
							<div>{i18next.t("theseAreTheServerErrorMessages")}</div>
							<ul>{errors}</ul>
						</React.Fragment>
					);
				} else if (typeof response.data === "string") {
					const errorKey: string = "errors." + response.data;
					if (i18next.exists(errorKey)) {
						content = i18next.t(errorKey);
					}
				}
				break;
			case 401:
				content = i18next.t("errors.not_authenticated");
				break;
			case 403:
				content = i18next.t("errors.forbidden");
				break;
			case 404:
				content = i18next.t("errors.not_found");
				break;
			case 500:
				let translationData: Record<string, string>;
				let extraInfo: string = "";
				const entities: JSX.Element[] = [];
				switch (response.data.message) {
					case "error_argumentnull":
						translationData = { uniqueKey: i18next.t(response.data.paramName) };
						break;
					case "error_duplicate":
						translationData = { uniqueKey: i18next.t("uniqueKey." + response.data.uniqueKey) };
						break;
					case "error_deleteFKConstraintStillUsed":
						forIn(response.data.entities, (value: string, key: string) => {
							entities.push(
								<li key={key}>
									<ul>
										<li>{value}</li>
									</ul>
								</li>
							);
						});
						extraInfo = i18next.t("uniqueKey." + response.data.uniqueKey);
						break;
				}
				content = (
					<React.Fragment>
						<div>{i18next.t("errors." + (response.data.message || "error_general"), translationData) + extraInfo}</div>
						{entities.length !== 0 && <ul>{entities}</ul>}
					</React.Fragment>
				);
				break;
		}

		store.dispatch(
			show(
				{
					title,
					message: content,
					dismissible: true,
					position: "tl",
					autoDismiss
				},
				"success"
			)
		);
	}
}
