import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, GridPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../../services/api/endpoint";
import NoteEditor from "../../note";

import style from "../../../../../assets/editor.module.scss";

interface IProps {
	taskId: number;
}

const TaskNotes: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.FollowUpTaskNotesList}
			listUrlArguments={{ taskId: props.taskId }}
			endpoint={Endpoint.FollowUpTaskNotes}
			addScreen={{ screen: NoteEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			editScreen={{ screen: NoteEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			delete={{ urlArguments: { taskId: props.taskId } }}
			localLoader
		>
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="content" title={t("note")} />
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
		</GridPanel>
	);
};

export default TaskNotes;
