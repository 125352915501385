import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import AbsenceReasons from "./absenceReasons";
import ContractTypes from "./contractTypes";
import Degrees from "./degrees";
import Expertises from "./expertises";
import JobFunctions from "./jobFunctions";
import Languages from "./languages";
import Nationalities from "./nationalities";
import Statutes from "./statutes";

const PersonInfo: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("personInfo")}>
			<Statutes reactKey="statutes" label={t("statutes")} url="/statutes" tileIcon="las la-stamp" isAllowed={hasPermission(Permission.StatutesRead)} />
			<Degrees reactKey="degrees" label={t("degrees")} url="/degrees" tileIcon="las la-graduation-cap" isAllowed={hasPermission(Permission.DegreesRead)} />
			<Nationalities reactKey="nationalities" label={t("nationalities")} url="/nationalities" tileIcon="las la-globe" isAllowed={hasPermission(Permission.NationalitiesRead)} />
			<ContractTypes reactKey="contractTypes" label={t("contractTypes")} url="/contractTypes" tileIcon="las la-file-contract" isAllowed={hasPermission(Permission.ContractTypesRead)} />
			<JobFunctions reactKey="jobFunctions" label={t("jobFunctions")} url="/jobFunctions" tileIcon="las la-briefcase" isAllowed={hasPermission(Permission.JobFunctionsRead)} />
			<AbsenceReasons reactKey="absenceReasons" label={t("absenceReasons")} url="/absenceReasons" tileIcon="las la-window-close" isAllowed={hasPermission(Permission.AbsenceReasonsRead)} />
			<Languages reactKey="languages" label={t("languages")} url="/languages" tileIcon="las la-language" isAllowed={hasPermission(Permission.LanguagesRead)} />
			<Expertises reactKey="expertises" label={t("expertises")} url="/expertises" tileIcon="las la-certificate" isAllowed={hasPermission(Permission.ExpertisesRead)} />
		</RoutedTabPanel>
	);
};

export default PersonInfo;
