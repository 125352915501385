import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { expertiseReducer } from "../../../state/reducers";
import { IExpertise } from "../../../utils/types/models";

const ExpertiseEditor: React.FC<IAddEntityScreenProps<IExpertise> | IEditEntityScreenProps<IExpertise>> = (props: IAddEntityScreenProps<IExpertise> | IEditEntityScreenProps<IExpertise>) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(expertiseReducer, getInitialState<IExpertise>());
	const initialExpertise: IExpertise = {
		id: (props as IEditEntityScreenProps<IExpertise>).recordId || 0,
		name: "",
		active: true
	};
	const [expertise, setExpertise] = useState<IExpertise>(initialExpertise);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setExpertise(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent): void {
		setExpertise(handleChange(expertise, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(expertise.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IExpertise): void {
		if (record && !close) {
			setExpertise(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IExpertise>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={expertise}
			endpoint={Endpoint.Expertises}
			entityState={state}
			entityType={t("expertise")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={expertise.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={expertise.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={expertise.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ExpertiseEditor;
