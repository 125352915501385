import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { absenceReasonReducer } from "../../../state/reducers";
import { IAbsenceReason } from "../../../utils/types/models";

const AbsenceReasonEditor: React.FC<IAddEntityScreenProps<IAbsenceReason> | IEditEntityScreenProps<IAbsenceReason>> = (
	props: IAddEntityScreenProps<IAbsenceReason> | IEditEntityScreenProps<IAbsenceReason>
) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(absenceReasonReducer, getInitialState<IAbsenceReason>());
	const initialAbsenceReason: IAbsenceReason = {
		id: (props as IEditEntityScreenProps<IAbsenceReason>).recordId || 0,
		name: "",
		active: true
	};
	const [absenceReason, setAbsenceReason] = useState<IAbsenceReason>(initialAbsenceReason);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setAbsenceReason(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent): void {
		setAbsenceReason(handleChange(absenceReason, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(absenceReason.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IAbsenceReason): void {
		if (record && !close) {
			setAbsenceReason(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IAbsenceReason>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={absenceReason}
			endpoint={Endpoint.AbsenceReasons}
			entityState={state}
			entityType={t("absenceReason")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={absenceReason.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={absenceReason.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={absenceReason.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default AbsenceReasonEditor;
