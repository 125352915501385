enum FollowUpTaskStatus {
	New = "New",

	InProgressGreen = "InProgressGreen",

	InProgressOrange = "InProgressOrange",

	InProgressRed = "InProgressRed",

	Finished = "Finished"
}

export default FollowUpTaskStatus;
