import React, { useEffect, useReducer, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { UploadOnBeforeRemoveEvent, UploadOnBeforeUploadEvent, UploadResponse } from "@progress/kendo-react-upload";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState } from "@selas/state-management";
import { ApiFileUpload, handleChange, ManageableField, SearchBox, TextArea, YesNoSwitch } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../services/api/endpoint";
import { getToken, hasPermission } from "../../../services/authentication";
import { emailTemplateReducer, opportunityAttachmentReducer, projectParticipantAttachmentReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { CommunicationLanguage, Permission } from "../../../utils/enums";
import { IGroupProps, IOpportunityProps, IProjectParticipantProps, IProjectProps, ISessionProps, isOpportunityProps, isProjectParticipantProps } from "../../../utils/props";
import { IEmailTemplate, IOpportunityAttachment, IProjectParticipant, IProjectParticipantAttachment, IUser } from "../../../utils/types/models";
import EmailRecipientList from "../../global/EmailRecipientList";
import { IEmailData } from "../followup/requestBackOfficeTask/sendEmail";
import EmailTemplateEditor from "../masterdata/emailTemplate";

interface IProps {
	recipientType: "opportunity" | "participant" | "allParticipants" | "selectionOfParticipants";
	emailData: IEmailData;
	onChange: (newEmailData: IEmailData) => void;
	receivers: IProjectParticipant[];
}

type Props = IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps;

const SendSimpleEmail: React.FC<IProps & Props> = (props: IProps & Props) => {
	const { t } = useTranslation();
	const [token, setToken] = useState("");

	const [emailTemplateState, emailTemplateDispatch] = useReducer(emailTemplateReducer, getInitialState<IEmailTemplate>());
	const [opportunityAttachmentState, opportunityAttachmentDispatch] = useReducer(opportunityAttachmentReducer, getInitialState<IOpportunityAttachment>());
	const [projectParticipantAttachmentState, projectParticipantAttachmentDispatch] = useReducer(projectParticipantAttachmentReducer, getInitialState<IProjectParticipantAttachment>());
	const apiService: ApiCommunicator = useApiService();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);

	useEffect(() => {
		getToken().then((securityToken: string) => {
			setToken(securityToken);
		});
	});

	useEffect(() => {
		const newEmailData: IEmailData = cloneDeep(props.emailData);
		if (!props.emailData.bcc) {
			newEmailData.bcc = [currentUser.email];
		} else if (!props.emailData.bcc.find((e: string) => e === currentUser.email)) {
			newEmailData.bcc.push(currentUser.email);
		}
		props.onChange(newEmailData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isOpportunityProps(props)) {
			apiService.callApi(opportunityAttachmentDispatch, Endpoint.OpportunityAttachments, "GET", { opportunityId: props.opportunityId });
		} else if (isProjectParticipantProps(props)) {
			apiService.callApi(projectParticipantAttachmentDispatch, Endpoint.ProjectParticipantAttachments, "GET", { projectParticipantId: props.projectParticipantId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onBeforeUpload(event: UploadOnBeforeUploadEvent | UploadOnBeforeRemoveEvent): void {
		event.headers.Authorization = `Bearer ${token}`;
		getToken().then((securityToken: string) => setToken(securityToken));
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(emailTemplateDispatch, Endpoint.EmailTemplates, "GET", { search: event.filter.value });
	}

	function fileUploaded(response: UploadResponse): void {
		const newEmailData: IEmailData = cloneDeep(props.emailData);
		newEmailData.attachments.push(response.response);
		props.onChange(newEmailData);
	}

	function fileRemoved(internalFilename: string): void {
		const newEmailData: IEmailData = cloneDeep(props.emailData);
		remove(newEmailData.attachments, { internalFilename });
		props.onChange(newEmailData);
	}

	function onChange(event: SwitchChangeEvent | ComboBoxCloseEvent | InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | MultiSelectChangeEvent): void {
		props.onChange(handleChange(props.emailData, event));
	}

	function getSelectedTab(): number {
		switch (props.emailData.selectedLanguage) {
			case CommunicationLanguage.Dutch:
				return 0;
			case CommunicationLanguage.French:
				return 1;
			case CommunicationLanguage.German:
				return 2;
			case CommunicationLanguage.English:
				return 3;
		}
	}

	function setSelectedTab(tab: number): void {
		const newEmailData: IEmailData = { ...props.emailData };
		switch (tab) {
			case 0:
				newEmailData.selectedLanguage = CommunicationLanguage.Dutch;
				break;
			case 1:
				newEmailData.selectedLanguage = CommunicationLanguage.French;
				break;
			case 2:
				newEmailData.selectedLanguage = CommunicationLanguage.German;
				break;
			case 3:
				newEmailData.selectedLanguage = CommunicationLanguage.English;
				break;
		}
		props.onChange(newEmailData);
	}

	let nlRequired: boolean = false;
	let frRequired: boolean = false;
	let deRequired: boolean = false;
	let enRequired: boolean = false;

	if (props.recipientType === "participant" || props.recipientType === "opportunity") {
		nlRequired = props.emailData.selectedLanguage === CommunicationLanguage.Dutch;
		frRequired = props.emailData.selectedLanguage === CommunicationLanguage.French;
		deRequired = props.emailData.selectedLanguage === CommunicationLanguage.German;
		enRequired = props.emailData.selectedLanguage === CommunicationLanguage.English;
	} else {
		const recipients: IProjectParticipant[] = props.receivers;
		each(recipients, (recipient: IProjectParticipant) => {
			switch (recipient.participant.communicationLanguage) {
				case CommunicationLanguage.Dutch:
					nlRequired = true;
					break;
				case CommunicationLanguage.French:
					frRequired = true;
					break;
				case CommunicationLanguage.German:
					deRequired = true;
					break;
				case CommunicationLanguage.English:
					enRequired = true;
					break;
			}
		});
	}

	function setEmailTemplate(emailTemplate: IEmailTemplate): void {
		const newEmailData: IEmailData = cloneDeep(props.emailData);
		if (emailTemplate) {
			newEmailData.emailTemplate = emailTemplate;
			newEmailData.emailTemplateId = emailTemplate.id;
			newEmailData.subjectNl = emailTemplate.subjectNl;
			newEmailData.bodyNl = emailTemplate.bodyNl;
			newEmailData.subjectFr = emailTemplate.subjectFr;
			newEmailData.bodyFr = emailTemplate.bodyFr;
			newEmailData.subjectDe = emailTemplate.subjectDe;
			newEmailData.bodyDe = emailTemplate.bodyDe;
			newEmailData.subjectEn = emailTemplate.subjectEn;
			newEmailData.bodyEn = emailTemplate.bodyEn;
		} else if (emailTemplate === null) {
			newEmailData.emailTemplateId = 0;
			newEmailData.emailTemplate = null;
		}
		props.onChange(newEmailData);
	}

	return (
		<div className="emailTabContent">
			<div className="row">
				{(props.recipientType === "participant" || props.recipientType === "allParticipants" || props.recipientType === "selectionOfParticipants") && (
					<div className="k-form-field col-2">
						<div>{t("customRecipient")}</div>
						<YesNoSwitch name="customRecipient" checked={props.emailData.customRecipient} onChange={onChange} />
					</div>
				)}
				{(props.recipientType === "opportunity" || props.emailData.customRecipient) && (
					<div className="k-form-field col">
						<div>{t("to")} *</div>
						<EmailRecipientList
							value={props.emailData.to}
							onChange={(data: string[]) => {
								const newEmailData: IEmailData = cloneDeep(props.emailData);
								newEmailData.to = data;
								props.onChange(newEmailData);
							}}
							required
						/>
					</div>
				)}
			</div>
			<div className="row">
				<div className="k-form-field col">
					<div>{t("cc")}</div>
					<EmailRecipientList
						value={props.emailData.cc}
						onChange={(data: string[]) => {
							const newEmailData: IEmailData = cloneDeep(props.emailData);
							newEmailData.cc = data;
							props.onChange(newEmailData);
						}}
						required
					/>
				</div>
			</div>
			<div className="row">
				<div className="k-form-field col">
					<div>{t("bcc")}</div>
					<EmailRecipientList
						value={props.emailData.bcc}
						onChange={(data: string[]) => {
							const newEmailData: IEmailData = cloneDeep(props.emailData);
							newEmailData.bcc = data;
							props.onChange(newEmailData);
						}}
						required
					/>
				</div>
			</div>
			<div className="row">
				<div className="k-form-field col">
					<span>{t("attachments")}</span>
					<ApiFileUpload
						multiple
						showFileList
						onBeforeUpload={onBeforeUpload}
						onBeforeRemove={onBeforeUpload}
						fileUploaded={fileUploaded}
						fileRemoved={fileRemoved}
						restrictions={{ maxFileSize: 10485760 }}
					/>
				</div>
				{isOpportunityProps(props) && (
					<div className="k-form-field col">
						<div>{t("opportunityAttachments")}</div>
						<MultiSelect
							name="opportunityAttachments"
							loading={opportunityAttachmentState.areEntitiesLoading}
							data={opportunityAttachmentState.entities}
							dataItemKey="id"
							textField="name"
							autoClose={false}
							onChange={onChange}
							disabled={!props.emailData.send}
						/>
					</div>
				)}
				{isProjectParticipantProps(props) && (
					<div className="k-form-field col">
						<div>{t("participantAttachments")}</div>
						<MultiSelect
							name="projectParticipantAttachments"
							loading={projectParticipantAttachmentState.areEntitiesLoading}
							data={projectParticipantAttachmentState.entities}
							dataItemKey="id"
							textField="name"
							autoClose={false}
							onChange={onChange}
							disabled={!props.emailData.send}
						/>
					</div>
				)}
			</div>
			<ManageableField
				addScreen={{ screen: EmailTemplateEditor, isAllowed: hasPermission(Permission.EmailTemplatesAdd) }}
				editScreen={{ screen: EmailTemplateEditor, isAllowed: hasPermission(Permission.EmailTemplatesUpdate) }}
				fieldLabel={t("template")}
				recordId={props.emailData.emailTemplateId}
				setEntity={setEmailTemplate}
				readOnly={!props.emailData.send}
			>
				<SearchBox
					name="emailTemplateId"
					entities={emailTemplateState.entities}
					isLoading={emailTemplateState.areEntitiesLoading}
					entityId={props.emailData.emailTemplateId}
					entity={props.emailData.emailTemplate}
					textField="name"
					onFilterChange={onFilterChange}
					onClose={(event: ComboBoxCloseEvent) => setEmailTemplate(event.target.value)}
					onClear={() => setEmailTemplate(null)}
					disabled={!props.emailData.send}
				/>
			</ManageableField>
			<div className="k-form-field">
				<TabStrip selected={getSelectedTab()} onSelect={(event: TabStripSelectEventArguments) => setSelectedTab(event.selected)} tabPosition="left" className="emailTabStrip">
					<TabStripTab title={`${t(CommunicationLanguage.Dutch)}${nlRequired ? " *" : ""}`}>
						<label className="k-form-field">
							<span>{`${t("subject")}${nlRequired ? " *" : ""}`}</span>
							<Input name="subjectNl" value={props.emailData.subjectNl} onChange={onChange} required={nlRequired} />
						</label>
						<label className="k-form-field">
							<span>{`${t("body")}${nlRequired ? " *" : ""}`}</span>
							<TextArea name="bodyNl" value={props.emailData.bodyNl} onChange={onChange} className="emailBody" required={nlRequired} />
						</label>
					</TabStripTab>
					<TabStripTab title={`${t(CommunicationLanguage.French)}${frRequired ? " *" : ""}`}>
						<label className="k-form-field">
							<span>{`${t("subject")}${frRequired ? " *" : ""}`}</span>
							<Input name="subjectFr" value={props.emailData.subjectFr} onChange={onChange} required={frRequired} />
						</label>
						<label className="k-form-field">
							<span>{`${t("body")}${frRequired ? " *" : ""}`}</span>
							<TextArea name="bodyFr" value={props.emailData.bodyFr} onChange={onChange} className="emailBody" required={frRequired} />
						</label>
					</TabStripTab>
					<TabStripTab title={`${t(CommunicationLanguage.German)}${deRequired ? " *" : ""}`}>
						<label className="k-form-field">
							<span>{`${t("subject")}${deRequired ? " *" : ""}`}</span>
							<Input name="subjectDe" value={props.emailData.subjectDe} onChange={onChange} required={deRequired} />
						</label>
						<label className="k-form-field">
							<span>{`${t("body")}${deRequired ? " *" : ""}`}</span>
							<TextArea name="bodyDe" value={props.emailData.bodyDe} onChange={onChange} className="emailBody" required={deRequired} />
						</label>
					</TabStripTab>
					<TabStripTab title={`${t(CommunicationLanguage.English)}${enRequired ? " *" : ""}`}>
						<label className="k-form-field">
							<span>{`${t("subject")}${enRequired ? " *" : ""}`}</span>
							<Input name="subjectEn" value={props.emailData.subjectEn} onChange={onChange} required={enRequired} />
						</label>
						<label className="k-form-field">
							<span>{`${t("body")}${enRequired ? " *" : ""}`}</span>
							<TextArea name="bodyEn" value={props.emailData.bodyEn} onChange={onChange} className="emailBody" required={enRequired} />
						</label>
					</TabStripTab>
				</TabStrip>
			</div>
		</div>
	);
};

export default SendSimpleEmail;
