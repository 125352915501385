import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";

import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Input, TextArea, TextAreaChangeEvent, TextAreaHandle } from "@progress/kendo-react-inputs";
import { IAction } from "@selas/models";
import { getInitialState, IEntityState } from "@selas/state-management";
import { EntityEditor, IAddEntityScreenProps, IEditEntityScreenProps } from "@selas/ui-components";
import { getDate, isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../services/api/endpoint";
import { groupNotesReducer, opportunityNotesReducer, projectParticipantNoteReducer, sessionNotesReducer } from "../../../state/reducers";
import followUpTaskNoteReducer from "../../../state/reducers/followUpTaskNoteReducer";
import projectNotesReducer from "../../../state/reducers/projectNotesReducer";
import { IApplicationState } from "../../../store";
import { IFollowUpTaskNote, IGroupNote, INote, IOpportunityNote, IProjectNote, IProjectParticipantNote, ISessionNote, IUser } from "../../../utils/types/models";

interface ITaskProps {
	taskId: number;
}

interface IProjectParticipantProps {
	projectParticipantId: number;
}

interface IOpportunityProps {
	opportunityId: number;
}

interface IGroupProps {
	groupId: number;
}

interface IProjectProps {
	projectId: number;
}

interface ISessionProps {
	sessionId: number;
}

type SpecificProps = ITaskProps | IProjectParticipantProps | IOpportunityProps | IGroupProps | IProjectProps | ISessionProps;

type BaseProps = IAddEntityScreenProps<INote> | IEditEntityScreenProps<INote>;

function isTaskProps(toBeDetirmined: SpecificProps): toBeDetirmined is ITaskProps {
	return (toBeDetirmined as ITaskProps).taskId !== undefined;
}

function isProjectParticipantProps(toBeDetermined: SpecificProps): toBeDetermined is IProjectParticipantProps {
	return (toBeDetermined as IProjectParticipantProps).projectParticipantId !== undefined;
}

function isOpportunnityProps(toBeDetermined: SpecificProps): toBeDetermined is IOpportunityProps {
	return (toBeDetermined as IOpportunityProps).opportunityId !== undefined;
}

function isGroupProps(toBeDetermined: SpecificProps): toBeDetermined is IGroupProps {
	return (toBeDetermined as IGroupProps).groupId !== undefined;
}

function isProjectProps(toBeDetermined: SpecificProps): toBeDetermined is IProjectProps {
	return (toBeDetermined as IProjectProps).projectId !== undefined;
}

function isSessionProps(toBeDetermined: SpecificProps): toBeDetermined is ISessionProps {
	return (toBeDetermined as ISessionProps).sessionId !== undefined;
}

function getInitialNote(props: BaseProps & SpecificProps, currentUser: IUser): INote {
	const task: INote = {
		id: (props as IEditEntityScreenProps<INote>).recordId || 0,
		creatorId: currentUser.id,
		creator: currentUser,
		content: ""
	};
	if (isTaskProps(props)) {
		(task as IFollowUpTaskNote).taskId = props.taskId;
	} else if (isProjectParticipantProps(props)) {
		(task as IProjectParticipantNote).projectParticipantId = props.projectParticipantId;
	} else if (isOpportunnityProps(props)) {
		(task as IOpportunityNote).opportunityId = props.opportunityId;
	} else if (isGroupProps(props)) {
		(task as IGroupNote).groupId = props.groupId;
	} else if (isProjectProps(props)) {
		(task as IProjectNote).projectId = props.projectId;
	} else if (isSessionProps(props)) {
		(task as ISessionNote).sessionId = props.sessionId;
	}
	return task;
}

const NoteEditor: React.FC<BaseProps & SpecificProps> = (props: BaseProps & SpecificProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const [note, setNote] = useState<INote>(getInitialNote(props, currentUser));
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	let reducer: Reducer<IEntityState<INote>, IAction>;
	let initialState: IEntityState<INote>;
	if (isTaskProps(props)) {
		reducer = (followUpTaskNoteReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<INote>();
	} else if (isProjectParticipantProps(props)) {
		reducer = (projectParticipantNoteReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<IProjectParticipantNote>();
	} else if (isOpportunnityProps(props)) {
		reducer = (opportunityNotesReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<IOpportunityNote>();
	} else if (isGroupProps(props)) {
		reducer = (groupNotesReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<IGroupNote>();
	} else if (isProjectProps(props)) {
		reducer = (projectNotesReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<IProjectNote>();
	} else if (isSessionProps(props)) {
		reducer = (sessionNotesReducer as unknown) as Reducer<IEntityState<INote>, IAction>;
		initialState = getInitialState<ISessionNote>();
	}
	const [noteState, noteDispatch] = useReducer(reducer, initialState);
	const firstField: React.RefObject<TextAreaHandle> = useRef<TextAreaHandle>();

	useEffect(() => {
		if (noteState?.entity) {
			setNote(noteState.entity);
		}
	}, [noteState]);

	function onChange(event: TextAreaChangeEvent): void {
		const newNote: INote = cloneDeep(note);
		newNote.content = event.value as string;
		setNote(newNote);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(note.content)) {
			messages.push(t("fill_in_required_field", { field: t("note").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: INote): void {
		if (record && !close) {
			setNote(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<INote>).readonly;
	let endpoint: Endpoint;
	let extraParams: Record<string, unknown>;

	if (isTaskProps(props)) {
		endpoint = Endpoint.FollowUpTaskNotes;
		extraParams = { taskId: props.taskId };
	} else if (isProjectParticipantProps(props)) {
		endpoint = Endpoint.ProjectParticipantNotes;
		extraParams = { projectParticipantId: props.projectParticipantId };
	} else if (isOpportunnityProps(props)) {
		endpoint = Endpoint.OpportunityNotes;
		extraParams = { opportunityId: props.opportunityId };
	} else if (isGroupProps(props)) {
		endpoint = Endpoint.GroupNotes;
		extraParams = { groupId: props.groupId };
	} else if (isProjectProps(props)) {
		endpoint = Endpoint.ProjectNotes;
		extraParams = { projectId: props.projectId };
	} else if (isSessionProps(props)) {
		endpoint = Endpoint.SessionNotes;
		extraParams = { sessionId: props.sessionId };
	}

	return (
		<EntityEditor
			width="70%"
			record={note}
			endpoint={endpoint}
			extraUrlParams={extraParams}
			entityState={noteState}
			entityType={t("note")}
			dispatch={noteDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName=""
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("creator")}</span>
					<Input defaultValue={note.creator?.fullName} disabled />
				</label>
				<div className="k-form-field">
					<span>{t("createdDate")}</span>
					<DateTimePicker name="createdDate" value={getDate(note?.createdDate)} format={"yyyy-MM-dd HH:mm"} disabled />
				</div>
				<label className="k-form-field">
					<span>{t("note")} *</span>
					<TextArea name="content" ref={firstField} value={note?.content} onChange={onChange} required disabled={readonly} style={{ height: "300px" }} />
				</label>
			</div>
		</EntityEditor>
	);
};

export default NoteEditor;
