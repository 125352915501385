import React, { useEffect, useReducer } from "react";

import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState } from "@selas/state-management";
import { IEditEntityScreenProps, Loader, StandardButton } from "@selas/ui-components";
import { getDate } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import errorLogReducer from "../../../state/reducers/errorLogReducer";
import { IErrorLogEntry } from "../../../utils/types/models";

const ErrorLogViewer: React.FC<IEditEntityScreenProps<IErrorLogEntry>> = ({ recordId, actionButtonClicked }: IEditEntityScreenProps<IErrorLogEntry>) => {
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(errorLogReducer, getInitialState<IErrorLogEntry>());
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (recordId) {
			apiService.callApi(dispatch, Endpoint.ErrorLog, "GET", { id: recordId });
		}
	}, [apiService, recordId]);

	return (
		<>
			{state.isEntityLoading && <Loader />}
			<Dialog width="90%" height="90%" title={t("error")} onClose={() => actionButtonClicked(true)}>
				<div className="k-form h-100 d-flex flex-column">
					<div className="row">
						<div className="col">
							<div className="k-form-field">
								<span>{t("createdDate")}</span>
								<DateTimePicker value={getDate(state.entity?.createdDate)} format="yyyy-MM-dd" disabled />
							</div>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("user")}</span>
								<Input value={state.entity?.createdBy} readOnly />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("source")}</span>
								<Input value={state.entity?.source} readOnly />
							</label>
						</div>
					</div>
					<div className="row flex-grow-1">
						<div className="col d-flex">
							<label className="k-form-field flex-grow-1 d-flex flex-column">
								<span>{t("message")}</span>
								<TextArea value={state.entity?.message} readOnly className="flex-grow-1" />
							</label>
						</div>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => actionButtonClicked(true)}>{t("close")}</StandardButton>
				</DialogActionsBar>
			</Dialog>
		</>
	);
};

export default ErrorLogViewer;
