import React, { Ref, useEffect, useReducer, useRef } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { hideLoader, showLoader } from "@selas/state-management";
import { customCell, dateCell, enumFilterCell, GridPanel, IGridPanelRef, notify, StandardButton, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialBackOfficeTaskRequestState } from "../../../../state";
import { backOfficeTaskRequestReducer } from "../../../../state/reducers";
import { Permission, ProjectParticipantStatus } from "../../../../utils/enums";
import { IContact } from "../../../../utils/types/models";
import { colorCell, colorCellClassName } from "../../../global/colorCell";
import CopyFromContactButton from "../../../global/copyContactButton";
import ParticipantTemplateButtons from "../../../global/participantTemplateButtons";
import { getProjectParticipantStatusColor } from "../../../pages/work/participants";
import ProjectParticipantEditor from "../projectParticipant";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
	contact: IContact;
	statusChange: boolean;
	companyId: number;
}

const ProjectParticipants: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();
	const [backOfficeState, backOfficeDispatch] = useReducer(backOfficeTaskRequestReducer, initialBackOfficeTaskRequestState);
	const gridRef: Ref<IGridPanelRef> = useRef();

	useEffect(() => {
		if (backOfficeState.isRequesting) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [backOfficeState.isRequesting, reduxDispatch]);

	useEffect(() => {
		if (backOfficeState.success) {
			reduxDispatch(notify(t("success"), t("emailRequestedSuccessfully"), "success"));
		}
	}, [backOfficeState.success, t, reduxDispatch]);

	function sendInfo(): void {
		apiService.callApi(backOfficeDispatch, Endpoint.RequestPartcipantsInfoByEmail, "POST", { projectId: props.projectId });
	}

	let extraToolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.ProjectsUpdate)) {
		extraToolbarButtons = [
			<ParticipantTemplateButtons key="participantButtons" projectId={props.projectId} />,
			<StandardButton key="requestParticipantsInfo" primary onClick={sendInfo}>
				{t("requestParticipantsInfo")}
			</StandardButton>,
			<CopyFromContactButton key="copyFromContact" contact={props.contact} onContactCopied={() => gridRef.current.refresh()} projectId={props.projectId} />
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectParticipantsList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.ProjectParticipants}
			addScreen={{
				screen: ProjectParticipantEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate),
				extraProps: { projectId: props.projectId, companyId: props.companyId, statusChange: props.statusChange }
			}}
			editScreen={{ screen: ProjectParticipantEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId, statusChange: props.statusChange } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			extraToolbarButtons={extraToolbarButtons}
			ref={gridRef}
			localLoader
		>
			<GridColumn
				field="status"
				title={t("projectParticipantStatus")}
				cell={customCell(colorCell(true, translatedCell(), getProjectParticipantStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(ProjectParticipantStatus)}
			/>
			<GridColumn field="participant.fullName" title={t("name")} />
			<GridColumn field="fireOrQuitDate" title={t("fireOrQuitDate")} cell={customCell(dateCell("yyyy-MM-dd"))} filter="date" />
			<GridColumn field="noticePeriodEndDate" title={t("noticePeriodEndDate")} cell={customCell(dateCell("yyyy-MM-dd"))} filter="date" />
			<GridColumn field="contractType.name" title={t("contractType")} />
			<GridColumn field="responsibleUser.fullName" title={t("projectParticipantResponsible")} />
		</GridPanel>
	);
};

export default ProjectParticipants;
