import { Reducer } from "react";

import { IAction } from "@selas/models";
import { AxiosResponse } from "axios";

import { initialPermissionState, IPermssionState } from "..";

const permissionReducer: Reducer<IPermssionState, IAction> = (state: IPermssionState = initialPermissionState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_PERMISSIONS":
			state = {
				...state,
				loading: true
			};
			break;
		case "REQUEST_SUCCESS_GET_PERMISSIONS": {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (action.payload && payload.response && payload.response.data) {
				state = {
					...state,
					permissions: payload.response.data || []
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_PERMISSIONS":
			state = {
				...state,
				loading: false
			};
			break;
		case "REQUEST_END_GET_PERMISSIONS":
			state = {
				...state,
				loading: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default permissionReducer;
