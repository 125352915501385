import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import Groups from "./groups";
import MasterServices from "./masterServices";
import Opportunities from "./opportunities";
import Participants from "./participants";
import Planning from "./planning";
import Projects from "./projects";
import Sessions from "./sessions";

const Work: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("work")}>
			<Opportunities reactKey="opportunities" label={t("opportunities")} url="/opportunities" tileIcon="las la-lightbulb" isAllowed={hasPermission(Permission.OpportunitiesRead)} />
			<Projects reactKey="projects" label={t("projects")} url="/projects" tileIcon="las la-project-diagram" isAllowed={hasPermission(Permission.ProjectsRead)} />
			<Groups reactKey="groups" label={t("groups")} url="/groups" tileIcon="las la-circle-notch" isAllowed={hasPermission(Permission.GroupsRead)} />
			<Participants reactKey="participants" label={t("participants")} url="/participants" tileIcon="las la-user-graduate" isAllowed={hasPermission(Permission.ProjectParticipantsRead)} />
			<Sessions reactKey="sessions" label={t("sessions")} url="/sessions" tileIcon="las la-chalkboard-teacher" isAllowed={hasPermission(Permission.SessionsRead)} />
			<MasterServices reactKey="services" label={t("invoicing")} url="/invoicing" tileIcon="las la-cogs" isAllowed={hasPermission(Permission.InvoicingAccess)} />
			<Planning reactKey="planning" label={t("planning.planning")} url="/planning" tileIcon="las la-calendar" isAllowed={hasPermission(Permission.PlanningDisplay)} />
		</RoutedTabPanel>
	);
};

export default Work;
