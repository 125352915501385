import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction } from "@selas/models";
import { hideLoader, showLoader } from "@selas/state-management";
import { Confirm, Form, handleChange, notify, SearchBox, setEntity, StandardButton, SubmitButton, TextArea, usePreventWindowUnload } from "@selas/ui-components";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { initialOpportunityState, initialProjectState, initialUserState } from "../../../state";
import { opportunityReducer, projectReducer, userReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { IOpportunity, IProject, IUser } from "../../../utils/types/models";

interface ICreateProjectFromOpportunityModel {
	opportunityId: number;
	opportunity: IOpportunity;
	title: string;
	description: string;
	responsibleUserId: number;
	responsibleUser: IUser;
	spread: number;
}

interface IProps {
	opportunity?: IOpportunity;
	close: (record?: IProject) => void;
}

const CreateFromOpportunityDialog: React.FC<IProps> = ({ opportunity, close }: IProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [model, setModel] = useState<ICreateProjectFromOpportunityModel>({
		opportunityId: opportunity?.id,
		opportunity,
		title: opportunity?.title,
		description: opportunity?.description,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		spread: 1
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const [opportunityState, opportunityDispatch] = useReducer(opportunityReducer, initialOpportunityState);
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();
	const firstCombobox: React.RefObject<ComboBox> = useRef();
	const firstInput: React.RefObject<Input> = useRef();

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (!opportunity && firstCombobox && firstCombobox.current) {
			firstCombobox.current.focus();
		} else if (opportunity && firstInput && firstInput.current) {
			firstInput.current.focus();
		}
	}, [firstInput, firstCombobox, opportunity]);

	useEffect(() => {
		if (!projectState.isAdding && projectState.addedEntity) {
			close(projectState.addedEntity);
		}
	}, [projectState.isAdding, projectState.addedEntity, close]);

	useEffect(() => {
		if (projectState.isAdding) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
		return () => {
			reduxDispatch(hideLoader());
		};
	}, [reduxDispatch, projectState.isAdding]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "opportunityId":
				dispatch = opportunityDispatch;
				filterEndpoint = Endpoint.Opportunities;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function onChange(event: InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | ComboBoxCloseEvent | NumericTextBoxChangeEvent | DropDownListChangeEvent): void {
		const newModel: ICreateProjectFromOpportunityModel = handleChange(model, event);
		if (event.target.name === "opportunityId" && event.target.value) {
			newModel.title = event.target.value.title;
			newModel.description = event.target.value.description;
			newModel.responsibleUserId = event.target.value.responsibleUserId;
			newModel.responsibleUser = event.target.value.responsibleUser;
		}
		updateModel(newModel);
	}
	function updateModel(newModel: ICreateProjectFromOpportunityModel): void {
		setModel(newModel);
		setDataChanged(true);
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function save(): void {
		const errorMessages: string[] = [];
		if (!model.opportunityId) {
			errorMessages.push(t("fill_in_required_field", { field: t("opportunity").toLowerCase() }));
		}
		if (!model.responsibleUserId) {
			errorMessages.push(t("fill_in_required_field", { field: t("responsibleUser").toLowerCase() }));
		}
		if (!errorMessages || errorMessages.length <= 0) {
			apiService.callApi(projectDispatch, Endpoint.CreateFromOpportunity, "POST", null, {
				...model,
				opportunity: undefined,
				responsibleUser: undefined
			});
		} else {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		}
	}

	return (
		<>
			<Form>
				<Dialog width="50%" title={t("createProject")} onClose={() => handleClose()}>
					<div className="k-form">
						<div className="k-form-field">
							<span>{t("opportunity")} *</span>
							<SearchBox
								name="opportunityId"
								entities={opportunityState.entities}
								isLoading={opportunityState.areEntitiesLoading}
								entityId={model.opportunityId}
								entity={model.opportunity}
								required
								textField="title"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => updateModel(setEntity(model, null, "opportunityId", "opportunity"))}
								disabled={opportunity !== undefined && opportunity !== null}
								myRef={firstCombobox}
							/>
						</div>
						<label className="k-form-field">
							<span>{t("title")}</span>
							<Input name="title" ref={firstInput} value={model.title} onChange={onChange} />
						</label>
						<label className="k-form-field">
							<span>{t("description")}</span>
							<TextArea name="description" value={model.description} onChange={onChange} />
						</label>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("projectResponsible")} *</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={model.responsibleUserId}
										entity={model.responsibleUser}
										required
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => updateModel(setEntity(model, null, "responsibleUser", "responsibleUserId"))}
									/>
								</div>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("spread")}</span>
									<NumericTextBox name="spread" value={model.spread} onChange={onChange} />
								</label>
							</div>
						</div>
					</div>
					<DialogActionsBar>
						<StandardButton onClick={() => handleClose()} type="button">
							{t("cancel")}
						</StandardButton>
						<SubmitButton primary onClick={() => save()}>
							{t("create")}
						</SubmitButton>
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
};

export default CreateFromOpportunityDialog;
