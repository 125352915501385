import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import Topics from "../catalogue/topics";
import CatalogueServices from "./catalogueServices";
import InvoicingGroups from "./invoicingGroups";
import ServiceTypes from "./servicetypes";
import SessionTemplates from "./sessionTemplates";

const Catalogue: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("catalogue")}>
			<CatalogueServices reactKey="catalogueServices" label={t("services")} url="/services" tileIcon="las la-cogs" isAllowed={hasPermission(Permission.CoachesRead)} />
			<ServiceTypes reactKey="serviceTypes" label={t("serviceTypes")} url="/servicetypes" tileIcon="las la-headset" isAllowed={hasPermission(Permission.ServiceTypesRead)} />
			<InvoicingGroups reactKey="invoicingGroups" label={t("invoicingGroups")} url="/invoicinggroups" tileIcon="las la-list" isAllowed={hasPermission(Permission.InvoicingGroupsRead)} />
			<Topics reactKey="topics" label={t("topics")} url="/topics" tileIcon="las la-chalkboard-teacher" isAllowed={hasPermission(Permission.TopicsRead)} />
			<SessionTemplates reactKey="sessionTemplates" label={t("sessionTemplates")} url="/sessiontemplates" tileIcon="las la-stamp" isAllowed={hasPermission(Permission.SessionTemplatesRead)} />
		</RoutedTabPanel>
	);
};

export default Catalogue;
