import React from "react";
import ReactDOM from "react-dom";

import Axios, { AxiosResponse } from "axios";
import { Provider } from "react-redux";

import App from "./App";
import "./services/api";
import { setAdCredentials } from "./services/authentication";
import "./services/translation/i18n";
import store from "./store";

import "./index.scss";

Axios.get("/api/environment").then((result: AxiosResponse) => {
	setAdCredentials(result.data.tenantId, result.data.clientId);
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById("root")
	);
});
