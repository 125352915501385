import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import { customCell, dateCell, GridPanel } from "@selas/ui-components";
import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { Permission } from "../../../../utils/enums";

import style from "../../../../assets/editor.module.scss";
import EmailEditor from "../../followup/email";

interface IProps {
	projectId: number;
}

const ProjectEmails: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectEmailsList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.ProjectEmails}
			editScreen={{ screen: EmailEditor, isAllowed: true, extraProps: { projectId: props.projectId } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			localLoader
			sort={[{ field: "createdDate", dir: "desc" }]}
		>
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="to" title={t("to")} />
			<GridColumn field="cc" title={t("cc")} />
			<GridColumn field="bcc" title={t("bcc")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="body" title={t("body")} />
		</GridPanel>
	);
};

export default ProjectEmails;
