import { ReactElement } from "react";
import React from "react";

import { GridColumnMenuItem, GridColumnMenuItemGroup, GridColumnMenuProps, GridColumnMenuSort } from "@progress/kendo-react-grid";
import { render } from "@selas/state-management";
import { newKey } from "@selas/utils";
import pull from "lodash/pull";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ColumnSelector, { ColumnConfiguration } from "./columnSelector";

interface Props extends GridColumnMenuProps {
	columns: string[];
	allColumns: ColumnConfiguration[];
	setColumns: (columns: string[]) => void;
}

function ColumnConfigurationMenu({ columns, allColumns, setColumns, column, onCloseMenu, ...props }: Props): ReactElement {
	const { t } = useTranslation();
	const reduxDispatch = useDispatch();

	function hideColumn() {
		const newColumns = [...columns];
		if (newColumns.includes(column.field)) {
			pull(newColumns, column.field);
		}
		setColumns(newColumns);
		onCloseMenu();
	}

	function showColumnSelector() {
		const key = newKey("columnSelector");
		reduxDispatch(
			render(key, ColumnSelector, {
				renderKey: key,
				columns,
				allColumns,
				setColumns
			})
		);
		onCloseMenu();
	}

	return (
		<div>
			<GridColumnMenuSort column={column} onCloseMenu={onCloseMenu} {...props} />
			<GridColumnMenuItemGroup>
				{columns.length > 1 && <GridColumnMenuItem title={t("hideColumn")} iconClass="k-i-table-column-delete" onClick={hideColumn} />}
				<GridColumnMenuItem title={t("showColumnChooser")} iconClass="k-i-table-properties" onClick={showColumnSelector} />
			</GridColumnMenuItemGroup>
		</div>
	);
}

export default ColumnConfigurationMenu;
