import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { derender, getInitialState, render } from "@selas/state-management";
import { Confirm, EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, TextArea, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import { cloneDeep, map } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { taskSubjectReducer } from "../../../../state/reducers";
import { ICheckListItem, ITaskSubject } from "../../../../utils/types/models";
import CheckListItemEditor from "./checkListItemEditor";

import styles from "./editor.module.scss";

const TaskSubjectEditor: React.FC<IAddEntityScreenProps<ITaskSubject> | IEditEntityScreenProps<ITaskSubject>> = (props: IAddEntityScreenProps<ITaskSubject> | IEditEntityScreenProps<ITaskSubject>) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(taskSubjectReducer, getInitialState<ITaskSubject>());
	const reduxDispatch: Dispatch = useDispatch();

	const initialTaskSubject: ITaskSubject = {
		id: (props as IEditEntityScreenProps<ITaskSubject>).recordId || 0,
		name: "",
		description: "",
		checkList: [],
		active: true
	};

	const [taskSubject, setTaskSubject] = useState<ITaskSubject>(initialTaskSubject);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setTaskSubject(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		setTaskSubject(handleChange(taskSubject, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(taskSubject.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: ITaskSubject): void {
		if (record && !close) {
			setTaskSubject(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function addItem(): void {
		const key: string = newKey("addItem");
		reduxDispatch(
			render(key, CheckListItemEditor, {
				onClose: (title?: string) => {
					if (title) {
						const newTaskSubject: ITaskSubject = cloneDeep(taskSubject);
						newTaskSubject.checkList.push({
							title,
							finished: false
						});
						setTaskSubject(newTaskSubject);
						setDataChanged(true);
					}
					reduxDispatch(derender(key));
				}
			})
		);
	}

	function updateItem(index: number): void {
		const taskListEditorKey: string = newKey("editChecklistItemConfirm");
		const checkListIstItem: ICheckListItem = taskSubject.checkList[index];
		reduxDispatch(
			render(taskListEditorKey, CheckListItemEditor, {
				initialTitle: checkListIstItem.title,
				onClose: (title: string) => {
					if (!isNullOrEmpty(title)) {
						const newTaskSubject: ITaskSubject = cloneDeep(taskSubject);
						newTaskSubject.checkList.splice(index, 1, { title, finished: checkListIstItem.finished });
						setTaskSubject(newTaskSubject);
						setDataChanged(true);
					}
					reduxDispatch(derender(taskListEditorKey));
				}
			})
		);
	}

	function deleteItem(index: number): void {
		const confirmKey: string = newKey("deleteItem");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm"),
				children: t("confirm_content", { action: t("remove") }),
				onConfirm: () => {
					const newTaskSubject: ITaskSubject = cloneDeep(taskSubject);
					newTaskSubject.checkList.splice(index, 1);
					setTaskSubject(newTaskSubject);
					setDataChanged(true);
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ITaskSubject>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={taskSubject}
			endpoint={Endpoint.TaskSubjects}
			entityState={state}
			entityType={t("taskSubject")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={taskSubject.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={taskSubject.name} onChange={onChange} required disabled={readonly} />
				</div>
				<div className="k-form-field">
					<span>{t("description")}</span>
					<TextArea name="description" value={taskSubject.description} onChange={onChange} disabled={readonly} />
				</div>
				<div className="k-form-field" style={{ position: "relative", height: "200px", marginBottom: "40px" }}>
					<span className="manageable-field-label">
						{t("checkList")} <i className="las la-plus smallAction" onClick={() => addItem()} />
					</span>
					<div className={styles.itemsListingContainer}>
						{map(taskSubject.checkList, (item: ICheckListItem, index: number) => (
							<div key={`checkListItem_${index}`} className={styles.itemPreviewRow}>
								<div className={`manageable-field-label ${styles.itemPreviewDescription}`}>
									<i className="las la-pencil-alt" style={{ marginRight: "10px" }} onClick={() => updateItem(index)} />
									<i className="las la-trash" style={{ marginRight: "10px" }} onClick={() => deleteItem(index)} />
									{item.title}
								</div>
							</div>
						))}
					</div>
				</div>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={taskSubject.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default TaskSubjectEditor;
