import React, { useState } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { derender, render } from "@selas/state-management";
import { customCell, enumMultiSelectFilterCell, GridPanel, GridPanelColumn, IRoutedTabProps, translatedCell, YesNoSwitch } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { History } from "history";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { IApplicationState } from "../../../store";
import { Color, Permission } from "../../../utils/enums";
import OpportunityStatus from "../../../utils/enums/opportunityStatus";
import { isGridCellProps } from "../../../utils/props";
import { IOpportunity, IProject, IUser } from "../../../utils/types/models";
import OpportunityEditor from "../../editors/opportunity";
import CreateFromOpportunityDialog from "../../editors/project/createFromOpportunity";
import { colorCell, colorCellClassName } from "../../global/colorCell";
import percentageFilterCell from "../../global/percentageFilterCell";
import { editProject } from "./projects";

export function getOpportunityStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case OpportunityStatus.New:
			return Color.Blue;
		case OpportunityStatus.InProgress:
		case OpportunityStatus.OfferSent:
			return Color.Orange;
		case OpportunityStatus.Rejected:
			return Color.Red;
		case OpportunityStatus.Approved:
			return Color.Green;
		case OpportunityStatus.Pending:
			return Color.Grey;
	}
}

const Opportunities: React.FC<IRoutedTabProps> = (props: IRoutedTabProps) => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();
	const history: History = useHistory();

	return (
		<GridPanel
			listEndpoint={showAll ? Endpoint.OpportunitiesList : Endpoint.MyOpportunitiesList}
			endpoint={Endpoint.Opportunities}
			userGridPanelConfiguration={{
				gridKey: props.reactKey,
				configurationEndpoint: Endpoint.GridPanelConfigurations,
				filterable: true
			}}
			addScreen={{ screen: OpportunityEditor, isAllowed: hasPermission(Permission.OpportunitiesAdd) }}
			editScreen={{ screen: OpportunityEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.OpportunitiesDelete) }}
			extraToolbarButtons={
				hasPermission(Permission.OpportunitiesAll) && [
					<span key="showAllLabel" style={{ marginRight: "10px" }}>
						{t("showAllOpportunities")}
					</span>,
					<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
				]
			}
			extraCommands={[
				{
					iconClass: "las la-clipboard",
					tooltip: t("createProject"),
					recordAction: (opportunity: IOpportunity) => {
						const createProjectKey: string = newKey("createProject");
						reduxDispatch(
							render(createProjectKey, CreateFromOpportunityDialog, {
								opportunity,
								close: (record: IProject) => {
									if (record) {
										if (hasPermission(Permission.ProjectsRead)) {
											history.push("/work/projects");
										}
										editProject(record, currentUser, reduxDispatch, t);
									}
									reduxDispatch(derender(createProjectKey));
								}
							})
						);
					},
					showCommand: () => hasPermission(Permission.ProjectsAdd)
				}
			]}
		>
			<GridPanelColumn
				field="status"
				title={t("opportunityStatus")}
				cell={customCell(colorCell(true, translatedCell(), getOpportunityStatusColor), colorCellClassName)}
				filterCell={enumMultiSelectFilterCell(OpportunityStatus)}
				isDefault
			/>
			<GridPanelColumn field="title" title={t("title")} isDefault />
			<GridPanelColumn field="contact.fullName" title={t("participant")} isDefault />
			<GridPanelColumn field="contact.phoneNumber" title={t("phoneNumber")} isDefault />
			<GridPanelColumn field="contact.email" title={t("email")} width="200px" isDefault />
			<GridPanelColumn field="company.name" title={t("company")} isDefault />
			<GridPanelColumn field="companyContact.fullName" title={t("companyContact")} isDefault />
			<GridPanelColumn field="partner.name" title={t("partner")} isDefault />
			<GridPanelColumn field="partnerContact.fullName" title={t("partnerContact")} isDefault />
			<GridPanelColumn field="responsibleUser.fullName" title={t("opportunityResponsible")} isDefault />
			<GridPanelColumn field="cost" title={t("cost")} filter="numeric" format="{0:n2}" isDefault />
			<GridPanelColumn field="chanceOfSucces" title={t("chanceOfSucces")} format="{0:p}" filter="numeric" filterCell={percentageFilterCell} isDefault />
			<GridPanelColumn field="type.name" title={t("serviceType")} isDefault={false} />
			<GridPanelColumn field="origin.name" title={t("origin")} isDefault={false} />
			<GridPanelColumn field="forecastCost" title={t("forecastCost")} isDefault={false} />
		</GridPanel>
	);
};

export default Opportunities;
