import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import EmailTemplateEditor from "../../editors/masterdata/emailTemplate";

const EmailTemplates: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.EmailTemplatesList}
			endpoint={Endpoint.EmailTemplates}
			addScreen={{ screen: EmailTemplateEditor, isAllowed: hasPermission(Permission.EmailTemplatesAdd) }}
			editScreen={{ screen: EmailTemplateEditor, isAllowed: hasPermission(Permission.EmailTemplatesUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.EmailTemplatesDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default EmailTemplates;
