import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import { customCell, dateCell, GridPanel } from "@selas/ui-components";

import style from "../../../../../assets/editor.module.scss";
import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { Permission } from "../../../../../utils/enums";
import EmailEditor from "../../../followup/email";

interface IProps {
	sessionId: number;
	projectId: number;
	outlookMeetingId: string;
	readOnly: boolean;
}

const SessionEmails: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.SessionEmailsList}
			listUrlArguments={{ sessionId: props.sessionId }}
			endpoint={Endpoint.SessionEmails}
			editScreen={{ screen: EmailEditor, isAllowed: !props.readOnly && hasPermission(Permission.SessionsUpdate), extraProps: { sessionId: props.sessionId, projectId: props.projectId } }}
			delete={{ urlArguments: { sessionId: props.sessionId }, isAllowed: hasPermission(Permission.SessionsUpdate) }}
			localLoader
			sort={[{ field: "createdDate", dir: "desc" }]}
		>
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="to" title={t("to")} />
			<GridColumn field="cc" title={t("cc")} />
			<GridColumn field="bcc" title={t("bcc")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="body" title={t("body")} />
		</GridPanel>
	);
};

export default SessionEmails;
