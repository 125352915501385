import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { UploadOnBeforeUploadEvent, UploadResponse } from "@progress/kendo-react-upload";
import { IAction } from "@selas/models";
import { derender, getInitialState, IEntityState, render } from "@selas/state-management";
import { ApiFileUpload, Confirm, EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { getToken } from "../../../services/authentication";
import { opportunityAttachmentReducer, projectParticipantAttachmentReducer } from "../../../state/reducers";
import followUpTaskAttachmentReducer from "../../../state/reducers/followUpTaskAttachmentReducer";
import { IAttachment, IFollowUpTaskAttachment, IOpportunityAttachment, IProjectParticipantAttachment } from "../../../utils/types/models";

interface IOpportunityProps {
	opportunityId: number;
}

interface IProjectParticipantProps {
	projectParticipantId: number;
}

interface ITaskProps {
	taskId: number;
}

type SpecificProps = IOpportunityProps | IProjectParticipantProps | ITaskProps;

type BaseProps = IAddEntityScreenProps<IAttachment> | IEditEntityScreenProps<IAttachment>;

function isOpportunityProps(toBeDetermined: SpecificProps): toBeDetermined is IOpportunityProps {
	return (toBeDetermined as IOpportunityProps).opportunityId !== undefined;
}

function isProjectParticipantProps(toBeDetermined: SpecificProps): toBeDetermined is IProjectParticipantProps {
	return (toBeDetermined as IProjectParticipantProps).projectParticipantId !== undefined;
}

function isTaskProps(toBeDetermined: SpecificProps): toBeDetermined is ITaskProps {
	return (toBeDetermined as ITaskProps).taskId !== undefined;
}

function getInitialAttachment(props: BaseProps & SpecificProps): IAttachment {
	const attachment: IAttachment = {
		id: (props as IEditEntityScreenProps<IAttachment>).recordId || 0,
		name: "",
		content: null
	};
	if (isOpportunityProps(props)) {
		(attachment as IOpportunityAttachment).opportunityId = props.opportunityId;
	} else if (isProjectParticipantProps(props)) {
		(attachment as IProjectParticipantAttachment).projectParticipantId = props.projectParticipantId;
	} else if (isTaskProps(props)) {
		(attachment as IFollowUpTaskAttachment).taskId = props.taskId;
	}
	return attachment;
}

const AttachmentEditor: React.FC<BaseProps & SpecificProps> = (props: BaseProps & SpecificProps) => {
	const { t } = useTranslation();

	const [attachment, setAttachment] = useState<IAttachment>(getInitialAttachment(props));
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [uploadError, setUploadError] = useState(false);
	const [token, setToken] = useState("");

	useEffect(() => {
		getToken().then((securityToken: string) => setToken(securityToken));
	});

	let reducer: Reducer<IEntityState<IAttachment>, IAction>;
	let initialState: IEntityState<IAttachment>;
	if (isOpportunityProps(props)) {
		reducer = (opportunityAttachmentReducer as unknown) as Reducer<IEntityState<IAttachment>, IAction>;
		initialState = getInitialState<IOpportunityAttachment>();
	} else if (isProjectParticipantProps(props)) {
		reducer = (projectParticipantAttachmentReducer as unknown) as Reducer<IEntityState<IAttachment>, IAction>;
		initialState = getInitialState<IProjectParticipantAttachment>();
	} else if (isTaskProps(props)) {
		reducer = (followUpTaskAttachmentReducer as unknown) as Reducer<IEntityState<IAttachment>, IAction>;
		initialState = getInitialState<IFollowUpTaskAttachment>();
	}

	const [attachmentState, attachmentDispatch] = useReducer(reducer, initialState);

	const reduxDispatch: Dispatch = useDispatch();
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (attachmentState.entity) {
			setAttachment(attachmentState.entity);
		}
	}, [attachmentState.entity]);

	function onChange(event: InputChangeEvent): void {
		setAttachment(handleChange(attachment, event));
		setDataChanged(true);
	}

	function onActionButtonClicked(close: boolean, record: IAttachment): void {
		if (record && !close) {
			setAttachment(record);
			setDataChanged(false);
		}

		props.actionButtonClicked(close, record);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(attachment.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!attachment.content || isNullOrEmpty(attachment.content.internalFilename)) {
			messages.push(t("fill_in_required_field", { field: t("file").toLowerCase() }));
		}
		if (uploadError) {
			messages.push(t("please_fix_upload"), "success");
		}
		return messages;
	}

	function onUploadError(): void {
		setUploadError(true);
	}

	function onBeforeUpload(event: UploadOnBeforeUploadEvent): void {
		event.headers.Authorization = `Bearer ${token}`;
	}

	function fileUploaded(response: UploadResponse): void {
		const newAttachment: IAttachment = {
			...attachment,
			content: {
				filename: response.response.filename,
				internalFilename: response.response.internalFilename,
				size: response.response.size,
				type: response.response.type
			}
		};
		if (isNullOrEmpty(newAttachment.name)) {
			newAttachment.name = response.response.filename;
		}

		if (isOpportunityProps(props)) {
			(newAttachment as IOpportunityAttachment).opportunityId = props.opportunityId;
		} else if (isProjectParticipantProps(props)) {
			(newAttachment as IProjectParticipantAttachment).projectParticipantId = props.projectParticipantId;
		} else if (isTaskProps(props)) {
			(newAttachment as IFollowUpTaskAttachment).taskId = props.taskId;
		}

		setAttachment(newAttachment);
		setDataChanged(true);
		setUploadError(false);
	}

	function onRemoveFile(): void {
		const key: string = newKey("confirm_file_remove");
		reduxDispatch(
			render(key, Confirm, {
				title: t("confirm_title"),
				onConfirm: () => {
					const newAttachment: IAttachment = {
						...attachment,
						content: null
					};

					if (isOpportunityProps(props)) {
						(newAttachment as IOpportunityAttachment).opportunityId = props.opportunityId;
					} else if (isProjectParticipantProps(props)) {
						(newAttachment as IProjectParticipantAttachment).projectParticipantId = props.projectParticipantId;
					} else if (isTaskProps(props)) {
						(newAttachment as IFollowUpTaskAttachment).taskId = props.taskId;
					}

					setAttachment(newAttachment);
					setDataChanged(true);
					setUploadError(false);

					reduxDispatch(derender(key));
				},
				onDecline: () => reduxDispatch(derender(key)),
				children: t("confirm_file_remove")
			})
		);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IAttachment>).readonly;
	let endpoint: Endpoint;
	let extraParams: Record<string, unknown>;

	if (isOpportunityProps(props)) {
		endpoint = Endpoint.OpportunityAttachments;
		extraParams = { opportunityId: props.opportunityId };
	} else if (isProjectParticipantProps(props)) {
		endpoint = Endpoint.ProjectParticipantAttachments;
		extraParams = { projectParticipantId: props.projectParticipantId };
	} else if (isTaskProps(props)) {
		endpoint = Endpoint.FollowUpTaskAttachments;
		extraParams = { taskId: props.taskId };
	}

	return (
		<EntityEditor
			width="70%"
			record={attachment}
			endpoint={endpoint}
			extraUrlParams={extraParams}
			entityState={attachmentState}
			entityType={t("attachment")}
			dispatch={attachmentDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={attachment.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={attachment.name} required onChange={onChange} disabled={readonly} />
				</label>
				{!readonly && (
					<div className="k-form-field">
						<span>
							{t("file")} * {uploadError && <i className="las la-exclamation-triangle" style={{ color: "red", fontSize: "15px" }} />}
						</span>
						<div className="d-flex align-items-center">
							<div className="flex-grow-1" style={{ marginRight: "10px" }}>
								<ApiFileUpload
									showFileList
									initialFiles={attachment.content ? [attachment.content] : []}
									onError={onUploadError}
									fileUploaded={fileUploaded}
									multiple={false}
									onBeforeUpload={onBeforeUpload}
								/>
							</div>
							<Button primary onClick={onRemoveFile}>
								{t("remove")}
							</Button>
						</div>
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default AttachmentEditor;
