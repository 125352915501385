import { SchedulerItemProps, SchedulerViewItem } from "@progress/kendo-react-scheduler";
import React from "react";

export default function OutllookPlanningSchedulerViewItem({ children, ...props }: React.PropsWithChildren<SchedulerItemProps>): JSX.Element {
	return (
		<SchedulerViewItem {...props} isRecurring={props.dataItem.isRecurring}>
			{children}
		</SchedulerViewItem>
	);
}
