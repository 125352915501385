import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, enumFilterCell, GridPanel, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../../services/api/endpoint";
import { EmailImportance } from "../../../../../utils/enums";
import EmailEditor from "../../email";

import style from "../../../../../assets/editor.module.scss";

interface IProps {
	taskId: number;
}

const TaskEmails: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.FollowUpTaskEmailsList}
			listUrlArguments={{ taskId: props.taskId }}
			endpoint={Endpoint.FollowUpTaskEmails}
			addScreen={{ screen: EmailEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			editScreen={{ screen: EmailEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			delete={{ urlArguments: { taskId: props.taskId } }}
			localLoader
			sort={[{ field: "createdDate", dir: "desc" }]}
		>
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="to" title={t("to")} />
			<GridColumn field="cc" title={t("cc")} />
			<GridColumn field="bcc" title={t("bcc")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="importance" title={t("importance")} cell={customCell(translatedCell())} filterCell={enumFilterCell(EmailImportance)} />
			<GridColumn field="body" title={t("body")} />
		</GridPanel>
	);
};

export default TaskEmails;
