import { ReactElement, useState } from "react";
import React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { derender } from "@selas/state-management";
import { Form, StandardButton, SubmitButton } from "@selas/ui-components";
import chunk from "lodash/chunk";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import pull from "lodash/pull";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// import StandardButton from "../../buttons/standardButton";
// import SubmitButton from "../../buttons/submitButton";
// import Form from "../../editor/form";

export interface ColumnConfiguration {
	field: string;
	title: string;
}

interface Props {
	renderKey: string;
	columns: string[];
	allColumns: ColumnConfiguration[];
	setColumns: (columns: string[]) => void;
}

export default function ColumnSelector({ renderKey, columns, allColumns, setColumns }: Props): ReactElement {
	const { t } = useTranslation();
	const [selectedColumns, setSelectedColumns] = useState<string[]>(columns);
	const reduxDispatch = useDispatch();

	function onCheckboxChange(event: CheckboxChangeEvent) {
		const newSelectedColumns = [...selectedColumns];
		if (event.value && !newSelectedColumns?.includes(event.target.element.name)) {
			newSelectedColumns?.push(event.target.element.name);
		} else if (!event.value && newSelectedColumns?.includes(event.target.element.name)) {
			pull(newSelectedColumns, event.target.element.name);
		}
		setSelectedColumns(newSelectedColumns);
	}

	function save() {
		setColumns(selectedColumns);
		reduxDispatch(derender(renderKey));
	}

	return (
		<Form>
			<Dialog width="600px" height="400px" title={t("selectColumns")} onClose={() => reduxDispatch(derender(renderKey))}>
				<div className="k-form">
					{flatMap(chunk(allColumns, 3), (chunkedColumns: ColumnConfiguration[]) => (
						<div className="row">
							{map(chunkedColumns, (column: ColumnConfiguration) => (
								<div className="col-4 k-form-field">
									<Checkbox name={column.field} value={selectedColumns?.includes(column.field)} label={column.title} onChange={onCheckboxChange} />
								</div>
							))}
						</div>
					))}
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => reduxDispatch(derender(renderKey))}>{t("cancel")}</StandardButton>
					<SubmitButton primary onClick={save}>
						{t("save")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
		</Form>
	);
}
