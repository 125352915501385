import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "redux";
import { initialProjectState, IProjectState } from "..";

import Endpoint from "../../services/api/endpoint";
import { IProject } from "../../utils/types/models";

const projectReducer: Reducer<IProjectState, IAction> = (state: IProjectState = initialProjectState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.MyProjectsList: {
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.MyProjectsList: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				let results: IProject[] = payload.response.data.data;
				if (payload.response.data.group) {
					results = payload.response.data.group;
				}
				state = {
					...state,
					list: results || [],
					totalCount: payload.response.data.total || 0
				};
			}
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.MyProjectsList: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				listLoadError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.MyProjectsList: {
			state = {
				...state,
				isListLoading: false
			};

			break;
		}
		case "CLEAR_POST_" + Endpoint.MyProjectsList: {
			state = {
				...state,
				list: [],
				totalCount: 0,
				isListLoading: true,
				listLoadError: null
			};
			break;
		}
		case "REQUEST_POST_" + Endpoint.CreateFromOpportunity:
			state = {
				...state,
				isAdding: true,
				addedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.CreateFromOpportunity: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					addedEntity: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.CreateFromOpportunity: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.CreateFromOpportunity:
			state = {
				...state,
				isAdding: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.CreateFromOpportunity:
			state = {
				...state,
				isAdding: false,
				addedEntity: null,
				addError: null
			};
			break;

		case "REQUEST_GET_" + Endpoint.ProjectParticipantsReport:
			state = {
				...state,
				isDownloading: true
			};
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.ProjectParticipantsReport: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				downloadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProjectParticipantsReport: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };

			if (action.payload && payload.response && payload.response.data) {
				const contentDisposition: string = payload.response.headers["content-disposition"];
				const match: RegExpMatchArray = contentDisposition.match(/filename\s*=\s*(.+);/i);
				const filename: string = match[1];

				state = {
					...state,
					downloadFile: payload.response.data,
					filename
				};
			}
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.ProjectParticipantsReport:
			state = {
				...state,
				isDownloading: false
			};
			break;

		case "REQUEST_POST_" + Endpoint.CopyProject:
			state = {
				...state,
				isAdding: true,
				addedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.CopyProject: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					addedEntity: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.CopyProject: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.CopyProject:
			state = {
				...state,
				isAdding: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.CopyProject:
			state = {
				...state,
				isAdding: false,
				addedEntity: null,
				addError: null
			};
			break;

		default:
			return state;
	}
	return state;
};

export default createEntityReducer<IProject, IProjectState>(Endpoint.Projects, Endpoint.ProjectsList, projectReducer);
