import React, { useEffect, useReducer, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Upload, UploadFileInfo, UploadOnAddEvent } from "@progress/kendo-react-upload";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { Confirm, Form, notify, StandardButton, SubmitButton } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { hideLoader, showLoader } from "@selas/state-management";
import Endpoint from "../../services/api/endpoint";
import { initialBackOfficeTaskRequestState } from "../../state";
import { backOfficeTaskRequestReducer } from "../../state/reducers";

interface IProps {
	excelType: string;
	inputData?: Record<string, unknown>;
	onClose: () => void;
}
const ExcelUploadDialog: React.FC<IProps> = ({ excelType, inputData, onClose }: IProps) => {
	const { t } = useTranslation();
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [askSaveChange, setAskSaveChange] = useState<boolean>(false);
	const [file, setFile] = useState<UploadFileInfo>(null);
	const [backOfficeState, backOfficeDispatch] = useReducer(backOfficeTaskRequestReducer, initialBackOfficeTaskRequestState);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!backOfficeState.isRequesting && backOfficeState.success) {
			reduxDispatch(notify(t("uploadParticipantsList"), t("processRequestInitiated"), "success"));
			onClose();
		}
		return () => {
			reduxDispatch(hideLoader());
		};
	}, [backOfficeState.isRequesting, backOfficeState.success, onClose, reduxDispatch, t]);

	useEffect(() => {
		if (backOfficeState.isRequesting) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [backOfficeState.isRequesting, reduxDispatch]);

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			onClose();
		}
	}

	function upload(): void {
		if (file == null) {
			reduxDispatch(notify(t("information"), t("fill_in_required_field", { field: t("document").toLowerCase() }), "success"));
		}
		const formData: FormData = new FormData();
		formData.append("type", excelType);
		formData.append("file", file.getRawFile());
		if (inputData) {
			formData.append("inputData", JSON.stringify(inputData));
		}
		apiService.callApi(backOfficeDispatch, Endpoint.BackofficeTaskRequestImportExcel, "POST", null, formData);
	}

	function addFile(event: UploadOnAddEvent): void {
		const files: UploadFileInfo[] = event.affectedFiles.filter((f: UploadFileInfo) => !f.validationErrors);

		if (files.length > 0) {
			setFile(files[0]);
			setDataChanged(true);
		}
	}

	function removedFile(): void {
		setFile(null);
		setDataChanged(false);
	}

	return (
		<Form>
			<Dialog title={t("upload.uploadSelectedFiles") + " " + t("document")} width="70%" onClose={() => handleClose()} autoFocus>
				<div className="k-form">
					<div className="k-form-field">
						<span>{t("document")} *</span>

						<Upload
							batch={false}
							defaultFiles={[]}
							multiple={false}
							autoUpload={false}
							withCredentials={false}
							showActionButtons={false}
							onAdd={addFile}
							onRemove={removedFile}
							restrictions={{
								allowedExtensions: [".xlsx"]
							}}
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							className="manualUpload"
						/>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => handleClose()}>{t("cancel")}</StandardButton>
					<SubmitButton primary onClick={() => upload()} disabled={!dataChanged}>
						{t("upload.uploadSelectedFiles")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && dataChanged && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						upload();
					}}
					onDecline={() => onClose()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</Form>
	);
};

export default ExcelUploadDialog;
