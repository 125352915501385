import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import get from "lodash/get";

import HeatRow from "./row";

function heatCell<T>(fieldToCheck?: keyof T): React.FC<GridCellProps> {
	const HeatCell: React.FC<GridCellProps> = (props: GridCellProps) => {
		if (fieldToCheck && !props.dataItem[fieldToCheck]) {
			return <></>;
		}
		return <HeatRow reactKey={props.field} currentHeat={get(props.dataItem, props.field)} readonly />;
	};

	return HeatCell;
}

export default heatCell;
