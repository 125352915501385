import React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody } from "@progress/kendo-react-layout";
import round from "lodash/round";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { hasPermission } from "../../../../services/authentication";
import { IApplicationState } from "../../../../store";
import { Permission, SessionStatus } from "../../../../utils/enums";
import { ISession, IUser } from "../../../../utils/types/models";

import style from "./planning.module.scss";

interface CardProps {
	key: number;
	session: ISession;
	orangeLimit: string;
	redLimit: string;
	listCard: boolean;
	openSession: (session: ISession, readOnly: boolean) => void;
}

export const SessionCard: React.FC<CardProps> = (props: CardProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	function generateOccupationView(occupation: number): React.ReactNode {
		let colorClass: string = "las la-check " + style.greenStatusSmall;

		if (parseFloat(props.redLimit) === 0 || parseFloat(props.orangeLimit) === 0 || (!occupation && !props.session.maximumParticipantCount)) {
			colorClass = "las la-question " + style.greyStatusSmall;
			return <i title={t("planning.no_information")} className={colorClass} />;
		}

		if (occupation < parseFloat(props.orangeLimit)) {
			colorClass = "las la-check " + style.greenStatusSmall;
		} else if (occupation >= parseFloat(props.orangeLimit) && occupation < parseFloat(props.redLimit)) {
			colorClass = "las la-exclamation " + style.orangeStatusSmall;
		} else if (occupation >= parseFloat(props.redLimit)) {
			colorClass = "las la-ban " + style.redStatusSmall;
		}

		return <i title={t("planning.occupation_message", { percentage: round(occupation, 2) })} className={colorClass} />;
	}

	function getColoredStatus(status: SessionStatus): React.ReactNode {
		switch (status) {
			case SessionStatus.New:
				return <pre className={style.multiline + " " + style.blue}>{t(status)}</pre>;
			case SessionStatus.InSession:
			case SessionStatus.PlannedOrange:
				return <pre className={style.multiline + " " + style.orange}>{t(status)}</pre>;
			case SessionStatus.PlannedGreen:
			case SessionStatus.Closed:
			case SessionStatus.Cancelled:
				return <pre className={style.multiline + " " + style.green}>{t(status)}</pre>;
			case SessionStatus.PlannedRed:
				return <pre className={style.multiline + " " + style.red}>{t(status)}</pre>;
		}
	}

	return (
		<div key={"session_" + props.session.id}>
			<Card className={style.card}>
				<CardBody style={{ padding: "10px" }}>
					<pre style={{ fontWeight: "bold", fontSize: "18px" }} className={style.multiline}>
						{props.session.name}
					</pre>
					{getColoredStatus(props.session.status)}
					{props.listCard && (
						<>
							<pre className={style.multiline}>{t("responsible") + ": " + props.session.responsibleUser?.fullName}</pre>
							<pre className={style.multiline}>
								{t("start") + ": " + (props.session.plannedStart ? intl.formatDate(new Date(props.session.plannedStart), "yyyy-MM-dd HH:mm") : "-")}
								<br />
								{t("end") + ": " + (props.session.plannedStop ? intl.formatDate(new Date(props.session.plannedStop), "yyyy-MM-dd HH:mm") : "-")}
							</pre>
						</>
					)}
					{!props.listCard && (
						<>
							<pre className={style.multiline}>{t("start") + ": " + (props.session.plannedStart ? intl.formatDate(new Date(props.session.plannedStart), "yyyy-MM-dd HH:mm") : "-")}</pre>
							<pre className={style.multiline}>{t("end") + ": " + (props.session.plannedStop ? intl.formatDate(new Date(props.session.plannedStop), "yyyy-MM-dd HH:mm") : "-")}</pre>
						</>
					)}
					<div className="row">
						<div className="col-1" style={{ display: "flex", justifyContent: "flex-start" }}>
							{generateOccupationView(props.session.occupationPercentage)}
						</div>
						<div className={"col " + style.groupsName}>{props.session.group?.name}</div>
						{/* {hasPermission(Permission.SessionsUpdate) && (hasPermission(Permission.SessionsAdministrator) || props.session.responsibleUserId === currentUser.id) && ( */}
						<div className="col-1" style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								look="flat"
								iconClass="las la-chevron-circle-right"
								style={{ cursor: "pointer", fontSize: "18px", paddingBottom: "0px", verticalAlign: "bottom" }}
								title={t("planning.openSession")}
								onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
									props.openSession(
										props.session,
										!hasPermission(Permission.SessionsUpdate) && !hasPermission(Permission.SessionsAdministrator) && props.session.responsibleUserId !== currentUser.id
									)
								}
							/>
						</div>
						{/* )} */}
					</div>
				</CardBody>
			</Card>
		</div>
	);
};
