import { IAction } from "@selas/models";
import { IEntityState, ILoaderState, IRenderState, loaderReducer, renderReducer } from "@selas/state-management";
import { NotificationsState, reducer as notifications } from "react-notification-system-redux";
import { applyMiddleware, combineReducers, compose, createStore, Reducer, Store } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { IAuthenticationState, IFileState, IPermssionState, IUserState } from "../state";
import { authenticationReducer, permissionReducer, roleReducer, userReducer } from "../state/reducers";
import fileReducer from "../state/reducers/fileReducer";
import { IRole } from "../utils/types/models";

const middleware: ThunkMiddleware[] = [thunk];

// @ts-ignore
// tslint:disable-next-line: no-any
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface IApplicationState {
	authenticationState: IAuthenticationState;
	roleState: IEntityState<IRole>;
	userState: IUserState;
	permissionState: IPermssionState;
	notificationState: NotificationsState;
	loaderState: ILoaderState;
	renderState: IRenderState;
	fileState: IFileState;
}

const allReducers: Reducer<IApplicationState, IAction> = combineReducers<IApplicationState>({
	authenticationState: authenticationReducer,
	roleState: roleReducer,
	userState: userReducer,
	permissionState: permissionReducer,
	notificationState: notifications,
	loaderState: loaderReducer,
	renderState: renderReducer,
	fileState: fileReducer
});

const store: Store<IApplicationState> = createStore(allReducers, composeEnhancers(applyMiddleware(...middleware)));
export default store;
