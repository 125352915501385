import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, GridPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { Permission } from "../../../../../utils/enums";
import NoteEditor from "../../../followup/note";

import style from "../../../../../assets/editor.module.scss";

interface IProps {
	projectParticipantId: number;
}

const ProjectParticipantNotes: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectParticipantNotesList}
			listUrlArguments={{ projectParticipantId: props.projectParticipantId }}
			endpoint={Endpoint.ProjectParticipantNotes}
			addScreen={{
				screen: NoteEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectParticipantId: props.projectParticipantId }
			}}
			editScreen={{
				screen: NoteEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectParticipantId: props.projectParticipantId }
			}}
			delete={{ isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate), urlArguments: { projectParticipantId: props.projectParticipantId } }}
			localLoader
		>
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="createdDate" title={t("createdDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="content" title={t("note")} />
		</GridPanel>
	);
};

export default ProjectParticipantNotes;
