import React, { useEffect, useReducer, useRef } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, hideLoader, render, showLoader } from "@selas/state-management";
import { checkBoxCell, customCell, GridPanel, IGridPanelRef, StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { opportunityServiceReducer } from "../../../../state/reducers";
import { Permission } from "../../../../utils/enums";
import { IOpportunityService } from "../../../../utils/types/models";
import OpportunityServiceEditor from "../opportunityService";

import style from "../../../../assets/editor.module.scss";
import ImportFromInvoicngGroupDialogue from "../../project/projectParticipant/importFromInvoicingGroup";

interface IProps {
	opportunityId: number;
}

const OpportunityServices: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [opportunityServiceState, opportunityServiceDispatch] = useReducer(opportunityServiceReducer, getInitialState<IOpportunityService>());
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (opportunityServiceState.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [opportunityServiceState.isUpdating, reduxDispatch]);

	useEffect(() => {
		if (!opportunityServiceState.isUpdating && opportunityServiceState.updatedEntity) {
			ref.current.refresh();
		}
	}, [opportunityServiceState.isUpdating, opportunityServiceState.updatedEntity, reduxDispatch]);

	function moveUpPriority(record: IOpportunityService): void {
		callApiPriority(true, record);
	}

	function moveDownPriority(record: IOpportunityService): void {
		callApiPriority(false, record);
	}

	function callApiPriority(moveUp: boolean, record: IOpportunityService): void {
		apiService.callApi(
			opportunityServiceDispatch,
			Endpoint.OpportunityServicesUpdatePriority,
			"PUT",
			{ opportunityId: record.opportunityId, id: record.id },
			{ id: record.id, newPriority: record.priority + (moveUp ? -1 : 1) }
		);
	}

	function importFromInvoicingGroup(): void {
		const importKey: string = newKey("import");
		reduxDispatch(
			render(importKey, ImportFromInvoicngGroupDialogue, {
				opportunityId: props.opportunityId,
				onClose: (refresh: boolean) => {
					if (refresh) {
						ref.current.refresh();
					}
					reduxDispatch(derender(importKey));
				}
			})
		);
	}

	let toolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.OpportunitiesUpdate)) {
		toolbarButtons = [
			<StandardButton key="importFromInvoicingGroupButton" onClick={importFromInvoicingGroup} primary>
				{t("importFromInvoicingGroup")}
			</StandardButton>
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.OpportunityServicesList}
			listUrlArguments={{ opportunityId: props.opportunityId }}
			endpoint={Endpoint.OpportunityServices}
			addScreen={{ screen: OpportunityServiceEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			editScreen={{ screen: OpportunityServiceEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			delete={{ urlArguments: { opportunityId: props.opportunityId }, isAllowed: hasPermission(Permission.OpportunitiesUpdate) }}
			localLoader
			sort={[{ field: "priority", dir: "asc" }]}
			ref={ref}
			extraToolbarButtons={toolbarButtons}
			extraCommands={[
				{
					iconClass: "las la-angle-up",
					tooltip: t("priorityUp"),
					recordAction: moveUpPriority,
					showCommand: (dataItem: IOpportunityService) => hasPermission(Permission.OpportunitiesUpdate) && dataItem.priority > 1 && dataItem.priority !== 0
				},
				{
					iconClass: "las la-angle-down",
					tooltip: t("priorityDown"),
					recordAction: moveDownPriority,
					showCommand: (dataItem: IOpportunityService) => hasPermission(Permission.OpportunitiesUpdate) && dataItem.priority < ref.current.totalCount && dataItem.priority !== 0
				}
			]}
		>
			<GridColumn field="code" title={t("serviceCode")} />
			<GridColumn field="type.name" title={t("serviceType")} />
			<GridColumn field="shortDescription" title={t("shortDescription")} />
			<GridColumn field="isProjectLevelService" title={t("isProjectLevelService")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
			<GridColumn field="priority" title={t("priority")} filter="numeric" />
		</GridPanel>
	);
};

export default OpportunityServices;
