import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "redux";

import Endpoint from "../../services/api/endpoint";
import { ISessionFollowUpTask } from "../../utils/types/models";

const sessionFollowUpTaskReducer: Reducer<IEntityState<ISessionFollowUpTask>, IAction> = (state: IEntityState<ISessionFollowUpTask> = getInitialState<ISessionFollowUpTask>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_PUT_" + Endpoint.SessionFollowUpTasksPostMultiple:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_PUT_" + Endpoint.SessionFollowUpTasksPostMultiple: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				updatedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_PUT_" + Endpoint.SessionFollowUpTasksPostMultiple: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.SessionFollowUpTasksPostMultiple:
			state = {
				...state,
				isUpdating: false
			};
			break;
	}
	return state;
};

export default createEntityReducer<ISessionFollowUpTask>(Endpoint.SessionFollowUpTasks, Endpoint.SessionFollowUpTasksList, sessionFollowUpTaskReducer);
