import React, { useRef } from "react";

import { Card, CardBody } from "@progress/kendo-react-layout";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";

import { IProjectParticipant } from "../../../../../utils/types/models";

import style from "./style.module.scss";

interface ParticipantCardProps {
	participant: IProjectParticipant;
	hoverCard?: (dragIndex: number, hoverIndex: number) => void;
}

export interface DragItem {
	id: string;
	type: string;
	participant: IProjectParticipant;
}

export const ParticipantCard: React.FC<ParticipantCardProps> = (props: ParticipantCardProps) => {
	const { t } = useTranslation();
	const ref: React.MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

	const [{ isDragging }, drag] = useDrag({
		item: { type: "PARTICIPANT", id: "card_participant_" + props.participant.id, participant: props.participant },
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: !!monitor.isDragging()
		}),
		canDrag: true
	});

	drag(ref);

	return (
		<div key={"card_participant_" + props.participant.id} ref={ref}>
			<Card className={style.participant} style={{ opacity: isDragging ? 0.8 : 1 }}>
				<CardBody style={{ padding: "10px" }}>
					<div className="row">
						<div className="col" style={{ display: "flex", justifyContent: "flex-start" }}>
							<pre style={{ fontWeight: "bold" }} className={style.multiline}>
								{props.participant.participant.fullName}
							</pre>
						</div>
						<div className="col-1" style={{ display: "flex", justifyContent: "flex-end" }}>
							<pre className={style.multiline}>{t(props.participant.statute)}</pre>
						</div>
					</div>
					<div className="row">
						<div className="col" style={{ display: "flex", justifyContent: "flex-start" }}>
							<pre className={style.multiline}>{t(props.participant.participant.communicationLanguage)}</pre>
						</div>
						<div className="col" style={{ display: "flex", justifyContent: "flex-end" }}>
							<pre className={style.multiline}>{t(props.participant.ageClass)}</pre>
						</div>
					</div>
					<div className="row">
						<div className="col" style={{ display: "flex", justifyContent: "flex-end" }}>
							<pre className={style.multiline}>{props.participant.participant.address.postalCode}</pre>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};
