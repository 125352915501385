import { IAction } from "@selas/models";
import { AxiosError } from "axios";
import { Reducer } from "react";
import { IBackOfficeTaskRequestState, initialBackOfficeTaskRequestState } from "..";

import Endpoint from "../../services/api/endpoint";

const backOfficeTaskRequestReducer: Reducer<IBackOfficeTaskRequestState, IAction> = (state: IBackOfficeTaskRequestState = initialBackOfficeTaskRequestState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.BackofficeTaskRequestEmailAndOrDocument:
		case "REQUEST_POST_" + Endpoint.BackofficeTaskRequestImportExcel:
		case "REQUEST_POST_" + Endpoint.BackofficeTaskRequestSendSessionInvite:
		case "REQUEST_POST_" + Endpoint.RequestPartcipantsInfoByEmail:
		case "REQUEST_POST_" + Endpoint.BackofficeRequestEmail:
			state = {
				...state,
				isRequesting: true,
				success: false,
				error: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.BackofficeTaskRequestEmailAndOrDocument:
		case "REQUEST_SUCCESS_POST_" + Endpoint.BackofficeTaskRequestImportExcel:
		case "REQUEST_SUCCESS_POST_" + Endpoint.BackofficeTaskRequestSendSessionInvite:
		case "REQUEST_SUCCESS_POST_" + Endpoint.RequestPartcipantsInfoByEmail:
		case "REQUEST_SUCCESS_POST_" + Endpoint.BackofficeRequestEmail:
			state = {
				...state,
				success: true
			};
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.BackofficeTaskRequestEmailAndOrDocument:
		case "REQUEST_FAILURE_POST_" + Endpoint.BackofficeTaskRequestImportExcel:
		case "REQUEST_FAILURE_POST_" + Endpoint.BackofficeTaskRequestSendSessionInvite:
		case "REQUEST_FAILURE_POST_" + Endpoint.RequestPartcipantsInfoByEmail:
		case "REQUEST_FAILURE_POST_" + Endpoint.BackofficeRequestEmail: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				error: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.BackofficeTaskRequestEmailAndOrDocument:
		case "REQUEST_END_POST_" + Endpoint.BackofficeTaskRequestImportExcel:
		case "REQUEST_END_POST_" + Endpoint.BackofficeTaskRequestSendSessionInvite:
		case "REQUEST_END_POST_" + Endpoint.RequestPartcipantsInfoByEmail:
		case "REQUEST_END_POST_" + Endpoint.BackofficeRequestEmail:
			state = {
				...state,
				isRequesting: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.BackofficeTaskRequestEmailAndOrDocument:
		case "CLEAR_POST_" + Endpoint.BackofficeTaskRequestImportExcel:
		case "CLEAR_POST_" + Endpoint.BackofficeTaskRequestSendSessionInvite:
		case "CLEAR_POST_" + Endpoint.RequestPartcipantsInfoByEmail:
		case "CLEAR_POST_" + Endpoint.BackofficeRequestEmail:
			state = {
				...state,
				isRequesting: false,
				success: false,
				error: null
			};
			break;
	}
	return state;
};

export default backOfficeTaskRequestReducer;
