import React, { MutableRefObject, useEffect, useReducer, useRef, useState } from "react";

import { State } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, render } from "@selas/state-management";
import { Confirm, Form, notify, SearchBox, StandardButton, SubmitButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { sessionParticipantReducer } from "../../../state/reducers";
import { ISessionParticipant } from "../../../utils/types/models";

interface IProps {
	projectId: number;
	sessionId: number;
	onSave: (sessionParticipantId: number) => void;
	onCancel: () => void;
}

const SelectSessionParticipant: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [sessionParticipant, setSessionParticipant] = useState(null);
	const [sessionParticipantState, sessionParticipantDispatch] = useReducer(sessionParticipantReducer, getInitialState<ISessionParticipant>());
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();
	const comboboxRef: MutableRefObject<ComboBox> = useRef<ComboBox>();

	useEffect(() => {
		if (comboboxRef && comboboxRef.current) {
			comboboxRef.current.focus();
		}
	}, [comboboxRef]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		const queryRequest: State = {
			skip: 0,
			take: 500,
			sort: [
				{
					field: "projectParticipant.participant.fullName",
					dir: "asc"
				}
			],
			filter: {
				logic: "and",
				filters: [
					{
						field: "projectParticipant.participant.fullName",
						operator: "contains",
						value: event.filter.value
					}
				]
			}
		};
		apiService.callApi(sessionParticipantDispatch, Endpoint.SessionParticipantsList, "POST", { projectId: props.projectId, sessionId: props.sessionId }, queryRequest);
	}

	function onChange(event: ComboBoxCloseEvent): void {
		setSessionParticipant(event.target.value);
	}

	function save(): void {
		if (!sessionParticipant) {
			reduxDispatch(notify(t("information"), t("fill_in_required_field", { field: t("participant").toLowerCase() }), "success"));
		} else {
			props.onSave(sessionParticipant.id);
		}
	}

	function handleClose(): void {
		if (sessionParticipant) {
			const confirmKey: string = newKey("confirm");
			reduxDispatch(
				render(confirmKey, Confirm, {
					title: t("pending_changes"),
					children: t("ask_save"),
					onConfirm: () => {
						reduxDispatch(derender(confirmKey));
						save();
					},
					onDecline: () => {
						reduxDispatch(derender(confirmKey));
						props.onCancel();
					},
					onCancel: () => reduxDispatch(derender(confirmKey))
				})
			);
		} else {
			props.onCancel();
		}
	}

	return (
		<Form>
			<Dialog width="30%" onClose={handleClose} title={t("individualSession")}>
				<div className="k-form">
					<div className="k-form-field">{t("selectExistingParticipantProjectChangesWillBeSaved")}</div>
					<div className="k-form-field">
						<span>{t("participant")} *</span>
						<SearchBox
							myRef={comboboxRef}
							name="sessionParticipant"
							entities={sessionParticipantState.list}
							isLoading={sessionParticipantState.isListLoading}
							entityId={sessionParticipant?.id}
							entity={sessionParticipant}
							textField="projectParticipant.participant.fullName"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setSessionParticipant(null)}
							required
						/>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={handleClose}>{t("cancel")}</StandardButton>
					<SubmitButton disabled={!sessionParticipant} onClick={save}>
						{t("save")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
		</Form>
	);
};

export default SelectSessionParticipant;
