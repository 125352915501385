import React, { Children, forwardRef, RefAttributes, useCallback, useEffect, useImperativeHandle, useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridFilterChangeEvent, GridRowClickEvent, GridSelectionChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { useApiService } from "@selas/api-communication";
import { IEntity } from "@selas/models";
import { createEntityReducer, derender, getInitialState, hideLoader, IEntityState, render, showLoader } from "@selas/state-management";
import { Confirm, Loader, StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import includes from "lodash/includes";
import merge from "lodash/merge";
import pull from "lodash/pull";
import pullAllBy from "lodash/pullAllBy";
import some from "lodash/some";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { commandCell, customCell } from "./customCells/gridCells";
import { IAnchorCommand, IFunctionCommand, ILinkCommand, IRecordCommand } from "./customCells/gridCells/commandCell";
import { GridPanelColumnProps } from "./gridPanelColumn";
import ColumnConfigurationMenu from "./gridPanelColumn/columnConfigurationMenu";
import { ColumnConfiguration } from "./gridPanelColumn/columnSelector";
import {
	IAddEntityScreenConfiguration,
	IAddEntityScreenProps,
	IDeleteEntityConfiguration,
	IEditEntityScreenConfiguration,
	IEditEntityScreenFunctionConfiguration,
	IEditEntityScreenProps,
	IEntityLinkConfiguration,
	isAddEntityLink,
	isAddScreen,
	isDeleteConfiguration,
	isEditEntityLink,
	isEditScreen,
	isEntityLinkConfiguration,
	isObject
} from "./gridPanelColumn/types";
import { intitialGridPanelConfigurationState } from "./gridpanelConfigurationStates";
import createGridPanelConfigurationReducer from "./state/reducers/gridPanelConfigurationReducer";

function getGridCommandColumn<T>(title: string, commands: (IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T> | IAnchorCommand<T>)[]): React.ReactElement {
	return (
		<GridColumn
			title={title}
			cell={customCell(commandCell(commands))}
			width={Math.max(60, commands.length * 20 + 28) + "px"}
			filterable={false}
			editable={false}
			sortable={false}
			groupable={false}
			headerClassName="notSortable"
		/>
	);
}

export interface UserGridPanelConfiguration {
	gridKey: string;
	configurationEndpoint: string;
	filterable: boolean;
}

interface IGridPanelProps<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined> {
	listEndpoint: string;
	listUrlArguments?: Record<string, unknown>;
	endpoint?: string;
	children?: React.ReactElement<GridPanelColumnProps>[] | React.ReactElement<GridPanelColumnProps>;
	userGridPanelConfiguration?: UserGridPanelConfiguration;
	addScreen?: string | IEntityLinkConfiguration | React.ComponentType<IAddEntityScreenProps<TEntity>> | IAddEntityScreenConfiguration<TEntity, TAddScreenExtraProps>;
	editScreen?:
		| string
		| IEntityLinkConfiguration
		| React.ComponentType<IEditEntityScreenProps<TEntity>>
		| IEditEntityScreenFunctionConfiguration<TEntity, TEditScreenExtraProps>
		| IEditEntityScreenConfiguration<TEntity, TEditScreenExtraProps>;
	delete: boolean | IDeleteEntityConfiguration<TEntity>;
	filter?: CompositeFilterDescriptor;
	group?: GroupDescriptor[];
	sort?: SortDescriptor[];
	style?: React.CSSProperties;
	frontActions?: boolean;
	disableDoubleClick?: boolean;
	className?: string;
	extraCommands?: (IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[];
	extraToolbarButtons?: React.ReactElement[];
	localLoader?: boolean;
}

interface IGridPanelRef {
	refresh: () => void;
	totalCount: number;
}

// ***********  CHECKBOX COLUMN ***********

interface SingleSelectionProps<TEntity extends IEntity> {
	onSelectionChange?: (entity: TEntity) => void;
}

interface MultipleSelectionProps<TEntity extends IEntity> {
	selectionMode: "multiple";
	onSelectionChange?: (selectionState: TEntity[] | HeaderSelectedState) => void;
}

export interface HeaderSelectedState {
	deselectedIds: number[];
}

// ****************************************

let timeout: NodeJS.Timeout = null;
const SELECTED_FIELD = "selected";

function GridPanelWithoutRef<TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props:
		| IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps>
		| (IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps> & (SingleSelectionProps<TEntity> | MultipleSelectionProps<TEntity>)),
	ref: React.Ref<IGridPanelRef>
): React.ReactElement {
	const onSelectionChange = "onSelectionChange" in props ? props.onSelectionChange : undefined;
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(createEntityReducer<TEntity, IEntityState<TEntity>>(props.endpoint, props.listEndpoint), getInitialState<TEntity>());
	const [currentColumns, setCurrentColumns] = useState<string[]>(Children.map(props.children, (child) => child.props.field));
	const [allColumns] = useState<ColumnConfiguration[]>(Children.map(props.children, (child) => ({ field: child.props.field, title: child.props.title })));

	const [gridPanelConfigurationState, gridPanelConfigurationDispatch] = useReducer(
		createGridPanelConfigurationReducer(props.userGridPanelConfiguration?.configurationEndpoint),
		intitialGridPanelConfigurationState
	);

	const [commands, setCommands] = useState<(IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[]>([]);
	const initState: State = { skip: 0, take: 25, filter: props.filter, group: props.group, sort: props.sort };
	const [gridState, setGridState] = useState<State>(initState);
	const [displayFilter, setDisplayFilter] = useState(props.filter);
	const [entities, setEntities] = useState<TEntity[]>([]);
	const [selectionState, setSelectionState] = useState<TEntity[] | HeaderSelectedState>([]);
	const [deleted, setDeleted] = useState(false);
	const apiService = useApiService();
	const reduxDispatch: Dispatch = useDispatch();
	const history = useHistory();

	function refreshGrid() {
		setSelectionState([]);
		if (onSelectionChange) {
			onSelectionChange(null);
		}
		apiService.callApi(dispatch, props.listEndpoint, "POST", { ...props.listUrlArguments }, gridState);
	}

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refreshGrid();
		},
		totalCount: state.totalCount
	}));

	const refreshGridConfiguration = useCallback(() => {
		if (props.userGridPanelConfiguration) {
			apiService.callApi(gridPanelConfigurationDispatch, props.userGridPanelConfiguration.configurationEndpoint, "GET", { gridKey: props.userGridPanelConfiguration.gridKey });
		}
	}, [props.userGridPanelConfiguration, apiService]);

	useEffect(() => {
		refreshGridConfiguration();
	}, [refreshGridConfiguration]);

	useEffect(() => {
		if (props.userGridPanelConfiguration && !gridPanelConfigurationState.isUpdating && gridPanelConfigurationState.updatedEntity) {
			refreshGridConfiguration();
		}
	}, [gridPanelConfigurationState.isUpdating, gridPanelConfigurationState.updatedEntity, props.userGridPanelConfiguration, refreshGridConfiguration]);

	useEffect(() => {
		if (!gridPanelConfigurationState.isSaving && gridPanelConfigurationState.savedGridPanelConfiguration) {
			refreshGridConfiguration();
		}
	}, [gridPanelConfigurationState.isSaving, gridPanelConfigurationState.savedGridPanelConfiguration, refreshGridConfiguration]);

	useEffect(() => {
		if (!gridPanelConfigurationState.isLoading && gridPanelConfigurationState.gridPanelConfiguration?.columns && gridPanelConfigurationState.gridPanelConfiguration.columns.length > 0) {
			setCurrentColumns(gridPanelConfigurationState.gridPanelConfiguration.columns);
		} else {
			setCurrentColumns(flatten(Children.map(props.children, (child) => (child.props.isDefault ? child.props.field : undefined))));
		}
	}, [props.children, gridPanelConfigurationState.gridPanelConfiguration, gridPanelConfigurationState.isLoading, gridState]);

	useEffect(() => {
		const newCommands: (IFunctionCommand<TEntity> | IRecordCommand<TEntity> | ILinkCommand<TEntity>)[] = props.extraCommands || [];
		if (props.delete === true || (isDeleteConfiguration(props.delete) && props.delete.isAllowed !== false)) {
			newCommands.unshift({
				tooltip: t("remove"),
				iconClass: "las la-times",
				recordAction: deleteEntity
			});
		}

		if (props.editScreen) {
			if (isEditEntityLink(props.editScreen)) {
				if (typeof props.editScreen === "string" || (isEntityLinkConfiguration(props.editScreen) && props.editScreen.isAllowed !== false)) {
					let editLink = "";
					let openInNewTab = false;
					if (isEntityLinkConfiguration(props.editScreen)) {
						editLink = props.editScreen.link;
						openInNewTab = props.editScreen.openInNewTab;
					}
					newCommands.unshift({
						tooltip: t("edit"),
						iconClass: "las la-pencil-alt",
						link: editLink,
						newTab: openInNewTab
					});
				}
			} else if (
				isEditScreen(props.editScreen) ||
				(!isEditScreen(props.editScreen) && ((typeof props.editScreen.isAllowed === "boolean" && props.editScreen.isAllowed !== false) || typeof props.editScreen.isAllowed === "function"))
			) {
				let showCommand: (dataItem: TEntity) => boolean;
				if (!isEditScreen(props.editScreen) && typeof props.editScreen.isAllowed === "function") {
					showCommand = props.editScreen.isAllowed;
				}
				newCommands.unshift({
					tooltip: t("edit"),
					iconClass: "las la-pencil-alt",
					recordAction: editEntity,
					showCommand
				});
			}
		}
		setCommands(newCommands);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		refreshGrid();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridState, props.listEndpoint]);

	useEffect(() => {
		if (deleted) {
			setDeleted(false);
			refreshGrid();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleted]);

	useEffect(() => {
		if (!props.localLoader) {
			if (state.isListLoading || state.isDeleting) {
				reduxDispatch(showLoader());
			} else if (!state.isListLoading && !state.isDeleting) {
				reduxDispatch(hideLoader());
			}
		}
	}, [props.localLoader, reduxDispatch, state.isDeleting, state.isListLoading]);

	useEffect(() => {
		if (state.list) {
			setEntities(state.list);
		}
	}, [state.list]);

	function saveColumns(colums: string[]): void {
		const newgridPanelConfiguration = cloneDeep(gridPanelConfigurationState);
		newgridPanelConfiguration.gridPanelConfiguration.columns = colums;
		apiService.callApi(
			gridPanelConfigurationDispatch,
			props.userGridPanelConfiguration?.configurationEndpoint,
			"PUT",
			{ gridKey: props.userGridPanelConfiguration?.gridKey },
			newgridPanelConfiguration.gridPanelConfiguration
		);
	}

	function addEntity(): void {
		if (props.addScreen && !isAddEntityLink(props.addScreen)) {
			const key: string = newKey("add_" + typeof state.entity);
			const addScreenProps: IAddEntityScreenProps<TEntity> = {
				actionButtonClicked: (close: boolean, record?: TEntity) => editorActionButtonClicked(close, key, record),
				hideActive: false
			};
			if (isAddScreen(props.addScreen)) {
				reduxDispatch(render(key, props.addScreen, addScreenProps));
			} else {
				reduxDispatch(
					render(key, props.addScreen.screen, {
						...addScreenProps,
						...props.addScreen.extraProps
					})
				);
			}
		}
	}

	function editEntity(record: TEntity): void {
		if (props.editScreen && !isEditEntityLink(props.editScreen)) {
			const key: string = newKey("edit");
			const editScreenProps: IEditEntityScreenProps<TEntity> = {
				recordId: record.id,
				readonly: false,
				actionButtonClicked: (close: boolean, record?: TEntity) => editorActionButtonClicked(close, key, record),
				hideActive: false
			};
			if (isEditScreen(props.editScreen)) {
				reduxDispatch(render(key, props.editScreen, editScreenProps));
			} else {
				const isAllowed =
					(typeof props.editScreen.isAllowed === "boolean" && props.editScreen.isAllowed !== false) ||
					(typeof props.editScreen.isAllowed === "function" && props.editScreen.isAllowed(record) !== false);
				if (isAllowed) {
					editScreenProps.readonly = editScreenProps.readonly || !props.editScreen.isAllowed;
					reduxDispatch(
						render(key, props.editScreen.screen, {
							...editScreenProps,
							...(isObject(props.editScreen.extraProps) ? props.editScreen.extraProps : props.editScreen.extraProps(record))
						})
					);
				}
			}
		} else if (props.editScreen && isEditEntityLink(props.editScreen)) {
			let link = "";
			let openNewInNew = false;
			let isAllowed = true;
			if (isEntityLinkConfiguration(props.editScreen)) {
				link = props.editScreen.link;
				if (props.editScreen.openInNewTab) {
					openNewInNew = true;
				}
				if (props.editScreen.isAllowed === false) {
					isAllowed = false;
				}
			} else {
				link = props.editScreen;
			}
			if (isAllowed) {
				link = link.replace(":id", record.id.toString());
				if (openNewInNew) {
					window.open("#" + link);
				} else {
					history.push(link);
				}
			}
		}
	}

	function deleteEntity(entity: TEntity): void {
		const confirmKey: string = newKey("confirm");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title", { action: t("remove").toLowerCase() }),
				children: t("confirm_content", { action: t("remove").toLowerCase() }),
				onDecline: () => reduxDispatch(derender(confirmKey)),
				onConfirm: () => {
					reduxDispatch(derender(confirmKey));
					let deleteArguments: Record<string, unknown>;
					if (isDeleteConfiguration(props.delete)) {
						if (isObject(props.delete.urlArguments)) {
							deleteArguments = props.delete.urlArguments;
						} else {
							// @ts-ignore
							deleteArguments = props.delete.urlArguments(entity);
						}
					}
					apiService.callApi(dispatch, props.endpoint, "DELETE", merge({ id: entity.id }, deleteArguments), null, null, () => setDeleted(true));
				}
			})
		);
	}

	function onExpandChange(event: GridExpandChangeEvent): void {
		event.dataItem[event.target.props.expandField] = event.value;
		setEntities([...entities]);
	}

	function handleRowClick(event: GridRowClickEvent): void {
		if ("onSelectionChange" in props && !("selectionMode" in props)) {
			setSelectionState([event.dataItem]);
			if (props.onSelectionChange) {
				props.onSelectionChange(event.dataItem);
			}
		}
	}

	function handleSelectionChange(event: GridSelectionChangeEvent): void {
		if ("selectionMode" in props && props.selectionMode === "multiple") {
			const { dataItem } = event;
			let newSelectionState: TEntity[] | HeaderSelectedState = [];
			newSelectionState = cloneDeep(selectionState);
			if (Array.isArray(newSelectionState) && some(newSelectionState, { id: dataItem.id })) {
				pullAllBy(newSelectionState, [{ id: dataItem.id }], "id");
			} else if (Array.isArray(newSelectionState)) {
				newSelectionState.push(dataItem);
			} else if (!Array.isArray(newSelectionState) && (newSelectionState.deselectedIds.length === 0 || !includes(newSelectionState.deselectedIds, dataItem.id))) {
				newSelectionState.deselectedIds.push(dataItem.id);
			} else if (!Array.isArray(newSelectionState)) {
				pull(newSelectionState.deselectedIds, dataItem.id);
			}

			if (props.onSelectionChange) {
				props.onSelectionChange(newSelectionState);
			}

			setSelectionState(newSelectionState);
		}
	}

	function handleHeaderSelectionChange(): void {
		let newSelectionState: TEntity[] | HeaderSelectedState;
		if ((!Array.isArray(selectionState) && selectionState.deselectedIds.length === 0) || (Array.isArray(selectionState) && selectionState.length === state.totalCount)) {
			newSelectionState = [];
		} else {
			newSelectionState = { deselectedIds: [] };
		}
		setSelectionState(newSelectionState);
		if ("selectionMode" in props && props.onSelectionChange && props.selectionMode === "multiple") {
			props.onSelectionChange(newSelectionState);
		}
	}

	function editorActionButtonClicked(close: boolean, key: string, record: TEntity): void {
		reduxDispatch(derender(key));
		if (record) {
			if (!close) {
				editEntity(record);
			}
			refreshGrid();
		}
	}

	const toolbarButtons: React.ReactElement[] = [];
	if (props.addScreen) {
		if (isAddEntityLink(props.addScreen)) {
			let link = null;
			if (!isEntityLinkConfiguration(props.addScreen)) {
				link = props.addScreen;
			} else if (props.addScreen.isAllowed) {
				link = props.addScreen.link;
			}
			if (link) {
				toolbarButtons.push(
					<Link key="add_toolbarkey" to={link}>
						<StandardButton primary>{t("add")}</StandardButton>
					</Link>
				);
			}
		} else if (isAddScreen(props.addScreen) || (!isAddScreen(props.addScreen) && props.addScreen.isAllowed)) {
			toolbarButtons.push(
				<StandardButton key="add_toolbarkey" primary onClick={addEntity}>
					{t("add")}
				</StandardButton>
			);
		}
	}
	if (props.extraToolbarButtons && props.extraToolbarButtons.length > 0) {
		toolbarButtons.push(...props.extraToolbarButtons);
	}

	return (
		<>
			{props.localLoader && (state.isListLoading || state.isDeleting) && <Loader />}
			<Grid
				className={"noTopBorder flex-grow-1 flex-shrink-1 " + props.className || ""}
				style={{ ...props.style }}
				total={state.totalCount}
				{...gridState}
				filter={displayFilter}
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
				onRowDoubleClick={!props.disableDoubleClick ? (event: GridRowDoubleClickEvent) => editEntity(event.dataItem) : undefined}
				onRowClick={handleRowClick}
				onExpandChange={onExpandChange}
				onSelectionChange={handleSelectionChange}
				onHeaderSelectionChange={handleHeaderSelectionChange}
				expandField="expanded"
				selectedField={SELECTED_FIELD}
				data={entities?.map((item) => ({
					...item,
					[SELECTED_FIELD]: (!Array.isArray(selectionState) && !includes(selectionState.deselectedIds, item.id)) || (Array.isArray(selectionState) && some(selectionState, { id: item.id }))
				}))}
				filterable
				sortable
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				scrollable="scrollable"
				onFilterChange={(event: GridFilterChangeEvent) => {
					clearTimeout(timeout);
					timeout = setTimeout(
						() =>
							setGridState((oldState: State) => {
								return { ...oldState, skip: 0, filter: event.filter };
							}),
						500
					);
					setDisplayFilter(event.filter);
				}}
			>
				<GridToolbar>
					<div className={(toolbarButtons && toolbarButtons.length > 0 ? "" : "noButtons ") + "toolbarButtonContainer d-flex w-100 align-items-center"}>
						{toolbarButtons}
						<div className="flex-grow-1" />
						<i className="refreshButton las la-sync" onClick={refreshGrid} />
					</div>
				</GridToolbar>

				{"selectionMode" in props && props.selectionMode === "multiple" && (
					<GridColumn
						key={`${props.userGridPanelConfiguration?.gridKey}_${SELECTED_FIELD}`}
						field={SELECTED_FIELD}
						sortable={false}
						filterable={false}
						headerSelectionValue={
							(!Array.isArray(selectionState) && selectionState.deselectedIds.length === 0) || (Array.isArray(selectionState) && selectionState.length === state.totalCount)
						}
						width={undefined}
					/>
				)}

				{commands.length > 0 && props.frontActions && getGridCommandColumn(t("actions"), commands)}
				{props.userGridPanelConfiguration &&
					Children.map(
						props.children,
						(child, idx) =>
							currentColumns?.includes(child.props.field) && (
								<GridColumn
									{...child.props}
									key={`${props.userGridPanelConfiguration?.gridKey}_${idx}`}
									width={child.props.width ?? "unset"}
									columnMenu={(props) => <ColumnConfigurationMenu columns={currentColumns} allColumns={allColumns} setColumns={saveColumns} {...props} />}
								/>
							)
					)}
				{!props.userGridPanelConfiguration && props.children}
				{commands.length > 0 && !props.frontActions && getGridCommandColumn(t("actions"), commands)}
			</Grid>
		</>
	);
}

type GridPanelType = <TEntity extends IEntity, TAddScreenExtraProps = undefined, TEditScreenExtraProps = undefined>(
	props: (
		| IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps>
		| (IGridPanelProps<TEntity, TAddScreenExtraProps, TEditScreenExtraProps> & (SingleSelectionProps<TEntity> | MultipleSelectionProps<TEntity>))
	) &
		RefAttributes<IGridPanelRef>
) => React.ReactElement;
const GridPanel: GridPanelType = forwardRef(GridPanelWithoutRef) as GridPanelType;

export { getGridCommandColumn };
export type { IGridPanelRef };
export default GridPanel;
