import React, { ReactElement } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";

import { Color } from "../../utils/enums";

type getColorFunction = (props: GridCellProps | ListItemProps) => Color;

export function statusDropdownItemRender(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps, getColor: getColorFunction): React.ReactNode {
	let colorClass: string;
	const color: Color = getColor(itemProps);
	switch (color) {
		case Color.Green:
			colorClass = "las la-thumbs-up greenStatus";
			break;
		case Color.Orange:
			colorClass = "las la-hand-point-right orangeStatus";
			break;
		case Color.Red:
			colorClass = "las la-thumbs-down redStatus";
			break;
		case Color.Blue:
			colorClass = "las la-external-link-alt blueStatus";
			break;
		case Color.Grey:
			colorClass = "las la-clock greyStatus";
			break;
		default:
			colorClass = "";
			break;
	}

	return React.cloneElement(
		li,
		li.props,
		<div>
			<i className={colorClass} style={{ marginRight: "10px" }} />
			{li.props.children}
		</div>
	);
}
