import React, { useEffect, useReducer, useState } from "react";

import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialSystemState } from "@selas/state-management";
import { isNullOrEmpty } from "@selas/utils";

import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import Endpoint from "../../services/api/endpoint";
import { municipalityReducer } from "../../state/reducers";
import { IAddress, IMunicipality } from "../../utils/types/models";

interface IProps {
	address: IAddress;
	addressField: string;
	onChange: (event: InputChangeEvent) => void;
	setAddress: (address: IAddress) => void;
	required?: boolean;
	numberRequired?: boolean;
	readonly: boolean;
}

const AddressEdit: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [municipalityState, dispatch] = useReducer(municipalityReducer, getInitialSystemState<IMunicipality>());
	const [municipality, setMunicipality] = useState<IMunicipality>();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		setMunicipality({
			id: 0,
			code: isNullOrEmpty(props.address.postalCode) ? "" : props.address.postalCode,
			name: isNullOrEmpty(props.address.municipality) ? "" : props.address.municipality,
			province: "",
			country: ""
		});
	}, [props.address.postalCode, props.address.municipality]);

	function onMunicipalityClose(event: ComboBoxCloseEvent): void {
		const newAddress: IAddress = { ...props.address };
		if (event.target.value && event.target.value.id) {
			const selectedMunicipality: IMunicipality = event.target.value;
			newAddress.postalCode = selectedMunicipality.code;
			newAddress.municipality = selectedMunicipality.name;
			newAddress.province = selectedMunicipality.province;
			newAddress.country = selectedMunicipality.country;
		} else if (event.target.value) {
			if (event.target.name === props.addressField + ".postalCode") {
				newAddress.postalCode = event.target.value.code;
			} else if (event.target.name === props.addressField + ".municipality") {
				newAddress.municipality = event.target.value.name;
			}
		} else {
			if (event.target.name === props.addressField + ".postalCode") {
				newAddress.postalCode = "";
			} else if (event.target.name === props.addressField + ".municipality") {
				newAddress.municipality = "";
			}
		}
		props.setAddress(newAddress);
	}

	function onMunicipalityChange(event: ComboBoxChangeEvent): void {
		setMunicipality(event.value);
	}

	function municipalityItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, itemProps.dataItem.code + " " + itemProps.dataItem.name);
	}

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-8">
					<label className="k-form-field">
						<span>
							{t("street")}
							{props.required && " *"}
						</span>
						<Input
							name={props.addressField + ".street"}
							className="full-width-field"
							value={props.address.street}
							onChange={props.onChange}
							required={props.required}
							disabled={props.readonly}
						/>
					</label>
				</div>
				<div className="col-4">
					<label className="k-form-field">
						<span>
							{t("number")}
							{props.numberRequired && " *"}
						</span>
						<Input
							name={props.addressField + ".number"}
							className="full-width-field"
							value={props.address.number}
							onChange={props.onChange}
							required={props.numberRequired}
							disabled={props.readonly}
						/>
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col-4">
					<div className="k-form-field">
						<span>
							{t("postalCode")}
							{props.required && " *"}
						</span>
						<ComboBox
							name={props.addressField + ".postalCode"}
							className="full-width-field hiddenTrigger"
							data={municipalityState.entities}
							dataItemKey="id"
							textField="code"
							itemRender={municipalityItem}
							value={municipality}
							onClose={onMunicipalityClose}
							onChange={onMunicipalityChange}
							allowCustom
							clearButton={false}
							filterable
							onFilterChange={(event: ComboBoxFilterChangeEvent) => apiService.callApi(dispatch, Endpoint.Municipalities, "GET", { search: event.filter.value, field: "postalCode" })}
							required={props.required}
							disabled={props.readonly}
							valid={!props.required || (props.required && !isNullOrEmpty(props.address?.postalCode))}
						/>
					</div>
				</div>
				<div className="col-8">
					<div className="k-form-field">
						<span>
							{t("municipality")}
							{props.required && " *"}
						</span>
						<ComboBox
							name={props.addressField + ".municipality"}
							className="full-width-field hiddenTrigger"
							data={municipalityState.entities}
							dataItemKey="id"
							textField="name"
							itemRender={municipalityItem}
							value={municipality}
							onClose={onMunicipalityClose}
							onChange={onMunicipalityChange}
							allowCustom
							clearButton={false}
							filterable
							onFilterChange={(event: ComboBoxFilterChangeEvent) => apiService.callApi(dispatch, Endpoint.Municipalities, "GET", { search: event.filter.value, field: "name" })}
							required={props.required}
							disabled={props.readonly}
							valid={!props.required || (props.required && !isNullOrEmpty(props.address?.municipality))}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("province")}</span>
						<Input name={props.addressField + ".province"} className="full-width-field" value={props.address.province} onChange={props.onChange} disabled={props.readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>
							{t("country")}
							{props.required && " *"}
						</span>
						<Input
							name={props.addressField + ".country"}
							className="full-width-field"
							value={props.address.country}
							onChange={props.onChange}
							required={props.required}
							disabled={props.readonly}
						/>
					</label>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddressEdit;
