import { Reducer } from "react";

import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import Endpoint from "../../services/api/endpoint";
import { IGroupParticipantBase } from "../../utils/types/models";

const groupParticipantsReducer: Reducer<IEntityState<IGroupParticipantBase>, IAction> = (state: IEntityState<IGroupParticipantBase> = getInitialState<IGroupParticipantBase>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.ProjectParticipantsDetailByGroup: {
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProjectParticipantsDetailByGroup: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				let results: IGroupParticipantBase[] = payload.response.data.data;
				if (payload.response.data.group) {
					results = payload.response.data.group;
				}
				state = {
					...state,
					totalCount: payload.response.data.total || 0,
					list: results || []
				};
			}
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.ProjectParticipantsDetailByGroup: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				listLoadError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.ProjectParticipantsDetailByGroup: {
			state = {
				...state,
				isListLoading: false
			};

			break;
		}
		case "CLEAR_POST_" + Endpoint.ProjectParticipantsDetailByGroup: {
			state = {
				...state,
				list: [],
				isListLoading: false,
				listLoadError: null
			};
			break;
		}
	}
	return state;
};

export default createEntityReducer<IGroupParticipantBase>(Endpoint.ProjectParticipants, Endpoint.ProjectParticipantsList, groupParticipantsReducer);
