import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, dateCell, enumFilterCell, GridPanel, IRoutedTabProps, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { BackOfficeTaskType } from "../../../utils/enums";

const BackOfficeTasks: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel listEndpoint={Endpoint.BackofficeTasksList} endpoint={Endpoint.BackofficeTasks} delete={false}>
			<GridColumn field="createdDate" title={t("date")} filter="date" cell={customCell(dateCell("yyyy-MM-dd"))} />
			<GridColumn field="type" title={t("type")} cell={customCell(translatedCell())} filterCell={enumFilterCell(BackOfficeTaskType)} />
			<GridColumn field="processed" title={t("processed")} filter="boolean" cell={customCell(checkBoxCell())} />
			<GridColumn field="error" title={t("error")} filter="boolean" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default BackOfficeTasks;
