import { Reducer } from "react";

import { IAction } from "@selas/models";
import { createEntityReducer, IEntityState } from "@selas/state-management";

import Endpoint from "../../services/api/endpoint";
import { IContact } from "../../utils/types/models";

export const companyContactReducer: Reducer<IEntityState<IContact>, IAction> = createEntityReducer<IContact>(Endpoint.CompanyContacts, Endpoint.CompanyContactsList);

export const partnerContactReducer: Reducer<IEntityState<IContact>, IAction> = createEntityReducer<IContact>(Endpoint.PartnerContacts, Endpoint.PartnerContactsList);

export default createEntityReducer<IContact>(Endpoint.Contacts, Endpoint.ContactsList);
