import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IEntity } from "@selas/models";
import { derender, getInitialState, render } from "@selas/state-management";
import { Confirm, EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, SearchBox, setEntity, Tab, TabPanel, TranslatedSwitch, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty, newKey } from "@selas/utils";
import _ from "lodash";
import filter from "lodash/filter";
import find from "lodash/find";
import some from "lodash/some";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Endpoint from "../../../../services/api/endpoint";
import ILanguageItem from "../../../../services/translation/languageitem";
import { ILanguageConfiguration, languages } from "../../../../services/translation/languages";
import { roleReducer, userReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { emailRegex } from "../../../../utils";
import { Permission } from "../../../../utils/enums";
import { IRole, IUser, IUserRole } from "../../../../utils/types/models";

import editorStyles from "../../../../assets/editor.module.scss";
import { hasPermission } from "../../../../services/authentication";
import { initialUserState } from "../../../../state";

const UserEditor: React.FC<IAddEntityScreenProps<IUser> | IEditEntityScreenProps<IUser>> = (props: IAddEntityScreenProps<IUser> | IEditEntityScreenProps<IUser>) => {
	const { t } = useTranslation();
	const languageItems: ILanguageItem[] = languages.map((languageItem: ILanguageConfiguration) => {
		return {
			code: languageItem.code,
			translationKey: languageItem.translationKey,
			description: t(languageItem.translationKey)
		};
	});
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const [replacemantState, replacementDispatch] = useReducer(userReducer, initialUserState);
	const [roleState, roleDispatch] = useReducer(roleReducer, getInitialState<IRole>());
	const initalUser: IUser = {
		id: (props as IEditEntityScreenProps<IUser>).recordId || 0,
		firstName: "",
		lastName: "",
		fullName: "",
		email: "",
		mobilePhoneNumber: "",
		language: "nl",
		active: true,
		userRoles: []
	};
	const [user, setUser] = useState<IUser>(initalUser);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const apiService: ApiCommunicator = useApiService();
	const firstField: React.MutableRefObject<Input> = useRef();
	const reduxDispatch: ThunkDispatch<IApplicationState, {}, AnyAction> = useDispatch<ThunkDispatch<IApplicationState, {}, AnyAction>>();

	useEffect(() => {
		if (!hasPermission(Permission.UsersRead)) {
			props.actionButtonClicked(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		apiService.callApi(roleDispatch, Endpoint.Roles, "GET");
	}, [apiService]);

	useEffect(() => {
		if (userState.entity) {
			setUser(userState.entity);
		}
	}, [userState.entity]);

	function actionButtonClicked(close: boolean, record?: IUser): void {
		if (record && !close) {
			setUser(record);
		}
		if (record && record.id === currentUser.id) {
			reduxDispatch(apiService.callApiRedux(Endpoint.CurrentUser, "GET"));
			if (
				some(record.userRoles, (userRole: IUserRole) => {
					const role: IRole = find(roleState.entities, { id: userRole.roleId });
					return role.permissions[Permission.UsersRead];
				})
			) {
				props.actionButtonClicked(close, record);
			} else {
				props.actionButtonClicked(close);
			}
		} else {
			props.actionButtonClicked(close, record);
		}
	}

	function onChange(event: InputChangeEvent | SwitchChangeEvent | ComboBoxCloseEvent): void {
		const userRecord: IUser = handleChange(user, event);
		if (userRecord.id === currentUser.id && !userRecord.active) {
			confirmChange(userRecord, t("confirm_currentuser_activechange"));
		} else {
			setUser(userRecord);
			setDataChanged(true);
		}
	}

	function onLanguageChange(event: DropDownListChangeEvent): void {
		const userRecord: IUser = { ...user };

		if (event.target.value) {
			userRecord.language = event.target.value.code;
			setUser(userRecord);
			setDataChanged(true);
		}
	}

	function confirmChange(userRecord: IUser, confirmContent: string): void {
		const confirmKey: string = newKey("confirm_change");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("edit"),
				children: confirmContent,
				onConfirm: () => {
					setDataChanged(true);
					setUser(userRecord);
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	function onChangeAllRoles(event: SwitchChangeEvent): void {
		const userRecord: IUser = { ...user };
		userRecord.userRoles = [];
		if (event.value) {
			roleState.entities.forEach((role: IRole) => {
				userRecord.userRoles.push({ roleId: role.id, userId: userRecord.id });
			});
		}
		if (userRecord.id === currentUser.id && !_.isEqual(userRecord.userRoles, currentUser.userRoles)) {
			confirmChange(userRecord, t("confirm_currentuser_rolechange"));
		} else {
			setDataChanged(true);
			setUser(userRecord);
		}
	}

	function onChangeRole(event: SwitchChangeEvent): void {
		const userRecord: IUser = _.cloneDeep(user);
		const roleId: number = parseInt(event.target.name, 0);
		if (event.value) {
			userRecord.userRoles.push({ roleId, userId: userRecord.id });
			setDataChanged(true);
		} else {
			const matchedRole: IUserRole = userRecord.userRoles.find((userRole: IUserRole) => userRole.roleId === roleId);
			if (matchedRole) {
				userRecord.userRoles.splice(userRecord.userRoles.indexOf(matchedRole), 1);
				setDataChanged(true);
			}
		}
		if (userRecord.id === currentUser.id && !_.isEqual(userRecord.userRoles, currentUser.userRoles)) {
			confirmChange(userRecord, t("confirm_currentuser_rolechange"));
		} else {
			setUser(userRecord);
		}
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IUser, idField: keyof IUser): void {
		const newUser: IUser = setEntity(user, entity, field, idField);
		setUser(newUser);
		setDataChanged(true);
	}

	function allEntitiesSelected<S, T>(entities: S[], userEntities: T[], match: (entity: S, userEntity: T) => boolean): boolean {
		if (userEntities && entities.length > 0) {
			let hasAllRoles: boolean = true;
			entities.forEach((entity: S): void => {
				const matchedCompany: T = userEntities.find((userEntity: T) => match(entity, userEntity));
				if (!matchedCompany) {
					hasAllRoles = false;
					return;
				}
			});

			return hasAllRoles;
		}
		return false;
	}

	function hasEntity<T>(entities: T[], match: (entity: T) => boolean): boolean {
		if (entities) {
			const matchedRole: T = entities.find(match);
			return matchedRole ? true : false;
		}
		return false;
	}

	function allRolesSelected(): boolean {
		return allEntitiesSelected<IRole, IUserRole>(roleState.entities, user.userRoles, (role: IRole, roleCompany: IUserRole) => roleCompany.roleId === role.id);
	}

	function hasRole(roleId: number): boolean {
		return hasEntity<IUserRole>(user.userRoles, (userRole: IUserRole) => userRole.roleId === roleId);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(user.firstName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(user.lastName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(user.email) || !user.email.match(emailRegex)) {
			messages.push(t("fill_in_required_field", { field: t("email").toLowerCase() }));
		}
		if (isNullOrEmpty(user.language)) {
			messages.push(t("fill_in_required_field", { field: t("language").toLowerCase() }));
		}
		if (!user.userRoles || user.userRoles.length <= 0) {
			messages.push(t("select_at_least_one", { entity: t("role").toLowerCase() }));
		}
		return messages;
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(replacementDispatch, Endpoint.Users, "GET", { search: event.filter.value });
	}

	const readonly: boolean =
		(props as IEditEntityScreenProps<IUser>).readonly ||
		(hasPermission(Permission.RolesRead) && ((!user.id && !hasPermission(Permission.UsersAdd)) || (user.id && !hasPermission(Permission.UsersUpdate))));

	return (
		<EntityEditor
			width="70%"
			record={user}
			endpoint={Endpoint.Users}
			entityState={userState}
			entityType={t("user")}
			dispatch={userDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={user.firstName + " " + user.lastName}
			actionButtonClicked={actionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="details" label={t("details")}>
					<div className="k-form">
						<label className="k-form-field">
							<span>{t("firstName")} *</span>
							<Input name="firstName" ref={firstField} className="full-width-field" value={user.firstName} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("lastName")} *</span>
							<Input name="lastName" className="full-width-field" value={user.lastName} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("email")} *</span>
							<Input name="email" type="email" className="full-width-field" value={user.email} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("mobilePhoneNumber")}</span>
							<Input name="mobilePhoneNumber" className="full-width-field" value={user.mobilePhoneNumber} onChange={onChange} disabled={readonly} />
						</label>
						<div className="k-form-field">
							<span>{t("language")} *</span>
							<DropDownList
								className="full-width-field"
								data={languageItems}
								dataItemKey="code"
								textField="description"
								value={find(languageItems, { code: user.language })}
								onChange={onLanguageChange}
								required
								disabled={readonly}
							/>
						</div>
						<div className="k-form-field">
							<span>{t("replacement")}</span>
							<SearchBox
								name="replacementId"
								entities={filter(replacemantState.entities, (replacingUser: IUser) => replacingUser.id !== user.id)}
								entityId={user.replacementId}
								entity={user.replacement}
								textField="fullName"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "replacement", "replacementId")}
								disabled={readonly}
							/>
						</div>
						<div className="k-form-field">
							<div>{t("active")}</div>
							<YesNoSwitch name="active" checked={user.active} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</Tab>
				<Tab reactKey="roles" label={t("roles")}>
					<div className="k-form">
						<div className={"row " + editorStyles.spaced2Row + " " + editorStyles.switchRow}>
							<div className="col-md-11">{t("selectAll")}</div>
							<div className="col-md-1">
								<TranslatedSwitch checked={allRolesSelected()} onChange={onChangeAllRoles} disabled={readonly} />
							</div>
						</div>
						{roleState.entities.map((role: IRole) => (
							<div className={"row spaced-row " + editorStyles.switchRow} key={role.id}>
								<div className="col-md-11">
									<span className={hasRole(role.id) ? editorStyles.checkedRow : ""}>{role.name}</span>
								</div>
								<div className="col-md-1">
									<TranslatedSwitch name={role.id.toString()} onChange={onChangeRole} checked={hasRole(role.id)} disabled={readonly} />
								</div>
							</div>
						))}
					</div>
				</Tab>
			</TabPanel>
		</EntityEditor>
	);
};

export default UserEditor;
