import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useReducer } from "react";

import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IDictionary } from "@selas/models";
import dayjs from "dayjs";
import groupBy from "lodash/groupBy";
import { useTranslation } from "react-i18next";

import { IPlanningChild } from ".";
import Endpoint from "../../../../services/api/endpoint";
import { initialSessionState } from "../../../../state";
import { sessionReducer } from "../../../../state/reducers";
import { dateToString } from "../../../../utils";
import { ISession, IUser } from "../../../../utils/types/models";
import UserWeek from "./userWeek";

import style from "./planning.module.scss";

export interface BoardProps {
	users: IUser[];
	week: SelectionRange;
	redLimit: string;
	orangeLimit: string;
	openSession: (session: ISession, readOnly: boolean) => void;
	showLoader: (key: string) => void;
	hideLoader: (key: string) => void;
}

function PlanningBoard({ users, week, redLimit, orangeLimit, openSession, showLoader, hideLoader }: BoardProps, ref: Ref<IPlanningChild>): JSX.Element {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [boardSessions, boardSessionsDispatch] = useReducer(sessionReducer, initialSessionState);
	const apiService: ApiCommunicator = useApiService();

	const refresh: () => void = useCallback(() => {
		apiService.callApi(boardSessionsDispatch, Endpoint.SessionsInWeek, "GET", { start: week.start.toISOString(), end: week.end.toISOString() });
	}, [apiService, week.end, week.start]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	useEffect(() => {
		const key: string = "boardSessions";
		if (boardSessions.areEntitiesLoading) {
			showLoader(key);
		} else {
			hideLoader(key);
		}
	}, [hideLoader, boardSessions.areEntitiesLoading, showLoader]);

	useImperativeHandle(
		ref,
		() => ({
			refresh
		}),
		[refresh]
	);

	const itemsByUser: IDictionary<ISession[]> = useMemo(() => groupBy(boardSessions.entities, "responsibleUserId"), [boardSessions.entities]);

	return (
		<>
			<div className={"row " + style.boardHeader} style={{ width: "98%" }}>
				<div className="col-1" />
				<div className="col">{t("planning.monday") + " " + dateToString(week.start, intl)}</div>
				<div className="col">{t("planning.tuesday") + " " + dateToString(dayjs(week.start).add(1, "day").toDate(), intl)}</div>
				<div className="col">{t("planning.wednesday") + " " + dateToString(dayjs(week.start).add(2, "day").toDate(), intl)}</div>
				<div className="col">{t("planning.thursday") + " " + dateToString(dayjs(week.start).add(3, "day").toDate(), intl)}</div>
				<div className="col">{t("planning.friday") + " " + dateToString(dayjs(week.start).add(4, "day").toDate(), intl)}</div>
				<div className="col">{t("planning.saturday") + " " + dateToString(dayjs(week.start).add(5, "day").toDate(), intl)}</div>
			</div>
			<div className={style.planningUsersGrid}>
				<div style={{ width: "98%" }}>
					<div>
						{users.map((user: IUser) => (
							<UserWeek key={user.id} week={week} user={user} sessions={itemsByUser[user.id]} orangeLimit={orangeLimit} redLimit={redLimit} openSession={openSession} />
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default forwardRef(PlanningBoard);
