import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "redux";

import Endpoint from "../../services/api/endpoint";
import { IProjectParticipantService } from "../../utils/types/models";

const projectParticipantServiceReducer: Reducer<IEntityState<IProjectParticipantService>, IAction> = (
	state: IEntityState<IProjectParticipantService> = getInitialState<IProjectParticipantService>(),
	action: IAction
) => {
	switch (action.type) {
		case "REQUEST_PUT_" + Endpoint.ProjectParticipantServicesUpdatePriority:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_SUCCESS_PUT_" + Endpoint.ProjectParticipantServicesUpdatePriority: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				updatedEntity: payload.response.data
			};

			break;
		}
		case "REQUEST_FAILURE_PUT_" + Endpoint.ProjectParticipantServicesUpdatePriority: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				updateError: payload.error
			};

			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.ProjectParticipantServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false
			};

			break;
		case "CLEAR_PUT_" + Endpoint.ProjectParticipantServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_POST_" + Endpoint.ProjectParticipantServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: true,
				addedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProjectParticipantServicesImportFromInvoicingGroup: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				addedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.ProjectParticipantServicesImportFromInvoicingGroup: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.ProjectParticipantServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: false
			};
			break;
	}
	return state;
};

export default createEntityReducer(Endpoint.ProjectParticipantServices, Endpoint.ProjectParticipantServicesList, projectParticipantServiceReducer);
