/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { hideLoader, showLoader } from "@selas/state-management";
import { PendingSavePrompt, usePreventWindowUnload } from "@selas/ui-components";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Endpoint from "../../../services/api/endpoint";
import ILanguageItem from "../../../services/translation/languageitem";
import { ILanguageConfiguration, languages } from "../../../services/translation/languages";
import { IAuthenticationState } from "../../../state";
import { setCurrentLanguage } from "../../../state/actions/authActions";
import { IApplicationState } from "../../../store";

import style from "./myprofile.module.scss";

const MyProfile: React.FC = () => {
	const { t } = useTranslation();
	const languageItems: ILanguageItem[] = languages.map((languageItem: ILanguageConfiguration) => {
		return {
			code: languageItem.code,
			translationKey: languageItem.translationKey,
			description: t(languageItem.translationKey)
		};
	});
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const reduxDispatch: ThunkDispatch<IApplicationState, {}, AnyAction> = useDispatch<ThunkDispatch<IApplicationState, {}, AnyAction>>();
	const [profileChanged, setProfileChanged] = useState(false);
	const [language, setLanguage] = useState(find(languageItems, { code: authenticationState.currentUser.language }));
	const apiService: ApiCommunicator = useApiService();

	usePreventWindowUnload(profileChanged);

	useEffect(() => {
		if (!authenticationState.savingCurrentUser && !authenticationState.savingCurrentUserError) {
			reduxDispatch(setCurrentLanguage(language.code));
			setLanguage({
				code: language.code,
				translationKey: language.translationKey,
				description: t(language.translationKey, {
					lng: language.code
				})
			});
			setProfileChanged(false);
		}

		if (authenticationState.savingCurrentUser) {
			reduxDispatch(showLoader());
		} else if (!authenticationState.savingCurrentUser) {
			reduxDispatch(hideLoader());
		}
	}, [authenticationState.savingCurrentUser, authenticationState.savingCurrentUserError]);

	function handleChange(event: DropDownListChangeEvent): void {
		if (event.target.value) {
			switch (event.target.name) {
				case "language":
					setLanguage(event.target.value);
					break;
			}
			setProfileChanged(true);
		}
	}

	function save(setNavigationAllowed?: (navigationAllowed: boolean) => void): void {
		authenticationState.currentUser.language = language.code;
		if (setNavigationAllowed) {
			reduxDispatch(
				apiService.callApiRedux(Endpoint.CurrentUser, "PUT", null, authenticationState.currentUser, null, (success: boolean) => {
					if (success) {
						setNavigationAllowed(true);
					}
				})
			);
		} else {
			reduxDispatch(apiService.callApiRedux(Endpoint.CurrentUser, "PUT", null, authenticationState.currentUser));
		}
	}

	return (
		<div className={"container " + style.myProfile}>
			<div style={{ padding: "20px" }}>
				<PendingSavePrompt when={profileChanged} title={t("pending_changes")} onConfirm={save}>
					{t("ask_save")}
				</PendingSavePrompt>
				<div className={"row " + style.myProfileTitle}>
					<div className="col">
						<h1>{t("yourprofile")}</h1>
					</div>
				</div>
				<div className={"row " + style.myProfileOverview}>
					<div className="col-md-3">
						<div className={"row " + style.myProfileImage}>
							<i className="la la-user" />
						</div>
					</div>
					<div className="col-md-9">
						<div className={"col-12 " + style.myProfileName}>
							{authenticationState.currentUser.firstName} {authenticationState.currentUser.lastName}
						</div>

						<div className={style.myProfileFields}>
							<div className={"col-12 " + style.myProfileField}>
								{t("email")}: <span className={style.myProfileFieldValue}>{authenticationState.currentUser.email}</span>
							</div>
							<div className={"col-12 " + style.myProfileField}>
								{t("language")}:
								<span className={style.myProfileFieldValue}>
									<DropDownList name="language" data={languageItems} dataItemKey="code" textField="description" value={language} onChange={handleChange} required />
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-11" />
					<div className="col-md-1">
						<Button primary disabled={!profileChanged} onClick={() => save()}>
							{t("save")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyProfile;
