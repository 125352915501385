import React from "react";

import sortBy from "lodash/sortBy";

import { ISession, IUser } from "../../../../utils/types/models";
import { SessionCard } from "./sessionCard";

import style from "./planning.module.scss";

export interface UserDayProps {
	date: Date;
	user: IUser;
	sessions: ISession[];
	id: string;
	redLimit: string;
	orangeLimit: string;
	openSession: (session: ISession, readOnly: boolean) => void;
}

export const UserDay: React.FC<UserDayProps> = (props: UserDayProps) => {
	return (
		<div className={style.userDay}>
			{sortBy(props.sessions, "plannedStart").map((s: ISession) => {
				return <SessionCard key={s.id} session={s} redLimit={props.redLimit} orangeLimit={props.orangeLimit} openSession={props.openSession} listCard={false} />;
			})}
		</div>
	);
};
