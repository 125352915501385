import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "react";

import Endpoint from "../../services/api/endpoint";
import { IGroup } from "../../utils/types/models";

const groupReducer: Reducer<IEntityState<IGroup>, IAction> = (state: IEntityState<IGroup> = getInitialState<IGroup>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.SearchGroups: {
			state = {
				...state,
				areEntitiesLoading: true,
				entitiesLoadError: null
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.SearchGroups: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					entities: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.SearchGroups: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				entitiesLoadError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.SearchGroups: {
			state = {
				...state,
				areEntitiesLoading: false
			};

			break;
		}

		default:
			return state;
	}
	return state;
};

export default createEntityReducer<IGroup, IEntityState<IGroup>>(Endpoint.Groups, Endpoint.GroupsList, groupReducer);
