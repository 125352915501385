import React, { Dispatch, useEffect, useReducer } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
	ComboBoxCloseEvent,
	ComboBoxFilterChangeEvent,
	DropDownList,
	DropDownListChangeEvent,
	ListItemProps,
	MultiSelectChangeEvent,
	MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState, getInitialSystemState } from "@selas/state-management";
import { EnumDropDownList, handleChange, ManageableField, ManageableMultiSelect, SearchBox, setEntity, YesNoSwitch } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { degreeReducer, genderReducer, languageReducer, nationalityReducer } from "../../../../state/reducers";
import { emailRegex } from "../../../../utils";
import { CommunicationLanguage, Permission } from "../../../../utils/enums";
import { IAddress, IDegree, IGender, ILanguage, INationality, IParticipant, IParticipantLanguage } from "../../../../utils/types/models";
import AddressEdit from "../../../global/addressEdit";
import DatePickerForDateOnly from "../../datepicker/datePicker";
import DegreeEditor from "../../personInfo/degree";
import LanguageEditor from "../../personInfo/language";
import NationalityEditor from "../../personInfo/nationality";

interface IProps {
	participant: IParticipant;
	firstField?: React.MutableRefObject<Input>;
	updateParticipant?: (newParticpant: IParticipant) => void;
	readonly: boolean;
	hideActive: boolean;
}

const ParticipantEditorContent: React.FC<IProps> = ({ participant, firstField, updateParticipant, readonly, hideActive }: IProps) => {
	const { t } = useTranslation();
	const [nationalityState, nationalityDispatch] = useReducer(nationalityReducer, getInitialState<INationality>());
	const [languageState, languageDispatch] = useReducer(languageReducer, getInitialState<ILanguage>());
	const [degreeState, degreeDispatch] = useReducer(degreeReducer, getInitialState<IDegree>());
	const [genderState, genderDispatch] = useReducer(genderReducer, getInitialSystemState<IGender>());
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (!readonly) {
			apiService.callApi(nationalityDispatch, Endpoint.Nationalities, "GET");
			apiService.callApi(languageDispatch, Endpoint.Languages, "GET");
			apiService.callApi(degreeDispatch, Endpoint.Degrees, "GET");
			apiService.callApi(genderDispatch, Endpoint.Genders, "GET");
		}
	}, [apiService, readonly]);

	function onChange(event: InputChangeEvent | ComboBoxCloseEvent | SwitchChangeEvent | DatePickerChangeEvent | DropDownListChangeEvent): void {
		updateParticipant(handleChange(participant, event));
	}

	function setAddress(address: IAddress): void {
		const newParticipant: IParticipant = { ...participant };
		newParticipant.address = address;
		updateParticipant(newParticipant);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let endpoint: Endpoint;
		switch (event.target.name) {
			case "nationalityId":
				dispatch = nationalityDispatch;
				endpoint = Endpoint.Nationalities;
				break;
			case "highestDegreeId":
				dispatch = degreeDispatch;
				endpoint = Endpoint.Degrees;
				break;
			case "knownLanguages":
				dispatch = languageDispatch;
				endpoint = Endpoint.Languages;
		}
		apiService.callApi(dispatch, endpoint, "GET", { search: event.filter.value });
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IParticipant, idField: keyof IParticipant): void {
		updateParticipant(setEntity(participant, entity, field, idField));
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const participantRecord: IParticipant = cloneDeep(participant);

		if (event.value) {
			if (event.target.name === "knownLanguages") {
				participantRecord.knownLanguages = [];
				for (const lang of event.value) {
					participantRecord.knownLanguages.push({ participantId: participantRecord.id, languageId: lang.id, language: lang });
				}
			}
			updateParticipant(participantRecord);
		}
	}

	function addOrUpdateLanguage(language: ILanguage): void {
		const participantRecord: IParticipant = cloneDeep(participant);
		const existingParticipantLanguage: IParticipantLanguage = find(participantRecord.knownLanguages, { languageId: language.id });
		if (existingParticipantLanguage) {
			existingParticipantLanguage.language = language;
		} else {
			participantRecord.knownLanguages.push({
				participantId: participantRecord.id,
				languageId: language.id,
				language
			});
		}
		apiService.callApi(languageDispatch, Endpoint.Languages, "GET");
		updateParticipant(participantRecord);
	}

	function genderItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, t(itemProps.dataItem.name));
	}

	function genderValueRender(element: React.ReactElement<HTMLSpanElement>, value: IGender): React.ReactNode {
		if (!value) {
			return element;
		}
		return React.cloneElement(element, element.props, t(value.name));
	}

	return (
		<>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("firstName")}*</span>
						<Input name="firstName" ref={firstField} value={participant.firstName} onChange={onChange} disabled={readonly} required />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("lastName")}*</span>
						<Input name="lastName" value={participant.lastName} onChange={onChange} disabled={readonly} required />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("nationalRegistryNumber")}</span>
						<Input name="nationalRegistryNumber" value={participant.nationalRegistryNumber} onChange={onChange} disabled={readonly} />
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("phoneNumber")}</span>
						<Input name="phoneNumber" value={participant.phoneNumber} onChange={onChange} disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("mobilePhoneNumber")}</span>
						<Input name="mobilePhoneNumber" value={participant.mobilePhoneNumber} onChange={onChange} disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("email")}</span>
						<Input name="emailAddress" value={participant.emailAddress} onChange={onChange} pattern={emailRegex.source} disabled={readonly} />
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ManageableField
						fieldLabel={t("nationality")}
						recordId={participant.nationalityId}
						addScreen={{ screen: NationalityEditor, isAllowed: hasPermission(Permission.NationalitiesAdd) }}
						editScreen={{ screen: NationalityEditor, isAllowed: hasPermission(Permission.NationalitiesUpdate) }}
						setEntity={(nationality: INationality) => setChild(nationality, "nationality", "nationalityId")}
						readOnly={readonly}
					>
						<SearchBox
							name="nationalityId"
							entities={nationalityState.entities}
							isLoading={nationalityState.areEntitiesLoading}
							entityId={participant.nationalityId}
							entity={participant.nationality}
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "nationality", "nationalityId")}
							disabled={readonly}
						/>
					</ManageableField>
				</div>
				<div className="col">
					<div className="k-form-field">
						<DatePickerForDateOnly
							name="dateOfBirth"
							value={participant.dateOfBirth}
							formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
							format={"yyyy-MM-dd"}
							onChange={onChange}
							disabled={readonly}
							label={"dateOfBirth"}
						/>
					</div>
				</div>
				<div className="col">
					<div className="k-form-field">
						<span>{t("gender")}</span>
						<DropDownList
							name="genderId"
							loading={genderState.isLoading}
							data={genderState.entities}
							value={participant.gender}
							dataItemKey="id"
							textField="name"
							onChange={onChange}
							disabled={readonly}
							itemRender={genderItem}
							valueRender={genderValueRender}
						/>
					</div>
				</div>
				<div className="col">
					<div className="k-form-field">
						<span>{t("communicationLanguage")} *</span>
						<EnumDropDownList name="communicationLanguage" value={participant.communicationLanguage} enum={CommunicationLanguage} onChange={onChange} disabled={readonly} required />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ManageableMultiSelect
						fieldLabel={t("knownLanguages")}
						addScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesAdd) }}
						editScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesUpdate) }}
						setEntity={addOrUpdateLanguage}
						name="knownLanguages"
						data={languageState.entities}
						textField="name"
						dataItemKey="id"
						value={map(participant.knownLanguages, "language")}
						loading={languageState.areEntitiesLoading}
						onChange={onMultiSelectChange}
						filterable
						onFilterChange={onFilterChange}
						autoClose={false}
						disabled={readonly}
						readOnly={readonly}
					/>
				</div>
				<div className="col">
					<ManageableField
						fieldLabel={t("highestDegree")}
						recordId={participant.highestDegreeId}
						addScreen={{ screen: DegreeEditor, isAllowed: hasPermission(Permission.DegreesAdd) }}
						editScreen={{ screen: DegreeEditor, isAllowed: hasPermission(Permission.DegreesUpdate) }}
						setEntity={(degree: IDegree) => setChild(degree, "highestDegree", "highestDegreeId")}
						readOnly={readonly}
					>
						<SearchBox
							name="highestDegreeId"
							entities={degreeState.entities}
							isLoading={degreeState.areEntitiesLoading}
							entityId={participant.highestDegreeId}
							entity={participant.highestDegree}
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "highestDegree", "highestDegreeId")}
							disabled={readonly}
						/>
					</ManageableField>
				</div>
			</div>
			<AddressEdit address={participant.address} addressField="address" onChange={onChange} setAddress={setAddress} numberRequired={false} readonly={readonly} />
			{!hideActive && (
				<div className="k-form-field">
					<div>{t("active")}</div>
					<YesNoSwitch name="active" checked={participant.active} onChange={onChange} disabled={readonly} />
				</div>
			)}
			<div className="k-form-field">
				<div>{t("isInfoAndRefferalEmailAllowed")}</div>
				<YesNoSwitch name="isInfoAndRefferalEmailAllowed" checked={participant.isInfoAndRefferalEmailAllowed} onChange={onChange} disabled={readonly} defaultChecked />
			</div>
		</>
	);
};

export default ParticipantEditorContent;
