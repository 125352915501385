import React, { Dispatch, useEffect, useRef } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction } from "@selas/models";
import { derender, render } from "@selas/state-management";
import { checkBoxCell, customCell, GridPanel, IGridPanelRef, Loader, notify, NullableBooleanFilterCell, StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { ISessionState } from "../../../../../state";
import { Permission } from "../../../../../utils/enums";
import { IProjectParticipant, ISessionParticipant } from "../../../../../utils/types/models";
import SessionFeedbackViewer from "../../sessionFeedback";
import SessionParticipantEditor from "../../sessionParticipant";
import ImportFromOtherGroupSession from "../importFromOtherGroupSession";

import style from "../../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
	sessionId: number;
	sessionState: ISessionState;
	sessionDispatch: Dispatch<IAction>;
	readOnly: boolean;
}

const SessionParticipants: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (props.sessionState.importZoomLogsSuccess) {
			ref.current.refresh();
		}
	}, [props.sessionState.importZoomLogsSuccess]);

	function importZoomLogs(): void {
		if (props.sessionState.entity?.zoomSessionId) {
			apiService.callApi(props.sessionDispatch, Endpoint.SessionImportZoomLogs, "GET", { projectId: props.projectId, id: props.sessionId });
		} else {
			reduxDispatch(notify(t("zoomSessionId"), t("noZoomSessionId"), "success"));
		}
	}

	function showSessionFeedback(record: ISessionParticipant): void {
		const sessionFeedbackKey: string = newKey("sessionFeedback");
		reduxDispatch(render(sessionFeedbackKey, SessionFeedbackViewer, { feedback: record.sessionFeedback, renderKey: sessionFeedbackKey }));
	}

	function importFromOtherGroup(): void {
		const key: string = newKey("importFromOtherGroup");
		reduxDispatch(
			render(key, ImportFromOtherGroupSession, {
				onClose: (newParticipants?: IProjectParticipant[]) => {
					if (!isEmpty(newParticipants)) {
						reduxDispatch(notify(t("reminder"), t("inviteReminderMessageSession"), "success"));
						apiService.callApi(props.sessionDispatch, Endpoint.AddNewParticipantsToSession, "PUT", { projectId: props.projectId, sessionId: props.sessionId }, newParticipants);
					}
					reduxDispatch(derender(key));
				},
				session: props.sessionState.entity,
				projectId: props.projectId
			})
		);
	}

	let extraToolbarButtons: JSX.Element[];
	if (hasPermission(Permission.SessionsUpdate) && !props.readOnly) {
		extraToolbarButtons = [
			<StandardButton key="importZoomLogs" primary onClick={importZoomLogs}>
				{t("importZoomLogs")}
			</StandardButton>,
			<StandardButton key="importFromOtherGroup" primary onClick={importFromOtherGroup}>
				{t("importFromGroup")}
			</StandardButton>
		];
	}

	return (
		<>
			{props.sessionState.isImportingZoomLogs && <Loader />}

			<GridPanel
				ref={ref}
				className={style.tabGrid}
				listEndpoint={Endpoint.SessionParticipantsList}
				listUrlArguments={{ projectId: props.projectId, sessionId: props.sessionId }}
				endpoint={Endpoint.SessionParticipants}
				addScreen={{
					screen: SessionParticipantEditor,
					isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.SessionsUpdate) && !props.readOnly,
					extraProps: { projectId: props.projectId, sessionId: props.sessionId }
				}}
				editScreen={{
					screen: SessionParticipantEditor,
					isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.SessionsUpdate) && !props.readOnly,
					extraProps: { projectId: props.projectId, sessionId: props.sessionId }
				}}
				delete={{ urlArguments: { projectId: props.projectId, sessionId: props.sessionId }, isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.SessionsUpdate) && !props.readOnly }}
				extraToolbarButtons={extraToolbarButtons}
				localLoader
				extraCommands={[
					{
						iconClass: "las la-clipboard-list",
						recordAction: showSessionFeedback,
						tooltip: t("showFeedback"),
						showCommand: (dataItem: ISessionParticipant) => dataItem.sessionFeedback !== null && dataItem.sessionFeedback !== undefined
					}
				]}
			>
				<GridColumn field="projectParticipant.participant.fullName" title={t("name")} />
				<GridColumn field="projectParticipant.project.title" title={t("project")} />
				<GridColumn field="invited" title={t("invited")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
				<GridColumn field="accepted" title={t("accepted")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
				<GridColumn field="present" title={t("present")} filterCell={NullableBooleanFilterCell} width="160px" cell={customCell(checkBoxCell())} />
				<GridColumn field="absenceReason.name" title={t("absenceReason")} />
			</GridPanel>
		</>
	);
};

export default SessionParticipants;
