import React, { ReactElement } from "react";

import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";

function percentageFilterCell({ onChange, value }: GridFilterCellProps): ReactElement {
	function handleChange(event: NumericTextBoxChangeEvent): void {
		const selectedValue: number = event.target.value;
		onChange({
			value: selectedValue,
			operator: "eq",
			syntheticEvent: event.syntheticEvent
		});
	}

	function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		event.preventDefault();
		onChange({
			value: "",
			operator: "",
			syntheticEvent: event
		});
	}

	const filterValue: number = value;

	return (
		<div className="k-filtercell">
			<NumericTextBox value={filterValue} onChange={handleChange} format="p" min={0} max={1} step={0.1} />
			<div className="k-filtercell-operator">
				<button className={"k-button k-button-icon" + (value && " k-clear-button-visible")} title={"grid.filterClearButton"} type="button" onClick={onClearButtonClick}>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		</div>
	);
}

export default percentageFilterCell;
