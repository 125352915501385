import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { derender, render } from "@selas/state-management";
import { GridPanel } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../../services/api/endpoint";
import { IAttachment } from "../../../../../utils/types/models";
import FileExample from "../../../../global/fileExample";
import AttachmentEditor from "../../attachment";

import style from "../../../../../assets/editor.module.scss";

interface IProps {
	taskId: number;
}

const TaskAttachments: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const dispatch: Dispatch = useDispatch();

	function showFileExample(record: IAttachment): void {
		const key: string = newKey("taskAttachment_example");
		dispatch(
			render(key, FileExample, {
				file: record.content,
				onClose: () => dispatch(derender(key))
			})
		);
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.FollowUpTaskAttachmentsList}
			listUrlArguments={{ taskId: props.taskId }}
			endpoint={Endpoint.FollowUpTaskAttachments}
			addScreen={{ screen: AttachmentEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			editScreen={{ screen: AttachmentEditor, isAllowed: true, extraProps: { taskId: props.taskId } }}
			delete={{ urlArguments: { taskId: props.taskId } }}
			extraCommands={[
				{
					tooltip: t("view"),
					iconClass: "las la-search",
					recordAction: showFileExample
				}
			]}
			localLoader
		>
			<GridColumn field="name" title={t("name")} />
		</GridPanel>
	);
};

export default TaskAttachments;
