import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import Coaches from "./coaches";
import Facilities from "./facilities";
import Locations from "./locations";
import Provinces from "./provinces";

const Resources: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("resources")}>
			<Locations reactKey="locations" label={t("locations")} url="/locations" tileIcon="las la-map-marker" isAllowed={hasPermission(Permission.LocationsRead)} />
			<Coaches reactKey="coaches" label={t("coaches")} url="/coaches" tileIcon="las la-dumbbell" isAllowed={hasPermission(Permission.CoachesRead)} />
			<Facilities reactKey="facilities" label={t("facilities")} url="/facilities" tileIcon="las la-grip-horizontal" isAllowed={hasPermission(Permission.FacilitiesRead)} />
			<Provinces reactKey="provinces" label={t("provinces")} url="/provinces" tileIcon="las la-map" isAllowed={hasPermission(Permission.ProvincesRead)} />
		</RoutedTabPanel>
	);
};

export default Resources;
