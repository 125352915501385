import React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IFileData } from "@selas/models";
import { FileViewer } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

interface IProps {
	file: IFileData;
	onClose: () => void;
}

const FileExample: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<Dialog height="99%" width="99%" onClose={props.onClose} title={t("example")}>
			<div className="h-100 w-100 d-flex justify-content-center align-items-center file-example">
				<FileViewer file={props.file} />
			</div>
			<DialogActionsBar>
				<Button onClick={props.onClose}>{t("close")}</Button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default FileExample;
