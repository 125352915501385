import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, enumFilterCell, GridPanel, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { FollowUpTaskStatus, Permission } from "../../../../utils/enums";
import { colorCell, colorCellClassName } from "../../../global/colorCell";
import FollowUpTaskEditor, { getFollowUpTaskStatusColor } from "../../followup/followUpTask";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
}

const ProjectFollowUpTasks: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectFollowUpTasksList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.ProjectFollowUpTasks}
			addScreen={{ screen: FollowUpTaskEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			editScreen={{ screen: FollowUpTaskEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			localLoader
		>
			<GridColumn
				field="status"
				title={t("projectFollowUpTaskStatus")}
				cell={customCell(colorCell(true, translatedCell(), getFollowUpTaskStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(FollowUpTaskStatus)}
			/>
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="assignee.fullName" title={t("taskAssignee")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="dueDate" title={t("dueDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
		</GridPanel>
	);
};

export default ProjectFollowUpTasks;
