import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "redux";
import { initialSessionState, ISessionState } from "..";

import Endpoint from "../../services/api/endpoint";
import { ISession } from "../../utils/types/models";

const sessionReducer: Reducer<ISessionState, IAction> = (state: ISessionState = initialSessionState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.SessionImportZoomLogs:
			state = {
				...state,
				importZoomLogsSuccess: false,
				isImportingZoomLogs: true,
				importZoomLogsError: null
			};
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.SessionImportZoomLogs: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };

			state = {
				...state,
				importZoomLogsError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.SessionImportZoomLogs: {
			state = {
				...state,
				importZoomLogsSuccess: true
			};

			break;
		}
		case "REQUEST_END_GET_" + Endpoint.SessionImportZoomLogs:
			state = {
				...state,
				isImportingZoomLogs: false
			};
			break;
		case "REQUEST_PUT_" + Endpoint.SessionsSetToIndividual:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_FAILURE_PUT_" + Endpoint.SessionsSetToIndividual: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };

			state = {
				...state,
				updateError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_PUT_" + Endpoint.SessionsSetToIndividual: {
			const payload: { response: AxiosResponse<ISession> } = action.payload as { response: AxiosResponse<ISession> };
			state = {
				...state,
				updatedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.SessionsSetToIndividual:
			state = {
				...state,
				isUpdating: false
			};
			break;
		case "REQUEST_POST_" + Endpoint.PlanSessions:
			state = {
				...state,
				isPlanning: true,
				planningSuccess: false,
				planError: null
			};
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.PlanSessions: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };

			state = {
				...state,
				planError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.PlanSessions:
			state = {
				...state,
				planningSuccess: true
			};

			break;
		case "REQUEST_END_POST_" + Endpoint.PlanSessions:
			state = {
				...state,
				isPlanning: false
			};
			break;
		case "REQUEST_GET_" + Endpoint.OpenSessionsList:
		case "REQUEST_GET_" + Endpoint.SessionsInWeek: {
			state = {
				...state,
				areEntitiesLoading: true,
				entitiesLoadError: null
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.OpenSessionsList:
		case "REQUEST_SUCCESS_GET_" + Endpoint.SessionsInWeek: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					entities: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.OpenSessionsList:
		case "REQUEST_FAILURE_GET_" + Endpoint.SessionsInWeek: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				entitiesLoadError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.OpenSessionsList:
		case "REQUEST_END_GET_" + Endpoint.SessionsInWeek:
			state = {
				...state,
				areEntitiesLoading: false
			};
			break;
		case "REQUEST_PUT_" + Endpoint.AddNewParticipantsToSession:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_FAILURE_PUT_" + Endpoint.AddNewParticipantsToSession: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				updateError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_PUT_" + Endpoint.AddNewParticipantsToSession: {
			const payload: { response: AxiosResponse<ISession> } = action.payload as { response: AxiosResponse<ISession> };
			state = {
				...state,
				updatedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.AddNewParticipantsToSession: {
			state = {
				...state,
				isUpdating: false
			};
			break;
		}
		case "REQUEST_POST_" + Endpoint.SessionsByParticipant: {
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.SessionsByParticipant: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				listLoadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.SessionsByParticipant: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					totalCount: payload.response.data.total || 0,
					list: payload.response.data.data
				};
			}
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.SessionsByParticipant: {
			state = {
				...state,
				isListLoading: false
			};
			break;
		}
	}
	return state;
};

export default createEntityReducer<ISession, ISessionState>(Endpoint.Sessions, Endpoint.SessionsList, sessionReducer);
