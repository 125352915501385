import React, { useEffect, useReducer } from "react";

import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, hideLoader, showLoader } from "@selas/state-management";
import { Form, Loader, notify, StandardButton, SubmitButton, TextArea } from "@selas/ui-components";
import { getDate, isNullOrEmpty } from "@selas/utils";
import map from "lodash/map";
import some from "lodash/some";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { initialBackOfficeTaskRequestState, initialSessionState } from "../../../../state";
import { backOfficeTaskRequestReducer, sessionReducer } from "../../../../state/reducers";
import { ISessionParticipant } from "../../../../utils/types/models";

interface RequestBackOfficeInviteProps {
	renderKey: string;
	taskId: number;
	sessionId: number;
	projectId: number;
	onClose: (cancelled: boolean) => void;
}

const SendInvite: React.FC<RequestBackOfficeInviteProps> = (props: RequestBackOfficeInviteProps) => {
	const { t } = useTranslation();
	const { onClose: propsOnClose } = props;

	const [backOfficeTaskRequestState, backOfficeTaskRequestDispatch] = useReducer(backOfficeTaskRequestReducer, initialBackOfficeTaskRequestState);
	const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);

	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!backOfficeTaskRequestState.isRequesting && backOfficeTaskRequestState.success) {
			propsOnClose(false);
			reduxDispatch(derender(props.renderKey));
		}
	}, [backOfficeTaskRequestState.isRequesting, backOfficeTaskRequestState.success, props.renderKey, propsOnClose, reduxDispatch]);

	useEffect(() => {
		apiService.callApi(sessionDispatch, Endpoint.Sessions, "GET", { projectId: props.projectId, id: props.sessionId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (sessionState.isEntityLoading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, sessionState.isEntityLoading]);

	function onClose(): void {
		propsOnClose(true);
		reduxDispatch(derender(props.renderKey));
	}

	function create(): void {
		const errorMessages: string[] = [];

		if (isNullOrEmpty(sessionState.entity?.name)) {
			errorMessages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!sessionState.entity?.plannedStart) {
			errorMessages.push(t("fill_in_required_field", { field: t("plannedStart").toLowerCase() }));
		}
		if (!sessionState.entity?.plannedStop) {
			errorMessages.push(t("fill_in_required_field", { field: t("plannedStop").toLowerCase() }));
		}

		if (errorMessages.length > 0) {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"sendInvite_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		} else {
			apiService.callApi(backOfficeTaskRequestDispatch, Endpoint.BackofficeTaskRequestSendSessionInvite, "POST", null, { taskId: props.taskId, sessionId: props.sessionId });
		}
	}

	function containsEmptyEmails(): boolean {
		return (
			sessionState.entity &&
			some(sessionState.entity.sessionParticipants, (sessionParticipant: ISessionParticipant) => isNullOrEmpty(sessionParticipant.projectParticipant.participant.emailAddress))
		);
	}

	return (
		<>
			{backOfficeTaskRequestState.isRequesting && <Loader />}
			<Form>
				<Dialog width="50%" title={t("confirm_title", { action: t("sendInvitation").toLocaleLowerCase() })} onClose={onClose}>
					<div className="k-form">
						<div className="row" style={containsEmptyEmails() ? { textAlign: "center" } : undefined}>
							<div className="col">{t("confirm_message_all_filled_in")}</div>
						</div>
						{containsEmptyEmails() && (
							<div className="row" style={{ textAlign: "center", justifyContent: "center" }}>
								<div className="col" style={{ backgroundColor: "lightsalmon", borderRadius: "5px", maxWidth: "50%", margin: "10px 0px", padding: "10px 0px" }}>
									<h2>{t("warning_empty_emails")}</h2>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")}</span>
									<Input name="name" value={sessionState.entity?.name} disabled />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("description")}</span>
									<TextArea name="description" value={sessionState.entity?.description} disabled />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("plannedStart")}</span>
									<DateTimePicker name="plannedStart" value={getDate(sessionState.entity?.plannedStart)} format={"yyyy-MM-dd HH:mm"} disabled />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("plannedStop")}</span>
									<DateTimePicker name="plannedStop" value={getDate(sessionState.entity?.plannedStop)} format={"yyyy-MM-dd HH:mm"} disabled />
								</div>
							</div>
						</div>
					</div>
					<DialogActionsBar>
						<StandardButton onClick={onClose}>{t("cancel")}</StandardButton>
						<SubmitButton primary onClick={create}>
							{t("send")}
						</SubmitButton>
					</DialogActionsBar>
				</Dialog>
			</Form>
		</>
	);
};

export default SendInvite;
