import { IAction } from "@selas/models";
import { AxiosError, AxiosResponse } from "axios";
import { Reducer } from "react";
import { initialOffice365CalendarEventState, IOffice365CalendarEventState } from "..";

import Endpoint from "../../services/api/endpoint";

const office365CalendarEventReducer: Reducer<IOffice365CalendarEventState, IAction> = (state: IOffice365CalendarEventState = initialOffice365CalendarEventState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.Office365CalendarEvents:
			state = {
				...state,
				loading: true,
				loadError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.Office365CalendarEvents: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (payload && payload.response && payload.response.data) {
				state = {
					...state,
					entities: payload.response.data
				};
			}
			break;
		}
		case "REQUEST_FAILURE_GET_" + Endpoint.Office365CalendarEvents: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				loadError: payload.error
			};
			break;
		}
		case "REQUEST_END_GET_" + Endpoint.Office365CalendarEvents:
			state = {
				...state,
				loading: false,
				loadError: null
			};
			break;
	}
	return state;
};

export default office365CalendarEventReducer;
