import React, { ReactElement, useEffect } from "react";

import { MultiViewCalendar, MultiViewCalendarChangeEvent, MultiViewCalendarProps } from "@progress/kendo-react-dateinputs";
import { StandardButton } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import styles from "./dateRangeFilterCellCalendar.module.scss";

function getDateRangeFilterCellCalendar(close: () => void) {
	return function DateRangeFilterCellCalendar({ onChange, value, ...restProps }: MultiViewCalendarProps): ReactElement {
		const { t } = useTranslation();
		const [changeEvent, setChangeEvent] = React.useState<MultiViewCalendarChangeEvent>();

		useEffect(() => {
			return () => setChangeEvent(undefined);
		}, []);

		function handleChange(e: MultiViewCalendarChangeEvent): void {
			e.syntheticEvent?.persist();
			if ("start" in e.value && e.value.start && e.value.end) {
				onChange(e);
				setChangeEvent(undefined);
			} else {
				setChangeEvent(e);
			}
		}

		function applyFilter(): void {
			onChange(changeEvent);
			setChangeEvent(undefined);
		}

		function closeFilter(): void {
			close();
		}

		return (
			<>
				<div className={`${styles.buttonRow} d-flex p-2 justify-content-end`}>
					<StandardButton className={"mr-2"} onClick={closeFilter}>
						{t("close")}
					</StandardButton>
					<StandardButton primary onClick={applyFilter} disabled={!changeEvent}>
						{t("grid.filterSubmitButton")}
					</StandardButton>
				</div>
				<MultiViewCalendar {...restProps} value={changeEvent?.value ?? value} onChange={handleChange} />
			</>
		);
	};
}

export default getDateRangeFilterCellCalendar;
