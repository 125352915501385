enum CommunicationLanguage {
	Dutch = "Dutch",

	French = "French",

	German = "German",

	English = "English"
}

export default CommunicationLanguage;
