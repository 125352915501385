import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useReducer } from "react";

import { Day } from "@progress/kendo-date-math";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { Scheduler, WorkWeekView } from "@progress/kendo-react-scheduler";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getDate } from "@selas/utils";
import filter from "lodash/filter";
import map from "lodash/map";

import some from "lodash/some";
import { IPlanningChild } from ".";
import Endpoint from "../../../../services/api/endpoint";
import { initialOffice365CalendarEventState as initialOffice365CalendarEventsState } from "../../../../state";
import { office365CalendarEventReducer } from "../../../../state/reducers";
import { IOffice365CalendarEvent, IUser } from "../../../../utils/types/models";
import Empty from "../../../global/empty";
import OutllookPlanningSchedulerViewItem from "./outllookPlanningSchedulerViewItem";

interface Props {
	week: SelectionRange;
	users: IUser[];
	showLoader: (key: string) => void;
	hideLoader: (key: string) => void;
}

function OutlookPlanning({ week, users = [], showLoader, hideLoader }: Props, ref: Ref<IPlanningChild>): JSX.Element {
	const [calendar, calendarDispatch] = useReducer(office365CalendarEventReducer, initialOffice365CalendarEventsState);
	const apiService: ApiCommunicator = useApiService();

	const refresh: () => void = useCallback(() => {
		apiService.callApi(calendarDispatch, Endpoint.Office365CalendarEvents, "GET", { start: week.start.toISOString(), end: week.end.toISOString() });
	}, [apiService, week.end, week.start]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	useEffect(() => {
		const key: string = "outlookPlanning";
		if (calendar.loading) {
			showLoader(key);
		} else {
			hideLoader(key);
		}
	}, [calendar.loading, hideLoader, showLoader]);

	useImperativeHandle(
		ref,
		() => ({
			refresh
		}),
		[refresh]
	);

	let calendarItems = calendar.entities;
	if (users.length > 0) {
		calendarItems = filter(calendarItems, (calendarItem) => some(users, { id: calendarItem.userId }));
	}

	return (
		<Scheduler
			date={week.start}
			data={map(calendarItems, (entry: IOffice365CalendarEvent) => ({ ...entry, start: getDate(entry.start), end: getDate(entry.end) }))}
			modelFields={{
				title: "subject"
			}}
			group={{ orientation: "vertical", resources: ["Coaches"] }}
			resources={[{ name: "Coaches", data: map(users, (user: IUser) => ({ text: user.fullName, value: user.id })), field: "userId", valueField: "value", textField: "text" }]}
			item={OutllookPlanningSchedulerViewItem}
			className="h-100"
		>
			<WorkWeekView header={Empty} workWeekStart={Day.Monday} workWeekEnd={Day.Saturday} />
		</Scheduler>
	);
}

export default forwardRef(OutlookPlanning);
