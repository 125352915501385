import React, { Dispatch, Reducer, useEffect, useReducer, useRef, useState } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState, getInitialSystemState, IEntityState } from "@selas/state-management";
import { EntityEditor, EnumDropDownList, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, ManageableField, SearchBox, setEntity, TextArea, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";

import { isNull } from "lodash";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import {
	companyContactReducer,
	companyReducer,
	contactReducer,
	contactTypeReducer,
	genderReducer,
	jobFunctionReducer,
	partnerContactReducer,
	partnerReducer,
	statuteReducer
} from "../../../state/reducers";
import { emailRegex } from "../../../utils";
import { CommunicationLanguage, Permission } from "../../../utils/enums";
import { IAddress, ICompany, IContact, IContactType, IGender, IJobFunction, IPartner, IStatute } from "../../../utils/types/models";
import AddressEdit from "../../global/addressEdit";
import { HeatField } from "../../global/heat";
import DatePickerForDateOnly from "../datepicker/datePicker";
import JobFunctionEditor from "../personInfo/jobFunction";
import StatuteEditor from "../personInfo/statute";
import CompanyEditor from "./company";
import ContactTypeEditor from "./contactType";
import PartnerEditor from "./partner";

interface ICompanyProps {
	companyId: number;
	allowedToEditCompany: boolean;
}

interface IPartnerProps {
	partnerId: number;
	allowedToEditPartner: boolean;
}

type PropsBase = IAddEntityScreenProps<IContact> | IEditEntityScreenProps<IContact>;

const ContactEditor: React.FC<PropsBase | (PropsBase & ICompanyProps) | (PropsBase & IPartnerProps)> = (props: PropsBase | (PropsBase & ICompanyProps) | (PropsBase & IPartnerProps)) => {
	const { t } = useTranslation();
	const initialContact: IContact = {
		id: (props as IEditEntityScreenProps<IContact>).recordId || 0,
		firstName: "",
		lastName: "",
		fullName: "",
		lbc: false,
		birthdayCard: false,
		linkedIn: "",
		typeId: 0,
		companyId: (props as ICompanyProps).companyId,
		partnerId: (props as IPartnerProps).partnerId,
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		language: CommunicationLanguage.Dutch,
		extra: undefined,
		phoneNumber: undefined,
		mobilePhoneNumber: undefined,
		email: undefined,
		newYearCard: false,
		promotionalGift: false,
		isMarketingContactAllowed: false,
		active: true
	};
	const [hoveringCompanyHeat, setHoveringCompanyHeat] = useState<number>(null);
	const [hoveringPartnerHeat, setHoveringPartnerHeat] = useState<number>(null);
	const [contact, setContact] = useState<IContact>(initialContact);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const reducer: Reducer<IEntityState<IContact>, IAction> = (props as ICompanyProps).companyId ? companyContactReducer : (props as IPartnerProps).partnerId ? partnerContactReducer : contactReducer;
	const [contactState, contactDispatch] = useReducer(reducer, getInitialState<IContact>());
	const [contactTypeState, contactTypeDispatch] = useReducer(contactTypeReducer, getInitialState<IContactType>());
	const [statuteState, statuteDispatch] = useReducer(statuteReducer, getInitialState<IStatute>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [jobFunctionState, jobFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [genderState, genderDispatch] = useReducer(genderReducer, getInitialSystemState<IGender>());
	const firstField: React.MutableRefObject<Input> = useRef();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (contactState.entity) {
			setContact(contactState.entity);
		}
	}, [contactState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.ContactsAdd, Permission.ContactsUpdate)) {
			apiService.callApi(contactTypeDispatch, Endpoint.ContactTypes, "GET");
			apiService.callApi(statuteDispatch, Endpoint.Statutes, "GET");
			apiService.callApi(genderDispatch, Endpoint.Genders, "GET");
		}
	}, [apiService]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | DropDownListChangeEvent | DatePickerChangeEvent | ComboBoxCloseEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		const newContact: IContact = handleChange(contact, event);
		if (!newContact.companyId) {
			newContact.companyHeat = null;
		} else if (!newContact.partnerId) {
			newContact.partnerHeat = null;
		}
		if (event.target.name === "firstName" || event.target.name === "lastName") {
			if (!isNullOrEmpty(newContact.firstName) && !isNullOrEmpty(newContact.lastName)) {
				newContact.fullName = newContact.firstName + " " + newContact.lastName;
			}
		}
		updateContact(newContact);
	}

	function changeHeat(newHeat: number, isCompanyHeat: boolean): void {
		const newContact: IContact = cloneDeep(contact);
		if (isCompanyHeat) {
			newContact.companyHeat = newHeat;
		} else {
			newContact.partnerHeat = newHeat;
		}
		updateContact(newContact);
	}

	function setAddress(address: IAddress): void {
		const newContact: IContact = cloneDeep(contact);
		newContact.address = address;
		updateContact(newContact);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(contact.firstName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(contact.lastName)) {
			messages.push(t("fill_in_required_field", { field: t("lastName").toLowerCase() }));
		}
		if (!contact.typeId) {
			messages.push(t("fill_in_required_field", { field: t("contactType").toLowerCase() }));
		}
		if (!isNullOrEmpty(contact.email) && !contact.email.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "email@test.com" }));
		}
		if (!isNull(contact.dateOfBirth) && contact.dateOfBirth < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("dateOfBirth").toLowerCase() }));
		}
		if (!contact.language) {
			messages.push(t("fill_in_required_field", { field: t("language").toLowerCase() }));
		}
		return messages;
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IContact, idField: keyof IContact): void {
		const newContact: IContact = setEntity(contact, entity, field, idField);
		if (field === "type" && entity) {
			apiService.callApi(contactTypeDispatch, Endpoint.ContactTypes, "GET");
		} else if (field === "statute" && entity) {
			apiService.callApi(statuteDispatch, Endpoint.Statutes, "GET");
		} else if (field === "company" && !entity) {
			newContact.companyHeat = null;
		} else if (field === "partner" && !entity) {
			newContact.partnerHeat = null;
		}
		updateContact(newContact);
	}

	function updateContact(newContact: IContact): void {
		setContact(newContact);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "partnerId":
				dispatch = partnerDispatch;
				filterEndpoint = Endpoint.Partners;
				break;
			case "jobFunctionId":
				dispatch = jobFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function onActionButtonClicked(close: boolean, record: IContact): void {
		if (record && !close) {
			setContact(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function genderItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, t(itemProps.dataItem.name));
	}

	function genderValueRender(element: React.ReactElement<HTMLSpanElement>, value: IGender): React.ReactNode {
		if (!value) {
			return element;
		}
		return React.cloneElement(element, element.props, t(value.name));
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IContact>).readonly;
	let rootEditor: boolean = false;
	let endpoint: Endpoint = Endpoint.Contacts;
	let extraUrlParameters: Record<string, unknown>;
	if ((props as ICompanyProps).companyId) {
		endpoint = Endpoint.CompanyContacts;
		extraUrlParameters = { companyId: (props as ICompanyProps).companyId };
	} else if ((props as IPartnerProps).partnerId) {
		endpoint = Endpoint.PartnerContacts;
		extraUrlParameters = { partnerId: (props as IPartnerProps).partnerId };
	} else {
		rootEditor = true;
	}

	return (
		<EntityEditor
			width="70%"
			record={contact}
			endpoint={endpoint}
			extraUrlParams={extraUrlParameters}
			entityState={contactState}
			entityType={t("contact")}
			dispatch={contactDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={contact.fullName}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("firstName")} *</span>
							<Input name="firstName" ref={firstField} value={contact.firstName} onChange={onChange} required disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("lastName")} *</span>
							<Input name="lastName" value={contact.lastName} onChange={onChange} required disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("fullName")}</span>
							<Input name="fullName" value={contact.fullName ? contact.fullName : contact.firstName + " " + contact.lastName} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="k-form-field">
							<span>{t("gender")}</span>
							<DropDownList
								name="genderId"
								loading={genderState.isLoading}
								data={genderState.entities}
								value={contact.gender}
								dataItemKey="id"
								textField="name"
								onChange={onChange}
								disabled={readonly}
								itemRender={genderItem}
								valueRender={genderValueRender}
							/>
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<DatePickerForDateOnly
								name="dateOfBirth"
								value={contact.dateOfBirth}
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								format={"yyyy-MM-dd"}
								onChange={onChange}
								disabled={readonly}
								label={"dateOfBirth"}
							/>
						</div>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("linkedIn")}</span>
							<Input name="linkedIn" value={contact.linkedIn} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={`${t("contactType")} *`}
							recordId={contact.typeId}
							addScreen={{ screen: ContactTypeEditor, isAllowed: hasPermission(Permission.ContactTypesAdd) }}
							editScreen={{ screen: ContactTypeEditor, isAllowed: hasPermission(Permission.ContactTypesUpdate) }}
							setEntity={(type: IContactType) => setChild(type, "type", "typeId")}
							readOnly={readonly}
						>
							<DropDownList
								name="typeId"
								loading={contactTypeState.areEntitiesLoading}
								data={contactTypeState.entities}
								value={contact.type}
								required
								dataItemKey="id"
								textField="name"
								onChange={onChange}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<div className="k-form-field">
							<span>{t("language")} *</span>
							<EnumDropDownList name="language" value={contact.language} enum={CommunicationLanguage} onChange={onChange} disabled={readonly} required />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={t("statute")}
							recordId={contact.statuteId}
							addScreen={{ screen: StatuteEditor, isAllowed: hasPermission(Permission.StatutesAdd) }}
							editScreen={{ screen: StatuteEditor, isAllowed: hasPermission(Permission.StatutesUpdate) }}
							setEntity={(statute: IStatute) => setChild(statute, "statute", "statuteId")}
							readOnly={readonly}
						>
							<DropDownList
								name="statuteId"
								loading={statuteState.areEntitiesLoading}
								data={statuteState.entities}
								value={contact.statute}
								dataItemKey="id"
								textField="name"
								onChange={onChange}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<ManageableField
							fieldLabel={t("jobFunction")}
							recordId={contact.jobFunctionId}
							addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
							editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
							setEntity={(jobFunction: IJobFunction) => setChild(jobFunction, "jobFunction", "jobFunctionId")}
							readOnly={readonly}
						>
							<SearchBox
								name="jobFunctionId"
								entities={jobFunctionState.entities}
								isLoading={jobFunctionState.areEntitiesLoading}
								entityId={contact.jobFunctionId}
								entity={contact.jobFunction}
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "jobFunction", "jobFunctionId")}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
				</div>
				{((props as ICompanyProps).companyId || rootEditor) && (
					<div className="row">
						<div className="col">
							<ManageableField
								fieldLabel={t("company")}
								recordId={contact.companyId}
								addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
								editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
								setEntity={(company: ICompany) => setChild(company, "company", "companyId")}
								readOnly={readonly || !(props as ICompanyProps).allowedToEditCompany}
							>
								<SearchBox
									name="companyId"
									entities={companyState.entities}
									isLoading={companyState.areEntitiesLoading}
									entityId={contact.companyId}
									entity={contact.company}
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => setChild(null, "company", "companyId")}
									disabled={readonly || !(props as ICompanyProps).allowedToEditCompany}
								/>
							</ManageableField>
						</div>
						{contact.companyId && (
							<div style={{ paddingRight: "15px" }}>
								<HeatField
									reactKey="companyHeat"
									currentHeat={contact.companyHeat}
									hoveringHeat={{ heat: hoveringPartnerHeat, setheat: setHoveringPartnerHeat }}
									changeHeat={(newHeat: number) => changeHeat(newHeat, true)}
									readonly={readonly}
								/>
							</div>
						)}
					</div>
				)}
				{((props as IPartnerProps).partnerId || rootEditor) && (
					<div className="row">
						<div className="col">
							<ManageableField
								fieldLabel={t("partner")}
								recordId={contact.partnerId}
								addScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
								editScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersUpdate) }}
								setEntity={(partner: IPartner) => setChild(partner, "partner", "partnerId")}
								readOnly={readonly || !(props as IPartnerProps).allowedToEditPartner}
							>
								<SearchBox
									name="partnerId"
									entities={partnerState.entities}
									isLoading={partnerState.areEntitiesLoading}
									entityId={contact.partnerId}
									entity={contact.partner}
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => setChild(null, "partner", "partnerId")}
									disabled={readonly || !(props as IPartnerProps).allowedToEditPartner}
								/>
							</ManageableField>
						</div>
						{contact.partnerId && (
							<div style={{ paddingRight: "15px" }}>
								<HeatField
									reactKey="partnerHeat"
									currentHeat={contact.partnerHeat}
									hoveringHeat={{ heat: hoveringCompanyHeat, setheat: setHoveringCompanyHeat }}
									changeHeat={(newHeat: number) => changeHeat(newHeat, false)}
									readonly={readonly}
								/>
							</div>
						)}
					</div>
				)}
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("phoneNumber")}</span>
							<Input name="phoneNumber" value={contact.phoneNumber} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("mobilePhoneNumber")}</span>
							<Input name="mobilePhoneNumber" value={contact.mobilePhoneNumber} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("email")}</span>
							<Input name="email" type="email" value={contact.email} onChange={onChange} pattern={emailRegex.source} disabled={readonly} />
						</label>
					</div>
				</div>
				<AddressEdit address={contact.address} addressField="address" onChange={onChange} setAddress={setAddress} readonly={readonly} />
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("extra")}</span>
							<TextArea name="extra" value={contact.extra} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="k-form-field">
							<div>{t("isMarketingContactAllowed")}</div>
							<YesNoSwitch name="isMarketingContactAllowed" checked={contact.isMarketingContactAllowed} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("newYearCard")}</div>
							<YesNoSwitch name="newYearCard" checked={contact.newYearCard} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("birthdayCard")}</div>
							<YesNoSwitch name="birthdayCard" checked={contact.birthdayCard} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("promotionalGift")}</div>
							<YesNoSwitch name="promotionalGift" checked={contact.promotionalGift} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("lbc")}</div>
							<YesNoSwitch name="lbc" checked={contact.lbc} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</div>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={contact.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ContactEditor;
