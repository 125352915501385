import React from "react";
import { useTranslation } from "react-i18next";

import pkg from "../../../../package.json";

import style from "./help.module.scss";

const Help: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column">
			<div className={"row " + style.version}>
				<div className="col d-flex align-items-center justify-content-center">
					{t("version")}: {pkg.version}
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/6q7fGn_BFDY" frameBorder="0" />
				</div>
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/irWL0men1Iw" frameBorder="0" />
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/63aMAHWOOLo" frameBorder="0" />
				</div>
				<div className="col">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/EPULAL1cANY" frameBorder="0" />
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/ZJYdinq0j5E" frameBorder="0" />
				</div>
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/GsVODBwPBVg" frameBorder="0" />
				</div>
			</div>
			<div className="row">
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/S8AVq5nFvxc" frameBorder="0" />
				</div>
				<div className="col d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/eZriiRkKik8" frameBorder="0" />
				</div>
			</div>
			<div className="row">
				<div className="col-6 d-flex align-items-center justify-content-center">
					<iframe title="helpVideo" className={style.helpVideo} src="https://www.youtube.com/embed/VK4zaEAqEnY" frameBorder="0" />
				</div>
			</div>
		</div>
	);
};

export default Help;
