import React, { useEffect, useReducer, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState } from "@selas/state-management";
import { Form, SearchBox, StandardButton, SubmitButton, TranslatedSwitch, usePreventWindowUnload } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { groupReducer, projectParticipantDistributeReducer } from "../../../../state/reducers";
import { IGroup, IProjectParticipant } from "../../../../utils/types/models";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	group: IGroup;
	projectId: number;
	onClose: (participants: IProjectParticipant[]) => void;
}

const ImportFromOtherGroup: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();

	const [pickedGroup, setPickedGroup] = useState<IGroup>(null);
	const [otherGroupsState, otherGroupsDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [projectParticipantsInGroup, projectParticipantsInGroupDispatch] = useReducer(projectParticipantDistributeReducer, getInitialState<IProjectParticipant>());

	const [selectedParticipants, setSelectedParticipants] = useState<IProjectParticipant[]>([]);

	const [dataChanged, setDataChanged] = useState(false);

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (pickedGroup) {
			apiService.callApi(projectParticipantsInGroupDispatch, Endpoint.ProjectParticipantsByGroupList, "GET", { projectId: pickedGroup.projectId, groupId: pickedGroup.id });
		}
	}, [apiService, pickedGroup]);

	useEffect(() => {
		if (!projectParticipantsInGroup.isListLoading && !isEmpty(projectParticipantsInGroup.list)) {
			const firstSelectedList: IProjectParticipant[] = [];
			forEach(projectParticipantsInGroup.list, (participant: IProjectParticipant) => {
				if (find(props.group.projectParticipants, ["projectParticipantId", participant.id]) === undefined) {
					firstSelectedList.push(participant);
				}
			});
			setSelectedParticipants(firstSelectedList);
		}
	}, [apiService, projectParticipantsInGroup.isListLoading, projectParticipantsInGroup.list, props.group.projectParticipants]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(otherGroupsDispatch, Endpoint.SearchGroups, "GET", { projectId: props.projectId, groupId: props.group.id, search: event.filter.value });
	}

	function onChange(event: ComboBoxCloseEvent): void {
		setPickedGroup(event.target.value);
		setDataChanged(true);
	}

	function onFilterClear(): void {
		setPickedGroup(null);
		setDataChanged(false);
	}

	function onChangeParticipant(event: SwitchChangeEvent): void {
		const eventParticipantId: number = parseInt(event.target.name, 0);
		const matchedParticipant: IProjectParticipant = projectParticipantsInGroup.list.find((pp: IProjectParticipant) => pp.id === eventParticipantId);

		const newParticipants: IProjectParticipant[] = cloneDeep(selectedParticipants);

		if (event.value) {
			newParticipants.push(matchedParticipant);
			setSelectedParticipants(newParticipants);
			setDataChanged(true);
		} else {
			remove(newParticipants, ["id", eventParticipantId]);
			setSelectedParticipants(newParticipants);
			setDataChanged(true);
		}
	}

	return (
		<Form>
			<Dialog title={t("importFromGroup")} width="50%" onClose={() => props.onClose([])} autoFocus>
				<div className="k-form">
					<div className="row">
						<div className="col">
							<SearchBox
								name="groupId"
								entities={otherGroupsState.entities}
								isLoading={otherGroupsState.areEntitiesLoading}
								entityId={pickedGroup?.id}
								entity={pickedGroup}
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={onFilterClear}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							{pickedGroup?.id &&
								projectParticipantsInGroup.list.map((projectParticpant: IProjectParticipant) => (
									<div className={"k-form-field " + style.switchRow} key={projectParticpant.id}>
										<span className={find(selectedParticipants, ["id", projectParticpant.id]) !== undefined ? style.checkedRow : ""} style={{ marginRight: "10px" }}>
											{projectParticpant.participant.fullName}
										</span>
										<TranslatedSwitch
											name={projectParticpant.id.toString()}
											onChange={onChangeParticipant}
											checked={find(selectedParticipants, ["id", projectParticpant.id]) !== undefined}
											disabled={find(props.group.projectParticipants, ["projectParticipantId", projectParticpant.id]) !== undefined}
										/>
									</div>
								))}
						</div>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => props.onClose([])}>{t("cancel")}</StandardButton>
					<SubmitButton primary disabled={!dataChanged} onClick={() => props.onClose(selectedParticipants)}>
						{t("select")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
		</Form>
	);
};

export default ImportFromOtherGroup;
