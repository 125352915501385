import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import LocationEditor from "../../editors/resources/location";

const Locations: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.LocationsList}
			endpoint={Endpoint.Locations}
			addScreen={{ screen: LocationEditor, isAllowed: hasPermission(Permission.LocationsAdd) }}
			editScreen={{ screen: LocationEditor, isAllowed: hasPermission(Permission.LocationsUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.LocationsDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="satisfactionScore" title={t("satisfactionScore")} filter="numeric" />
			<GridColumn field="building" title={t("building")} />
			<GridColumn field="room" title={t("room")} />
			<GridColumn field="price" title={t("price")} filter="numeric" format="{0:n2}" />
			<GridColumn field="address.province" title={t("province")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default Locations;
