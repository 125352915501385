import React, { Dispatch, useEffect, useReducer, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import { Confirm, Form, Loader, notify, SearchBox, StandardButton, SubmitButton } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { invoicingGroupReducer, opportunityServiceReducer, projectParticipantServiceReducer, projectServiceReducer } from "../../../../state/reducers";
import { IOpportunityProps, IProjectParticipantProps, IProjectProps, isOpportunityProps, isProjectParticipantProps, isProjectProps } from "../../../../utils/props";
import { IInvoicingGroup, IOpportunityService, IProjectParticipantService, IProjectService } from "../../../../utils/types/models";

interface IProps {
	onClose: (refresh: boolean) => void;
}

const ImportFromInvoicngGroupDialogue: React.FC<IProps & (IOpportunityProps | IProjectParticipantProps | IProjectProps)> = ({
	onClose,
	...entityProps
}: IProps & (IOpportunityProps | IProjectParticipantProps | IProjectProps)) => {
	const { t } = useTranslation();
	const [invoicingGroup, setInvoicingGroup] = useState<IInvoicingGroup>(null);
	const [dataChanged, setDataChanged] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [invoicingGroupState, invcoingGroupDispatch] = useReducer(invoicingGroupReducer, getInitialState<IInvoicingGroup>());
	const [opportunityServiceState, opportunityServiceDispatch] = useReducer(opportunityServiceReducer, getInitialState<IOpportunityService>());
	const [projectServiceState, projectServiceDispatch] = useReducer(projectServiceReducer, getInitialState<IProjectService>());
	const [projectParticipantServiceState, projectParticipantServiceDispatch] = useReducer(projectParticipantServiceReducer, getInitialState<IProjectParticipantService>());
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (
			(!opportunityServiceState.isAdding && opportunityServiceState.addedEntity) ||
			(!projectServiceState.isAdding && projectServiceState.addedEntity) ||
			(!projectParticipantServiceState.isAdding && projectParticipantServiceState.addedEntity)
		) {
			onClose(true);
		}
	}, [
		onClose,
		opportunityServiceState.addedEntity,
		opportunityServiceState.isAdding,
		projectParticipantServiceState.addedEntity,
		projectParticipantServiceState.isAdding,
		projectServiceState.addedEntity,
		projectServiceState.isAdding
	]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(invcoingGroupDispatch, Endpoint.InvoicingGroups, "GET", { search: event.filter.value });
	}

	function onCloseCombobox(event: ComboBoxCloseEvent): void {
		setInvoicingGroup(event.target.value);
		setDataChanged(true);
	}

	function handleClose(): void {
		if (dataChanged) {
			setAskSaveChange(true);
		} else {
			onClose(false);
		}
	}

	function save(): void {
		if (invoicingGroup == null) {
			reduxDispatch(notify(t("information"), t("fill_in_required_field", { field: t("invoicingGroup").toLowerCase() }), "success"));
		} else {
			let urlArguments: Record<string, number>;
			let dispatch: Dispatch<IAction>;
			let endpoint: Endpoint;
			if (isOpportunityProps(entityProps)) {
				urlArguments = {
					opportunityId: entityProps.opportunityId
				};
				dispatch = opportunityServiceDispatch;
				endpoint = Endpoint.OpportunityServicesImportFromInvoicingGroup;
			} else if (isProjectProps(entityProps)) {
				urlArguments = {
					projectId: entityProps.projectId
				};
				dispatch = projectServiceDispatch;
				endpoint = Endpoint.ProjectServicesImportFromInvoicingGroup;

				if (isProjectParticipantProps(entityProps)) {
					urlArguments.participantId = entityProps.projectParticipantId;
					dispatch = projectParticipantServiceDispatch;
					endpoint = Endpoint.ProjectParticipantServicesImportFromInvoicingGroup;
				}
			}
			urlArguments.invoicingGroupId = invoicingGroup.id;
			apiService.callApi(dispatch, endpoint, "POST", urlArguments);
		}
	}

	return (
		<Form>
			{(opportunityServiceState.isAdding || projectServiceState.isAdding || projectParticipantServiceState.isAdding) && <Loader />}
			<Dialog width="70%" title={t("importFromInvoicingGroup")} onClose={handleClose}>
				<div className="k-form">
					<div className="k-form-field">
						<span>{t("invoicingGroup")}</span>
						<SearchBox
							name="invoicingGroupId"
							entities={invoicingGroupState.entities}
							isLoading={invoicingGroupState.areEntitiesLoading}
							entityId={invoicingGroup?.id}
							entity={invoicingGroup}
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onCloseCombobox}
							onClear={() => setInvoicingGroup(null)}
						/>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={handleClose}>{t("cancel")}</StandardButton>
					<SubmitButton primary disabled={!dataChanged} onClick={save}>
						{t("import")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && dataChanged && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => onClose(false)}
					onCancel={() => setAskSaveChange(false)}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</Form>
	);
};

export default ImportFromInvoicngGroupDialogue;
