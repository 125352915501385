import React from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Rating } from "@progress/kendo-react-inputs";
import { derender } from "@selas/state-management";
import { StandardButton } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { ISessionFeedack } from "../../../utils/types/models";

interface IProps {
	feedback: ISessionFeedack;
	renderKey: string;
}

const SessionFeedbackViewer: React.FC<IProps> = ({ feedback, renderKey }: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();

	function onClose(): void {
		reduxDispatch(derender(renderKey));
	}

	return (
		<Dialog width="70%" title={t("sessionFeedback")} onClose={onClose} className="k-form">
			<ol>
				<li>
					<label className="k-form-field">
						<span>{t("ratingQuestion")}?</span>
						<Rating name="sessionRating" value={feedback?.sessionRating} disabled precision="half" />
					</label>
					<label className="k-form-field">
						<span>{t("ratingReasonQuestion")}?</span>
						<p>
							<pre>{feedback?.sessionRatingReason}</pre>
						</p>
					</label>
				</li>
				<li>
					<label className="k-form-field">
						<span>{t("coachSatisfactionQuestion")}?</span>
						<p>{t(feedback?.coachSatisfaction)}</p>
					</label>
				</li>
				<li>
					<label className="k-form-field">
						<span>{t("digitalInteractionSatisfactionQuestion")}?</span>
						<p>{t(feedback?.digitalInteractionSatisfaction === "NotApplicable" ? "NotApplicableWasPhysicalSession" : feedback?.digitalInteractionSatisfaction)}</p>
					</label>
					<label className="k-form-field">
						<span>{t("physicalInteractionSatisfactionQuestion")}?</span>
						<p>{t(feedback?.physicalInteractionSatisfaction === "NotApplicable" ? "NotApplicableWasDigitalSession" : feedback?.physicalInteractionSatisfaction)}</p>
					</label>
				</li>
				<li>
					<label className="k-form-field">
						<span>{t("locationSatisfactionQuestion")}?</span>
						<p>{t(feedback?.locationSatisfaction === "NotApplicable" ? "NotApplicableWasDigitalSession" : feedback?.locationSatisfaction)}</p>
					</label>
				</li>
				<li>
					<label className="k-form-field">
						<span>{t("remarks")}</span>
						<p>
							<pre>{feedback?.remarks}</pre>
						</p>
					</label>
				</li>
			</ol>
			<DialogActionsBar>
				<StandardButton onClick={onClose}>{t("close")}</StandardButton>
			</DialogActionsBar>
		</Dialog>
	);
};

export default SessionFeedbackViewer;
