enum BackOfficeTaskType {
	SendEmail = "SendEmail",
	GeneratePdfFromHtml = "GeneratePdfFromHtml",
	GenerateDocumentFromSharepointTemplate = "GenerateDocumentFromSharepointTemplate",
	ProcessOpportunityParticipantExcel = "ProcessOpportunityParticipantExcel",
	ProcessProjectParticipantExcel = "ProcessProjectParticipantExcel",
	SendInvite = "SendInvite"
}

export default BackOfficeTaskType;
