import { AccountInfo } from "@azure/msal-browser";
import { IDictionary } from "@selas/models";
import { getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError } from "axios";

import { ICompanyWeb, IInvoiceServiceBase, IOffice365CalendarEvent, IOpportunity, IProject, ISession, ISessionFeedack, ISetting, IUser } from "../utils/types/models";

export interface IAuthenticationState {
	authenticated: boolean;
	// tslint:disable-next-line: no-any
	loginError: any;
	account: AccountInfo;

	loadingUser: boolean;
	currentUser: IUser;
	savingCurrentUser: boolean;
	savingCurrentUserError: AxiosError;
}

export interface IPermssionState {
	permissions: {
		[key: string]: {
			[key: string]: string[];
		};
	};
	loading: boolean;
}

export const initialPermissionState: IPermssionState = {
	permissions: {},
	loading: false
};

export interface ISettingState {
	settingsLoading: boolean;
	settings: IDictionary<ISetting>;
	settingsLoadError: AxiosError;

	setting: ISetting;

	isUpdating: boolean;
	updateError: AxiosError;
}

export const initialSettingState: ISettingState = {
	settingsLoading: false,
	settings: {},
	settingsLoadError: null,

	setting: null,

	isUpdating: false,
	updateError: null
};

export interface IFileState {
	isDownloading: boolean;
	downloadFile: Blob;
	downloadError: AxiosError;
	filename: string;
}

export const initialFileState: IFileState = {
	isDownloading: false,
	downloadFile: null,
	downloadError: null,
	filename: null
};

export interface IBackOfficeTaskRequestState {
	isRequesting: boolean;
	success: boolean;
	error: AxiosError;
}

export const initialBackOfficeTaskRequestState: IBackOfficeTaskRequestState = {
	isRequesting: false,
	success: false,
	error: null
};

export interface ICompanyWebState {
	isDownloading: boolean;
	downloadError: AxiosError;
	data: ICompanyWeb;
}

export const initialCompanyWebState: ICompanyWebState = {
	isDownloading: false,
	downloadError: null,
	data: null
};

export interface ISessionState extends IEntityState<ISession> {
	importZoomLogsError: AxiosError;
	importZoomLogsSuccess: boolean;
	isImportingZoomLogs: boolean;

	isPlanning: boolean;
	planningSuccess: boolean;
	planError: AxiosError;
}

export interface IInvoiceServiceState extends IEntityState<IInvoiceServiceBase> {
	isUpdating: boolean;
	isUpdated: boolean;
	error: AxiosError;
}

export const initialInvoiceServiceState: IInvoiceServiceState = {
	...getInitialState<IInvoiceServiceBase>(),
	isUpdating: false,
	isUpdated: false,
	error: null
};

export const initialSessionState: ISessionState = {
	...getInitialState(),
	importZoomLogsError: null,
	importZoomLogsSuccess: false,
	isImportingZoomLogs: false,

	isPlanning: false,
	planningSuccess: false,
	planError: null
};

export interface IProjectState extends IEntityState<IProject> {
	isDownloading: boolean;
	downloadError: AxiosError;
	filename: string;
	downloadFile: Blob;
}
export const initialProjectState: IProjectState = {
	...getInitialState(),
	isDownloading: null,
	downloadError: null,
	filename: null,
	downloadFile: null
};

export interface IOpportunityState extends IEntityState<IOpportunity> {
	isDownloading: boolean;
	downloadError: AxiosError;
	filename: string;
	downloadFile: Blob;
}

export const initialOpportunityState: IOpportunityState = {
	...getInitialState(),
	isDownloading: null,
	downloadError: null,
	filename: null,
	downloadFile: null
};

export interface ISessionFeedbackState {
	loading: boolean;
	entity: ISessionFeedack;
	loadError: AxiosError;
}

export const initialSessionFeedbackState: ISessionFeedbackState = {
	loading: false,
	entity: null,
	loadError: null
};

export interface IUserState extends IEntityState<IUser> {
	allActiveUsers: IUser[];
	allActiveUsersLoading: boolean;
	allActiveUsersError: boolean;
}

export const initialUserState: IUserState = {
	...getInitialState(),
	allActiveUsers: [],
	allActiveUsersLoading: false,
	allActiveUsersError: false
};

export interface IOffice365CalendarEventState {
	loading: boolean;
	entities: IOffice365CalendarEvent[];
	loadError: AxiosError;
}

export const initialOffice365CalendarEventState: IOffice365CalendarEventState = {
	loading: false,
	entities: [],
	loadError: null
};
