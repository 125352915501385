import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Checkbox, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, ManageableField, SearchBox, setEntity, TextArea, YesNoSwitch } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { absenceReasonReducer, projectParticipantReducer, sessionParticipantReducer } from "../../../state/reducers";
import { Permission } from "../../../utils/enums";
import { IAbsenceReason, IProjectParticipant, ISessionParticipant } from "../../../utils/types/models";
import PresentStatusChip from "../../global/presentStatusChip";
import AbsenceReasonEditor from "../personInfo/absenceReason";
import ProjectParticipantEditor from "./projectParticipant";

interface IProps {
	projectId: number;
	sessionId: number;
}

const SessionParticipantEditor: React.FC<(IAddEntityScreenProps<ISessionParticipant> | IEditEntityScreenProps<ISessionParticipant>) & IProps> = (
	props: (IAddEntityScreenProps<ISessionParticipant> | IEditEntityScreenProps<ISessionParticipant>) & IProps
) => {
	const { t } = useTranslation();

	const initialSessionParticipant: ISessionParticipant = {
		id: (props as IEditEntityScreenProps<ISessionParticipant>).recordId || 0,
		projectParticipantId: null,
		sessionId: props.sessionId,
		invited: false,
		accepted: false,
		present: null,
		signature: null,
		marketingScoreComment: ""
	};
	const [sessionParticipant, setSessionParticipant] = useState<ISessionParticipant>(initialSessionParticipant);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [searchAllProjects, setSearchAllProjects] = useState(false);

	const [sessionParticipantState, sessionParticipantDispatch] = useReducer(sessionParticipantReducer, getInitialState<ISessionParticipant>());
	const [projectParticipantState, projectParticipantDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());
	const [absenceReasonState, absenceReasonDispatch] = useReducer(absenceReasonReducer, getInitialState<IAbsenceReason>());

	const firstField: React.MutableRefObject<ComboBox> = useRef();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (sessionParticipantState.entity) {
			setSessionParticipant(sessionParticipantState.entity);
			if (sessionParticipantState.entity.projectParticipant.projectId !== props.projectId) {
				setSearchAllProjects(true);
			}
		}
	}, [sessionParticipantState.entity, props.projectId]);

	function onChange(event: SwitchChangeEvent | ComboBoxCloseEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent): void {
		updateSessionParticipant(handleChange(sessionParticipant, event));
	}

	function onPresentClick(present: boolean): void {
		const newSessionParticipant: ISessionParticipant = cloneDeep(sessionParticipant);
		newSessionParticipant.present = present;
		updateSessionParticipant(newSessionParticipant);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof ISessionParticipant, idField: keyof ISessionParticipant): void {
		const newSessionParticipant: ISessionParticipant = setEntity(sessionParticipant, entity, field, idField);
		updateSessionParticipant(newSessionParticipant);
	}

	function updateSessionParticipant(newSessionParticipant: ISessionParticipant): void {
		setSessionParticipant(newSessionParticipant);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		const filters: { search: string; projectId: number } = { search: event.filter.value, projectId: props.projectId };
		switch (event.target.name) {
			case "absenceReasonId":
				dispatch = absenceReasonDispatch;
				filterEndpoint = Endpoint.AbsenceReasons;
				break;
			case "projectParticipantId":
				dispatch = projectParticipantDispatch;
				filterEndpoint = Endpoint.ProjectParticipants;
				if (searchAllProjects) {
					filterEndpoint = Endpoint.GeneralProjectParticipants;
					filters.projectId = undefined;
				}
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", filters);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!sessionParticipant.projectParticipantId) {
			messages.push(t("fill_in_required_field", { field: t("projectParticipant").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: ISessionParticipant): void {
		if (record && !close) {
			setSessionParticipant(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function participantItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, `${itemProps.dataItem.participant.fullName} ${itemProps.dataItem.project ? "(" + itemProps.dataItem.project.title + ")" : ""}`);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ISessionParticipant>).readonly;

	return (
		<EntityEditor
			width="50%"
			record={sessionParticipant}
			endpoint={Endpoint.SessionParticipants}
			extraUrlParams={{ projectId: props.projectId, sessionId: props.sessionId }}
			entityState={sessionParticipantState}
			entityType={t("sessionParticipant")}
			dispatch={sessionParticipantDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={sessionParticipant.projectParticipant?.participant?.fullName}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={`${t("projectParticipant")} *`}
							recordId={sessionParticipant.projectParticipantId}
							addScreen={{ screen: ProjectParticipantEditor, isAllowed: hasPermission(Permission.ProjectsAdd), extraProps: { projectId: props.projectId, statusChange: false } }}
							editScreen={{
								screen: ProjectParticipantEditor,
								isAllowed: hasPermission(Permission.ProjectsUpdate),
								extraProps: { projectId: sessionParticipant.projectParticipant?.projectId ?? props.projectId, statusChange: false }
							}}
							setEntity={(participant: IProjectParticipant) => setChild(participant, "projectParticipant", "projectParticipantId")}
							readOnly={readonly}
						>
							<div className="d-flex align-items-center">
								<div className="flex-grow-1" style={{ marginRight: "10px" }}>
									<SearchBox
										name="projectParticipantId"
										entities={projectParticipantState.entities}
										isLoading={projectParticipantState.areEntitiesLoading}
										entityId={sessionParticipant.projectParticipantId}
										entity={sessionParticipant.projectParticipant}
										textField="participant.fullName"
										itemRender={participantItem}
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "projectParticipant", "projectParticipantId")}
										disabled={readonly}
										required
									/>
								</div>
								<Checkbox label={t("searchAllProjects")} value={searchAllProjects} onChange={() => setSearchAllProjects(!searchAllProjects)} />
							</div>
						</ManageableField>
					</div>
					<div className="col">
						<ManageableField
							fieldLabel={t("absenceReason")}
							recordId={sessionParticipant.absenceReasonId}
							addScreen={{ screen: AbsenceReasonEditor, isAllowed: hasPermission(Permission.AbsenceReasonsAdd) }}
							editScreen={{ screen: AbsenceReasonEditor, isAllowed: hasPermission(Permission.AbsenceReasonsUpdate) }}
							setEntity={(entity: IAbsenceReason) => setChild(entity, "absenceReason", "absenceReasonId")}
							readOnly={readonly}
						>
							<SearchBox
								name="absenceReasonId"
								entities={absenceReasonState.entities}
								isLoading={absenceReasonState.areEntitiesLoading}
								entityId={sessionParticipant.absenceReasonId}
								entity={sessionParticipant.absenceReason}
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(undefined, "absenceReason", "absenceReasonId")}
								disabled={readonly}
								myRef={firstField}
							/>
						</ManageableField>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("marketingScore")}</span>
							<NumericTextBox name="marketingScore" value={sessionParticipant.marketingScore} onChange={onChange} min={0} format="n0" disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("marketingScoreComment")}</span>
							<TextArea name="marketingScoreComment" value={sessionParticipant.marketingScoreComment} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				{sessionParticipant.signature && (
					<div className="row">
						<div className="col">
							<div className="k-form-field">
								<div>{t("signature")}</div>
								<img height="50px" src={"/api/files/" + sessionParticipant.signature?.internalFilename + "/" + sessionParticipant.signature?.filename} alt="signature" />
							</div>
						</div>
					</div>
				)}
				<div className="row">
					<div className="col">
						<div className="k-form-field">
							<div>{t("invited")}</div>
							<YesNoSwitch name="invited" checked={sessionParticipant.invited} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("accepted")}</div>
							<YesNoSwitch name="accepted" checked={sessionParticipant.accepted} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<div>{t("attendance")}</div>
							<PresentStatusChip present={sessionParticipant.present} onChangePresent={onPresentClick} />
						</div>
					</div>
				</div>
			</div>
		</EntityEditor>
	);
};

export default SessionParticipantEditor;
