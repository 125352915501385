import React, { useState } from "react";

import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { notify } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import map from "lodash/map";
import pull from "lodash/pull";
import some from "lodash/some";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { emailRegex } from "../../utils";

import "./EmailRecipientList.scss";

interface IProps {
	value: string[];
	onChange: (newValue: string[]) => void;
	required?: boolean;
	disabled?: boolean;
}

const EmailRecipientList: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [newEmail, setNewEmail] = useState("");
	const reduxDispatch: Dispatch = useDispatch();

	function onRemove(emailAddress: string): void {
		const newAddresses: string[] = [...props.value];
		props.onChange(pull(newAddresses, emailAddress));
	}

	function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
		if ((event.key === "Enter" || event.key === "Tab") && !isNullOrEmpty(newEmail)) {
			event.preventDefault();
			event.stopPropagation();

			if (!newEmail.match(emailRegex)) {
				reduxDispatch(notify(t("information"), t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "email@test.com" }), "success"));
			} else if (props.value && some(props.value, (val: string) => val === newEmail)) {
				reduxDispatch(notify(t("information"), t("emailAllreadyInList"), "success"));
			} else {
				props.onChange([...(props.value ?? []), newEmail]);
				setNewEmail("");
			}
		}
	}

	function onBlur(): void {
		setNewEmail("");
	}

	return (
		<div className="d-flex align-items-center editableChipList">
			<Input
				value={newEmail}
				onChange={(event: InputChangeEvent) => setNewEmail(event.value)}
				type="email"
				pattern={emailRegex.source}
				onKeyDown={onKeyPress}
				disabled={props.disabled}
				onBlur={onBlur}
			/>
			<div className={`k-chip-list k-selection-single ${props.disabled ? "k-disabled" : ""}`}>
				{map(props.value, (emailAddress: string) => (
					<div key={emailAddress} className="k-chip k-chip-focused k-chip-filled">
						<span className="k-selected-icon-wrapper" />
						<span className="k-chip-content">
							<span aria-label={emailAddress} className="k-chip-label">
								{emailAddress}
							</span>
						</span>
						<span className="k-remove-icon">
							<span className="k-icon k-i-close-circle" onClick={() => onRemove(emailAddress)} />
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default EmailRecipientList;
