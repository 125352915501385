import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import BackOfficeTasks from "./backOfficeTasks";
import ErrorLog from "./errorLog";

const Support: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("support")}>
			<BackOfficeTasks reactKey="backOfficeTasks" label={t("backOfficeTasks")} url="/backofficetasks" tileIcon="las la-file-invoice" isAllowed={hasPermission(Permission.BackOfficeTasksRead)} />
			<ErrorLog reactKey="errorLog" label={t("errorLog")} url="/errors" tileIcon="las la-file-invoice" isAllowed={hasPermission(Permission.ErrorLogRead)} />
		</RoutedTabPanel>
	);
};

export default Support;
