import React, { useReducer, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, DropDownListCloseEvent } from "@progress/kendo-react-dropdowns";
import { RadioButton, RadioButtonChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState } from "@selas/state-management";
import { handleChange, ManageableField, SearchBox, YesNoSwitch } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { documentTemplateReducer } from "../../../../state/reducers";
import { CommunicationLanguage, Permission } from "../../../../utils/enums";
import { IDocumentTemplate, IOpportunityParticipant } from "../../../../utils/types/models";
import DocumentTemplateEditor from "../../masterdata/documentTemplate";
import { IEmailData } from "./sendEmail";

export interface IDocumentData {
	create: boolean;
	documentTemplateId: number;
	documentTemplate?: IDocumentTemplate;
	requestedLanguage: CommunicationLanguage;
	participant?: IOpportunityParticipant;
}

export function isDocumentData(toBeDetirmined: IDocumentData | IEmailData): toBeDetirmined is IDocumentData {
	return (toBeDetirmined as IDocumentData).create !== undefined;
}

interface IProps {
	recipientType: "task" | "opportunity" | "participant" | "allParticipants" | "selectionOfParticipants";
	documentData: IDocumentData;
	opportunityParticipants?: IOpportunityParticipant[];
	onChange: (newDocumentData: IDocumentData) => void;
}

const CreateDocument: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [documentTemplateState, documentTemplateDispatch] = useReducer(documentTemplateReducer, getInitialState<IDocumentTemplate>());
	const [selectParticipant, setSelectParticipant] = useState<boolean>(false);
	const apiService: ApiCommunicator = useApiService();

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		apiService.callApi(documentTemplateDispatch, Endpoint.DocumentTemplates, "GET", { search: event.filter.value });
	}

	function onChange(event: ComboBoxCloseEvent | SwitchChangeEvent | DropDownListChangeEvent): void {
		const newDocumentData: IDocumentData = handleChange(props.documentData, event);
		props.onChange(newDocumentData);
	}

	function onCloseDropdown(event: DropDownListCloseEvent): void {
		const newDocumentData: IDocumentData = cloneDeep(props.documentData);
		newDocumentData.participant = event.target.value;
		props.onChange(newDocumentData);
	}

	function onChangeRequestLanguage(event: RadioButtonChangeEvent): void {
		const newDocumentData: IDocumentData = cloneDeep(props.documentData);
		newDocumentData.requestedLanguage = event.value;
		props.onChange(newDocumentData);
	}

	function setDocumentTemplate(documentTemplate: IDocumentTemplate): void {
		const newDocumentData: IDocumentData = cloneDeep(props.documentData);
		if (documentTemplate) {
			newDocumentData.documentTemplateId = documentTemplate.id;
			newDocumentData.documentTemplate = documentTemplate;
		} else if (documentTemplate === null) {
			newDocumentData.documentTemplateId = 0;
			newDocumentData.documentTemplate = null;
		}
		props.onChange(newDocumentData);
	}

	return (
		<>
			<div className="k-form-field">
				<div>{t("create")}</div>
				<YesNoSwitch name="create" checked={props.documentData.create} onChange={onChange} />
			</div>
			{(props.recipientType === "opportunity" || (props.recipientType === "task" && !isEmpty(props.opportunityParticipants))) && (
				<div className="k-form-field">
					<div>{t("opportunityParticipant")}</div>
					<YesNoSwitch name="selectParticipant" checked={selectParticipant} onChange={(e: SwitchChangeEvent) => setSelectParticipant(e.value)} disabled={!props.documentData.create} />
				</div>
			)}
			{selectParticipant && (
				<div className="k-form-field">
					<span>{t("participant")} *</span>
					<DropDownList
						name="participantTemplate"
						data={props.opportunityParticipants}
						value={props.documentData.participant}
						dataItemKey="id"
						textField="participant.fullName"
						onChange={onChange}
						onClose={onCloseDropdown}
						disabled={!props.documentData.create}
					/>
				</div>
			)}
			<ManageableField
				addScreen={{ screen: DocumentTemplateEditor, isAllowed: hasPermission(Permission.DocumentTemplatesAdd) }}
				editScreen={{ screen: DocumentTemplateEditor, isAllowed: hasPermission(Permission.DocumentTemplatesUpdate) }}
				fieldLabel={t("document")}
				recordId={props.documentData.documentTemplateId}
				setEntity={setDocumentTemplate}
				readOnly={!props.documentData.create}
			>
				<SearchBox
					name="documentTemplateId"
					entities={documentTemplateState.entities}
					isLoading={documentTemplateState.areEntitiesLoading}
					entityId={props.documentData.documentTemplateId}
					entity={props.documentData.documentTemplate}
					textField="name"
					onFilterChange={onFilterChange}
					onClose={onChange}
					onClear={() => setDocumentTemplate(null)}
					disabled={!props.documentData.create}
					required={props.documentData.create}
				/>
			</ManageableField>
			{(props.recipientType === "task" || props.recipientType === "opportunity" || props.recipientType === "participant") && (
				<div className="k-form-field">
					<div>{t("language")}</div>
					<div className="row" style={{ marginRight: "0px" }}>
						<div className="col">
							<RadioButton
								label={t(CommunicationLanguage.Dutch)}
								checked={props.documentData.requestedLanguage === CommunicationLanguage.Dutch}
								value={CommunicationLanguage.Dutch}
								style={{ marginRight: "10px" }}
								onChange={onChangeRequestLanguage}
								disabled={!props.documentData.create}
							/>
						</div>
						<div className="col">
							<RadioButton
								label={t(CommunicationLanguage.French)}
								checked={props.documentData.requestedLanguage === CommunicationLanguage.French}
								value={CommunicationLanguage.French}
								style={{ marginRight: "10px" }}
								onChange={onChangeRequestLanguage}
								disabled={!props.documentData.create}
							/>
						</div>
						<div className="col">
							<RadioButton
								label={t(CommunicationLanguage.German)}
								checked={props.documentData.requestedLanguage === CommunicationLanguage.German}
								value={CommunicationLanguage.German}
								style={{ marginRight: "10px" }}
								onChange={onChangeRequestLanguage}
								disabled={!props.documentData.create}
							/>
						</div>
						<div className="col">
							<RadioButton
								label={t(CommunicationLanguage.English)}
								checked={props.documentData.requestedLanguage === CommunicationLanguage.English}
								value={CommunicationLanguage.English}
								style={{ marginRight: "10px" }}
								onChange={onChangeRequestLanguage}
								disabled={!props.documentData.create}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CreateDocument;
