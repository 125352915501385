import { IEndpointType } from "@selas/api-communication";
import { IDictionary } from "@selas/models";

import Endpoint from "./endpoint";

let endpoints: IDictionary<IEndpointType>;
endpoints = {};
endpoints[Endpoint.Roles] = {
	href: "/api/roles/:id",
	authNeeded: true
};
endpoints[Endpoint.RolesList] = {
	href: "/api/roles/list",
	authNeeded: true
};
endpoints[Endpoint.Users] = {
	href: "/api/users/:id",
	authNeeded: true
};
endpoints[Endpoint.UsersList] = {
	href: "/api/users/list",
	authNeeded: true
};
endpoints[Endpoint.CurrentUser] = {
	href: "/api/users/currentuser",
	authNeeded: true
};
endpoints[Endpoint.Permissions] = {
	href: "/api/permissions",
	authNeeded: true
};
endpoints[Endpoint.Settings] = {
	href: "/api/settings",
	authNeeded: true
};
endpoints[Endpoint.SettingsByKey] = {
	href: "/api/settings/byKey/:key",
	authNeeded: true
};
endpoints[Endpoint.Statutes] = {
	href: "/api/statutes/:id",
	authNeeded: true
};
endpoints[Endpoint.StatutesList] = {
	href: "/api/statutes/list",
	authNeeded: true
};
endpoints[Endpoint.ServiceTypes] = {
	href: "/api/servicetypes/:id",
	authNeeded: true
};
endpoints[Endpoint.ServiceTypesList] = {
	href: "/api/servicetypes/list",
	authNeeded: true
};
endpoints[Endpoint.Degrees] = {
	href: "/api/degrees/:id",
	authNeeded: true
};
endpoints[Endpoint.DegreesList] = {
	href: "/api/degrees/list",
	authNeeded: true
};
endpoints[Endpoint.ContactTypes] = {
	href: "/api/contacttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.ContactTypesList] = {
	href: "/api/contacttypes/list",
	authNeeded: true
};
endpoints[Endpoint.Nationalities] = {
	href: "/api/nationalities/:id",
	authNeeded: true
};
endpoints[Endpoint.NationalitiesList] = {
	href: "/api/nationalities/list",
	authNeeded: true
};
endpoints[Endpoint.Sectors] = {
	href: "/api/sectors/:id",
	authNeeded: true
};
endpoints[Endpoint.SectorsList] = {
	href: "/api/sectors/list",
	authNeeded: true
};
endpoints[Endpoint.ContractTypes] = {
	href: "/api/contracttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.ContractTypesList] = {
	href: "/api/contracttypes/list",
	authNeeded: true
};
endpoints[Endpoint.JobFunctions] = {
	href: "/api/jobfunctions/:id",
	authNeeded: true
};
endpoints[Endpoint.JobFunctionsList] = {
	href: "/api/jobfunctions/list",
	authNeeded: true
};
endpoints[Endpoint.AbsenceReasons] = {
	href: "/api/absencereasons/:id",
	authNeeded: true
};
endpoints[Endpoint.AbsenceReasonsList] = {
	href: "/api/absencereasons/list",
	authNeeded: true
};
endpoints[Endpoint.Languages] = {
	href: "/api/languages/:id",
	authNeeded: true
};
endpoints[Endpoint.LanguagesList] = {
	href: "/api/languages/list",
	authNeeded: true
};
endpoints[Endpoint.Municipalities] = {
	href: "/api/municipalities",
	authNeeded: true
};
endpoints[Endpoint.Expertises] = {
	href: "/api/expertises/:id",
	authNeeded: true
};
endpoints[Endpoint.ExpertisesList] = {
	href: "/api/expertises/list",
	authNeeded: true
};
endpoints[Endpoint.JointCommittees] = {
	href: "/api/jointcommittees/:id",
	authNeeded: true
};
endpoints[Endpoint.JointCommitteesList] = {
	href: "/api/jointcommittees/list",
	authNeeded: true
};
endpoints[Endpoint.Topics] = {
	href: "/api/topics/:id",
	authNeeded: true
};
endpoints[Endpoint.TopicsList] = {
	href: "/api/topics/list",
	authNeeded: true
};
endpoints[Endpoint.Facilities] = {
	href: "/api/facilities/:id",
	authNeeded: true
};
endpoints[Endpoint.FacilitiesList] = {
	href: "/api/facilities/list",
	authNeeded: true
};
endpoints[Endpoint.InvoicingMoments] = {
	href: "/api/invoicingmoments/:id",
	authNeeded: true
};
endpoints[Endpoint.InvoicingMomentsList] = {
	href: "/api/invoicingmoments/list",
	authNeeded: true
};
endpoints[Endpoint.NacebelCodes] = {
	href: "/api/nacebelcodes/:id",
	authNeeded: true
};
endpoints[Endpoint.NacebelCodesList] = {
	href: "/api/nacebelcodes/list",
	authNeeded: true
};
endpoints[Endpoint.Contacts] = {
	href: "/api/contacts/:id",
	authNeeded: true
};
endpoints[Endpoint.ContactsList] = {
	href: "/api/contacts/list",
	authNeeded: true
};
endpoints[Endpoint.CompanyGroups] = {
	href: "/api/companygroups/:id",
	authNeeded: true
};
endpoints[Endpoint.CompanyGroupsList] = {
	href: "/api/companygroups/list",
	authNeeded: true
};
endpoints[Endpoint.Companies] = {
	href: "/api/companies/:id",
	authNeeded: true
};
endpoints[Endpoint.CompaniesList] = {
	href: "/api/companies/list",
	authNeeded: true
};
endpoints[Endpoint.Partners] = {
	href: "/api/partners/:id",
	authNeeded: true
};
endpoints[Endpoint.PartnersList] = {
	href: "/api/partners/list",
	authNeeded: true
};
endpoints[Endpoint.PartnerContacts] = {
	href: "/api/partners/:partnerId/contacts/:id",
	authNeeded: true
};
endpoints[Endpoint.PartnerContactsList] = {
	href: "/api/partners/:partnerId/contacts/list",
	authNeeded: true
};
endpoints[Endpoint.CompanyContacts] = {
	href: "/api/companies/:companyId/contacts/:id",
	authNeeded: true
};
endpoints[Endpoint.CompanyContactsList] = {
	href: "/api/companies/:companyId/contacts/list",
	authNeeded: true
};
endpoints[Endpoint.Participants] = {
	href: "/api/participants/:id",
	authNeeded: true
};
endpoints[Endpoint.ParticipantsList] = {
	href: "/api/participants/list",
	authNeeded: true
};
endpoints[Endpoint.Locations] = {
	href: "/api/locations/:id",
	authNeeded: true
};
endpoints[Endpoint.Opportunities] = {
	href: "/api/opportunities/:id",
	authNeeded: true
};
endpoints[Endpoint.LocationsList] = {
	href: "/api/locations/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunitiesList] = {
	href: "/api/opportunities/list",
	authNeeded: true
};
endpoints[Endpoint.Coaches] = {
	href: "/api/coaches/:id",
	authNeeded: true
};
endpoints[Endpoint.MyOpportunitiesList] = {
	href: "/api/opportunities/mylist",
	authNeeded: true
};
endpoints[Endpoint.CoachesList] = {
	href: "/api/coaches/list",
	authNeeded: true
};
endpoints[Endpoint.Provinces] = {
	href: "/api/provinces/:id",
	authNeeded: true
};
endpoints[Endpoint.ProvincesList] = {
	href: "/api/provinces/list",
	authNeeded: true
};
endpoints[Endpoint.CatalogueServices] = {
	href: "/api/catalogueservices/:id",
	authNeeded: true
};
endpoints[Endpoint.CatalogueServicesList] = {
	href: "/api/catalogueservices/list",
	authNeeded: true
};
endpoints[Endpoint.CatalogueServicesUpdatePriority] = {
	href: "/api/catalogueservices/:id/updatepriority",
	authNeeded: true
};
endpoints[Endpoint.OpportunityServices] = {
	href: "/api/opportunities/:opportunityId/services/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityServicesList] = {
	href: "/api/opportunities/:opportunityId/services/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunityServicesImportFromInvoicingGroup] = {
	href: "/api/opportunities/:opportunityId/services/importfrominvoicinggroup/:invoicingGroupId",
	authNeeded: true
};
endpoints[Endpoint.OpportunityServicesUpdatePriority] = {
	href: "/api/opportunities/:opportunityId/services/:id/updatepriority",
	authNeeded: true
};
endpoints[Endpoint.OpportunityParticipants] = {
	href: "/api/opportunities/:opportunityId/participants/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityParticipantsList] = {
	href: "/api/opportunities/:opportunityId/participants/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunityAttachments] = {
	href: "/api/opportunities/:opportunityId/attachments/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityAttachmentsList] = {
	href: "/api/opportunities/:opportunityId/attachments/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunityFollowUpTasks] = {
	href: "/api/opportunities/:opportunityId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityFollowUpTasksList] = {
	href: "/api/opportunities/:opportunityId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunityNotes] = {
	href: "/api/opportunities/:opportunityId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityNotesList] = {
	href: "/api/opportunities/:opportunityId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.Origins] = {
	href: "/api/OpportunityOrigins/:id",
	authNeeded: true
};
endpoints[Endpoint.OriginsList] = {
	href: "/api/OpportunityOrigins/list",
	authNeeded: true
};
endpoints[Endpoint.Messages] = {
	href: "/api/tasks/:taskId/messages",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskNotes] = {
	href: "/api/tasks/:taskId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskNotesList] = {
	href: "/api/tasks/:taskId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.Projects] = {
	href: "/api/projects/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectsList] = {
	href: "/api/projects/list",
	authNeeded: true
};
endpoints[Endpoint.MyProjectsList] = {
	href: "/api/projects/mylist",
	authNeeded: true
};
endpoints[Endpoint.ProjectsSearchMyList] = {
	href: "/api/projects/searchMyList",
	authNeeded: true
};
endpoints[Endpoint.CreateFromOpportunity] = {
	href: "/api/projects/createfromopportunity",
	authNeeded: true
};
endpoints[Endpoint.CreateFromProject] = {
	href: "/api/opportunities/createFromProject",
	authNeeded: true
};
endpoints[Endpoint.CopyProject] = {
	href: "/api/projects/copyproject",
	authNeeded: true
};
endpoints[Endpoint.invoiceServicesList] = {
	href: "/api/invoiceServices/:projectId/list",
	authNeeded: true
};
endpoints[Endpoint.GridPanelConfigurations] = {
	href: "/api/users/currentuser/gridpanelconfigurations/:gridKey",
	authNeeded: true
};
endpoints[Endpoint.invoiceServices] = {
	href: "/api/invoiceServices/:id",
	authNeeded: true
};
endpoints[Endpoint.invoiceServicesSetStatus] = {
	href: "/api/invoiceServices/:id/setStatusToBeInvoiced",
	authNeeded: true
};
endpoints[Endpoint.invoiceServicesSetStatusList] = {
	href: "/api/invoiceServices/setStatusToBeInvoiced",
	authNeeded: true
};
endpoints[Endpoint.invoiceServicesSetInvoiceDetails] = {
	href: "/api/invoiceServices/setInvoiceDetails",
	authNeeded: true
};
endpoints[Endpoint.ProjectServices] = {
	href: "/api/projects/:projectId/services/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectServicesList] = {
	href: "/api/projects/:projectId/services/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectServicesImportFromInvoicingGroup] = {
	href: "/api/projects/:projectId/services/importfrominvoicinggroup/:invoicingGroupId",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipants] = {
	href: "/api/projects/:projectId/participants/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantsList] = {
	href: "/api/projects/:projectId/participants/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantServicesUpdatePriority] = {
	href: "/api/projects/:projectId/participants/:participantId/services/:id/updatePriority",
	authNeeded: true
};
endpoints[Endpoint.GeneralProjectParticipantsList] = {
	href: "/api/projectparticipants/list",
	authNeeded: true
};
endpoints[Endpoint.GeneralProjectParticipants] = {
	href: "/api/projectparticipants",
	authNeeded: true
};
endpoints[Endpoint.MyProjectParticipantsList] = {
	href: "/api/projectparticipants/mylist",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantNotAssignedToGroup] = {
	href: "/api/projects/:projectId/participants/notAssignedToGroup",
	authNeeded: true
};
endpoints[Endpoint.Intakes] = {
	href: "/api/projects/:projectId/participants/:participantId/intake",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantServices] = {
	href: "/api/projects/:projectId/participants/:participantId/services/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantServicesList] = {
	href: "/api/projects/:projectId/participants/:participantId/services/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantServicesImportFromInvoicingGroup] = {
	href: "/api/projects/:projectId/participants/:participantId/services/importfrominvoicinggroup/:invoicingGroupId",
	authNeeded: true
};
endpoints[Endpoint.Sessions] = {
	href: "/api/projects/:projectId/sessions/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionsList] = {
	href: "/api/projects/:projectId/sessions/list",
	authNeeded: true
};
endpoints[Endpoint.SessionsSetToIndividual] = {
	href: "/api/projects/:projectId/sessions/:id/settoindividual",
	authNeeded: true
};
endpoints[Endpoint.SessionImportZoomLogs] = {
	href: "/api/projects/:projectId/sessions/:id/importzoomlogs",
	authNeeded: true
};
endpoints[Endpoint.SessionFeedback] = {
	href: "/api/projects/:projectId/sessions/:sessionId/participants/:participantId/sessionfeedback",
	authNeeded: true
};
endpoints[Endpoint.GroupSessionsList] = {
	href: "/api/projects/:projectId/groups/:groupId/sessions/list",
	authNeeded: true
};
endpoints[Endpoint.GeneralSessionsList] = {
	href: "/api/sessions/list",
	authNeeded: true
};
endpoints[Endpoint.MySessionsList] = {
	href: "/api/sessions/mylist",
	authNeeded: true
};
endpoints[Endpoint.SessionParticipants] = {
	href: "/api/projects/:projectId/sessions/:sessionId/participants/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionParticipantsList] = {
	href: "/api/projects/:projectId/sessions/:sessionId/participants/list",
	authNeeded: true
};
endpoints[Endpoint.Groups] = {
	href: "/api/projects/:projectId/groups/:id",
	authNeeded: true
};
endpoints[Endpoint.GroupsList] = {
	href: "/api/projects/:projectId/groups/list",
	authNeeded: true
};
endpoints[Endpoint.GroupNotes] = {
	href: "/api/groups/:groupId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.GroupNotesList] = {
	href: "/api/groups/:groupId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.GeneralGroupsList] = {
	href: "/api/groups/list",
	authNeeded: true
};
endpoints[Endpoint.MyGroupsList] = {
	href: "/api/groups/mylist",
	authNeeded: true
};
endpoints[Endpoint.DocumentTemplates] = {
	href: "/api/documentTemplates/:id",
	authNeeded: true
};
endpoints[Endpoint.DocumentTemplatesList] = {
	href: "/api/documentTemplates/list",
	authNeeded: true
};
endpoints[Endpoint.SessionTemplates] = {
	href: "/api/sessiontemplates/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionTemplatesList] = {
	href: "/api/sessiontemplates/list",
	authNeeded: true
};

endpoints[Endpoint.TaskSubjects] = {
	href: "/api/taskSubjects/:id",
	authNeeded: true
};
endpoints[Endpoint.TaskSubjectsList] = {
	href: "/api/taskSubjects/list",
	authNeeded: true
};
endpoints[Endpoint.EmailTemplates] = {
	href: "/api/emailTemplates/:id",
	authNeeded: true
};
endpoints[Endpoint.EmailTemplatesList] = {
	href: "/api/emailTemplates/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectFollowUpTasks] = {
	href: "/api/projects/:projectId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectFollowUpTasksList] = {
	href: "/api/projects/:projectId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.GroupFollowUpTasks] = {
	href: "/api/projects/:projectId/groups/:groupId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.GroupFollowUpTasksList] = {
	href: "/api/projects/:projectId/groups/:groupId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.SessionFollowUpTasks] = {
	href: "/api/projects/:projectId/sessions/:sessionId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionFollowUpTasksList] = {
	href: "/api/projects/:projectId/sessions/:sessionId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.SessionFollowUpTasksPostMultiple] = {
	href: "/api/projects/:projectId/sessions/:sessionId/tasks/multiple",
	authNeeded: true
};
endpoints[Endpoint.SessionTemplateFollowUpTasks] = {
	href: "/api/sessiontemplates/:sessionTemplateId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionTemplateFollowUpTasksList] = {
	href: "/api/sessiontemplates/:sessionTemplateId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.SessionNotesList] = {
	href: "/api/sessions/:sessionId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.SessionNotes] = {
	href: "/api/sessions/:sessionId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantFollowUpTasks] = {
	href: "/api/projects/:projectId/participants/:participantId/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantFollowUpTasksList] = {
	href: "/api/projects/:projectId/participants/:participantId/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantsReport] = {
	href: "/api/projects/:id/projectparticipantsreport",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantNotes] = {
	href: "/api/projectParticipants/:projectParticipantId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantNotesList] = {
	href: "/api/projectParticipants/:projectParticipantId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantEmails] = {
	href: "/api/projectParticipants/:projectParticipantId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantEmailsList] = {
	href: "/api/projectParticipants/:projectParticipantId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantAttachments] = {
	href: "/api/projectParticipants/:projectParticipantId/attachments/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantAttachmentsList] = {
	href: "/api/projectParticipants/:projectParticipantId/attachments/list",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskEmails] = {
	href: "/api/tasks/:taskId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskEmailsList] = {
	href: "/api/tasks/:taskId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskAttachments] = {
	href: "/api/tasks/:taskId/attachments/:id",
	authNeeded: true
};
endpoints[Endpoint.FollowUpTaskAttachmentsList] = {
	href: "/api/tasks/:taskId/attachments/list",
	authNeeded: true
};
endpoints[Endpoint.MyTasksList] = {
	href: "/api/tasks/mylist",
	authNeeded: true
};
endpoints[Endpoint.TasksList] = {
	href: "/api/tasks/list",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTasks] = {
	href: "/api/backofficetasks",
	authNeeded: true
};
endpoints[Endpoint.ErrorLogList] = {
	href: "/api/errorlog/list",
	authNeeded: true
};
endpoints[Endpoint.ErrorLog] = {
	href: "/api/errorlog/:id",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTasksList] = {
	href: "/api/backofficetasks/list",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTaskRequestEmailAndOrDocument] = {
	href: "/api/backofficetaskrequests/emailandordocument",
	authNeeded: true
};
endpoints[Endpoint.BackofficeRequestEmail] = {
	href: "/api/backofficetaskrequests/simpleEmail",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTaskRequestImportExcel] = {
	href: "/api/backofficetaskrequests/importexcel",
	authNeeded: true
};
endpoints[Endpoint.Genders] = {
	href: "/api/genders",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantsTemplateFile] = {
	href: "/api/files/projectParticipantstemplatefile/:projectId",
	authNeeded: true
};
endpoints[Endpoint.OpportunityParticipantsTemplateFile] = {
	href: "/api/files/opportunityParticipantstemplatefile/:opportunityId",
	authNeeded: true
};
endpoints[Endpoint.CompanyWeb] = {
	href: "/api/companyweb/:vatnumber",
	authNeeded: true
};
endpoints[Endpoint.GovernmentServices] = {
	href: "/api/governmentservices/:id",
	authNeeded: true
};
endpoints[Endpoint.GovernmentServicesList] = {
	href: "/api/governmentservices/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectGroups] = {
	href: "/api/projectgroups/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectGroupsList] = {
	href: "/api/projectgroups/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectNotes] = {
	href: "/api/projects/:projectId/notes/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectNotesList] = {
	href: "/api/projects/:projectId/notes/list",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTaskRequestSendSessionInvite] = {
	href: "/api/backofficetaskrequests/sendinvite",
	authNeeded: true
};
endpoints[Endpoint.BackofficeTaskRequestCancelSessionInvite] = {
	href: "/api/backofficetaskrequests/cancelInvite",
	authNeeded: true
};
endpoints[Endpoint.RequestPartcipantsInfoByEmail] = {
	href: "/api/backofficetaskrequests/:projectId/requestparticipantinfobymail",
	authNeeded: true
};
endpoints[Endpoint.PlanSessions] = {
	href: "/api/projects/:projectId/groups/:groupId/sessions/plan",
	authNeeded: true
};
endpoints[Endpoint.OpenSessionsList] = {
	href: "/api/sessions/listopen",
	authNeeded: true
};
endpoints[Endpoint.AllActiveUsers] = {
	href: "/api/users/allactive",
	authNeeded: true
};
endpoints[Endpoint.SessionsInWeek] = {
	href: "/api/sessions/inweek/:start/:end",
	authNeeded: true
};
endpoints[Endpoint.InvoicingGroups] = {
	href: "/api/invoicinggroups/:id",
	authNeeded: true
};
endpoints[Endpoint.InvoicingGroupsList] = {
	href: "/api/invoicinggroups/list",
	authNeeded: true
};
endpoints[Endpoint.OpportunityEmails] = {
	href: "/api/opportunities/:opportunityId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.OpportunityEmailsList] = {
	href: "/api/opportunities/:opportunityId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.ProjectEmails] = {
	href: "/api/projects/:projectId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectEmailsList] = {
	href: "/api/projects/:projectId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.GroupEmails] = {
	href: "/api/groups/:groupId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.GroupEmailsList] = {
	href: "/api/groups/:groupId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.SessionEmails] = {
	href: "/api/sessions/:sessionId/emails/:id",
	authNeeded: true
};
endpoints[Endpoint.SessionEmailsList] = {
	href: "/api/sessions/:sessionId/emails/list",
	authNeeded: true
};
endpoints[Endpoint.SearchGroups] = {
	href: "/api/projects/:projectId/groups/:groupId/otherGroups",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantsByGroupList] = {
	href: "/api/projects/:projectId/participants/byGroup/:groupId",
	authNeeded: true
};
endpoints[Endpoint.AddNewParticipantsToSession] = {
	href: "/api/projects/:projectId/sessions/:sessionId/addNewParticipants",
	authNeeded: true
};
endpoints[Endpoint.ProjectParticipantsDetailByGroup] = {
	href: "/api/projectparticipants/listByGroup/:groupId",
	authNeeded: true
};
endpoints[Endpoint.SessionsByParticipant] = {
	href: "/api/group/:groupId/participant/:participantId/sessions/list",
	authNeeded: true
};
endpoints[Endpoint.Office365CalendarEvents] = {
	href: "/api/office365calendarevents/:start/:end",
	authNeeded: true
};
export default endpoints;
