enum SettingType {
	String = "String",
	Integer = "Integer",
	Decimal = "Decimal",
	Date = "Date",
	DateTime = "DateTime",
	Time = "Time"
}

export default SettingType;
