enum Endpoint {
	Users = "USERS",
	UsersList = "USERS_LIST",
	CurrentUser = "CURRENT_USER",
	Roles = "ROLES",
	RolesList = "ROLES_LIST",
	Permissions = "PERMISSIONS",
	Settings = "SETTINGS",
	SettingsByKey = "SETTINGS_BY_KEY",
	Statutes = "STATUTES",
	StatutesList = "STATUTES_LIST",
	ServiceTypes = "SERVICETYPES",
	ServiceTypesList = "SERVICETYPES_LIST",
	Degrees = "DEGREES",
	DegreesList = "DEGREES_LIST",
	ContactTypes = "CONTACTTYPES",
	ContactTypesList = "CONTACTTYPES_LIST",
	Nationalities = "NATIONALITIES",
	NationalitiesList = "NATIONALITIES_LIST",
	Sectors = "SECTORS",
	SectorsList = "SECTORS_LIST",
	ContractTypes = "CONTRACTTYPES",
	ContractTypesList = "CONTRACTTYPES_LIST",
	JobFunctions = "JOBFUNCTIONS",
	JobFunctionsList = "JOBFUNCTIONS_LIST",
	AbsenceReasons = "ABSENCEREASONS",
	AbsenceReasonsList = "ABSENCEREASONS_LIST",
	Languages = "LANGUAGES",
	LanguagesList = "LANGUAGES_LIST",
	Municipalities = "MUNICIPALITIES",
	Expertises = "EXPERTISES",
	ExpertisesList = "EXPERTISES_LIST",
	JointCommittees = "JOINTCOMMITTEES",
	JointCommitteesList = "JOINTCOMMITTEES_LIST",
	Topics = "TOPICS",
	TopicsList = "TOPICS_LIST",
	Facilities = "FACILITIES",
	FacilitiesList = "FACILITIES_LIST",
	InvoicingMoments = "INVOICINGMOMENTS",
	InvoicingMomentsList = "INVOICINGMOMENTS_LIST",
	NacebelCodes = "NACEBELCODES",
	NacebelCodesList = "NACEBELCODES_LIST",
	Contacts = "CONTACTS",
	ContactsList = "CONTACTS_LIST",
	Companies = "COMPANIES",
	CompaniesList = "COMPANIES_LIST",
	CompanyGroups = "COMPANYGROUPS",
	CompanyGroupsList = "COMPANYGROUP_LIST",
	Partners = "PARTNERS",
	PartnersList = "PARTNERS_LIST",
	PartnerContacts = "PARTNER_CONTACTS",
	PartnerContactsList = "PARTNER_CONTACTS_LIST",
	Participants = "PARTICIPANTS",
	ParticipantsList = "PARTICIPANTS_LIST",
	CompanyContacts = "COMPANY_CONTACTS",
	CompanyContactsList = "COMPANY_CONTACTS_LIST",
	OpportunitiesList = "OPPORTUNITIES_LIST",
	Opportunities = "OPPORTUNITIES",
	MyOpportunitiesList = "MY_OPPORTUNITIES_LIST",
	Locations = "LOCATIONS",
	LocationsList = "LOCATIONS_LIST",
	Coaches = "COACHES",
	CoachesList = "COACHES_LIST",
	Provinces = "PROVINCES",
	ProvincesList = "PROVINCES_LIST",
	CatalogueServices = "CATALOGUE_SERVICES",
	CatalogueServicesList = "CATALOGUE_SERVICES_LIST",
	CatalogueServicesUpdatePriority = "CATALOGUE_SERVICES_UPDATE_PRIORITY",
	OpportunityServices = "OPPORTUNITY_SERVICES",
	OpportunityServicesList = "OPPORTUNITY_SERVICES_LIST",
	OpportunityServicesUpdatePriority = "OPPORTUNITY_SERVICES_UPDATE_PRIORITY",
	OpportunityServicesImportFromInvoicingGroup = "OPPORTUNITY_SERVICES_IMPORT_FROM_INVOICINGGROUP",
	OpportunityParticipants = "OPPORTUNITY_PARTICIPANTS",
	OpportunityParticipantsList = "OPPORTUNITY_PARTICIPANTS_LIST",
	OpportunityAttachments = "OPPORTUNITY_ATTACHMENTS",
	OpportunityAttachmentsList = "OPPORTUNITY_ATTACHMENTS_LIST",
	OpportunityNotes = "OPPORTUNITY_NOTES",
	OpportunityNotesList = "OPPORTUNITY_NOTES_LIST",
	Projects = "PROJECTS",
	ProjectsList = "PROJECTS_LIST",
	MyProjectsList = "MY_PROJECTS_LIST",
	CreateFromOpportunity = "CREATE_FROM_OPPORTUNITY",
	CreateFromProject = "CREATE_FROM_PROJECT",
	CopyProject = "COPY_PROJECT",
	invoiceServices = "INVOICE_SERVICES",
	invoiceServicesSetInvoiceDetails = "INVOICE_SERVICES_SET_INVOICE_DETAILS",
	invoiceServicesList = "INVOICE_SERVICES_LIST",
	invoiceServicesSetStatus = "INVOICE_SERVICESE_SET_STATUS",
	invoiceServicesSetStatusList = "INVOICE_SERVICES_SET_STATUS_LIST",
	ProjectParticipants = "PROJECT_PARTICIPANTS",
	ProjectParticipantsList = "PROJECT_PARTICIPANTS_LIST",
	ProjectParticipantsReport = "PROJECT_PARTICIPANTS_REPORT",
	ProjectServices = "PROJECT_SERVICES",
	ProjectServicesList = "PROJECT_SERVICES_LIST",
	ProjectServicesImportFromInvoicingGroup = "PROJECT_SERVICES_IMPORT_FROM_INVOICINGGROUP",
	ProjectsSearchMyList = "PROJECT_SEARCH_MY_LIST",
	GeneralProjectParticipants = "GENERAL_PROJECT_PARTICIPANTS",
	GeneralProjectParticipantsList = "GENERAL_PROJECT_PARTICIPANTS_LIST",
	MyProjectParticipantsList = "MY_PROJECT_PARTICIPANTS_LIST",
	ProjectParticipantNotAssignedToGroup = "PROJECT_PARTICIPANTS_NOT_ASSIGNED_TO_GROUP",
	Intakes = "INTAKES",
	ProjectParticipantServices = "PROJECT_PARTICIPANT_SERVICES",
	ProjectParticipantServicesList = "PROJECT_PARTICIPANT_SERVICES_LIST",
	ProjectParticipantServicesUpdatePriority = "PROJECT_PARTICIPANTSERVICES_UPDATE_PRIORITY",
	ProjectParticipantServicesImportFromInvoicingGroup = "PROJECT_PARTICIPANT_SERVICES_IMPORT_FROM_INVOICINGGROUP",
	Sessions = "SESSIONS",
	SessionsList = "SESSIONS_LIST",
	GeneralSessionsList = "GENERAL_SESSIONS_LIST",
	SessionsSetToIndividual = "SESSIONS_SET_TO_INDIVIDUAL",
	MySessionsList = "MY_SESSIONS_LIST",
	SessionParticipants = "SESSION_PARTICIPANTS",
	SessionParticipantsList = "SESSION_PARTICIPANTS_LIST",
	SessionImportZoomLogs = "ZOOM_SESSION_PARTICIPANTS",
	SessionFeedback = "SESSION_FEEDBACK",
	Groups = "GROUPS",
	GroupsList = "GROUPS_LIST",
	GroupNotes = "GROUP_NOTES",
	GroupNotesList = "GROUP_NOTES_LIST",
	GeneralGroupsList = "GENERAL_GROUPS_LIST",
	MyGroupsList = "MY_GROUPS_LIST",
	GroupSessionsList = "GROUP_SESSIONS_LIST",
	OpportunityFollowUpTasks = "OPPORTUNITY_FOLLOW_UP_TASKS",
	OpportunityFollowUpTasksList = "OPPORTUNITY_FOLLOW_UP_TASKS_LIST",
	FollowUpTaskNotes = "FOLLOW_UP_TASK_NOTES",
	FollowUpTaskNotesList = "FOLLOW_UP_TASK_NOTES_LIST",
	Messages = "MESSAGES",
	DocumentTemplates = "DOCUMENT_TEMPLATES",
	DocumentTemplatesList = "DOCUMENT_TEMPLATES_LIST",
	SessionTemplates = "SESSION_TEMPLATES",
	SessionTemplatesList = "SESSION_TEMPLATES_LIST",
	TaskSubjects = "TASK_SUBJECTS",
	TaskSubjectsList = "TASK_SUBJECTS_LIST",
	EmailTemplates = "EMAIL_TEMPLATES",
	EmailTemplatesList = "EMAIL_TEMPLATES_LIST",
	ProjectFollowUpTasks = "PROJECT_FOLLOW_UP_TASKS",
	ProjectFollowUpTasksList = "PROJECT_FOLLOW_UP_TASKS_LIST",
	GridPanelConfigurations = "GRIDPANEL_CONFIGURATIONS",
	GroupFollowUpTasks = "GROUP_FOLLOW_UP_TASKS",
	GroupFollowUpTasksList = "GROUP_FOLLOW_UP_TASKS_LIST",
	SessionFollowUpTasks = "SESSION_FOLLOW_UP_TASKS",
	SessionFollowUpTasksList = "SESSION_FOLLOW_UP_TASKS_LIST",
	SessionFollowUpTasksPostMultiple = "SESSION_FOLLOW_UP_TASKS_POST_MULTIPLE",
	SessionTemplateFollowUpTasks = "SESSION_TEMPLATE_FOLLOW_UP_TASKS",
	SessionTemplateFollowUpTasksList = "SESSION_TEMPLATE_FOLLOW_UP_TASKS_LIST",
	ProjectParticipantFollowUpTasks = "PROJECT_PARTICIPANT_FOLLOW_UP_TASKS",
	ProjectParticipantFollowUpTasksList = "PROJECT_PARTICIPANT_FOLLOW_UP_TASKS_LIST",
	ProjectParticipantNotes = "PROJECT_PARTICIPANT_NOTES",
	ProjectParticipantNotesList = "PROJECT_PARTICIPANT_NOTES_LIST",
	ProjectParticipantEmails = "PROJECT_PARTICIPANT_EMAILS",
	ProjectParticipantEmailsList = "PROJECT_PARTICIPANT_EMAILS_LIST",
	ProjectParticipantAttachments = "PROJECT_PARTICIPANT_ATTACHMENTS",
	ProjectParticipantAttachmentsList = "PROJECT_PARTICIPANT_ATTACHMENTS_LIST",
	FollowUpTaskEmails = "FOLLOW_UP_TASK_EMAILS",
	FollowUpTaskEmailsList = "FOLLOW_UP_TASK_EMAILS_LIST",
	FollowUpTaskAttachments = "FOLLOW_UP_TASK_ATTACHMENTS",
	FollowUpTaskAttachmentsList = "FOLLOW_UP_TASK_ATTACHMENTS_LIST",
	TasksList = "TASKS_LIST",
	MyTasksList = "MY_TASKS_LIST",
	BackofficeTasks = "BACKOFFICE_TASKS",
	BackofficeTasksList = "BACKOFFICE_TASKS_LIST",
	ErrorLogList = "ERROR_LOG_LIST",
	ErrorLog = "ERROR_LOG",
	BackofficeTaskRequestEmailAndOrDocument = "BACKOFFICE_TASK_REQUESTS_EMAIL_AND_OR_DOCUMENT",
	BackofficeRequestEmail = "BACKOFFICE_REQUEST_EMAIL",
	BackofficeTaskRequestImportExcel = "BACKOFFICE_TASK_REQUESTS_IMPORT_EXCEL",
	RequestPartcipantsInfoByEmail = "REQUEST_PARTICIPANTS_INFO_BY_EMAIL",
	Genders = "GENDERS",
	ProjectParticipantsTemplateFile = "PROJECT_PARTICIPANTS_TEMPLATE_FILE",
	OpportunityParticipantsTemplateFile = "OPPORTUNITY_PARTICIPANTS_TEMPLATE_FILE",
	CompanyWeb = "COMPANY_WEB",
	GovernmentServices = "GOVERNMENT_SERVICES",
	GovernmentServicesList = "GOVERNMENT_SERVICES_LIST",
	ProjectGroups = "PROJECT_GROUPS",
	ProjectGroupsList = "PROJECT_GROUPS_LIST",
	BackofficeTaskRequestSendSessionInvite = "BACKOFFICE_TASK_REQUESTS_SEND_SESSION_INVITE",
	BackofficeTaskRequestCancelSessionInvite = "BACKOFFICE_TASK_REQUESTS_CANCEL_SESSION_INVITE",
	OpenSessionsList = "OPEN_SESSIONS_LIST",
	PlanSessions = "BACKOFFICE_TASK_REQUEST_CREATE_GROUP_SESSIONS",
	AllActiveUsers = "ALL_ACTIVE_USERS",
	SessionsInWeek = "SESSIONS_IN_WEEK",
	InvoicingGroups = "INVOICING_GROUPS",
	InvoicingGroupsList = "INVOICING_GROUPS_LIST",
	Origins = "ORIGINS",
	OriginsList = "ORIGINSLIST",
	OpportunityEmails = "OPPORTUNITY_EMAILS",
	OpportunityEmailsList = "OPPORTUNITY_EMAILS_LIST",
	ProjectEmails = "PROJECT_EMAILS",
	ProjectEmailsList = "PROJECT_EMAILS_LIST",
	ProjectNotes = "PROJECT_NOTES",
	ProjectNotesList = "PROJECT_NOTES_LIST",
	GroupEmails = "GROUP_EMAILS",
	GroupEmailsList = "GROUP_EMAILS_LIST",
	SessionEmails = "SESSION_EMAILS",
	SessionEmailsList = "SESSION_EMAILS_LIST",
	SessionNotes = "SESSION_NOTES",
	SessionNotesList = "SESSION_NOTES_LIST",
	SearchGroups = "SEARCH_GROUPS",
	ProjectParticipantsByGroupList = "PROJECTPARTICPANTS_BY_GROUP_LIST",
	AddNewParticipantsToSession = "ADD_NEW_PARTICIPANTS_TO_SESSION",
	ProjectParticipantsDetailByGroup = "PROJECTPARTICIPANTS_DETAIL_BY_GROUP",
	SessionsByParticipant = "SESSIONS_BY_PARTICIPANTS",
	Office365CalendarEvents = "OFFICE_365_CALENDAR_ENTRIES"
}

export default Endpoint;
