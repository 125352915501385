import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, enumFilterCell, GridPanel, IRoutedTabProps, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { CommunicationLanguage, Permission } from "../../../utils/enums";
import ParticipantEditor from "../../editors/customer/participant";

const Participants: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.ParticipantsList}
			endpoint={Endpoint.Participants}
			addScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsAdd) }}
			editScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.ParticipantsDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="fullName" title={t("fullName")} />
			<GridColumn field="nationalRegistryNumber" title={t("nationalRegistryNumber")} />
			<GridColumn field="nationality.name" title={t("nationality")} />
			<GridColumn field="communicationLanguage" title={t("communicationLanguage")} cell={customCell(translatedCell())} filterCell={enumFilterCell(CommunicationLanguage)} />
			<GridColumn field="highestDegree.name" title={t("highestDegree")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default Participants;
