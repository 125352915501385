import { ApiService } from "@selas/api-communication";
import { AxiosRequestConfig } from "axios";

import { getToken } from "../authentication";
import handleError from "../errorHandling";
import Endpoint from "./endpoint";
import endpoints from "./endpoints";

async function getRequestOptions(endpointName: string, requestOptions: AxiosRequestConfig): Promise<AxiosRequestConfig> {
	if (!requestOptions.headers) {
		requestOptions.headers = {};
	}

	if (endpoints[endpointName].authNeeded) {
		const token: string = await getToken();
		requestOptions.headers.Authorization = `Bearer ${token}`;
	}
	if (endpointName === Endpoint.ProjectParticipantsTemplateFile || endpointName === Endpoint.ProjectParticipantsReport || endpointName === Endpoint.OpportunityParticipantsTemplateFile) {
		requestOptions.responseType = "blob";
	}
	requestOptions.headers["Content-Type"] = "application/json";
	return requestOptions;
}

ApiService.init(endpoints, getRequestOptions, handleError);
