import React, { Dispatch, ReactElement, Reducer, useCallback, useEffect, useReducer, useRef, useState } from "react";

import { Chat, ChatMessage, ChatMessageBoxProps, ChatMessageProps, ChatMessageSendEvent, Message, User } from "@progress/kendo-react-conversational-ui";
import { DateTimePicker, DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { derender, getInitialState, IEntityState, render } from "@selas/state-management";
import {
	Confirm,
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	Loader,
	ManageableField,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel
} from "@selas/ui-components";
import { getDate, isNullOrEmpty, newKey } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialBackOfficeTaskRequestState, initialUserState } from "../../../../state";
import {
	backOfficeTaskRequestReducer,
	groupFollowUpTaskReducer,
	messageReducer,
	opportunityFollowUpTaskReducer,
	projectFollowUpTaskReducer,
	projectParticipantFollowUpTaskReducer,
	sessionFollowUpTaskReducer,
	taskSubjectReducer,
	userReducer
} from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { Color, FollowUpTaskStatus, Permission } from "../../../../utils/enums";
import {
	IGroupProps,
	IOpportunityProps,
	IProjectParticipantProps,
	IProjectProps,
	ISessionProps,
	isGridCellProps,
	isGroupProps,
	isOpportunityProps,
	isProjectParticipantProps,
	isProjectProps,
	isSessionProps
} from "../../../../utils/props";
import {
	IBackOfficeSessionInviteTaskRequest,
	ICheckListItem,
	IFollowUpTask,
	IGroupFollowUpTask,
	IMessage,
	IOpportunityFollowUpTask,
	IProjectFollowUpTask,
	IProjectParticipantFollowUpTask,
	ISessionFollowUpTask,
	ITaskSubject,
	IUser
} from "../../../../utils/types/models";
import BackOfficeButton from "../../../global/backOfficeButton";
import { statusDropdownItemRender } from "../../../global/statusDropdownItemRender";
import TaskSubjectEditor from "../../masterdata/taskSubject";
import CheckListItemEditor from "../../masterdata/taskSubject/checkListItemEditor";
import PlanSessions from "../requestBackOfficeTask/planSessions";
import RequestMailAndOrDocument from "../requestBackOfficeTask/requestMailAndOrDocument";
import SendInvite from "../requestBackOfficeTask/sendInvite";
import TaskAttachments from "./grids/taskAttachments";
import TaskEmails from "./grids/taskEmails";
import TaskNotes from "./grids/taskNotes";

import "./followUpTask.scss";

export type SpecificProps = IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps;

type BaseProps = IAddEntityScreenProps<IFollowUpTask> | IEditEntityScreenProps<IFollowUpTask>;

function getInitialTask(props: BaseProps & SpecificProps, currentUser: IUser): IFollowUpTask {
	const task: IFollowUpTask = {
		id: (props as IEditEntityScreenProps<IFollowUpTask>).recordId || 0,
		creatorId: currentUser.id,
		creator: currentUser,
		assigneeId: currentUser.id,
		assignee: currentUser,
		status: FollowUpTaskStatus.New,
		subject: "",
		checkList: []
	};

	if (isOpportunityProps(props)) {
		(task as IOpportunityFollowUpTask).opportunityId = props.opportunityId;
	} else if (isGroupProps(props)) {
		(task as IGroupFollowUpTask).groupId = props.groupId;
	} else if (isSessionProps(props)) {
		(task as ISessionFollowUpTask).sessionId = props.sessionId;
	} else if (isProjectParticipantProps(props)) {
		(task as IProjectParticipantFollowUpTask).projectParticipantId = props.projectParticipantId;
	} else if (isProjectProps(props)) {
		(task as IProjectFollowUpTask).projectId = props.projectId;
	}
	return task;
}

export function getFollowUpTaskStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case FollowUpTaskStatus.New:
			return Color.Blue;
		case FollowUpTaskStatus.InProgressGreen:
		case FollowUpTaskStatus.Finished:
			return Color.Green;
		case FollowUpTaskStatus.InProgressOrange:
			return Color.Orange;
		case FollowUpTaskStatus.InProgressRed:
			return Color.Red;
	}
}

const FollowUpTaskEditor: React.FC<BaseProps & SpecificProps> = (props: BaseProps & SpecificProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const [followUpTask, setFollowUpTask] = useState<IFollowUpTask>(getInitialTask(props, currentUser));
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [currentTaskSubject, setCurrentTaskSubject] = useState<ITaskSubject>();
	let reducer: Reducer<IEntityState<IFollowUpTask>, IAction>;
	let initialState: IEntityState<IFollowUpTask>;
	let statusType: string = "followUpTaskStatus";
	const [backOfficeTaskRequestState, backOfficeTaskRequestDispatch] = useReducer(backOfficeTaskRequestReducer, initialBackOfficeTaskRequestState);

	if (isOpportunityProps(props)) {
		reducer = (opportunityFollowUpTaskReducer as unknown) as Reducer<IEntityState<IFollowUpTask>, IAction>;
		initialState = getInitialState<IOpportunityFollowUpTask>();
		statusType = "opportunityFollowUpTaskStatus";
	} else if (isGroupProps(props)) {
		reducer = (groupFollowUpTaskReducer as unknown) as Reducer<IEntityState<IFollowUpTask>, IAction>;
		initialState = getInitialState<IGroupFollowUpTask>();
		statusType = "groupFollowUpTaskStatus";
	} else if (isSessionProps(props)) {
		reducer = (sessionFollowUpTaskReducer as unknown) as Reducer<IEntityState<IFollowUpTask>, IAction>;
		initialState = getInitialState<ISessionFollowUpTask>();
		statusType = "sessionFollowUpTaskStatus";
	} else if (isProjectParticipantProps(props)) {
		reducer = (projectParticipantFollowUpTaskReducer as unknown) as Reducer<IEntityState<IFollowUpTask>, IAction>;
		initialState = getInitialState<IProjectParticipantFollowUpTask>();
		statusType = "projectParticipantFollowUpTaskStatus";
	} else if (isProjectProps(props)) {
		reducer = (projectFollowUpTaskReducer as unknown) as Reducer<IEntityState<IFollowUpTask>, IAction>;
		initialState = getInitialState<IProjectFollowUpTask>();
		statusType = "projectFollowUpTaskStatus";
	}
	const [followUpTaskState, followUpTaskDispatch] = useReducer(reducer, initialState);
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const [taskSubjectState, taskSUbjectDispatch] = useReducer(taskSubjectReducer, getInitialState<ITaskSubject>());
	const [messageState, messageDispatch] = useReducer(messageReducer, getInitialState<IMessage>());
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	const firstField: React.MutableRefObject<ComboBox> = useRef();

	const refreshChat: () => void = useCallback(() => {
		if (followUpTask.id) {
			apiService.callApi(messageDispatch, Endpoint.Messages, "GET", { taskId: followUpTask.id });
		}
	}, [apiService, followUpTask.id]);

	useEffect(() => {
		if (followUpTaskState.entity) {
			setFollowUpTask(followUpTaskState.entity);
			setCurrentTaskSubject({
				id: 0,
				name: followUpTaskState.entity.subject,
				description: undefined,
				checkList: undefined,
				active: undefined
			});
		}
	}, [followUpTaskState.entity]);

	useEffect(() => {
		if (!backOfficeTaskRequestState.isRequesting) {
			refreshChat();
		}
	}, [refreshChat, backOfficeTaskRequestState.isRequesting]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let searchEndpoint: Endpoint;
		let dispatch: Dispatch<IAction>;
		switch (event.target.name) {
			case "assigneeId":
				searchEndpoint = Endpoint.Users;
				dispatch = userDispatch;
				break;
			case "subject":
				searchEndpoint = Endpoint.TaskSubjects;
				dispatch = taskSUbjectDispatch;
				break;
		}
		apiService.callApi(dispatch, searchEndpoint, "GET", { search: event.filter.value });
	}

	function onChange(event: ComboBoxCloseEvent | DropDownListChangeEvent | DateTimePickerChangeEvent): void {
		updateFollowUpTask(handleChange(followUpTask, event));
	}

	function setTaskSubject(taskSubject: ITaskSubject): void {
		const task: IFollowUpTask = cloneDeep(followUpTask);
		if (taskSubject.checkList) {
			task.checkList = taskSubject.checkList;
		}
		task.subject = taskSubject.name;
		updateFollowUpTask(task);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IFollowUpTask, idField: keyof IFollowUpTask): void {
		const newFollowUpTask: IFollowUpTask = setEntity(followUpTask, entity, field, idField);
		updateFollowUpTask(newFollowUpTask);
	}

	function onChangeChecklistItem(finished: boolean, index: number): void {
		const newFollowUpTask: IFollowUpTask = cloneDeep(followUpTask);
		newFollowUpTask.checkList[index].finished = finished;
		updateFollowUpTask(newFollowUpTask);
	}

	function addChecklistItem(): void {
		const taskListEditorKey: string = newKey("addChecklistItemEditor");
		reduxDispatch(
			render(taskListEditorKey, CheckListItemEditor, {
				onClose: (title: string) => {
					if (!isNullOrEmpty(title)) {
						const newFollowUpTask: IFollowUpTask = cloneDeep(followUpTask);
						newFollowUpTask.checkList.push({
							title,
							finished: false
						});
						updateFollowUpTask(newFollowUpTask);
					}
					reduxDispatch(derender(taskListEditorKey));
				}
			})
		);
	}

	function editChecklistItem(index: number): void {
		const taskListEditorKey: string = newKey("editChecklistItemConfirm");
		const checkListIstItem: ICheckListItem = followUpTask.checkList[index];
		reduxDispatch(
			render(taskListEditorKey, CheckListItemEditor, {
				initialTitle: checkListIstItem.title,
				onClose: (title: string) => {
					if (!isNullOrEmpty(title)) {
						const newFollowUpTask: IFollowUpTask = cloneDeep(followUpTask);
						newFollowUpTask.checkList.splice(index, 1, { title, finished: checkListIstItem.finished });
						updateFollowUpTask(newFollowUpTask);
					}
					reduxDispatch(derender(taskListEditorKey));
				}
			})
		);
	}

	function removeChecklistItem(index: number): void {
		const confirmKey: string = newKey("removeChecklistItemConfirm");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm"),
				children: t("confirm_content", { action: t("remove") }),
				onConfirm: () => {
					const newFollowUpTask: IFollowUpTask = cloneDeep(followUpTask);
					newFollowUpTask.checkList.splice(index, 1);
					updateFollowUpTask(newFollowUpTask);
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	function updateFollowUpTask(newFollowupTask: IFollowUpTask): void {
		setFollowUpTask(newFollowupTask);
		setDataChanged(true);
	}

	function onMessageSend(event: ChatMessageSendEvent): void {
		apiService.callApi(
			messageDispatch,
			Endpoint.Messages,
			"POST",
			{ taskId: followUpTask.id },
			{
				taskId: followUpTask.id,
				content: event.message.text
			},
			null,
			(succes: boolean) => {
				if (succes) {
					refreshChat();
				}
			}
		);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(followUpTask.subject)) {
			messages.push(t("fill_in_required_field", { field: t("subject").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IFollowUpTask): void {
		if (record && !close) {
			setFollowUpTask(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function renderFollowUpTaskStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getFollowUpTaskStatusColor);
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		let requestBackOfficeTaskProps: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps;
		if (isOpportunityProps(props)) {
			requestBackOfficeTaskProps = {
				opportunityId: props.opportunityId
			};
		} else if (isGroupProps(props)) {
			requestBackOfficeTaskProps = {
				projectId: props.projectId,
				groupId: props.groupId
			};
		} else if (isSessionProps(props)) {
			requestBackOfficeTaskProps = {
				projectId: props.projectId,
				sessionId: props.sessionId
			};
		} else if (isProjectParticipantProps(props)) {
			requestBackOfficeTaskProps = {
				projectId: props.projectId,
				projectParticipantId: props.projectParticipantId
			};
		} else if (isProjectProps(props)) {
			requestBackOfficeTaskProps = {
				projectId: props.projectId
			};
		}

		reduxDispatch(
			render(renderKey, RequestMailAndOrDocument, {
				renderKey,
				taskId: followUpTask.id,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						refreshChat();
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	function requestSendSessionInvite(): void {
		const backOfficeInviteRequest: IBackOfficeSessionInviteTaskRequest = {
			taskId: followUpTask.id,
			sessionId: (followUpTask as ISessionFollowUpTask).sessionId
		};

		const renderKey: string = newKey("sendInvite");

		reduxDispatch(
			render(renderKey, SendInvite, {
				renderKey,
				taskId: followUpTask.id,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						refreshChat();
					}
				},
				projectId: (props as ISessionProps).projectId,
				...backOfficeInviteRequest
			})
		);
	}

	function deleteSessionInvite(): void {
		const confirmKey: string = newKey("deleteSessionInvite");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title", { action: t("cancel").toLowerCase() }),
				children: t("confirm_cancel_invitation"),
				onConfirm: () => {
					apiService.callApi(backOfficeTaskRequestDispatch, Endpoint.BackofficeTaskRequestCancelSessionInvite, "POST", null, {
						taskId: followUpTask.id,
						sessionId: (followUpTask as ISessionFollowUpTask).sessionId
					});
					refreshChat();
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	function planSessions(): void {
		if (isGroupProps(props)) {
			const renderKey: string = newKey("planSessions");
			reduxDispatch(
				render(renderKey, PlanSessions, {
					taskId: followUpTask.id,
					groupId: props.groupId,
					projectId: props.projectId,
					onClose: () => {
						reduxDispatch(derender(renderKey));
						refreshChat();
					}
				})
			);
		}
	}

	function getMessages(): Message[] {
		return map(messageState.entities, (message: IMessage) => {
			let author: User = { id: -1, name: t("annaFromTravvant") };
			if (message.sender) {
				author = { id: message.senderId, name: message.sender?.fullName };
			}
			return {
				author,
				text: message.content,
				timestamp: getDate(message.createdDate)
			};
		});
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IFollowUpTask>).readonly;
	let endpoint: Endpoint;
	let extraParams: Record<string, unknown>;

	if (isOpportunityProps(props)) {
		endpoint = Endpoint.OpportunityFollowUpTasks;
		extraParams = { opportunityId: props.opportunityId };
	} else if (isGroupProps(props)) {
		endpoint = Endpoint.GroupFollowUpTasks;
		extraParams = { projectId: props.projectId, groupId: props.groupId };
	} else if (isSessionProps(props)) {
		endpoint = Endpoint.SessionFollowUpTasks;
		extraParams = { projectId: props.projectId, sessionId: props.sessionId };
	} else if (isProjectParticipantProps(props)) {
		endpoint = Endpoint.ProjectParticipantFollowUpTasks;
		extraParams = { projectId: props.projectId, participantId: props.projectParticipantId };
	} else if (isProjectProps(props)) {
		endpoint = Endpoint.ProjectFollowUpTasks;
		extraParams = { projectId: props.projectId };
	}

	return (
		<EntityEditor
			width={followUpTask.id > 0 ? "85%" : "70%"}
			record={followUpTask}
			endpoint={endpoint}
			extraUrlParams={extraParams}
			entityState={followUpTaskState}
			entityType={t("task")}
			dispatch={followUpTaskDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={followUpTask.subject}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						<div className="row">
							<div className="col-7">
								<ManageableField
									fieldLabel={t("subject") + " *"}
									recordId={undefined}
									addScreen={{ screen: TaskSubjectEditor, isAllowed: hasPermission(Permission.TaskSubjectsAdd) }}
									editScreen={{ screen: null, isAllowed: false }}
									setEntity={(taskSubject: ITaskSubject) => {
										setCurrentTaskSubject(taskSubject);
										setTaskSubject(taskSubject);
									}}
									readOnly={readonly}
								>
									<ComboBox
										name="subject"
										className="hiddenTrigger"
										data={taskSubjectState.entities}
										loading={taskSubjectState.areEntitiesLoading}
										value={currentTaskSubject}
										filterable
										dataItemKey="id"
										textField="name"
										onFilterChange={onFilterChange}
										onChange={(event: ComboBoxChangeEvent) => setCurrentTaskSubject(event.value)}
										onClose={(event: ComboBoxCloseEvent) => {
											if (event.target.value) {
												setTaskSubject(event.target.value);
											}
										}}
										allowCustom
										clearButton
										suggest
										required
										disabled={readonly}
										ref={firstField}
									/>
								</ManageableField>
								<label className="k-form-field">
									<span>{t("creator")}</span>
									<Input defaultValue={followUpTask.creator.fullName} disabled />
								</label>
								<div className="k-form-field">
									<span>{t("taskAssignee")} *</span>
									<SearchBox
										name="assigneeId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={followUpTask.assigneeId}
										entity={followUpTask.assignee}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "assignee", "assigneeId")}
										disabled={readonly}
									/>
								</div>
								<div className="k-form-field">
									<span>{t("dueDate")}</span>
									<DateTimePicker name="dueDate" value={getDate(followUpTask.dueDate)} onChange={onChange} format={"yyyy-MM-dd HH:mm"} disabled={readonly} />
								</div>
								<div className="k-form-field">
									<span>{t(statusType)} *</span>
									<EnumDropDownList
										name="status"
										value={followUpTask.status}
										enum={FollowUpTaskStatus}
										itemRender={renderFollowUpTaskStatus}
										onChange={onChange}
										required
										disabled={readonly}
									/>
								</div>
								<div className="row">
									{followUpTask.id > 0 && (
										<BackOfficeButton
											clickHandler={requestBackOfficeTask}
											spanStyle={isSessionProps(props) || isGroupProps(props) ? { fontSize: "20px" } : {}}
											iconClass="las la-envelope-open-text"
											iconStyle={isSessionProps(props) || isGroupProps(props) ? { fontSize: "130px" } : {}}
										>
											{t("sendEmail")}
											<br />
											{t("and")}/{t("or")}
											<br />
											{t("createDocument").toLowerCase()}
										</BackOfficeButton>
									)}
								</div>
								<div className="row">
									{followUpTask.id > 0 && isSessionProps(props) && (
										<>
											<BackOfficeButton
												clickHandler={requestSendSessionInvite}
												spanStyle={{ fontSize: "20px" }}
												iconClass={"las la-paper-plane"}
												iconStyle={{ fontSize: "130px" }}
											>
												{props.outlookMeetingId ? t("sendUpdateInvitation") : t("sendInvitation")}
											</BackOfficeButton>
											{props.outlookMeetingId && (
												<BackOfficeButton
													clickHandler={deleteSessionInvite}
													spanStyle={{ fontSize: "20px" }}
													iconClass={"las la-calendar-times"}
													iconStyle={{ fontSize: "130px" }}
												>
													{t("cancelInvitation")}
												</BackOfficeButton>
											)}
										</>
									)}
									{followUpTask.id > 0 && isGroupProps(props) && (
										<BackOfficeButton clickHandler={planSessions} spanStyle={{ fontSize: "20px" }} iconClass={"las la-spa"} iconStyle={{ fontSize: "130px" }}>
											{t("planSessions")}
										</BackOfficeButton>
									)}
								</div>
							</div>
							<div className="col checklist">
								<div className="row">
									<div className="col">
										<span className="manageable-field-label">
											<i className="las la-plus" onClick={addChecklistItem} />
											{t("checkList")}
										</span>
									</div>
								</div>
								{map(followUpTask.checkList, (checkList: ICheckListItem, index: number) => (
									<div key={`checklist_${index}`} className="checklist-item row">
										<div className="col">
											<Checkbox
												label={checkList.title}
												labelPlacement="after"
												value={checkList.finished}
												onChange={(event: CheckboxChangeEvent) => onChangeChecklistItem(event.value, index)}
												className={checkList.finished ? "finished" : ""}
											/>
											<span className="manageable-field-label">
												<i className="las la-pencil-alt" onClick={() => editChecklistItem(index)} />
												<i className="las la-trash" onClick={() => removeChecklistItem(index)} />
											</span>
										</div>
									</div>
								))}
							</div>
							{followUpTask.id > 0 && (
								<div className="col">
									{(messageState.areEntitiesLoading || messageState.isAdding) && <Loader />}
									<Chat
										user={{ id: currentUser.id, name: currentUser.fullName }}
										messages={getMessages()}
										onMessageSend={onMessageSend}
										message={(messageProps: ChatMessageProps) => <ChatMessage {...messageProps} dateFormat={"T"} />}
										messageBox={(chatMessageBoxProps: ChatMessageBoxProps) => (
											<>
												{chatMessageBoxProps.messageInput}
												{chatMessageBoxProps.sendButton}
												{<StandardButton look="flat" icon="refresh" onClick={refreshChat} />}
											</>
										)}
									/>
								</div>
							)}
						</div>
					</div>
				</Tab>
				{followUpTask.id > 0 && (
					<Tab reactKey="notes" label={t("notes")}>
						<TaskNotes taskId={followUpTask.id} />
					</Tab>
				)}
				{followUpTask.id > 0 && (
					<Tab reactKey="emails" label={t("emails")}>
						<TaskEmails taskId={followUpTask.id} />
					</Tab>
				)}
				{followUpTask.id > 0 && (
					<Tab reactKey="attachments" label={t("attachments")}>
						<TaskAttachments taskId={followUpTask.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default FollowUpTaskEditor;
