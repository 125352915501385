import React, { useEffect, useMemo, useReducer, useState } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { checkBoxCell, customCell, dateCell, enumFilterCell, GridPanel, GridPanelColumn, IRoutedTabProps, translatedCell, YesNoSwitch } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { UserGridPanelConfiguration } from "@selas/ui-components/dist/gridpanel";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialSettingState } from "../../../state";
import { settingReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Color, Permission, SessionStatus } from "../../../utils/enums";
import { isGridCellProps } from "../../../utils/props";
import { ISession, IUser } from "../../../utils/types/models";
import SessionEditor from "../../editors/project/session";
import { colorCell, colorCellClassName, occupationColorCell } from "../../global/colorCell";

export function getSessionStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case SessionStatus.New:
			return Color.Blue;
		case SessionStatus.InSession:
		case SessionStatus.PlannedOrange:
			return Color.Orange;
		case SessionStatus.PlannedGreen:
		case SessionStatus.Closed:
		case SessionStatus.Cancelled:
			return Color.Green;
		case SessionStatus.PlannedRed:
			return Color.Red;
	}
}

const Sessions: React.FC<IRoutedTabProps> = (props: IRoutedTabProps) => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	const [settingOrangeState, settingOrangeDispatch] = useReducer(settingReducer, initialSettingState);
	const [settingRedState, settingRedDispatch] = useReducer(settingReducer, initialSettingState);
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		apiService.callApi(settingOrangeDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationOrange" });
		apiService.callApi(settingRedDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationRed" });
	}, [apiService]);

	const userGridPanelConfiguration: UserGridPanelConfiguration = useMemo(
		() => ({
			gridKey: props.reactKey,
			configurationEndpoint: Endpoint.GridPanelConfigurations,
			filterable: true
		}),
		[props.reactKey]
	);

	return (
		<GridPanel
			listEndpoint={showAll ? Endpoint.GeneralSessionsList : Endpoint.MySessionsList}
			endpoint={Endpoint.Sessions}
			userGridPanelConfiguration={userGridPanelConfiguration}
			editScreen={{
				screen: SessionEditor,
				isAllowed: (session: ISession) => hasPermission(Permission.SessionsUpdate) && (hasPermission(Permission.SessionsAdministrator) || session.responsibleUserId === currentUser.id),
				extraProps: (session: ISession) => ({ projectId: session.projectId })
			}}
			delete={{
				isAllowed: hasPermission(Permission.SessionsDelete),
				urlArguments: (session: ISession) => ({ projectId: session.projectId })
			}}
			extraToolbarButtons={
				hasPermission(Permission.SessionsAll) && [
					<span key="showAllLabel" style={{ marginRight: "10px" }}>
						{t("showAllSessions")}
					</span>,
					<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
				]
			}
			sort={[{ field: "plannedStart", dir: "asc" }]}
		>
			<GridPanelColumn
				field="status"
				title={t("sessionStatus")}
				cell={customCell(colorCell(true, translatedCell(), getSessionStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(SessionStatus)}
				isDefault
			/>
			<GridPanelColumn field="name" title={t("name")} isDefault />
			<GridPanelColumn field="group.name" title={t("group")} isDefault />
			<GridPanelColumn field="plannedStart" title={t("plannedStart")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault />
			<GridPanelColumn field="plannedStop" title={t("plannedStop")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault />
			<GridPanelColumn field="actualStart" title={t("actualStart")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault={false} />
			<GridPanelColumn field="actualStop" title={t("actualStop")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault={false} />
			<GridPanelColumn
				field="occupationPercentage"
				title={t("occupation")}
				filter="numeric"
				cell={customCell(occupationColorCell(true, settingOrangeState.setting?.value, settingRedState.setting?.value), colorCellClassName)}
				isDefault
			/>
			<GridPanelColumn field="location.name" title={t("location")} isDefault={false} />
			<GridPanelColumn field="maximumParticipantCount" title={t("maximumParticipantCount")} isDefault={false} />
			<GridPanelColumn field="responsibleUser.fullName" title={t("coach")} width={currentUser.isReplacingUsers.length <= 0 ? "300px" : undefined} isDefault />
			<GridPanelColumn field="isCollective" title={t("isCollective")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} isDefault={false} />
			<GridPanelColumn field="isOnSite" title={t("isOnSite")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} isDefault={false} />
		</GridPanel>
	);
};

export default Sessions;
