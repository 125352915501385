import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";

export function useDebouncedState<T = unknown>(defaultValue: T, wait: number): readonly [T, Dispatch<SetStateAction<T>>] {
	const [value, setValue] = useState(defaultValue);
	const timeoutRef: MutableRefObject<NodeJS.Timeout> = useRef<NodeJS.Timeout>(null);

	function cancelTimeout(): void {
		return clearTimeout(timeoutRef.current);
	}

	useEffect(() => cancelTimeout, []);

	function debouncedSetValue(newValue: SetStateAction<T>): void {
		cancelTimeout();
		timeoutRef.current = setTimeout(() => {
			setValue(newValue);
		}, wait);
	}

	return [value, debouncedSetValue] as const;
}
