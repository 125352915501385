enum OpportunityStatus {
	New = "New",
	Pending = "Pending",
	InProgress = "InProgress",
	OfferSent = "OfferSent",
	Rejected = "Rejected",
	Approved = "Approved"
}

export default OpportunityStatus;
