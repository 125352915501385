enum SessionStatus {
	New = "New",
	PlannedGreen = "PlannedGreen",
	PlannedOrange = "PlannedOrange",
	PlannedRed = "PlannedRed",
	InSession = "InSession",
	Closed = "Closed",
	Cancelled = "Cancelled"
}

export default SessionStatus;
