import React from "react";

import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { checkBoxCell, customCell, GridPanel, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { cellContentsFunction } from "@selas/ui-components/dist/gridpanel/customCells/gridCells";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import OpportunityOriginEditor from "../../editors/masterdata/opportunityOrigin";

export function percentageCell(): cellContentsFunction {
	const PercentageCell: React.FC<GridCellProps> = ({ dataItem, field }: GridCellProps) => {
		const value: number = dataItem[field];

		return <>{value * 100}</>;
	};

	return PercentageCell;
}

export function unpercentageCell(): cellContentsFunction {
	const PercentageCell: React.FC<GridCellProps> = ({ dataItem, field }: GridCellProps) => {
		const value: number = dataItem[field];

		return <>{value / 100}</>;
	};

	return PercentageCell;
}

const OpportunityOrigins: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.OriginsList}
			endpoint={Endpoint.Origins}
			addScreen={{ screen: OpportunityOriginEditor, isAllowed: hasPermission(Permission.OpportunityOriginsAdd) }}
			editScreen={{ screen: OpportunityOriginEditor, isAllowed: hasPermission(Permission.OpportunityOriginsUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.OpportunityOriginsDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="chanceOfSucces" title={t("chanceOfSucces") + "(in %)"} cell={customCell(percentageCell())} filter="numeric" format="{0:c}" />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default OpportunityOrigins;
