enum ProjectParticipantStatus {
	NotStarted = "NotStarted",
	New = "New",
	OnHold = "OnHold",
	Intake = "Intake",
	Session = "Session",
	Completed = "Completed",
	Stopped = "Stopped",
	Aftercare = "Aftercare"
}

export default ProjectParticipantStatus;
