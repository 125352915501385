import React, { useEffect, useReducer } from "react";

import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, hideLoader, render, showLoader } from "@selas/state-management";
import { StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../services/api/endpoint";
import { initialFileState } from "../../state";
import fileReducer from "../../state/reducers/fileReducer";
import { IOpportunityProps, IProjectProps, isOpportunityProps, isProjectProps } from "../../utils/props";
import ExcelUploadDialog from "../editors/excelUploadDialog";

const ParticipantTemplateButtons: React.FC<IOpportunityProps | IProjectProps> = (props: IOpportunityProps | IProjectProps) => {
	const { t } = useTranslation();
	const [fileState, fileDispatch] = useReducer(fileReducer, initialFileState);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (fileState.isDownloading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [fileState.isDownloading, reduxDispatch]);

	useEffect(() => {
		if (fileState.filename) {
			fileDownload(fileState.downloadFile, fileState.filename);
		}
	}, [fileState.downloadFile, fileState.filename]);

	function downloadParticipantsTemplate(): void {
		if (isOpportunityProps(props)) {
			apiService.callApi(fileDispatch, Endpoint.OpportunityParticipantsTemplateFile, "GET", { opportunityId: props.opportunityId });
		} else if (isProjectProps(props)) {
			apiService.callApi(fileDispatch, Endpoint.ProjectParticipantsTemplateFile, "GET", { projectId: props.projectId });
		}
	}

	function uploadParticipantsList(): void {
		const key: string = newKey("addItem");
		let excelType: string;
		let inputData: Record<string, number>;
		if (isOpportunityProps(props)) {
			excelType = "importOpportunityParticipants";
			inputData = { opportunityId: props.opportunityId };
		} else {
			excelType = "importProjectParticipants";
			inputData = { projectId: props.projectId };
		}
		reduxDispatch(
			render(key, ExcelUploadDialog, {
				excelType,
				inputData,
				onClose: () => {
					reduxDispatch(derender(key));
				}
			})
		);
	}

	return (
		<>
			<StandardButton key="downloadParticipantsTemplate" primary onClick={downloadParticipantsTemplate}>
				{t("downloadParticipantsTemplate")}
			</StandardButton>
			<StandardButton key="uploadParticipantsList" primary onClick={uploadParticipantsList}>
				{t("uploadParticipantsList")}
			</StandardButton>
		</>
	);
};

export default ParticipantTemplateButtons;
