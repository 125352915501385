import React, { useEffect, useReducer } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { customCell, dateCell, enumFilterCell, GridPanel, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { initialSettingState } from "../../../../state";
import { settingReducer } from "../../../../state/reducers";
import { Permission, SessionStatus } from "../../../../utils/enums";
import { colorCell, colorCellClassName, occupationColorCell } from "../../../global/colorCell";
import { getSessionStatusColor } from "../../../pages/work/sessions";
import SessionEditor from "../session";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
}

const Sessions: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	const [settingOrangeState, settingOrangeDispatch] = useReducer(settingReducer, initialSettingState);
	const [settingRedState, settingRedDispatch] = useReducer(settingReducer, initialSettingState);
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		apiService.callApi(settingOrangeDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationOrange" });
		apiService.callApi(settingRedDispatch, Endpoint.SettingsByKey, "GET", { key: "SessionOccupationRed" });
	}, [apiService]);

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.SessionsList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.Sessions}
			addScreen={{ screen: SessionEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			editScreen={{ screen: SessionEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			localLoader
		>
			<GridColumn
				field="status"
				title={t("sessionStatus")}
				cell={customCell(colorCell(true, translatedCell(), getSessionStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(SessionStatus)}
			/>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="maximumParticipantCount" title={t("maximumParticipantCount")} filter="numeric" />
			<GridColumn field="plannedStart" title={t("plannedStart")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="plannedStop" title={t("plannedStop")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="responsibleUser.fullName" title={t("coach")} />
			<GridColumn
				field="occupationPercentage"
				title={t("occupation")}
				filter="numeric"
				cell={customCell(occupationColorCell(true, settingOrangeState.setting?.value, settingRedState.setting?.value), colorCellClassName)}
			/>
		</GridPanel>
	);
};

export default Sessions;
