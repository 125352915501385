import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, TextArea, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { emailTemplateReducer } from "../../../state/reducers";
import { CommunicationLanguage } from "../../../utils/enums";
import { IEmailTemplate } from "../../../utils/types/models";

const EmailTemplateEditor: React.FC<IAddEntityScreenProps<IEmailTemplate> | IEditEntityScreenProps<IEmailTemplate>> = (
	props: IAddEntityScreenProps<IEmailTemplate> | IEditEntityScreenProps<IEmailTemplate>
) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(emailTemplateReducer, getInitialState<IEmailTemplate>());

	const initialEmailTemplate: IEmailTemplate = {
		id: (props as IEditEntityScreenProps<IEmailTemplate>).recordId || 0,
		name: "",
		description: "",
		bodyDe: "",
		bodyEn: "",
		bodyFr: "",
		bodyNl: "",
		subjectDe: "",
		subjectEn: "",
		subjectFr: "",
		subjectNl: "",
		active: true
	};

	const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate>(initialEmailTemplate);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setEmailTemplate(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		setEmailTemplate(handleChange(emailTemplate, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(emailTemplate.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IEmailTemplate): void {
		if (record && !close) {
			setEmailTemplate(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IEmailTemplate>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={emailTemplate}
			endpoint={Endpoint.EmailTemplates}
			entityState={state}
			entityType={t("emailTemplate")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={emailTemplate.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={emailTemplate.name} onChange={onChange} required disabled={readonly} />
				</label>
				<label className="k-form-field">
					<span>{t("description")}</span>
					<TextArea name="description" value={emailTemplate.description} onChange={onChange} disabled={readonly} />
				</label>
				<div className="k-form-field">
					<TabStrip selected={selectedTab} onSelect={(event: TabStripSelectEventArguments) => setSelectedTab(event.selected)} tabPosition="left" style={{ height: "500px" }}>
						<TabStripTab title={t(CommunicationLanguage.Dutch)}>
							<label className="k-form-field">
								<span>{t("subject")}</span>
								<Input name="subjectNl" value={emailTemplate.subjectNl} onChange={onChange} />
							</label>
							<label className="k-form-field">
								<span>{t("body")}</span>
								<TextArea name="bodyNl" value={emailTemplate.bodyNl} onChange={onChange} style={{ height: "370px" }} />
							</label>
						</TabStripTab>
						<TabStripTab title={t(CommunicationLanguage.French)}>
							<label className="k-form-field">
								<span>{t("subject")}</span>
								<Input name="subjectFr" value={emailTemplate.subjectFr} onChange={onChange} />
							</label>
							<label className="k-form-field">
								<span>{t("body")}</span>
								<TextArea name="bodyFr" value={emailTemplate.bodyFr} onChange={onChange} style={{ height: "370px" }} />
							</label>
						</TabStripTab>
						<TabStripTab title={t(CommunicationLanguage.German)}>
							<label className="k-form-field">
								<span>{t("subject")}</span>
								<Input name="subjectDe" value={emailTemplate.subjectDe} onChange={onChange} />
							</label>
							<label className="k-form-field">
								<span>{t("body")}</span>
								<TextArea name="bodyDe" value={emailTemplate.bodyDe} onChange={onChange} style={{ height: "370px" }} />
							</label>
						</TabStripTab>
						<TabStripTab title={t(CommunicationLanguage.English)}>
							<label className="k-form-field">
								<span>{t("subject")}</span>
								<Input name="subjectEn" value={emailTemplate.subjectEn} onChange={onChange} />
							</label>
							<label className="k-form-field">
								<span>{t("body")}</span>
								<TextArea name="bodyEn" value={emailTemplate.bodyEn} onChange={onChange} style={{ height: "370px" }} />
							</label>
						</TabStripTab>
					</TabStrip>
				</div>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={emailTemplate.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default EmailTemplateEditor;
