import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, ManageableField, ManageableMultiSelect, SearchBox, setEntity, TextArea, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { coachReducer, companyReducer, expertiseReducer, jobFunctionReducer, languageReducer, provinceReducer } from "../../../state/reducers";
import { emailRegex } from "../../../utils";
import { Permission } from "../../../utils/enums";
import { IAddress, ICoach, ICoachExpertise, ICoachLanguage, ICoachProvince, ICompany, IExpertise, IJobFunction, ILanguage, IProvince } from "../../../utils/types/models";
import AddressEdit from "../../global/addressEdit";
import CompanyEditor from "../customer/company";
import ExpertiseEditor from "../personInfo/expertise";
import JobFunctionEditor from "../personInfo/jobFunction";
import LanguageEditor from "../personInfo/language";
import ProvinceEditor from "../resources/province";

const CoachEditor: React.FC<IAddEntityScreenProps<ICoach> | IEditEntityScreenProps<ICoach>> = (props: IAddEntityScreenProps<ICoach> | IEditEntityScreenProps<ICoach>) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();

	const initialCoach: ICoach = {
		id: (props as IEditEntityScreenProps<ICoach>).recordId || 0,
		name: "",
		companyId: null,
		company: null,
		jobFunctionId: null,
		jobFunction: null,
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		extra: "",
		phoneNumber: "",
		privateMobilePhoneNumber: "",
		mobilePhoneNumber: "",
		privateEmailAddress: "",
		emailAddress: "",
		internal: false,
		vatNumber: "",
		knownLanguages: [],
		activeProvinces: [],
		expertises: [],
		active: true
	};

	const [coachState, coachDispatch] = useReducer(coachReducer, getInitialState<ICoach>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [jobFunctionState, jobFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [languageState, languageDispatch] = useReducer(languageReducer, getInitialState<ILanguage>());
	const [provinceState, provinceDispatch] = useReducer(provinceReducer, getInitialState<IProvince>());
	const [expertiseState, expertiseDispatch] = useReducer(expertiseReducer, getInitialState<IExpertise>());

	const [coach, setCoach] = useState<ICoach>(initialCoach);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (coachState.entity) {
			setCoach(coachState.entity);
		}
	}, [coachState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.CoachesAdd, Permission.CoachesUpdate)) {
			apiService.callApi(languageDispatch, Endpoint.Languages, "GET");
			apiService.callApi(provinceDispatch, Endpoint.Provinces, "GET");
		}
	}, [apiService]);

	function onChange(event: InputChangeEvent | ComboBoxCloseEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent): void {
		const newCoach: ICoach = handleChange(coach, event);
		updateCoach(newCoach);
	}

	function setAddress(address: IAddress): void {
		const newCoach: ICoach = cloneDeep(coach);
		newCoach.address = address;
		updateCoach(newCoach);
	}

	function updateCoach(newCoach: ICoach): void {
		setCoach(newCoach);
		setDataChanged(true);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const coachRecord: ICoach = cloneDeep(coach);

		if (event.value) {
			if (event.target.name === "knownLanguages") {
				coachRecord.knownLanguages = [];
				for (const lang of event.value) {
					coachRecord.knownLanguages.push({ coachId: coachRecord.id, languageId: lang.id, language: lang });
				}
			} else if (event.target.name === "expertises") {
				coachRecord.expertises = [];
				for (const exp of event.value) {
					coachRecord.expertises.push({ coachId: coachRecord.id, expertiseId: exp.id, expertise: exp });
				}
			} else if (event.target.name === "activeProvinces") {
				coachRecord.activeProvinces = [];
				for (const prov of event.value) {
					coachRecord.activeProvinces.push({ coachId: coachRecord.id, provinceId: prov.id, province: prov });
				}
			}
			updateCoach(coachRecord);
		}
	}

	function addMutliSelectItem(item: ILanguage | IProvince | IExpertise, type: "language" | "province" | "expertise"): void {
		let dispatch: Dispatch<IAction>;
		let endpoint: Endpoint;
		const coachRecord: ICoach = cloneDeep(coach);
		let joinObject: ICoachLanguage | ICoachProvince | ICoachExpertise;
		let joinArray: ICoachLanguage[] | ICoachProvince[] | ICoachExpertise[];
		let idField: "languageId" | "provinceId" | "expertiseId";
		switch (type) {
			case "language":
				dispatch = languageDispatch;
				endpoint = Endpoint.Languages;
				joinArray = coachRecord.knownLanguages;
				idField = "languageId";
				break;
			case "province":
				dispatch = provinceDispatch;
				endpoint = Endpoint.Provinces;
				joinArray = coachRecord.activeProvinces;
				idField = "provinceId";
				break;
			case "expertise":
				joinArray = coachRecord.expertises;
				idField = "expertiseId";
				break;
		}
		joinObject = find(joinArray, { [idField]: item.id }) as ICoachLanguage | ICoachProvince | ICoachExpertise;
		if (joinObject) {
			switch (type) {
				case "language":
					(joinObject as ICoachLanguage).language = item;
					break;
				case "province":
					(joinObject as ICoachProvince).province = item;
					break;
				case "expertise":
					(joinObject as ICoachExpertise).expertise = item;
					break;
			}
		} else {
			switch (type) {
				case "language":
					joinObject = {
						coachId: coachRecord.id,
						languageId: item.id,
						language: item
					};
					break;
				case "province":
					joinObject = {
						coachId: coachRecord.id,
						provinceId: item.id,
						province: item
					};
					break;
				case "expertise":
					joinObject = {
						coachId: coachRecord.id,
						expertiseId: item.id,
						expertise: item
					};
					break;
			}
			joinArray.push(joinObject as ICoachLanguage & ICoachProvince & ICoachExpertise);
		}
		if (dispatch && endpoint) {
			apiService.callApi(dispatch, endpoint, "GET");
		}
		updateCoach(coachRecord);
	}

	function onMultiSelectFilterChange(event: MultiSelectFilterChangeEvent): void {
		apiService.callApi(expertiseDispatch, Endpoint.Expertises, "GET", { search: event.filter.value });
	}

	function onActionButtonClicked(close: boolean, record: ICoach): void {
		if (record && !close) {
			setCoach(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof ICoach, idField: keyof ICoach): void {
		const newCoach: ICoach = setEntity(coach, entity, field, idField);
		updateCoach(newCoach);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "jobFunctionId":
				dispatch = jobFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
			case "knownLanguages":
				dispatch = languageDispatch;
				filterEndpoint = Endpoint.Languages;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(coach.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!isNullOrEmpty(coach.emailAddress) && !coach.emailAddress.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "email@test.com" }));
		}
		if (isNullOrEmpty(coach.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(coach.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(coach.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(coach.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(coach.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		if (!coach.internal && isNullOrEmpty(coach.vatNumber)) {
			messages.push(t("fill_in_required_field", { field: t("vatNumber").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ICoach>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={coach}
			endpoint={Endpoint.Coaches}
			entityState={coachState}
			entityType={t("coach")}
			dispatch={coachDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={coach.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("name")} *</span>
							<Input name="name" value={coach.name} ref={firstField} onChange={onChange} disabled={readonly} required />
						</label>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<div>{t("internal")}</div>
							<YesNoSwitch name="internal" checked={coach.internal} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<ManageableField
							fieldLabel={t("company")}
							recordId={coach.companyId}
							addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
							editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
							setEntity={(company: ICompany) => setChild(company, "company", "companyId")}
							readOnly={readonly}
						>
							<SearchBox
								name="companyId"
								entities={companyState.entities}
								isLoading={companyState.areEntitiesLoading}
								entityId={coach.companyId}
								entity={coach.company}
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "company", "companyId")}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<ManageableField
							fieldLabel={t("jobFunction")}
							recordId={coach.jobFunctionId}
							addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
							editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
							setEntity={(record: IJobFunction) => setChild(record, "jobFunction", "jobFunctionId")}
							readOnly={readonly}
						>
							<SearchBox
								name="jobFunctionId"
								entities={jobFunctionState.entities}
								isLoading={jobFunctionState.areEntitiesLoading}
								entityId={coach.jobFunctionId}
								entity={coach.jobFunction}
								textField="name"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setChild(null, "jobFunction", "jobFunctionId")}
								disabled={readonly}
							/>
						</ManageableField>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("vatNumber")} {!coach.internal ? " *" : ""}
							</span>
							<Input name="vatNumber" value={coach.vatNumber} onChange={onChange} disabled={readonly} required={!coach.internal} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("phoneNumber")}</span>
							<Input name="phoneNumber" value={coach.phoneNumber} onChange={onChange} disabled={readonly} hidden={true} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("mobilePhoneNumber")}</span>
							<Input name="mobilePhoneNumber" value={coach.mobilePhoneNumber} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("email")}</span>
							<Input name="emailAddress" value={coach.emailAddress} onChange={onChange} disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
				</div>
				{hasPermission(Permission.PrivateContactInformationRead) && (
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("privateMobilePhoneNumber")}</span>
								<Input
									name="privateMobilePhoneNumber"
									value={coach.privateMobilePhoneNumber}
									onChange={onChange}
									disabled={!hasPermission(Permission.PrivateContactInformationWrite)}
								/>
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("privateEmailAddress")}</span>
								<Input
									name="privateEmailAddress"
									value={coach.privateEmailAddress}
									onChange={onChange}
									disabled={!hasPermission(Permission.PrivateContactInformationWrite)}
									pattern={emailRegex.source}
								/>
							</label>
						</div>
					</div>
				)}
				<AddressEdit address={coach.address} addressField="address" onChange={onChange} setAddress={setAddress} required numberRequired={true} readonly={readonly} />
				<ManageableMultiSelect
					fieldLabel={t("knownLanguages")}
					addScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesAdd) }}
					editScreen={{ screen: LanguageEditor, isAllowed: hasPermission(Permission.LanguagesUpdate) }}
					setEntity={(record: ILanguage) => addMutliSelectItem(record, "language")}
					name="knownLanguages"
					data={languageState.entities}
					textField="name"
					dataItemKey="id"
					value={map(coach.knownLanguages, "language")}
					loading={languageState.areEntitiesLoading}
					onChange={onMultiSelectChange}
					filterable
					onFilterChange={onFilterChange}
					autoClose={false}
					disabled={readonly}
					readOnly={readonly}
				/>
				<ManageableMultiSelect
					fieldLabel={t("activeProvinces")}
					addScreen={{ screen: ProvinceEditor, isAllowed: hasPermission(Permission.ProvincesAdd) }}
					editScreen={{ screen: ProvinceEditor, isAllowed: hasPermission(Permission.ProvincesUpdate) }}
					setEntity={(record: IProvince) => addMutliSelectItem(record, "province")}
					name="activeProvinces"
					data={provinceState.entities}
					textField="name"
					dataItemKey="id"
					value={map(coach.activeProvinces, "province")}
					loading={provinceState.areEntitiesLoading}
					onChange={onMultiSelectChange}
					autoClose={false}
					disabled={readonly}
					readOnly={readonly}
				/>
				<ManageableMultiSelect
					fieldLabel={t("expertises")}
					addScreen={{ screen: ExpertiseEditor, isAllowed: hasPermission(Permission.ExpertisesAdd) }}
					editScreen={{ screen: ExpertiseEditor, isAllowed: hasPermission(Permission.ExpertisesUpdate) }}
					setEntity={(record: IExpertise) => addMutliSelectItem(record, "expertise")}
					name="expertises"
					data={expertiseState.entities}
					textField="name"
					dataItemKey="id"
					value={map(coach.expertises, "expertise")}
					loading={expertiseState.areEntitiesLoading}
					onChange={onMultiSelectChange}
					filterable
					onFilterChange={onMultiSelectFilterChange}
					disabled={readonly}
					readOnly={readonly}
				/>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("extra")}</span>
							<TextArea name="extra" value={coach.extra} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={coach.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default CoachEditor;
