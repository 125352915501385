import { IAction } from "@selas/models";
import { createEntityReducer } from "@selas/state-management";
import { AxiosError } from "axios";
import { Reducer } from "redux";

import { IInvoiceServiceState, initialInvoiceServiceState } from "..";

import Endpoint from "../../services/api/endpoint";
import { IInvoiceServiceBase } from "../../utils/types/models";

const invoiceReducer: Reducer<IInvoiceServiceState, IAction> = (state: IInvoiceServiceState = initialInvoiceServiceState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_PUT_" + Endpoint.invoiceServicesSetStatusList:
		case "REQUEST_PUT_" + Endpoint.invoiceServicesSetInvoiceDetails:
		case "REQUEST_PUT_" + Endpoint.invoiceServicesSetStatus:
			state = {
				...state,
				isUpdating: true,
				isUpdated: false,
				error: null
			};
			break;
		case "REQUEST_SUCCESS_PUT_" + Endpoint.invoiceServicesSetStatusList:
		case "REQUEST_SUCCESS_PUT_" + Endpoint.invoiceServicesSetInvoiceDetails:
		case "REQUEST_SUCCESS_PUT_" + Endpoint.invoiceServicesSetStatus:
			state = {
				...state,
				isUpdated: true
			};

			break;
		case "REQUEST_FAILURE_PUT_" + Endpoint.invoiceServicesSetStatusList:
		case "REQUEST_FAILURE_PUT_" + Endpoint.invoiceServicesSetInvoiceDetails:
		case "REQUEST_FAILURE_PUT_" + Endpoint.invoiceServicesSetStatus: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				error: payload.error
			};

			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.invoiceServicesSetStatusList:
		case "REQUEST_END_PUT_" + Endpoint.invoiceServicesSetInvoiceDetails:
		case "REQUEST_END_PUT_" + Endpoint.invoiceServicesSetStatus:
			state = {
				...state,
				isUpdating: false
			};

			break;
		case "CLEAR_PUT_" + Endpoint.invoiceServicesSetStatusList:
		case "CLEAR_PUT_" + Endpoint.invoiceServicesSetInvoiceDetails:
		case "CLEAR_PUT_" + Endpoint.invoiceServicesSetStatus:
			state = {
				...state,
				isUpdating: false,
				isUpdated: false,
				error: null
			};

			break;
	}
	return state;
};

export default createEntityReducer<IInvoiceServiceBase, IInvoiceServiceState>(Endpoint.invoiceServices, Endpoint.invoiceServicesList, invoiceReducer);
