import React, { useEffect, useRef, useState } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { derender, render } from "@selas/state-management";
import { customCell, dateCell, enumFilterCell, GridPanel, IGridPanelRef, translatedCell, YesNoSwitch } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { IApplicationState } from "../../../store";
import { FollowUpTaskStatus, Permission } from "../../../utils/enums";
import {
	IFollowUpTask,
	IGroup,
	IGroupFollowUpTask,
	IOpportunity,
	IOpportunityFollowUpTask,
	IProject,
	IProjectFollowUpTask,
	IProjectParticipant,
	IProjectParticipantFollowUpTask,
	ISession,
	ISessionFollowUpTask,
	IUser
} from "../../../utils/types/models";
import FollowUpTaskEditor, { getFollowUpTaskStatusColor, SpecificProps } from "../../editors/followup/followUpTask";
import OpportunityEditor from "../../editors/opportunity";
import ProjectEditor from "../../editors/project";
import GroupEditor from "../../editors/project/group";
import ProjectParticipantEditor from "../../editors/project/projectParticipant";
import SessionEditor from "../../editors/project/session";
import { colorCell, colorCellClassName } from "../../global/colorCell";

import style from "./home.module.scss";

function isOpportunityTask(toBeDetermined: IFollowUpTask): toBeDetermined is IOpportunityFollowUpTask {
	return (toBeDetermined as IOpportunityFollowUpTask).opportunityId !== undefined;
}

function isProjectTask(toBeDetermined: IFollowUpTask): toBeDetermined is IProjectFollowUpTask {
	return (toBeDetermined as IProjectFollowUpTask).projectId !== undefined;
}

function isGroupTask(toBeDetermined: IFollowUpTask): toBeDetermined is IGroupFollowUpTask {
	return (toBeDetermined as IGroupFollowUpTask).groupId !== undefined;
}

function isSessionTask(toBeDetermined: IFollowUpTask): toBeDetermined is ISessionFollowUpTask {
	return (toBeDetermined as ISessionFollowUpTask).sessionId !== undefined;
}

function isProjectParticipantTask(toBeDetermined: IFollowUpTask): toBeDetermined is IProjectParticipantFollowUpTask {
	return (toBeDetermined as IProjectParticipantFollowUpTask).projectParticipantId !== undefined;
}

function getExtraParam(dataItem: IFollowUpTask): SpecificProps {
	if (isOpportunityTask(dataItem)) {
		return {
			opportunityId: dataItem.opportunityId
		};
	} else if (isGroupTask(dataItem)) {
		return {
			projectId: dataItem.group.projectId,
			groupId: dataItem.groupId
		};
	} else if (isSessionTask(dataItem)) {
		return {
			projectId: dataItem.session.projectId,
			sessionId: dataItem.sessionId,
			outlookMeetingId: dataItem.session.sessionInvite?.outlookMeetingId
		};
	} else if (isProjectParticipantTask(dataItem)) {
		return {
			projectId: dataItem.projectParticipant.projectId,
			projectParticipantId: dataItem.projectParticipantId
		};
	} else if (isProjectTask(dataItem)) {
		return {
			projectId: dataItem.projectId
		};
	}
}

const Home: React.FC = () => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ReduxDispatch = useDispatch();
	const [showAll, setShowAll] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	const gridRef: React.RefObject<IGridPanelRef> = useRef<IGridPanelRef>();

	useEffect(() => {
		gridRef.current.refresh();
	}, [showHistory]);

	// tslint:disable-next-line: no-any
	function editRecord(editor: any, permission: boolean, recordId: number, projectId: number): void {
		const key: string = newKey("editRelatedEntity");
		reduxDispatch(
			// @ts-ignore
			render(key, editor, {
				readonly: !permission,
				recordId,
				projectId,
				actionButtonClicked: (close: boolean, updatedRecord: IOpportunity | IGroup | ISession | IProjectParticipant | IProject) => {
					reduxDispatch(derender(key));
					if (!close) {
						editRecord(editor, permission, recordId, projectId);
					}
					if (updatedRecord) {
						gridRef.current.refresh();
					}
				}
			})
		);
	}

	function showRelatedEditor(record: IFollowUpTask): void {
		let editor: typeof OpportunityEditor | typeof GroupEditor | typeof SessionEditor | typeof ProjectParticipantEditor | typeof ProjectEditor;

		let permission: boolean;
		let projectId: number;
		let recordId: number;

		if (isOpportunityTask(record)) {
			editor = OpportunityEditor;
			permission = currentUser.permissions.opportunitiesUpdate;
			recordId = record.opportunityId;
		} else if (isGroupTask(record)) {
			editor = GroupEditor;
			permission = currentUser.permissions.groupsUpdate;
			recordId = record.groupId;
			projectId = record.group.projectId;
		} else if (isSessionTask(record)) {
			editor = SessionEditor;
			permission = currentUser.permissions.sessionsUpdate;
			recordId = record.sessionId;
			projectId = record.session.projectId;
		} else if (isProjectParticipantTask(record)) {
			editor = ProjectParticipantEditor;
			permission = currentUser.permissions.projectParticipantsUpdate;
			recordId = record.projectParticipantId;
			projectId = record.projectParticipant.projectId;
		} else if (isProjectTask(record)) {
			editor = ProjectEditor;
			permission = currentUser.permissions.projectsUpdate;
			recordId = record.projectId;
		}

		editRecord(editor, permission, recordId, projectId);
	}

	const extraToolbarButtons: React.ReactElement[] = [
		<span key="history" style={{ marginLeft: "15px", marginRight: "10px" }}>
			{t("history")}
		</span>,
		<YesNoSwitch key="historySwitch" onChange={(event: SwitchChangeEvent) => setShowHistory(event.value)} />
	];
	if (hasPermission(Permission.TasksAll)) {
		extraToolbarButtons.unshift(
			<span key="showAllLabel" style={{ marginRight: "10px" }}>
				{t("showAllTasks")}
			</span>,
			<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
		);
	}

	return (
		<div className={style.home}>
			<div className="row">
				<div className="col">
					<div className={style.title}>
						{t("welcome_message")} <span className={style.titleBold}>{currentUser.fullName}</span>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<GridPanel
						listEndpoint={showAll ? Endpoint.TasksList : Endpoint.MyTasksList}
						endpoint={undefined}
						listUrlArguments={{ showHistory }}
						editScreen={{ screen: FollowUpTaskEditor, isAllowed: true, extraProps: getExtraParam }}
						delete={false}
						style={{ height: "800px" }}
						extraToolbarButtons={extraToolbarButtons}
						extraCommands={[
							{
								iconClass: "las la-edit",
								tooltip: t("editRelatedEntity"),
								recordAction: showRelatedEditor
							}
						]}
						ref={gridRef}
					>
						<GridColumn
							field="status"
							title={t("followUpTaskStatus")}
							cell={customCell(colorCell(true, translatedCell(), getFollowUpTaskStatusColor), colorCellClassName)}
							filterCell={enumFilterCell(FollowUpTaskStatus)}
						/>
						<GridColumn field="companyName" title={t("company")} />
						<GridColumn field="creatorFullname" title={t("creator")} />
						<GridColumn field="subject" title={t("subject")} />
						<GridColumn field="assigneeFullname" title={t("taskAssignee")} />
						<GridColumn field="relatedTo" title={t("relatedTo")} headerClassName="notSortable" sortable={false} filterable={false} />
						<GridColumn field="dueDate" title={t("dueDate")} cell={customCell(dateCell("yyyy-MM-dd  HH:mm"))} filter="date" />
					</GridPanel>
				</div>
			</div>
		</div>
	);
};

export default Home;
