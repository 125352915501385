import React, { Dispatch, useReducer } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, RadioButton, RadioButtonChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { handleChange, SearchBox, setEntity, TextArea, YesNoSwitch } from "@selas/ui-components";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { initialUserState } from "../../../../state";
import { userReducer } from "../../../../state/reducers";
import { GroupType } from "../../../../utils/enums";
import { IGroup } from "../../../../utils/types/models";

interface IProps {
	group: IGroup;
	firstField?: React.MutableRefObject<Input>;
	updateGroup?: (newGroup: IGroup) => void;
	readonly: boolean;
}

const GroupEditorContent: React.FC<IProps> = ({ group, firstField, updateGroup, readonly }: IProps) => {
	const { t } = useTranslation();
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);

	const apiService: ApiCommunicator = useApiService();

	function onChange(event: InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | ComboBoxCloseEvent | DropDownListChangeEvent | NumericTextBoxChangeEvent | SwitchChangeEvent): void {
		updateGroup(handleChange(group, event));
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IGroup, idField: keyof IGroup): void {
		updateGroup(setEntity(group, entity, field, idField));
	}

	function onChangeGroupType(event: RadioButtonChangeEvent): void {
		const groupRecord: IGroup = cloneDeep(group);
		groupRecord.type = event.value;
		updateGroup(groupRecord);
	}

	return (
		<>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("name")} *</span>
						<Input name="name" ref={firstField} value={group.name} onChange={onChange} required disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("groupCode")}</span>
						<Input name="code" value={group.code} onChange={onChange} disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("maximumParticipantCount")}</span>
						<NumericTextBox name="maximumParticipantCount" value={group.maximumParticipantCount} onChange={onChange} format="n0" min={0} disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<div className="k-form-field">
						<span>{t("groupResponsible")} *</span>
						<SearchBox
							name="responsibleUserId"
							entities={userState.entities}
							isLoading={userState.areEntitiesLoading}
							entityId={group.responsibleUserId}
							entity={group.responsibleUser}
							required
							textField="fullName"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "responsibleUser", "responsibleUserId")}
							disabled={readonly}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("type")}</span>
						<div className="row" style={{ marginLeft: "10px" }}>
							<div className="col">
								<RadioButton
									label={t(GroupType.Travvant)}
									checked={group.type === GroupType.Travvant}
									value={GroupType.Travvant}
									style={{ marginRight: "10px" }}
									onChange={onChangeGroupType}
									disabled={readonly}
								/>
							</div>
							<div className="col">
								<RadioButton
									label={t(GroupType.Independent)}
									checked={group.type === GroupType.Independent}
									value={GroupType.Independent}
									style={{ marginRight: "10px" }}
									onChange={onChangeGroupType}
									disabled={readonly}
								/>
							</div>
							<div className="col">
								<RadioButton
									label={t(GroupType.Combo)}
									checked={group.type === GroupType.Combo}
									value={GroupType.Combo}
									style={{ marginRight: "10px" }}
									onChange={onChangeGroupType}
									disabled={readonly}
								/>
							</div>
						</div>
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("description")}</span>
						<TextArea name="description" value={group.description} onChange={onChange} disabled={readonly} />
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={group.active} onChange={onChange} disabled={readonly} />
					</div>
				</div>
			</div>
		</>
	);
};

export default GroupEditorContent;
