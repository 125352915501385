import React from "react";

import { useTranslation } from "react-i18next";
import HeatRow from "./row";

interface IProps {
	reactKey: string;
	currentHeat: number;
	hoveringHeat?: {
		heat: number;
		setheat: (newHeat: number) => void;
	};
	changeHeat?: (newHeat: number) => void;
	readonly: boolean;
}

const HeatField: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<div className="k-form-field heat">
			<span>{t("heat")}</span>
			<HeatRow {...props} />
		</div>
	);
};

export default HeatField;
