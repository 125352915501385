import React from "react";

import { Button } from "@progress/kendo-react-buttons";

import SelasLogo from "../../assets/Travant_Full_Logo.png";
import styles from "./login.module.scss";

interface IProps {
	login: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Login: React.FC<IProps> = (props: IProps) => {
	return (
		<div className={styles.container + " d-flex align-items-center justify-content-center"}>
			<div className={styles.logIn + " d-flex flex-column align-items-center justify-content-center"}>
				<img src={SelasLogo} className={styles.logo} alt="logo" />
				<div id={styles.login}>LOGIN</div>
				<p>Welkom terug! Log in door op de knop te klikken</p>
				<Button imageUrl="microsoft.png" onClick={props.login}>
					Log in met Microsoft 365
				</Button>
			</div>
		</div>
	);
};

export default Login;
