import { IAction } from "@selas/models";

import { ISetting } from "../../utils/types/models";

export function updateSetting(settingKey: string, setting: ISetting): IAction {
	return {
		type: "UPDATE_SETTING",
		settingKey,
		setting
	};
}
