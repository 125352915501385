import React, { useEffect, useReducer, useRef, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { hideLoader, showLoader } from "@selas/state-management";
import { Confirm, Form, handleChange, notify, StandardButton, SubmitButton } from "@selas/ui-components";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { initialProjectState } from "../../../state";
import { projectReducer } from "../../../state/reducers";
import { IProject } from "../../../utils/types/models";

interface ICopyProjectFromProjectModel {
	title: string;
	projectId: number;
}

interface IProps {
	project?: IProject;
	close: (record?: IProject) => void;
}

const CopyFromProjectDialog: React.FC<IProps> = ({ project, close }: IProps) => {
	const firstInput: React.RefObject<Input> = useRef();
	const { t } = useTranslation();
	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const reduxDispatch: ReduxDispatch = useDispatch();
	const apiService: ApiCommunicator = useApiService();
	const [dataChanged, setDataChanged] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [newProject, setNewProject] = useState<ICopyProjectFromProjectModel>({ title: project?.title, projectId: project.id });

	useEffect(() => {
		if (projectState.isAdding) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
		return () => {
			reduxDispatch(hideLoader());
		};
	}, [reduxDispatch, projectState]);

	useEffect(() => {
		if (!projectState.isAdding && projectState.addedEntity) {
			close(projectState.addedEntity);
		}
	}, [projectState.isAdding, projectState.addedEntity, close]);

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function copy(): void {
		const errorMessages: string[] = [];
		if (!newProject.title) {
			errorMessages.push(t("fill_in_required_field", { field: t("title").toLowerCase() }));
		}
		if (!errorMessages || errorMessages.length <= 0) {
			apiService.callApi(projectDispatch, Endpoint.CopyProject, "POST", null, newProject);
		} else {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		}
	}

	function updateNewProject(newModel: ICopyProjectFromProjectModel): void {
		setNewProject(newModel);
		setDataChanged(true);
	}

	function onChange(event: InputChangeEvent): void {
		const tempProject: ICopyProjectFromProjectModel = handleChange(newProject, event);
		updateNewProject(tempProject);
	}

	return (
		<>
			<Form>
				<Dialog width="50%" title={t("copyProjectFromProject")} onClose={() => handleClose()}>
					<div className="k-form">
						<label className="k-form-field">
							<span>{t("title")}</span>
							<Input name="title" ref={firstInput} value={newProject.title} onChange={onChange} />
						</label>
					</div>
					<DialogActionsBar>
						<StandardButton onClick={() => handleClose()} type="button">
							{t("cancel")}
						</StandardButton>
						<SubmitButton primary onClick={() => copy()}>
							{t("copy")}
						</SubmitButton>
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						copy();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
};

export default CopyFromProjectDialog;
