import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, InputChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { provinceReducer } from "../../../state/reducers";
import { IProvince } from "../../../utils/types/models";

const ProvinceEditor: React.FC<IAddEntityScreenProps<IProvince> | IEditEntityScreenProps<IProvince>> = (props: IAddEntityScreenProps<IProvince> | IEditEntityScreenProps<IProvince>) => {
	const { t } = useTranslation();

	const [state, dispatch] = useReducer(provinceReducer, getInitialState<IProvince>());
	const initialProvince: IProvince = {
		id: (props as IEditEntityScreenProps<IProvince>).recordId || 0,
		name: "",
		active: true
	};
	const [province, setProvince] = useState<IProvince>(initialProvince);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setProvince(state.entity);
		}
	}, [state.entity]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent): void {
		setProvince(handleChange(province, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(province.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IProvince): void {
		if (record && !close) {
			setProvince(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IProvince>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={province}
			endpoint={Endpoint.Provinces}
			entityState={state}
			entityType={t("province")}
			dispatch={dispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={province.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} value={province.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={province.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ProvinceEditor;
