import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, IAddEntityScreenProps, IEditEntityScreenProps } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { participantReducer } from "../../../../state/reducers";
import { emailRegex } from "../../../../utils";
import { CommunicationLanguage } from "../../../../utils/enums";
import { IParticipant } from "../../../../utils/types/models";
import ParticipantEditorContent from "./editorContent";

const ParticipantEditor: React.FC<IAddEntityScreenProps<IParticipant> | IEditEntityScreenProps<IParticipant>> = (props: IAddEntityScreenProps<IParticipant> | IEditEntityScreenProps<IParticipant>) => {
	const { t } = useTranslation();

	const intialParticipant: IParticipant = {
		id: (props as IEditEntityScreenProps<IParticipant>).recordId || 0,
		nationalRegistryNumber: "",
		firstName: "",
		lastName: "",
		fullName: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		phoneNumber: "",
		mobilePhoneNumber: "",
		emailAddress: "",
		nationalityId: null,
		dateOfBirth: null,
		genderId: null,
		knownLanguages: [],
		highestDegreeId: null,
		communicationLanguage: CommunicationLanguage.Dutch,
		active: true,
		isInfoAndRefferalEmailAllowed: true
	};

	const [participantState, participantDispatch] = useReducer(participantReducer, getInitialState<IParticipant>());
	const [participant, setParticipant] = useState<IParticipant>(intialParticipant);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (participantState.entity) {
			setParticipant(participantState.entity);
		}
	}, [participantState.entity]);

	function updateParticipant(newParticipant: IParticipant): void {
		setParticipant(newParticipant);
		setDataChanged(true);
	}

	function onActionButtonClicked(close: boolean, record: IParticipant): void {
		if (record && !close) {
			setParticipant(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(participant.firstName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(participant.lastName)) {
			messages.push(t("fill_in_required_field", { field: t("lastName").toLowerCase() }));
		}
		if (!isNullOrEmpty(participant.emailAddress) && !participant.emailAddress.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "email@test.com" }));
		}
		if (!participant.communicationLanguage) {
			messages.push(t("fill_in_required_field", { field: t("communicationLanguage").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IParticipant>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={participant}
			endpoint={Endpoint.Participants}
			entityState={participantState}
			entityType={t("participant")}
			dispatch={participantDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={participant.fullName || ""}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<ParticipantEditorContent participant={participant} firstField={firstField} updateParticipant={updateParticipant} readonly={readonly} hideActive={props.hideActive} />
			</div>
		</EntityEditor>
	);
};

export default ParticipantEditor;
