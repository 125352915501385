import React, { useEffect, useRef, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Confirm, Form, notify, StandardButton, SubmitButton, usePreventWindowUnload } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface IProps {
	initialTitle?: string;
	onClose: (title?: string) => void;
}

const CheckListItemEditor: React.FC<IProps> = ({ initialTitle = "", onClose }: IProps) => {
	const { t } = useTranslation();
	const [title, setTitle] = useState(initialTitle);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const ref: React.MutableRefObject<Input> = useRef<Input>(null);
	const reduxDispatch: Dispatch = useDispatch();

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	}, [ref]);

	function onChange(event: InputChangeEvent): void {
		setTitle(event.target.value as string);
		setDataChanged(true);
	}

	function save(): void {
		if (isNullOrEmpty(title)) {
			reduxDispatch(notify(t("information"), t("fill_in_required_field", { field: t("title").toLowerCase() }), "success"));
		} else {
			onClose(title);
		}
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			onClose();
		}
	}

	return (
		<Form>
			<Dialog title={t("add") + " " + t("item")} width="70%" onClose={() => handleClose()} autoFocus>
				<div className="k-form">
					<label className="k-form-field">
						<span>{t("title")} *</span>
						<Input ref={ref} required onChange={onChange} value={title} />
					</label>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => handleClose()}>{t("cancel")}</StandardButton>
					<SubmitButton primary disabled={!dataChanged} onClick={() => save()}>
						{t("save")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => onClose()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</Form>
	);
};

export default CheckListItemEditor;
