import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";
import Companies from "./companies";
import CompanyGroups from "./companyGroups";
import Contacts from "./contacts";
import ContactTypes from "./contactTypes";
import Participants from "./participants";
import Partners from "./partners";

const Customer: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("customer")}>
			<Contacts reactKey="contacts" label={t("contacts")} url="/contacts" tileIcon="las la-address-book" isAllowed={hasPermission(Permission.ContactsRead)} />
			<ContactTypes reactKey="contactTypes" label={t("contactTypes")} url="/contactTypes" tileIcon="las la-address-card" isAllowed={hasPermission(Permission.ContactTypesRead)} />
			<CompanyGroups reactKey="companyGroups" label={t("companyGroups")} url="/companyGroups" tileIcon="las la-layer-group" isAllowed={hasPermission(Permission.CompanyGroupsRead)} />
			<Companies reactKey="companies" label={t("companies")} url="/companies" tileIcon="las la-building" isAllowed={hasPermission(Permission.CompaniesRead)} />
			<Partners reactKey="partners" label={t("partners")} url="/partners" tileIcon="las la-handshake" isAllowed={hasPermission(Permission.PartnersRead)} />
			<Participants reactKey="participants" label={t("participants")} url="/participants" tileIcon="las la-id-badge" isAllowed={hasPermission(Permission.ParticipantsRead)} />
		</RoutedTabPanel>
	);
};

export default Customer;
