import React from "react";

import { RoutedTabPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import Roles from "./roles";
import Users from "./users";

import { hasPermission } from "../../../services/authentication";

const Security: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("security")}>
			<Users reactKey="users" label={t("users")} url="/users" tileIcon="las la-users" isAllowed={hasPermission(Permission.UsersRead)} />
			<Roles reactKey="roles" label={t("roles")} url="/roles" tileIcon="las la-lock-open" isAllowed={hasPermission(Permission.RolesRead)} />
		</RoutedTabPanel>
	);
};

export default Security;
