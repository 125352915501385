import { IEntityState } from "@selas/state-management";
import { AxiosError } from "axios";

export interface GridPanelConfiguration {
	ownerId: number;
	gridKey: string;
	columns: string[];
}

export interface GridPanelConfigurationState extends IEntityState<GridPanelConfiguration> {
	isLoading: boolean;
	gridPanelConfiguration: GridPanelConfiguration;
	loadError: AxiosError;

	savedGridPanelConfiguration: GridPanelConfiguration;
	isSaving: boolean;
	saveError: AxiosError;
}

export const intitialGridPanelConfigurationState: GridPanelConfigurationState = {
	isLoading: false,
	gridPanelConfiguration: null,
	loadError: null,

	savedGridPanelConfiguration: null,
	isSaving: false,
	saveError: null,

	isListLoading: false,
	isAdding: false,
	isDeleting: false,
	isEntityLoading: false,
	isUpdating: false,
	addedEntity: null,
	addError: null,
	areEntitiesLoading: false,
	list: null,
	totalCount: 0,
	listLoadError: null,
	entities: null,
	entitiesLoadError: null,
	entity: null,
	entityLoadError: null,
	updatedEntity: null,
	updateError: null,
	deleteError: null,
	deleteSuccess: null
};
