import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState, hideLoader, showLoader } from "@selas/state-management";
import { Confirm, Form, handleChange, ManageableField, notify, SearchBox, setEntity, StandardButton, SubmitButton, TextArea, usePreventWindowUnload } from "@selas/ui-components";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialOpportunityState, initialProjectState, initialUserState } from "../../../state";
import { opportunityReducer, partnerReducer, projectReducer, serviceTypeReducer, userReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Permission } from "../../../utils/enums";
import { IOpportunity, IPartner, IProject, IServiceType, IUser } from "../../../utils/types/models";
import ServiceTypeEditor from "../catalogue/serviceType";
import PartnerEditor from "../customer/partner";

interface ICreateOpportunityFromProjectModel {
	projectId: number;
	project: IProject;
	title: string;
	description: string;
	responsibleUserId: number;
	responsibleUser: IUser;
	typeId: number;
	type: IServiceType;
	forecastCost: number;
	partnerId: number;
	partner: IPartner;
}

interface IProps {
	project?: IProject;
	close: (record?: IOpportunity) => void;
}

const CreateFromProjectDialog: React.FC<IProps> = ({ project, close }: IProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [model, setModel] = useState<ICreateOpportunityFromProjectModel>({
		projectId: project?.id,
		project,
		title: project?.title,
		description: project?.description,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		typeId: null,
		type: null,
		forecastCost: 0,
		partner: null,
		partnerId: null
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const [opportunityState, opportunityDispatch] = useReducer(opportunityReducer, initialOpportunityState);
	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [serviceTypeState, serviceTypeDispatch] = useReducer(serviceTypeReducer, getInitialState<IServiceType>());
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();
	const firstCombobox: React.RefObject<ComboBox> = useRef();
	const firstInput: React.RefObject<Input> = useRef();

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (!project && firstCombobox && firstCombobox.current) {
			firstCombobox.current.focus();
		} else if (project && firstInput && firstInput.current) {
			firstInput.current.focus();
		}
	}, [firstInput, firstCombobox, project]);

	useEffect(() => {
		if (!opportunityState.isAdding && opportunityState.addedEntity) {
			close(opportunityState.addedEntity);
		}
	}, [opportunityState.isAdding, opportunityState.addedEntity, close]);

	useEffect(() => {
		if (opportunityState.isAdding) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
		return () => {
			reduxDispatch(hideLoader());
		};
	}, [reduxDispatch, opportunityState.isAdding]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "projectId":
				dispatch = projectDispatch;
				filterEndpoint = hasPermission(Permission.ProjectsAll) ? Endpoint.Projects : Endpoint.ProjectsSearchMyList;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
			case "typeId":
				dispatch = serviceTypeDispatch;
				filterEndpoint = Endpoint.ServiceTypes;
				break;
			case "partnerId":
				dispatch = partnerDispatch;
				filterEndpoint = Endpoint.Partners;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function onChange(event: InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | ComboBoxCloseEvent | NumericTextBoxChangeEvent | DropDownListChangeEvent): void {
		const newModel: ICreateOpportunityFromProjectModel = handleChange(model, event);
		if (event.target.name === "projectId" && event.target.value) {
			newModel.title = event.target.value.title;
			newModel.description = event.target.value.description;
			newModel.responsibleUserId = event.target.value.responsibleUserId;
			newModel.responsibleUser = event.target.value.responsibleUser;
			newModel.forecastCost = event.target.value.forecastCost;
			newModel.type = event.target.value.type;
			newModel.typeId = event.target.value.typeId;
			newModel.partner = event.target.value.partner;
			newModel.partnerId = event.target.value.partnerId;
		}
		updateModel(newModel);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof ICreateOpportunityFromProjectModel, idField: keyof ICreateOpportunityFromProjectModel): void {
		const newModel: ICreateOpportunityFromProjectModel = setEntity(model, entity, field, idField);
		updateModel(newModel);
	}

	function updateModel(newModel: ICreateOpportunityFromProjectModel): void {
		setModel(newModel);
		setDataChanged(true);
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function save(): void {
		const errorMessages: string[] = [];
		if (!model.projectId) {
			errorMessages.push(t("fill_in_required_field", { field: t("opportunity").toLowerCase() }));
		}
		if (!model.responsibleUserId) {
			errorMessages.push(t("fill_in_required_field", { field: t("responsibleUser").toLowerCase() }));
		}
		if (!model.typeId) {
			errorMessages.push(t("fill_in_required_field", { field: t("serviceType").toLowerCase() }));
		}
		if (model.forecastCost === 0) {
			errorMessages.push(t("fill_in_required_field", { field: t("forecastCost").toLowerCase() }));
		}
		if (!model.partnerId) {
			errorMessages.push(t("fill_in_required_field", { field: t("partner").toLowerCase() }));
		}
		if (!errorMessages || errorMessages.length <= 0) {
			apiService.callApi(opportunityDispatch, Endpoint.CreateFromProject, "POST", null, {
				...model,
				project: undefined,
				responsibleUser: undefined,
				type: undefined,
				language: undefined
			});
		} else {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		}
	}

	return (
		<>
			<Form>
				<Dialog width="50%" title={t("createOpportunityFromProject")} onClose={() => handleClose()}>
					<div className="k-form">
						<div className="k-form-field">
							<span>{t("project")} *</span>
							<SearchBox
								name="projectId"
								entities={projectState.entities}
								isLoading={projectState.areEntitiesLoading}
								entityId={model.projectId}
								entity={model.project}
								required
								textField="title"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => updateModel(setEntity(model, null, "projectId", "project"))}
								disabled={project !== undefined && project !== null}
								myRef={firstCombobox}
							/>
						</div>
						<label className="k-form-field">
							<span>{t("title")}</span>
							<Input name="title" ref={firstInput} value={model.title} onChange={onChange} />
						</label>
						<label className="k-form-field">
							<span>{t("description")}</span>
							<TextArea name="description" value={model.description} onChange={onChange} />
						</label>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("opportunityResponsible")} *</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={model.responsibleUserId}
										entity={model.responsibleUser}
										required
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => updateModel(setEntity(model, null, "responsibleUser", "responsibleUserId"))}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<ManageableField
										fieldLabel={t("partner") + " *"}
										recordId={model.partnerId}
										addScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
										editScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
										setEntity={(partner: IPartner) => setChild(partner, "partner", "partnerId")}
										readOnly={false}
									>
										<SearchBox
											name="partnerId"
											entities={partnerState.entities}
											isLoading={partnerState.areEntitiesLoading}
											entityId={model.partnerId}
											entity={model.partner}
											textField="name"
											onFilterChange={onFilterChange}
											onClose={onChange}
											onClear={() => setChild(null, "partner", "partnerId")}
											disabled={false}
											required
										/>
									</ManageableField>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={`${t("serviceType")} *`}
									recordId={model.typeId}
									addScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesAdd) }}
									editScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesUpdate) }}
									setEntity={(type: IServiceType) => setChild(type, "type", "typeId")}
									readOnly={false}
								>
									<SearchBox
										name="typeId"
										entities={serviceTypeState.entities}
										isLoading={serviceTypeState.areEntitiesLoading}
										entityId={model.typeId}
										entity={model.type}
										required
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "type", "typeId")}
										disabled={false}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("forecastCost")} *</span>
									<NumericTextBox name="forecastCost" value={model.forecastCost} format="n2" min={0} onChange={onChange} required disabled={false} />
								</label>
							</div>
						</div>
					</div>
					<DialogActionsBar>
						<StandardButton onClick={() => handleClose()} type="button">
							{t("cancel")}
						</StandardButton>
						<SubmitButton primary onClick={() => save()}>
							{t("create")}
						</SubmitButton>
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
};

export default CreateFromProjectDialog;
