import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { derender, hideLoader, render, showLoader } from "@selas/state-management";
import { customCell, dateCell, enumMultiSelectFilterCell, GridPanel, IGridPanelRef, IRoutedTabProps, notify, translatedCell, YesNoSwitch } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { TFunction } from "i18next";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import style from "../../../assets/masterDetail.module.scss";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialProjectState } from "../../../state";
import { projectReducer } from "../../../state/reducers";
import { Color, Permission, ProjectStatus } from "../../../utils/enums";
import { isGridCellProps } from "../../../utils/props";
import { IProject, IUser } from "../../../utils/types/models";
import ProjectEditor from "../../editors/project";
import { colorCell, colorCellClassName } from "../../global/colorCell";
import Services from "./services";
import DateRangeFilterCell from "../../global/dateRangeFilterCell/dateRangeFilterCell";

export function getProjectStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case ProjectStatus.New:
			return Color.Blue;
		case ProjectStatus.InProgress:
			return Color.Orange;
		case ProjectStatus.Finished:
			return Color.Green;
	}
}

export function editProject(record: IProject, currentUser: IUser, reduxDispatch: Dispatch, t: TFunction, ref?: React.MutableRefObject<IGridPanelRef>): void {
	if (hasPermission(Permission.ProjectsUpdate) && (record.responsibleUserId === currentUser.id || hasPermission(Permission.ProjectsAll))) {
		const projectEditorKey: string = newKey("ProjectEditor");
		reduxDispatch(
			render(projectEditorKey, ProjectEditor, {
				recordId: record.id,
				actionButtonClicked: (close: boolean) => {
					reduxDispatch(derender(projectEditorKey));
					if (!close) {
						editProject(record, currentUser, reduxDispatch, t, ref);
					}
					if (ref && ref.current) {
						ref.current.refresh();
					}
				}
			})
		);
	} else {
		reduxDispatch(notify(t("success"), t("createProjectSucces"), "success"));
	}
}

const MasterServices: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const [showOnlyToBeInvoiced, setShowOnlyToBeInvoiced] = useState(true);
	const [showLBC1, setShowLBC1] = useState(false);
	const [showLBC2, setShowLBC2] = useState(false);
	const [showLBCPay, setShowLBCPay] = useState(false);
	const reduxDispatch: Dispatch = useDispatch();
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const [projectState] = useReducer(projectReducer, initialProjectState);
	const [projectId, setProjectId] = useState(0);

	useEffect(() => {
		if (projectState.filename) {
			fileDownload(projectState.downloadFile, projectState.filename);
		}
	}, [projectState.downloadFile, projectState.filename]);

	useEffect(() => {
		if (projectState.isDownloading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [projectState.isDownloading, reduxDispatch]);

	useEffect(() => {
		ref.current.refresh();
	}, [showOnlyToBeInvoiced, showLBC1, showLBC2, showLBCPay]);

	const refreshGrid = useCallback(() => {
		ref.current.refresh();
	}, []);

	const extraToolbarItems: React.ReactElement[] = [];

	if (hasPermission(Permission.ProjectsAll)) {
		extraToolbarItems.push(
			<span key="showAllLabel" style={{ marginRight: "10px" }}>
				{t("showAllProjects")}
			</span>,
			<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
		);
	}

	extraToolbarItems.push(
		<span key="showOnlyToBeInvoiced" style={{ marginLeft: "30px", marginRight: "10px" }}>
			{t("showOnlyToBeInvoiced")}
		</span>,
		<YesNoSwitch key="showOnlyToBeInvoicedSwitch" defaultChecked onChange={(event: SwitchChangeEvent) => setShowOnlyToBeInvoiced(event.value)} />
	);

	extraToolbarItems.push(
		<span key="showLBC1" style={{ marginLeft: "30px", marginRight: "10px" }}>
			{t("showLBC1")}
		</span>,
		<YesNoSwitch key="showLBC1switch" onChange={(event: SwitchChangeEvent) => setShowLBC1(event.value)} />
	);
	extraToolbarItems.push(
		<span key="showLBC2" style={{ marginLeft: "10px", marginRight: "10px" }}>
			{t("showLBC2")}
		</span>,
		<YesNoSwitch key="showLBC2switch" onChange={(event: SwitchChangeEvent) => setShowLBC2(event.value)} />
	);
	extraToolbarItems.push(
		<span key="showLBCPay" style={{ marginLeft: "10px", marginRight: "10px" }}>
			{t("showLBCPay")}
		</span>,
		<YesNoSwitch key="showLBCPayswitch" onChange={(event: SwitchChangeEvent) => setShowLBCPay(event.value)} />
	);

	return (
		<div className="d-flex flex-column" style={{ overflowX: "auto" }}>
			<div className={style.header + " d-flex"}>
				{t("invoicing")}
				<div className="flex-grow-1" />
			</div>
			<GridPanel
				listEndpoint={showAll ? Endpoint.ProjectsList : Endpoint.MyProjectsList}
				listUrlArguments={{ showOnlyToBeInvoiced, showLBC1, showLBC2, showLBCPay }}
				className={style.masterGrid}
				endpoint={Endpoint.Projects}
				editScreen={{ screen: ProjectEditor, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
				delete={{ isAllowed: hasPermission(Permission.ProjectsDelete) }}
				extraToolbarButtons={extraToolbarItems}
				ref={ref}
				filter={{
					logic: "and",
					filters: [
						{
							field: "status",
							operator: "in",
							value: [ProjectStatus.New, ProjectStatus.InProgress]
						}
					]
				}}
				onSelectionChange={(entity: IProject) => {
					if (entity) {
						setProjectId(entity.id);
					}
				}}
				sort={[{ field: "title", dir: "desc" }]}
			>
				<GridColumn field="company.name" title={t("company")} />
				<GridColumn field="title" title={t("title")} />
				<GridColumn field="createdDate" title={t("createdDate")} cell={customCell(dateCell("yyyy-MM-dd"))} filterCell={DateRangeFilterCell} />
				<GridColumn field="totalAmount" title={t("totalAmount")} filter="numeric" />
				<GridColumn field="individualHours" title={t("individualHours")} filter="numeric" />
				<GridColumn field="collectiveHours" title={t("collectiveHours")} filter="numeric" />
				<GridColumn field="responsibleUser.fullName" title={t("projectResponsible")} />
				<GridColumn
					field="status"
					title={t("projectStatus")}
					cell={customCell(colorCell(true, translatedCell(), getProjectStatusColor), colorCellClassName)}
					filterCell={enumMultiSelectFilterCell(ProjectStatus)}
				/>
				<GridColumn field="company.vatNumber" title={t("vatNumber")} />
			</GridPanel>
			<div className={style.detailGrid + " "}>
				<div>
					<Services projectId={projectId} reactKey="services" label={t("invoicing")} url="/services" projectServicesUpdatedOrDeleted={refreshGrid} />
				</div>
			</div>
		</div>
	);
};

export default MasterServices;
