import React, { useEffect, useReducer, useRef } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState, hideLoader, showLoader } from "@selas/state-management";
import { checkBoxCell, customCell, GridPanel, IGridPanelRef, IRoutedTabProps } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { catalogueServiceReducer } from "../../../state/reducers";
import { Permission } from "../../../utils/enums";
import { ICatalogueService } from "../../../utils/types/models";
import CatalogueServiceEditor from "../../editors/catalogue/catalogueService";

const CatalogueServices: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const [catalogueServiceState, catalogueServiceDispatch] = useReducer(catalogueServiceReducer, getInitialState<ICatalogueService>());
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (catalogueServiceState.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [catalogueServiceState.isUpdating, reduxDispatch]);

	useEffect(() => {
		if (!catalogueServiceState.isUpdating && catalogueServiceState.updatedEntity) {
			ref.current.refresh();
		}
	}, [catalogueServiceState.isUpdating, catalogueServiceState.updatedEntity, reduxDispatch]);

	function moveUpPriority(record: ICatalogueService): void {
		callApiPriority(true, record);
	}

	function moveDownPriority(record: ICatalogueService): void {
		callApiPriority(false, record);
	}

	function callApiPriority(moveUp: boolean, record: ICatalogueService): void {
		apiService.callApi(catalogueServiceDispatch, Endpoint.CatalogueServicesUpdatePriority, "PUT", { id: record.id }, { id: record.id, newPriority: record.priority + (moveUp ? -1 : 1) });
	}

	return (
		<GridPanel
			listEndpoint={Endpoint.CatalogueServicesList}
			endpoint={Endpoint.CatalogueServices}
			addScreen={{ screen: CatalogueServiceEditor, isAllowed: hasPermission(Permission.CatalogueServicesAdd) }}
			editScreen={{ screen: CatalogueServiceEditor, isAllowed: hasPermission(Permission.CatalogueServicesUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.CatalogueServicesDelete) }}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
			sort={[{ field: "priority", dir: "asc" }]}
			extraCommands={[
				{
					iconClass: "las la-angle-up",
					tooltip: t("priorityUp"),
					recordAction: moveUpPriority,
					showCommand: (dataItem: ICatalogueService) => hasPermission(Permission.CatalogueServicesUpdate) && dataItem.priority > 1
				},
				{
					iconClass: "las la-angle-down",
					tooltip: t("priorityDown"),
					recordAction: moveDownPriority,
					showCommand: (dataItem: ICatalogueService) => hasPermission(Permission.CatalogueServicesUpdate) && dataItem.priority < ref.current.totalCount
				}
			]}
			ref={ref}
		>
			<GridColumn field="code" title={t("serviceCode")} />
			<GridColumn field="invoicingGroup.name" title={t("invoicingGroup")} />
			<GridColumn field="type.name" title={t("serviceType")} />
			<GridColumn field="shortDescription" title={t("shortDescription")} />
			<GridColumn field="isProjectLevelService" title={t("isProjectLevelService")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
			<GridColumn field="priority" title={t("priority")} filter="numeric" />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
		</GridPanel>
	);
};

export default CatalogueServices;
