import ITranslation from "./translation";
const translationsNL: ITranslation = {
	absenceReason: "Afwezigheidsreden",
	absenceReasons: "Afwezigheidsredenen",
	absent: "Afwezig",
	accepted: "Geaccepteerd",
	account: "Boekhoudkundige rekening",
	actions: "Acties",
	active: "Actief",
	activeProvinces: "Actieve provincies",
	actualStart: "Werkelijke start",
	actualStop: "Werkelijke stop",
	add: "Toevoegen",
	add_at_least_one: "Gelieve minstens 1 {{entity}} toe te voegen",
	addIntake: "Intake toevoegen",
	Aftercare: "Nazorg",
	ageClass: "Leeftijdsklasse",
	allParticipants: "Alle deelnemers",
	ambitions: "Ambities",
	amount: "Aantal",
	and: "en",
	annaFromTravvant: "Anna van Travvant",
	anomaly: "Anomalie",
	Approved: "Goedgekeurd",
	ask_close: "Er zijn niet opgeslagen wijzigingen. Bent u zeker dat u dit venster wilt sluiten?",
	ask_save: "Er zijn nog steeds niet opgeslagen wijzigingen. Wilt u deze opslaan?",
	assignee: "Verantwoordelijke",
	attachment: "Bijlage",
	attachments: "Bijlagen",
	attachmentsTooLarge: "De geselecteerde bijlagen zijn samen groter dan 10MB. Gelieve minder te selecteren.",
	attachTo: "Bijlage voor",
	attendance: "Aanwezigheid",
	backOfficeTasks: "Taken",
	bcc: "Bcc",
	begin: "Begin",
	birthdayCard: "Verjaardagskaart",
	body: "Body",
	bodyDe: "Body DE",
	bodyEn: "Body EN",
	bodyFr: "Body FR",
	bodyNl: "Body NL",
	building: "Gebouw",
	calendar: {
		today: "Vandaag"
	},
	cancel: "Annuleer",
	cancelInvitation: "Uitnodiging annuleren",
	Cancelled: "Geannuleerd",
	catalogue: "Catalogus",
	catalogueService: "Catalogusdienst",
	catering: "Catering",
	cateringPrice: "Catering prijs",
	cc: "Cc",
	chanceOfSucces: "Slaagkans",
	changeStatusError: "Mislukt, probeer opnieuw aub",
	changeStatusSuccess: "Status veranderd naar te factureren",
	changeStatusToInvoice: "Verander status naar te factureren",
	checkList: "Checklist",
	chooseFilter: "Kies filter",
	close: "Sluiten",
	Closed: "Afgesloten",
	coach: "Coach",
	coaches: "Coaches",
	coachSatisfactionQuestion: "Hoe tevreden ben je met de manier waarop je coach de outplacementsessie heeft begeleid",
	code: "Code",
	collectiveHours: "Collectieve uren",
	commissionPercentage: "Comissiepercentage",
	communicationLanguage: "Communicatie taal",
	companies: "Bedrijven",
	company: "Bedrijf",
	companyContact: "Bedrijfscontact",
	companyGroup: "Bedrijfsgroep",
	companyGroups: "Bedrijfsgroepen",
	companyHeat: "Bedrijfsheat",
	Completed: "Afgewerkt",
	confirm_cancel_invitation: "Bent u zeker dat u deze uitnodiging wil annuleren?",
	confirm_content: "Bent u zeker dat u dit record wil {{action}}?",
	confirm_currentuser_activechange: "Je deactiveert je eigen account. Je zal niet meer kunnen inloggen als je opslaat. Wilt je doorgaan?",
	confirm_currentuser_rolechange: "Je wijzigt de rollen van je eigen account. Mogelijks kan je dit niet meer zelf ongedaan maken. Wilt je doorgaan?",
	confirm_file_remove: "Bent u zeker dat u het bestand wilt verwijderen?",
	confirm_message_all_filled_in: "Bent u zeker dat u alles correct hebt ingevuld?",
	confirm_overwrite_field: "Bent u zeker dat u het veld '{{field}}' wil overschrijven?",
	confirm_overwrite_session_template: "Wil u graag de gegevens van de sessie overschrijven met die van de template?",
	confirm_reset: "Bent u zeker dat u de instellingen wil terugzetten?",
	confirm_role_update_current_user: "Je verandert de permissies van een rol die aan jouw account hangt. Mogelijks kan je dit niet meer zelf ongedaan maken. Wilt je doorgaan?",
	confirm_title: "Bevesting {{action}}",
	confirmLeave: "De data is niet opgeslagen, ben je zeker dat je dit scherm wil verlaten?",
	contact: "Contact",
	contacts: "Contacten",
	contactType: "Contacttype",
	contactTypes: "Contacttypes",
	content: "Inhoud",
	contractSent: "Contract verstuurd",
	contractSentToGovernment: "Contract doorgestuurd naar overheid",
	contractSignedReceived: "Contract ondertekend ontvangen",
	contractType: "Contracttype",
	contractTypes: "Contracttypes",
	copy: "Kopieer",
	copyProject: "Kopieer project",
	copyProjectFromProject: "Kopieer project",
	copyServices: "Kopieer diensten",
	cost: "Bedrag €",
	country: "Land",
	create: "Maak",
	createdDate: "Aangemaakt op",
	createDocument: "Maak document",
	createdOn: "Aangemaakt op",
	createFromContact: "Maak van contact",
	createNew: "Dit <1/> is niet gevonden.<3/>Wilt u een nieuw aanmaken?",
	createOpportunityFromProject: "Maak opportuniteit van project",
	createProject: "Maak project",
	createProjectFromOpportunity: "Maak project van opportuniteit",
	createProjectSucces: "Project succesvol aangemaakt",
	creator: "Aangemaakt door",
	customer: "Klant",
	customerPONumber: "Klant PO nummer",
	customerShow: "Toon klant",
	customRecipient: "Aangepaste ontvanger",
	Daily: "Dagelijks",
	date: "Datum",
	dateinput: {
		decrement: "Verlaag waarde",
		increment: "Verhoog waarde"
	},
	dateOfBirth: "Geboortedatum",
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	datetimepicker: {
		cancel: "Anuleer",
		date: "Datum",
		set: "Kies",
		time: "Tijd",
		toggleDateTimeSelector: "Open/sluit"
	},
	day: "dag",
	Deelnemer: "Deelnemer",
	degree: "Diploma",
	degrees: "Diploma's",
	delete: "Verwijder",
	description: "Beschrijving",
	details: "Details",
	digitalInteractionSatisfactionQuestion: "Wat vond je van de interactie in de digitale outplacementbegeleiding",
	displayedUsers: "Filter weergegeven gebruikers",
	distributeParticipants: "Verdeel deelnemers",
	document: "Document",
	documentName: "Document naam",
	documentNameDe: "Document naam DE",
	documentNameEn: "Document naam EN",
	documentNameFr: "Document naam FR",
	documentNameNl: "Document naam NL",
	documentRequestedSuccessfully: "Document(en) aanvraag geslaagd",
	documentTemplate: "Document template",
	documentTemplates: "Document templates",
	DoNotInvoice: "Niet factureren",
	downloadParticipantsTemplate: "Download deelnemers template",
	downloadProjectParticipants: "Download project deelnemers",
	dueDate: "Opleveringsdatum",
	Dutch: "Nederlands",
	edit: "Wijzigen",
	editRelatedEntity: "Gerelateerde entiteit wijzigen",
	education: "Opleiding",
	email: "Email",
	emailRequestedSuccessfully: "Email(s) aanvraag geslaagd",
	emails: "Emails",
	emailTemplate: "Emailtemplate",
	emailTemplates: "Emailtemplates",
	employmentConditions: "Voorkeur arbeidsvoorwaarden",
	employmentUnitEnrollDate: "Inschrijvingsdatum",
	employmentUnitLeaveDate: "Uitschrijvingsdatum",
	en: "Engels",
	end: "Einde",
	English: "Engels",
	environmentConditions: "Voorwaarden omgeving",
	error: "Error",
	errorLog: "Errorlog",
	errorLogId: "Errorlog ID",
	errors: {
		error_argumentnull: "Er was een leeg {{argument}} op de server",
		error_connection: "Er kan geen verbinding met een externe service gemaakt worden.",
		error_constraintfailure: "Een database validatie regel is gefaald.",
		error_dbconcurrency: "Dit record is in tussentijd gewijzigd of verwijderd door iemand anders. Gelieve te refreshen.",
		error_deleteFKConstraintStillUsed: "Je probeert een entiteit te verwijderen die nog gebruikt wordt in andere entiteiten.",
		error_duplicate: "Een record met {{uniqueKey}} bestaat al",
		error_entityused: "Deze entiteit wordt nog steeds gebruikt binnen de applicatie. Als je deze niet wil zien in de schermen, gelieve deze dan te deactiveren.",
		error_general: "Er is een error op de server gebeurd.",
		error_idfilledin: "De ID kan niet ingevuld zijn als je een entiteit toevoegd",
		error_idnotfilledin: "De ID moet meegegeven worden als je probeert te deleten",
		error_nofile: "Geen file om te uploaden",
		error_notAllServicesAreInvoiced: "Gelieve eerst alle diensten te factureren alvorens u het project probeert af te sluiten",
		error_routeiddoesnotmatch: "De ID in de url-route komt niet overeen met de ID in the entiteit",
		error_server_validation: "Servar validatie error.",
		error_sqlconnection: "Er kan geen verbinding met de database gemaakt worden.",
		error_sqlgeneral: "Er is een database error gebeurd.",
		error_validation: "Er is een validatie error.",
		error_vatnumber: "Er gebeurde een error voor het opgeven BTW nummer",
		forbidden: "Je hebt geen rechten op dit deel van de server",
		not_authenticated: "Je bent niet ingelogd op de server",
		not_found: "De server heeft geen resultaat gevonden",
		server_error: "De server heeft een '{{statuscode}}' antwoord terug gestuurd."
	},
	example: "Voorbeeld",
	excelExport: "Exporteer geselecteerde velden naar excel",
	excelImport: "Importeer excel",
	excelImportRequest: "Aanvraag voor de factuurdata aanpassing was succesvol",
	Executive: "Kader",
	expertise: "Expertise",
	expertises: "Expertises",
	extra: "Extra",
	facilities: "Faciliteiten",
	facility: "Faciliteit",
	Female: "V",
	file: "Bestand",
	fill_in_correct_date: "Gelieve een datum in te vullen na 1900 in het veld '{{field}}'",
	fill_in_correct_format: "Gelieve een correcte waarde in het veld '{{field}}' in te vullen. Bvb: '{{example}}'.",
	fill_in_correct_planned_hours: "Gelieve een correct datum/uur in te vullen zodat '{{begin}}' vóór '{{end}}' komt.",
	fill_in_required_field: "Gelieve het verplicht veld '{{field}}' in te vullen.",
	Finished: "Afgewerkt",
	fireOrQuitDate: "Ontslagdatum",
	firstName: "Voornaam",
	firstSessionDate: "Eerste sessie",
	followUpTaskStatus: "Taakstatus",
	for: "Voor",
	Forecast: "Forecast",
	forecastCost: "Prognose bedrag €",
	fr: "Frans",
	French: "Frans",
	from: "Verzonden door",
	fullName: "Volledige naam",
	functionConditions: "Voorwaarden functie-inhoud",
	functionPreviousEmployment: "Functie bij vorige werkgever",
	gender: "Geslacht",
	general: "Algemeen",
	German: "Duits",
	governmentService: "Overheidsdienst",
	governmentServices: "Overheidsdiensten",
	grid: {
		filterAfterOperator: "Is na",
		filterAfterOrEqualOperator: "Is na of gelijk aan",
		filterAndLogic: "En",
		filterBeforeOperator: "Is voor",
		filterBeforeOrEqualOperator: "Is voor of gelijk aan",
		filterBooleanAll: "(Alles)",
		filterBooleanIsNull: "Onbepaald",
		filterClearButton: "Maak leeg",
		filterContainsOperator: "Bevat",
		filterEndsWithOperator: "Eindigt op",
		filterEqOperator: "Is gelijk aan",
		filterGteOperator: "Is groter dan of gelijk aan",
		filterGtOperator: "Is groter dat",
		filterIsEmptyOperator: "Is leeg",
		filterIsFalse: "Nee",
		filterIsNotEmptyOperator: "Is niet leeg",
		filterIsNotNullOperator: "Is niet null",
		filterIsNullOperator: "Is null",
		filterIsTrue: "Ja",
		filterLteOperator: "Is kleiner dan of gelijk aan",
		filterLtOperator: "Is kleiner dan",
		filterNotContainsOperator: "Bevat niet",
		filterNotEqOperator: "Is niet gelijk aan",
		filterOrLogic: "Of",
		filterStartsWithOperator: "Begint met",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		groupPanelEmpty: "Sleep hier een kolom naar toe om erop te groeperen",
		noRecords: "Geen data beschikbaar",
		pagerFirstPage: "Ga naar de eerste pagina",
		pagerInfo: "{0} - {1} van {2} items",
		pagerItemsPerPage: "items per pagina",
		pagerLastPage: "Ga naar de laatste pagina",
		pagerNextPage: "Ga naar de volgende pagina",
		pagerOf: "van",
		pagerPage: "Pagina",
		pagerPreviousPage: "Ga naar de vorige pagina",
		sortAscending: "Sorteer Oplopend",
		sortDescending: "Sorteer Aflopend"
	},
	group: "Groep",
	groupCode: "Groepscode",
	groupFollowUpTaskStatus: "Groepstaak status",
	groupInfo: "Groepsinformatie",
	groupOccupationOrange: "Groep bezetting % - Oranje",
	groupOccupationRed: "Groep bezetting % - Rood",
	groupResponsible: "Groepsverantwoordelijke",
	groups: "Groepen",
	heat: "Heat",
	help: "Help",
	hideColumn: "Verberg kolom",
	High: "Hoog",
	highestDegree: "Hoogste diploma",
	history: "Historiek",
	home: "Startpagina",
	import: "Importeer",
	importance: "Urgentie",
	importFromGroup: "Importeer vanuit groep",
	importFromInvoicingGroup: "Importeer vanuit facturatiegroep",
	importZoomLogs: "Importeer Zoom logging",
	inapplicable: "Niet van toepassing",
	incommingJointCommittee: "(IN) Paritair comité",
	incommingNacebelCode: "(IN) Nacebelcode",
	Independent: "Zelfstandige",
	individualHours: "Individuele uren",
	individualSession: "Individuele sessie",
	information: "Informatie",
	InProgress: "In behandeling",
	InProgressGreen: "In behandeling",
	InProgressOrange: "In behandeling",
	InProgressRed: "In behandeling",
	InSession: "In sessie",
	intake: "Intake",
	internal: "Intern",
	interval: "Interval",
	invited: "Uitgenodigd",
	inviteReminderMessageGroup: "U hebt nieuwe deelnemers toegevoegd aan de groep. Vergeet niet om ze aan de gewenste sessie toe te voegen en de invites opnieuw te versturen!",
	inviteReminderMessageSession: "U hebt nieuwe deelnemers toegevoegd aan de sessie. Vergeet niet om de invites opnieuw te versturen!",
	Invoiced: "Gefactureerd",
	invoicing: "Facturatie",
	invoicingDate: "Factuurdatum",
	invoicingDateExcel: "Factuurdatum vb: 22/03/2021",
	invoicingDetails: "Vul factuurnummer en datum in",
	invoicingGroup: "Facturatiegroep",
	invoicingGroups: "Facturatiegroepen",
	invoicingMoment: "Facturatiemoment",
	invoicingMoments: "Facturatiemomenten",
	invoicingNumber: "Factuurnummer",
	isCollective: "Collectief",
	isInfoAndRefferalEmailAllowed: "Informatie -en doorverwijsmails toegestaan",
	isMarketingContactAllowed: "Marketing mails toegestaan",
	isOnSite: "On site",
	isProjectLevelService: "Project niveau",
	itemsSelected: "items",
	jobFunction: "Jobfunctie",
	jobFunctions: "Jobfuncties",
	jointCommittee: "Paritair Comité",
	jointCommittees: "Paritaire Comités",
	knownLanguages: "Talen machtig",
	language: "Taal",
	languages: "Talen",
	lastFunction: "Laatst uitgevoerde functie",
	lastName: "Achternaam",
	lbc: "LBC",
	LessSatisfied: "Minder tevreden",
	letterOfIntent: "Intentie aankondiging",
	linkedIn: "LinkedIn",
	location: "Locatie",
	locations: "Locaties",
	locationSatisfactionQuestion: "Wat vond je van de locatie waar de outplacementsessie plaatsvond",
	logout: "Uitloggen",
	longDescription: "Lange beschrijving",
	Low: "Laag",
	Male: "M",
	Management: "Directie",
	marketingScore: "Marketing score",
	marketingScoreComment: "Marketing score - opmerkingen",
	masterdata: "Masterdata",
	maximumParticipantCount: "Maximum aantal deelnemers",
	Minus45: "45-",
	miscellaneous: "Varia",
	missingLanguage: "Minstens 1 ontvanger heeft als communicatie taal {{language}}, maar hiervoor is het veld '{{field}}' niet ingevuld.",
	missingLanguageSingle: "U hebt de taal {{language}} aangeduid, maar hiervoor is het veld '{{field}}' niet ingevuld.",
	mobile: "Mobile",
	mobilePhoneNumber: "Gsm nummer",
	month: "maand",
	Monthly: "Maandelijks",
	multiviewcalendar: {
		nextView: "Navigeer naar next view",
		prevView: "Navigeer naar vorige view"
	},
	municipality: "Gemeente",
	myProfile: "Mijn profiel",
	nacebelCode: "NACEBEL-code",
	nacebelCodes: "NACEBEL-codes",
	name: "Naam",
	nationalities: "Nationaliteiten",
	nationality: "Nationaliteit",
	nationalRegistryNumber: "Rijksregisternummer",
	Neutral: "Neutraal",
	New: "Nieuw",
	newCompany: "Nieuw bedrijf",
	newEmploymentDate: "Nieuwe startdatum",
	newJobFunction: "Nieuwe jobfunctie",
	newYearCard: "Nieuwjaarskaart",
	nl: "Nederlands",
	no: "Nee",
	no_email_available: "Email niet beschikbaar",
	noGroupForSession: "Er is geen groep gevonden om een sessie aan te maken. Selecteer of creëer eerst een groep.",
	noProjectParticipantsFound: "Geen projectsdeelnemers gevonden.",
	noRecipients: "Met deze selectie zijn er geen ontvangers",
	Normal: "Normaal",
	NotApplicableWasDigitalSession: "Niet van toepassing (de sessie ging digitaal door)",
	NotApplicableWasPhysicalSession: "Niet van toepassing (de sessie ging fysiek door)",
	note: "Notitie",
	notes: "Notities",
	notFound: "{{entity}} niet gevonden",
	noticePeriodEndDate: "Opzeg te presteren tot",
	NotStarted: "Niet opgestart",
	notStartedDate: "Niet opgestart datum",
	notStartedReason: "Niet opgestart reden",
	noZoomSessionId: "Gelieve eerst een Zoom ID in te vullen aub.",
	number: "Nummer",
	numberOfMonths: "Aantal maanden",
	occupation: "Bezetting %",
	of: "van",
	off: "Uit",
	OfferSent: "Offerte verzonden",
	ok: "Ok",
	on: "Aan",
	OnHold: "On hold",
	onHoldDate: "Datum on hold",
	onHoldReason: "Reden on hold",
	opportunities: "Opportuniteiten",
	opportunity: "Opportuniteit",
	opportunityAttachments: "Opportuniteitsbijlages",
	opportunityFollowUpTaskStatus: "Opportuniteitstaak status",
	opportunityOrigins: "Opportuniteitsherkomst",
	opportunityParticipant: "Opportuniteitsdeelnemer",
	opportunityParticipantStatus: "Opportuniteitsdeelnemerstatus",
	opportunityResponsible: "Opportuniteitsverantwoordelijke",
	opportunityService: "Opportuniteitsdienst",
	opportunityStatus: "Opportuniteitstatus",
	or: "of",
	origin: "Herkomst",
	otherParticipants: "Andere deelnemers",
	outgoingJointCommittee: "(OUT) Paritair comité",
	outgoingNacebelCode: "(OUT) Nacebelcode",
	outplacementCertificate: "Outplacement attest",
	participant: "Deelnemer",
	participantAttachments: "Deelnemersbijlagen",
	participantDelete: "Deelnemer opties",
	participantDeleteInGroups: "Deelnemer verwijderen in alle groepen van dit project?",
	participantDeleteInOtherProjects: "Deelnemer verwijderen in groepen & sessies die nog niet gestart zijn van andere projecten?",
	participantDeleteInSessions: "Deelnemer verwijderen in alle sessies van dit project die nog niet zijn gestart?",
	participants: "Deelnemers",
	participantStatus: "Deelnemersstatus",
	partner: "Partner",
	partnerContact: "Partnercontact",
	partnerHeat: "Partnerheat",
	partners: "Partners",
	Pending: "In afwachting",
	pending_changes: "Wijzigingen",
	permission: "Permissie",
	permissions: {
		AbsenceReasonsAdd: "Toevoegen",
		AbsenceReasonsDelete: "Verwijderen",
		AbsenceReasonsRead: "Lezen",
		AbsenceReasonsUpdate: "Wijzigen",
		BackOfficeTasksRead: "Lezen",
		CatalogueServicesAdd: "Toevoegen",
		CatalogueServicesDelete: "Verwijderen",
		CatalogueServicesRead: "Lezen",
		CatalogueServicesUpdate: "Wijzigen",
		CoachesAdd: "Toevoegen",
		CoachesDelete: "Verwijderen",
		CoachesRead: "Lezen",
		CoachesUpdate: "Wijzigen",
		CompaniesAdd: "Toevoegen",
		CompaniesDelete: "Verwijderen",
		CompaniesRead: "Lezen",
		CompaniesUpdate: "Wijzigen",
		CompanyGroupsAdd: "Toevoegen",
		CompanyGroupsDelete: "Verwijderen",
		CompanyGroupsRead: "Lezen",
		CompanyGroupsUpdate: "Wijzigen",
		ContactsAdd: "Toevoegen",
		ContactsDelete: "Verwijderen",
		ContactsRead: "Lezen",
		ContactsUpdate: "Wijzigen",
		ContactTypesAdd: "Toevoegen",
		ContactTypesDelete: "Verwijderen",
		ContactTypesRead: "Lezen",
		ContactTypesUpdate: "Wijzigen",
		ContractTypesAdd: "Toevoegen",
		ContractTypesDelete: "Verwijderen",
		ContractTypesRead: "Lezen",
		ContractTypesUpdate: "Wijzigen",
		DegreesAdd: "Toevoegen",
		DegreesDelete: "Verwijderen",
		DegreesRead: "Lezen",
		DegreesUpdate: "Wijzigen",
		DocumentTemplatesAdd: "Toevoegen",
		DocumentTemplatesDelete: "Verwijderen",
		DocumentTemplatesRead: "Lezen",
		DocumentTemplatesUpdate: "Wijzigen",
		EmailTemplatesAdd: "Toevoegen",
		EmailTemplatesDelete: "Verwijderen",
		EmailTemplatesRead: "Lezen",
		EmailTemplatesUpdate: "Wijzigen",
		ErrorLogRead: "Lezen",
		ExpertisesAdd: "Toevoegen",
		ExpertisesDelete: "Verwijderen",
		ExpertisesRead: "Lezen",
		ExpertisesUpdate: "Wijzigen",
		FacilitiesAdd: "Toevoegen",
		FacilitiesDelete: "Verwijderen",
		FacilitiesRead: "Lezen",
		FacilitiesUpdate: "Wijzigen",
		GovernmentServicesAdd: "Toevoegen",
		GovernmentServicesDelete: "Verwijderen",
		GovernmentServicesRead: "Lezen",
		GovernmentServicesUpdate: "Wijzigen",
		GroupsAdministrator: "Groepadministrator",
		GroupsAll: "Alle groepen",
		GroupsRead: "Lezen",
		GroupsUpdate: "Wijzigen",
		invoiceServicesAll: "Alle services",
		invoiceServicesDelete: "Verwijderen",
		invoiceServicesRead: "Lezen",
		invoiceServicesUpdate: "Wijzigen",
		InvoicingAccess: "Toegang",
		InvoicingGroupsAdd: "Toevoegen",
		InvoicingGroupsDelete: "Verwijderen",
		InvoicingGroupsRead: "Lezen",
		InvoicingGroupsUpdate: "Wijzigen",
		InvoicingMomentsAdd: "Toevoegen",
		InvoicingMomentsDelete: "Verwijderen",
		InvoicingMomentsRead: "Lezen",
		InvoicingMomentsUpdate: "Wijzigen",
		JobFunctionsAdd: "Toevoegen",
		JobFunctionsDelete: "Verwijderen",
		JobFunctionsRead: "Lezen",
		JobFunctionsUpdate: "Wijzigen",
		JointCommitteesAdd: "Toevoegen",
		JointCommitteesDelete: "Verwijderen",
		JointCommitteesRead: "Lezen",
		JointCommitteesUpdate: "Wijzigen",
		LanguagesAdd: "Toevoegen",
		LanguagesDelete: "Verwijderen",
		LanguagesRead: "Lezen",
		LanguagesUpdate: "Wijzigen",
		LocationsAdd: "Toevoegen",
		LocationsDelete: "Verwijderen",
		LocationsRead: "Lezen",
		LocationsUpdate: "Wijzigen",
		NacebelCodesAdd: "Toevoegen",
		NacebelCodesDelete: "Verwijderen",
		NacebelCodesRead: "Lezen",
		NacebelCodesUpdate: "Wijzigen",
		NationalitiesAdd: "Toevoegen",
		NationalitiesDelete: "Verwijderen",
		NationalitiesRead: "Lezen",
		NationalitiesUpdate: "Wijzigen",
		OpportunitiesAdd: "Toevoegen",
		OpportunitiesAll: "Alle opportuniteiten",
		OpportunitiesDelete: "Verwijderen",
		OpportunitiesRead: "Lezen",
		OpportunitiesUpdate: "Wijzigen",
		OpportunityOriginsAdd: "Toevoegen",
		OpportunityOriginsDelete: "Verwijderen",
		OpportunityOriginsRead: "Lezen",
		OpportunityOriginsUpdate: "Wijzigen",
		ParticipantsAdd: "Toevoegen",
		ParticipantsDelete: "Verwijderen",
		ParticipantsRead: "Lezen",
		ParticipantsUpdate: "Wijzigen",
		PartnersAdd: "Toevoegen",
		PartnersDelete: "Verwijderen",
		PartnersRead: "Lezen",
		PartnersUpdate: "Wijzigen",
		PlanningDisplay: "Tonen",
		PrivateContactInformationRead: "Lezen",
		PrivateContactInformationWrite: "Wijzigen",
		ProjectGroupsAdd: "Toevoegen",
		ProjectGroupsDelete: "Verwijderen",
		ProjectGroupsRead: "Lezen",
		ProjectGroupsUpdate: "Wijzigen",
		ProjectParticipantsAdministrator: "DeelnemerAdministrator",
		ProjectParticipantsAll: "Alle deelnemers",
		ProjectParticipantsRead: "Lezen",
		ProjectParticipantsUpdate: "Wijzigen",
		ProjectsAdd: "Toevoegen",
		ProjectsAll: "Alle projecten",
		ProjectsDelete: "Verwijderen",
		ProjectsRead: "Lezen",
		ProjectsUpdate: "Wijzigen",
		ProvincesAdd: "Toevoegen",
		ProvincesDelete: "Verwijderen",
		ProvincesRead: "Lezen",
		ProvincesUpdate: "Wijzigen",
		RolesAdd: "Toevoegen",
		RolesDelete: "Verwijderen",
		RolesRead: "Lezen",
		RolesUpdate: "Wijzigen",
		SectorsAdd: "Toevoegen",
		SectorsDelete: "Verwijderen",
		SectorsRead: "Lezen",
		SectorsUpdate: "Wijzigen",
		ServiceTypesAdd: "Toevoegen",
		ServiceTypesDelete: "Verwijderen",
		ServiceTypesRead: "Lezen",
		ServiceTypesUpdate: "Wijzigen",
		SessionRegistrationAppAccess: "Toegang",
		SessionsAdministrator: "Sessieadministrator",
		SessionsAll: "Alle sessies",
		SessionsDelete: "Verwijderen",
		SessionsRead: "Lezen",
		SessionsUpdate: "Wijzigen",
		SessionTemplatesAdd: "Toevoegen",
		SessionTemplatesDelete: "Verwijderen",
		SessionTemplatesRead: "Lezen",
		SessionTemplatesUpdate: "Wijzigen",
		SettingsRead: "Lezen",
		SettingsUpdate: "Wijzigen",
		StatutesAdd: "Toevoegen",
		StatutesDelete: "Verwijderen",
		StatutesRead: "Lezen",
		StatutesUpdate: "Wijzigen",
		TasksAll: "Alle taken",
		TaskSubjectsAdd: "Toevoegen",
		TaskSubjectsDelete: "Verwijderen",
		TaskSubjectsRead: "Lezen",
		TaskSubjectsUpdate: "Wijzigen",
		TopicsAdd: "Toevoegen",
		TopicsDelete: "Verwijderen",
		TopicsRead: "Lezen",
		TopicsUpdate: "Wijzigen",
		UsersAdd: "Toevoegen",
		UsersDelete: "Verwijderen",
		UsersRead: "Lezen",
		UsersUpdate: "Wijzigen"
	},
	personInfo: "Persoonsinfo",
	phoneNumber: "Telefoonnummer",
	physicalInteractionSatisfactionQuestion: "Wat vond je van de interactie in de fysieke outplacementbegeleiding",
	plan: "Plan",
	Planned: "Ingepland",
	PlannedGreen: "Ingepland",
	PlannedOrange: "Ingepland",
	PlannedRed: "Ingepland",
	plannedStart: "Geplande start",
	plannedStop: "Geplande stop",
	planning: {
		currentWeek: "Deze week",
		friday: "Vrijdag",
		monday: "Maandag",
		nextWeek: "Volgende week",
		no_information: "Geen informatie",
		occupation_message: "Voor {{percentage}} % bezet",
		openSession: "Open sessie",
		planning: "Planning",
		prevWeek: "Vorige week",
		saturday: "Zaterdag",
		thursday: "Donderdag",
		tuesday: "Dinsdag",
		wednesday: "Woensdag"
	},
	planSessions: "Sessies inplannen",
	Plus45: "45+",
	postalCode: "Postcode",
	preferedFutureFunction: "Geprefereerde toekomstige functie",
	present: "Aanwezig",
	price: "Prijs €",
	priority: "Prioriteit",
	priorityDown: "Verlaag prioriteit",
	priorityUp: "Verhoog prioriteit",
	privacy: "Privacy",
	privateContactInformation: "Privé contactgegevens",
	privateEmailAddress: "Privé email",
	privateMobilePhoneNumber: "Privé GSM nummer",
	processed: "Verwerkt",
	processRequestInitiated: "Aanvraag tot verwerking van de excel was successvol.",
	project: "Project",
	projectFollowUpTaskStatus: "Projecttaak status",
	projectGroup: "Projectgroep",
	projectGroups: "Projectgroepen",
	projectName: "Projectnaam",
	projectOrParticipant: "Soort dienstlijn",
	projectParticipant: "Projectdeelnemer",
	projectParticipantFollowUpTaskStatus: "Projectdeelnemerstaak status",
	projectParticipantResponsible: "Deelnemerverantwoordelijke",
	projectParticipantService: "Projectdeelnemerdienst",
	projectParticipantStatus: "Projectdeelnemerstatus",
	projectResponsible: "Projectverantwoordelijke",
	projects: "Projecten",
	projectService: "Projectdienst",
	projectServiceStatus: "Projectdienststatus",
	projectShow: "Toon project",
	projectStatus: "Projectstatus",
	promotionalGift: "Relatiegeschenk",
	province: "Provincie",
	provinces: "Provincies",
	publicTender: "Openbare aanbesteding",
	putNameOnInvoice: "Zet naam op factuur",
	ratingQuestion: "Welke score geef je aan de afgelopen outplacementsessie",
	ratingReasonQuestion: "Waarom",
	recipientEmailNotAvailable: "Email van de ontvanger is niet beschikbaar",
	reclamation: "Terugvordering",
	Rejected: "Afgekeurd",
	rejectionReason: "Reden van afkeuring",
	relatedTo: "Gerelateerd aan",
	remarks: "Opmerkingen",
	reminder: "Herinnering",
	remove: "Verwijderen",
	repetition: "Aantal herhalingen",
	replacement: "Vervanging",
	reportableGovernmentService: "Te rapporteren overheidsdienst",
	requestParticipantsInfo: "Deelnemers info aanvragen",
	requests: "Requesten",
	reset: "Terugzetten",
	resources: "Resources",
	responsible: "Verantwoordelijke",
	responsibleFirstName: "Contact - Voornaam",
	responsibleLastName: "Contact - Achternaam",
	role: "Rol",
	roles: "Rollen",
	room: "Zaal",
	salesPrice: "Verkoopsprijs",
	satisfactionScore: "Tevredenheidsscore",
	Satisfied: "Tevreden",
	save: "Opslaan",
	saveAndClose: "Opslaan en sluiten",
	scheduler: {
		allDay: "Heel de dag",
		showFullDay: "Toon volledige dag"
	},
	searchAllProjects: "Zoeken in alle projecten",
	sector: "Sector",
	sectors: "Sectoren",
	security: "Beveiliging",
	select: "Selecteer",
	select_at_least_one: "Gelieve minstens 1 {{entity}} te selecteren.",
	selectAll: "Alles Selecteren",
	selectColumns: "Selecteer de kolommen",
	selectExistingParticipantProjectChangesWillBeSaved: "Selecteer een deelnemer van de sessie. Bij het opslaan zullen alle andere wijzingen op deze sessie mee worden opgeslagen.",
	selectionOfParticipants: "Deelnemers selecteren",
	selectRecipients: "Gelieve ontvangers te selecteren",
	send: "Verzenden",
	sendEmail: "Verzend e-mail",
	sendInvitation: "Verzend uitnodiging",
	sendSurveyOnClose: "Verzend enquête bij afsluiten",
	sendTo: "Verzend aan",
	sendUpdateInvitation: "Verzend update uitnodiging",
	seniorityPreviousEmployment: "Anciënniteit bij vorige werkgever",
	Servant: "Bediende",
	service: "Dienst",
	serviceCode: "Dienstcode",
	services: "Diensten",
	serviceShow: "Toon service",
	serviceStatus: "Dienststatus",
	serviceType: "Diensttype",
	serviceTypes: "Diensttypes",
	session: "Sessie",
	Session: "Sessie",
	sessionFeedback: "Sessie feedback",
	sessionFollowUpTaskStatus: "Sessietaak status",
	sessionName: "Naam sessie",
	sessionNotInApp: "Sessie is niet aanwezig in de web applicatie",
	sessionNotInOutlook: "Sessie is niet aanwezig in outlook",
	sessionOccupationOrange: "Sessie bezetting % - Oranje",
	sessionOccupationRed: "Sessie bezetting % - Rood",
	sessionParticipant: "Sessiedeelnemer",
	sessionRegistrationApp: "Sessieregistratie app",
	sessionResponsible: "Sessieverantwoordelijke",
	sessions: "Sessies",
	sessionStatus: "Sessiestatus",
	sessionTemplate: "Sessietemplate",
	sessionTemplateFollowUpTask: "Sessie template taak",
	sessionTemplates: "Sessietemplates",
	settings: "Instellingen",
	shortDescription: "Korte beschrijving",
	show: "Toon",
	showAllGroups: "Toon alle groepen",
	showAllOpportunities: "Toon alle opportuniteiten",
	showAllParticipants: "Toon alle deelnemers",
	showAllProjects: "Toon alle projecten",
	showAllSessions: "Toon alle sessies",
	showAllTasks: "Toon alle taken",
	showColumnChooser: "Toon kolomkiezer",
	showFeedback: "Toon feedback",
	showLBC1: "Toon \"LBC 1\"",
	showLBC2: "Toon \"LBC 2\"",
	showLBCPay: "Toon \"Loopbaancheque - Betalend\"",
	signature: "Handtekening",
	spread: "Spreiding",
	start: "Start",
	startDate: "Start datum",
	status: "Status",
	statute: "Statuut",
	statutes: "Statuten",
	stopDate: "Gestopt datum",
	Stopped: "Gestopt",
	stopReason: "Gestopt reden",
	street: "Straat",
	subject: "Onderwerp",
	subjectDe: "Onderwerp DE",
	subjectEn: "Onderwerp EN",
	subjectFr: "Onderwerp FR",
	subjectNl: "Onderwerp NL",
	subtotal: "Subtotaal",
	subType: "Subtype",
	success: "Success",
	support: "Support",
	surveyLink: "Surveylink",
	task: "Taak",
	taskAssignee: "Taakverantwoordelijke",
	taskAttachments: "Taakbijlages",
	tasks: "Taken",
	taskSubject: "Taak onderwerp",
	taskSubjects: "Taak onderwerpen",
	template: "Template",
	theseAreTheServerErrorMessages: "Dit zijn de server validatie errors:",
	thresholds: "Drempels",
	timepicker: {
		cancel: "Annuleer",
		now: "NU",
		selectNow: "Selecteer Nu",
		set: "Kies",
		toggleClock: "Wissel klok",
		toggleTimeSelector: "Open/sluit"
	},
	title: "Titel",
	to: "Aan",
	toBeDetirmined: "Te bepalen",
	ToBeInvoiced: "Te factureren",
	topic: "Topic",
	topics: "Topics",
	totalAmount: "Totaalbedrag €",
	totalHours: "Totaal",
	type: "Type",
	Undefined: "X",
	uniqueKey: {
		IX_AbsenceReasons_Name: "deze naam",
		IX_CatalogueServices_Code: "deze code",
		IX_Companies_VatNumber: "dit BTW nummer",
		IX_CompanyGroups_Name: "deze naam",
		IX_Contact_Email: "dit emailadres",
		IX_ContactTypes_Name: "deze naam",
		IX_ContractTypes_Name: "deze naam",
		IX_Degrees_Name: "deze naam",
		IX_DocumentTemplates_Name: "deze naam",
		IX_EmailTemplates_Name: "deze naam",
		IX_Expertises_Name: "deze naam",
		IX_Facilities_Name: "deze naam",
		IX_InvoicingMoments_Name: "deze naam",
		IX_JobFunctions_Name: "deze naam",
		IX_JointCommittees_Name: "deze naam",
		IX_Languages_Name: "deze naam",
		IX_NacebelCodes_Name: "deze naam",
		IX_Nationalities_Name: "deze naam",
		IX_Partners_VatNumber: "dit BTW nummer",
		IX_Roles_Name: "deze naam",
		IX_Sectors_Name: "deze naam",
		IX_ServiceTypes_Name: "deze naam",
		IX_sessions: "deze participants zitten nog in deze sessions: ",
		IX_SessionTemplateFollowUpTasks_Subject_SessionTemplateId: "dit onderwerp binnen deze template",
		IX_Statutes_Name: "deze naam",
		IX_TaskSubjects_Name: "deze naam",
		IX_Topics_Name: "deze naam",
		IX_Users_Email: "dit emailadres"
	},
	Unsatisfied: "Ontevreden",
	upload: {
		cancel: "Anuleer",
		clearSelectedFiles: "Maak leeg",
		dropFilesHere: "Bestanden hier neerzetten om te uploaden",
		files: "bestanden",
		headerStatusUploaded: "Klaar",
		headerStatusUploading: "Uploaden...",
		invalidFileExtension: "Bestandstype is niet toegestaan.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "Bestand is te groot.",
		invalidMinFileSize: "Bestand is te klein.",
		remove: "Verwijder",
		retry: "Probeer opnieuw",
		select: "Selecteer bestanden...",
		total: "Totaal",
		uploadSelectedFiles: "Upload"
	},
	uploadExcel: "Upload Excel",
	uploadParticipantsList: "Upload deelnemers",
	usedHours: "Opgebruikt",
	user: "Gebruiker",
	users: "Gebruikers",
	vatNumber: "BTW nummer",
	vatNumberFormat: "Fout formaat",
	VerySatisfied: "Zeer tevreden",
	VeryUnsatisfied: "Zeer ontevreden",
	warning_empty_emails: "Let op: Niet alle deelnemers hebben een emailadres.",
	Weekly: "Wekelijks",
	welcome_message: "Welkom ",
	work: "Werk",
	Worker: "Arbeider",
	workingExperiences: "Werkervaringen",
	year: "jaar",
	yes: "Ja",
	yourprofile: "Jouw profiel",
	zoomSessionId: "Zoom sessie ID",
	errorDownloadingAttachments: "Er is een fout gebeurd bij het downloaden van de bijlagen.",
	noAttachmentsToDownload: "Er zijn geen bijlagen om te downloaden.",
	noAttachmentsSelected: "Er zijn geen bijlagen geselecteerd.",
	downloadAttachments: "Download bijlagen",
	accessToTraP: "Toegang tot TraP",
	startDateIV1: "Start datum IV1",
	startDateIV2: "Start datum IV2",
	showOnlyToBeInvoiced: "Toon enkel te factureren"
};
export default translationsNL;
