import React, { Ref, useRef } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, enumFilterCell, GridPanel, IGridPanelRef, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { Color, OpportunityParticipantStatus, Permission } from "../../../../utils/enums";
import { isGridCellProps } from "../../../../utils/props";
import { IContact } from "../../../../utils/types/models";
import { colorCell, colorCellClassName } from "../../../global/colorCell";
import CopyFromContactButton from "../../../global/copyContactButton";
import ParticipantTemplateButtons from "../../../global/participantTemplateButtons";
import OpportunityParticipantEditor from "../opportunityParticipant";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	opportunityId: number;
	contact: IContact;
}

export function getOpportunityParticipantStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? props.dataItem.status : props.dataItem.key;

	switch (status) {
		case OpportunityParticipantStatus.New:
			return Color.Blue;
	}
}

const OpportunityParticipants: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const gridRef: Ref<IGridPanelRef> = useRef();

	let extraToolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.OpportunitiesUpdate)) {
		extraToolbarButtons = [
			<ParticipantTemplateButtons key="participantButtons" opportunityId={props.opportunityId} />,
			<CopyFromContactButton key="copyFromContact" contact={props.contact} onContactCopied={() => gridRef.current.refresh()} opportunityId={props.opportunityId} />
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.OpportunityParticipantsList}
			listUrlArguments={{ opportunityId: props.opportunityId }}
			endpoint={Endpoint.OpportunityParticipants}
			addScreen={{ screen: OpportunityParticipantEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			editScreen={{ screen: OpportunityParticipantEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			delete={{ urlArguments: { opportunityId: props.opportunityId }, isAllowed: hasPermission(Permission.OpportunitiesUpdate) }}
			extraToolbarButtons={extraToolbarButtons}
			ref={gridRef}
			localLoader
		>
			<GridColumn
				field="status"
				title={t("opportunityParticipantStatus")}
				cell={customCell(colorCell(true, translatedCell(), getOpportunityParticipantStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(OpportunityParticipantStatus)}
			/>
			<GridColumn field="participant.fullName" title={t("name")} />
			<GridColumn field="fireOrQuitDate" title={t("fireOrQuitDate")} cell={customCell(dateCell("yyyy-MM-dd"))} filter="date" />
			<GridColumn field="noticePeriodEndDate" title={t("noticePeriodEndDate")} cell={customCell(dateCell("yyyy-MM-dd"))} filter="date" />
			<GridColumn field="contractType.name" title={t("contractType")} />
		</GridPanel>
	);
};

export default OpportunityParticipants;
