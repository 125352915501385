import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { GridPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";

import style from "../../../assets/editor.module.scss";
import SessionTemplateTaskEditor from "./sessionTemplateFollowUpTaskEditor";

interface IProps {
	sessionTemplateId: number;
}

const SessionTemplateFollowUpTasks: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.SessionTemplateFollowUpTasksList}
			listUrlArguments={{ sessionTemplateId: props.sessionTemplateId }}
			endpoint={Endpoint.SessionTemplateFollowUpTasks}
			addScreen={{
				screen: SessionTemplateTaskEditor,
				isAllowed: hasPermission(Permission.SessionTemplatesAdd),
				extraProps: { sessionTemplateId: props.sessionTemplateId }
			}}
			editScreen={{
				screen: SessionTemplateTaskEditor,
				isAllowed: hasPermission(Permission.SessionTemplatesUpdate),
				extraProps: { sessionTemplateId: props.sessionTemplateId }
			}}
			delete={{ urlArguments: { sessionTemplateId: props.sessionTemplateId }, isAllowed: hasPermission(Permission.SessionTemplatesDelete) }}
			localLoader
		>
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="createdBy" title={t("creator")} />
		</GridPanel>
	);
};

export default SessionTemplateFollowUpTasks;
