import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, DropDownListChangeEvent, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState } from "@selas/state-management";
import { EntityEditor, handleChange, IAddEntityScreenProps, IEditEntityScreenProps, ManageableMultiSelect, YesNoSwitch } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { facilityReducer, locationReducer } from "../../../state/reducers";
import { emailRegex } from "../../../utils";
import { Permission } from "../../../utils/enums";
import { IAddress, IFacility, ILocation, ILocationFacility } from "../../../utils/types/models";
import AddressEdit from "../../global/addressEdit";
import FacilityEditor from "../resources/facility";

const LocationEditor: React.FC<IAddEntityScreenProps<ILocation> | IEditEntityScreenProps<ILocation>> = (props: IAddEntityScreenProps<ILocation> | IEditEntityScreenProps<ILocation>) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();

	const initialLocation: ILocation = {
		id: (props as IEditEntityScreenProps<ILocation>).recordId || 0,
		name: "",
		building: "",
		room: "",
		catering: false,
		cateringPrice: 0,
		emailAddress: "",
		mobilePhoneNumber: "",
		phoneNumber: "",
		responsibleFirstName: "",
		responsibleLastName: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		price: 0,
		satisfactionScore: 0,
		facilities: [],
		active: true
	};

	const [locationState, locationDispatch] = useReducer(locationReducer, getInitialState<ILocation>());
	const [facilityState, facilityDispatch] = useReducer(facilityReducer, getInitialState<IFacility>());

	const [location, setLocation] = useState<ILocation>(initialLocation);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (locationState.entity) {
			setLocation(locationState.entity);
		}
	}, [locationState.entity]);

	useEffect(() => {
		if (hasPermission(Permission.LocationsAdd, Permission.LocationsUpdate)) {
			apiService.callApi(facilityDispatch, Endpoint.Facilities, "GET", { search: "" });
		}
	}, [apiService]);

	function onChange(event: InputChangeEvent | ComboBoxCloseEvent | SwitchChangeEvent | NumericTextBoxChangeEvent | DropDownListChangeEvent): void {
		setLocation(handleChange(location, event));
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newLocation: ILocation = cloneDeep(location);
		newLocation.address = address;
		setLocation(newLocation);
		setDataChanged(true);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const locationRecord: ILocation = cloneDeep(location);
		locationRecord.facilities = [];

		if (event.value) {
			for (const facil of event.value) {
				locationRecord.facilities.push({ locationId: locationRecord.id, facilityId: facil.id, facility: facil });
			}
			setDataChanged(true);
		}
		setLocation(locationRecord);
	}

	function addOrUpdateFacility(facility: IFacility): void {
		const locationRecord: ILocation = cloneDeep(location);
		const existingLocationFacility: ILocationFacility = find(locationRecord.facilities, { facilityId: facility.id });
		if (existingLocationFacility) {
			existingLocationFacility.facility = facility;
		} else {
			locationRecord.facilities.push({
				locationId: locationRecord.id,
				facilityId: facility.id,
				facility
			});
		}
		setLocation(locationRecord);
	}

	function onMultiSelectFilterChange(event: MultiSelectFilterChangeEvent): void {
		apiService.callApi(facilityDispatch, Endpoint.Facilities, "GET", { search: event.filter.value });
	}

	function onActionButtonClicked(close: boolean, record: ILocation): void {
		if (record && !close) {
			setLocation(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(location.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(location.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(location.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(location.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(location.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(location.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditEntityScreenProps<ILocation>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={location}
			endpoint={Endpoint.Locations}
			entityState={locationState}
			entityType={t("location")}
			dispatch={locationDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={location.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("name")}*</span>
							<Input name="name" value={location.name} ref={firstField} onChange={onChange} disabled={readonly} required />
						</label>
					</div>
					<div className="col-2">
						<label className="k-form-field">
							<span>{t("satisfactionScore")}</span>
							<NumericTextBox name="satisfactionScore" value={location.satisfactionScore} onChange={onChange} min={0} format="n2" disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("building")}</span>
							<Input name="building" value={location.building} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("room")}</span>
							<Input name="room" value={location.room} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("price")}</span>
							<NumericTextBox name="price" value={location.price} onChange={onChange} min={0} format="n2" disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("email")}</span>
							<Input name="emailAddress" value={location.emailAddress} onChange={onChange} disabled={readonly} pattern={emailRegex.source} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("mobilePhoneNumber")}</span>
							<Input name="mobilePhoneNumber" value={location.mobilePhoneNumber} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("phoneNumber")}</span>
							<Input name="phoneNumber" value={location.phoneNumber} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("responsibleFirstName")}</span>
							<Input name="responsibleFirstName" value={location.responsibleFirstName} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("responsibleLastName")}</span>
							<Input name="responsibleLastName" value={location.responsibleLastName} onChange={onChange} disabled={readonly} />
						</label>
					</div>
					<div className="col-2">
						<div className="k-form-field">
							<div>{t("catering")}</div>
							<YesNoSwitch name="catering" checked={location.catering} onChange={onChange} disabled={readonly} />
						</div>
					</div>
					<div className="col-2">
						<label className="k-form-field">
							<span>{t("cateringPrice")}</span>
							<NumericTextBox name="cateringPrice" value={location.cateringPrice} onChange={onChange} min={0} format="n2" disabled={readonly} />
						</label>
					</div>
				</div>
				<ManageableMultiSelect
					fieldLabel={t("facilities")}
					addScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesAdd) }}
					editScreen={{ screen: FacilityEditor, isAllowed: hasPermission(Permission.FacilitiesUpdate) }}
					setEntity={addOrUpdateFacility}
					name="facilities"
					data={facilityState.entities}
					textField="name"
					dataItemKey="id"
					value={map(location.facilities, "facility")}
					filterable
					loading={facilityState.areEntitiesLoading}
					onFilterChange={onMultiSelectFilterChange}
					onChange={onMultiSelectChange}
					disabled={readonly}
					readOnly={readonly}
				/>
				<AddressEdit address={location.address} addressField="address" onChange={onChange} setAddress={setAddress} required numberRequired={true} readonly={readonly} />
				{!props.hideActive && (
					<div className="k-form-field">
						<div>{t("active")}</div>
						<YesNoSwitch name="active" checked={location.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default LocationEditor;
