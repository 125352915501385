import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, enumFilterCell, GridPanel, translatedCell } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import Endpoint from "../../../../../services/api/endpoint";
import { EmailImportance, Permission } from "../../../../../utils/enums";
import EmailEditor from "../../../followup/email";

import style from "../../../../../assets/editor.module.scss";
import { hasPermission } from "../../../../../services/authentication";

interface IProps {
	projectParticipantId: number;
	projectId: number;
}

const ProjectParticipantEmails: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectParticipantEmailsList}
			listUrlArguments={{ projectParticipantId: props.projectParticipantId }}
			endpoint={Endpoint.ProjectParticipantEmails}
			addScreen={{
				screen: EmailEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectParticipantId: props.projectParticipantId }
			}}
			editScreen={{
				screen: EmailEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectParticipantId: props.projectParticipantId }
			}}
			delete={{ urlArguments: { projectParticipantId: props.projectParticipantId } }}
			localLoader
			sort={[{ field: "createdDate", dir: "desc" }]}
		>
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="to" title={t("to")} />
			<GridColumn field="cc" title={t("cc")} />
			<GridColumn field="bcc" title={t("bcc")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="importance" title={t("importance")} cell={customCell(translatedCell())} filterCell={enumFilterCell(EmailImportance)} />
			<GridColumn field="body" title={t("body")} />
		</GridPanel>
	);
};

export default ProjectParticipantEmails;
