import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { derender, render } from "@selas/state-management";
import { GridPanel } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { Permission } from "../../../../utils/enums";
import { IAttachment } from "../../../../utils/types/models";
import FileExample from "../../../global/fileExample";
import AttachmentEditor from "../../followup/attachment";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	opportunityId: number;
}

const OpportunityAttachments: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const dispatch: Dispatch = useDispatch();

	function showFileExample(record: IAttachment): void {
		const key: string = newKey("opportunityAttachment_example");
		dispatch(
			render(key, FileExample, {
				file: record.content,
				onClose: () => dispatch(derender(key))
			})
		);
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.OpportunityAttachmentsList}
			listUrlArguments={{ opportunityId: props.opportunityId }}
			endpoint={Endpoint.OpportunityAttachments}
			addScreen={{ screen: AttachmentEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			editScreen={{ screen: AttachmentEditor, isAllowed: hasPermission(Permission.OpportunitiesUpdate), extraProps: { opportunityId: props.opportunityId } }}
			delete={{ urlArguments: { opportunityId: props.opportunityId }, isAllowed: hasPermission(Permission.OpportunitiesUpdate) }}
			extraCommands={[
				{
					tooltip: t("view"),
					iconClass: "las la-search",
					recordAction: showFileExample
				}
			]}
			localLoader
		>
			<GridColumn field="name" title={t("name")} />
		</GridPanel>
	);
};

export default OpportunityAttachments;
