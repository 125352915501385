import React, { useEffect, useReducer, useRef } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useApiService } from "@selas/api-communication";
import { ApiCommunicator } from "@selas/api-communication/dist/apiService";
import { derender, getInitialState, render } from "@selas/state-management";
import { customCell, dateCell, enumFilterCell, GridPanel, IGridPanelRef, StandardButton, translatedCell } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import style from "../../../../../assets/editor.module.scss";
import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { projectParticipantServiceReducer } from "../../../../../state/reducers";
import { Permission, ProjectServiceStatus } from "../../../../../utils/enums";
import { IProjectParticipantService } from "../../../../../utils/types/models";
import { colorCell, colorCellClassName } from "../../../../global/colorCell";
import { getProjectServiceStatusColor } from "../../grids/projectServices";
import ProjectParticipantServiceEditor from "../../projectParticipantService";
import ImportFromInvoicngGroupDialogue from "../importFromInvoicingGroup";

interface IProps {
	projectId: number;
	participantId: number;
}

const ProjectParticipantServices: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [projectParticipantServiceState, projectParticipantServiceDispatch] = useReducer(projectParticipantServiceReducer, getInitialState<IProjectParticipantService>());
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!projectParticipantServiceState.isUpdating && projectParticipantServiceState.updatedEntity) {
			if (ref && ref.current) {
				ref.current.refresh();
			}
		}
	}, [projectParticipantServiceState.isUpdating, projectParticipantServiceState.updatedEntity]);

	function moveUpPriority(record: IProjectParticipantService): void {
		callApiPriority(true, record);
	}

	function moveDownPriority(record: IProjectParticipantService): void {
		callApiPriority(false, record);
	}

	function callApiPriority(moveUp: boolean, record: IProjectParticipantService): void {
		apiService.callApi(
			projectParticipantServiceDispatch,
			Endpoint.ProjectParticipantServicesUpdatePriority,
			"PUT",
			{ projectId: props.projectId, participantId: props.participantId, id: record.id },
			{ id: record.id, newPriority: record.priority + (moveUp ? -1 : 1) }
		);
	}

	function importFromInvoicingGroup(): void {
		const importKey: string = newKey("import");
		reduxDispatch(
			render(importKey, ImportFromInvoicngGroupDialogue, {
				projectId: props.projectId,
				projectParticipantId: props.participantId,
				onClose: (refresh: boolean) => {
					if (refresh) {
						ref.current.refresh();
					}
					reduxDispatch(derender(importKey));
				}
			})
		);
	}

	let toolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate)) {
		toolbarButtons = [
			<StandardButton key="importFromInvoicingGroupButton" onClick={importFromInvoicingGroup} primary>
				{t("importFromInvoicingGroup")}
			</StandardButton>
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectParticipantServicesList}
			listUrlArguments={{ projectId: props.projectId, participantId: props.participantId }}
			endpoint={Endpoint.ProjectParticipantServices}
			addScreen={{
				screen: ProjectParticipantServiceEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectId: props.projectId, participantId: props.participantId }
			}}
			editScreen={{
				screen: ProjectParticipantServiceEditor,
				isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
				extraProps: { projectId: props.projectId, participantId: props.participantId }
			}}
			delete={{ urlArguments: { projectId: props.projectId, participantId: props.participantId }, isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate) }}
			localLoader
			sort={[{ field: "priority", dir: "asc" }]}
			extraToolbarButtons={toolbarButtons}
			extraCommands={[
				{
					iconClass: "las la-angle-up",
					tooltip: t("priorityUp"),
					recordAction: moveUpPriority,
					showCommand: (dataItem: IProjectParticipantService) => dataItem.priority > 1 && hasPermission(Permission.ProjectParticipantsUpdate)
				},
				{
					iconClass: "las la-angle-down",
					tooltip: t("priorityDown"),
					recordAction: moveDownPriority,
					showCommand: (dataItem: IProjectParticipantService) => hasPermission(Permission.ProjectParticipantsUpdate) && dataItem.priority < ref.current.totalCount
				}
			]}
			ref={ref}
		>
			<GridColumn
				field="status"
				title={t("projectServiceStatus")}
				cell={customCell(colorCell(true, translatedCell(), getProjectServiceStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(ProjectServiceStatus)}
			/>
			<GridColumn field="code" title={t("serviceCode")} />
			<GridColumn field="priority" title={t("priority")} filter="numeric" />
			<GridColumn field="type.name" title={t("serviceType")} />
			<GridColumn field="shortDescription" title={t("shortDescription")} />
			<GridColumn field="amount" title={t("amount")} filter="numeric" />
			<GridColumn field="salesPrice" title={t("salesPrice")} filter="numeric" format="{0:n2}" />
			<GridColumn field="invoicingMoment.name" title={t("invoicingMoment")} />
			<GridColumn field="invoiceNumber" title={t("invoicingNumber")} />
			<GridColumn field="invoiceDate" title={t("invoicingDate")} cell={customCell(dateCell("yyyy-MM-dd"))} />
		</GridPanel>
	);
};

export default ProjectParticipantServices;
