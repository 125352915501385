import React, { useRef, useState } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { derender, render } from "@selas/state-management";
import { Loader, notify, StandardButton } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import axios, { AxiosRequestConfig } from "axios";
import fileDownload from "js-file-download";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../../services/api/endpoint";
import { getToken, hasPermission } from "../../../../../services/authentication";
import { Permission } from "../../../../../utils/enums";
import { IAttachment } from "../../../../../utils/types/models";
import FileExample from "../../../../global/fileExample";
import AttachmentEditor from "../../../followup/attachment";

import style from "../../../../../assets/editor.module.scss";
import GridPanel, { HeaderSelectedState } from "../../../../../selas-cloud-platform/gridpanel";

interface IProps {
	projectParticipantId: number;
}

const ProjectParticipantAttachments: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [isDownloading, setIsDownloading] = useState(false);
	const selectionRef = useRef<IAttachment[] | HeaderSelectedState>();
	const dispatch: Dispatch = useDispatch();

	function showFileExample(record: IAttachment): void {
		const key: string = newKey("projectParticipantAttachment_example");
		dispatch(
			render(key, FileExample, {
				file: record.content,
				onClose: () => dispatch(derender(key))
			})
		);
	}

	function downloadAttachments(): void {
		async function actualDownLoad(): Promise<void> {
			const requestConfig: AxiosRequestConfig = {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${await getToken()}`
				}
			};
			const body = Array.isArray(selectionRef.current) ? { selectedIds: map(selectionRef.current, "id") } : (selectionRef.current ?? ({ selectedIds: []}));
			const response = await axios.post(`/api/projectparticipants/${props.projectParticipantId}/attachments/download`, body, requestConfig);
			const contentDisposition: string = response.headers["content-disposition"];
			const match: RegExpMatchArray = contentDisposition.match(/filename\s*=\s*(.+);/i);
			const filename: string = match[1];
			fileDownload(response.data, filename);
		}

		if (!selectionRef.current) {
			dispatch(notify(t("info"), t("noAttachmentsSelected"), "info"));
		} else {

			setIsDownloading(true);
			actualDownLoad()
				.then(() => setIsDownloading(false))
				.catch((e) => {
					if (e.response.status === 400) {
						e.response.data.text().then((text: string) => dispatch(notify(t("info"), t(text), "info")));
					} else {
						dispatch(notify(t("info"), t("errorDownloadingAttachments"), "info"));
					}
					setIsDownloading(false);
				});
		}
	}

	function handleSelectionChange(selectionState: IAttachment[] | HeaderSelectedState): void {
		selectionRef.current = selectionState;
	}

	let toolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.ProjectParticipantsUpdate)) {
		toolbarButtons = [
			<StandardButton key="downloadAttachments" onClick={downloadAttachments} primary>
				{t("downloadAttachments")}
			</StandardButton>
		];
	}

	return (
		<>
			{isDownloading && <Loader />}
			<GridPanel
				className={style.tabGrid}
				listEndpoint={Endpoint.ProjectParticipantAttachmentsList}
				listUrlArguments={{ projectParticipantId: props.projectParticipantId }}
				endpoint={Endpoint.ProjectParticipantAttachments}
				addScreen={{
					screen: AttachmentEditor,
					isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
					extraProps: { projectParticipantId: props.projectParticipantId }
				}}
				editScreen={{
					screen: AttachmentEditor,
					isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate),
					extraProps: { projectParticipantId: props.projectParticipantId }
				}}
				delete={{ urlArguments: { projectParticipantId: props.projectParticipantId }, isAllowed: hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate) }}
				extraCommands={[
					{
						tooltip: t("view"),
						iconClass: "las la-search",
						recordAction: showFileExample
					}
				]}
				localLoader
				extraToolbarButtons={toolbarButtons}
				selectionMode="multiple"
				onSelectionChange={handleSelectionChange}
			>
				<GridColumn field="name" title={t("name")} />
			</GridPanel>
		</>
	);
};

export default ProjectParticipantAttachments;
