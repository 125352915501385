import React, { useEffect, useReducer, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { getInitialState, hideLoader, showLoader } from "@selas/state-management";
import { Confirm, Form, handleChange, StandardButton, SubmitButton, Tab, TabPanel, TextArea } from "@selas/ui-components";
import { Method } from "axios";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { intakeReducer } from "../../../state/reducers";
import { Permission } from "../../../utils/enums";
import { IIntake } from "../../../utils/types/models";

interface IProps {
	participantId: number;
	projectId: number;
	onClose: () => void;
}

const IntakeEditor: React.FC<IProps> = ({ participantId, projectId, onClose }: IProps) => {
	const { t } = useTranslation();
	const initialIntake: IIntake = {
		id: 0,
		projectParticipantId: participantId
	};
	const [intake, setIntake] = useState<IIntake>(initialIntake);
	const [intakeLoading, setIntakeLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [closeOnSave, setCloseOnSave] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [intakeState, intakeDispatch] = useReducer(intakeReducer, getInitialState<IIntake>());
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		setIntakeLoading(true);
		apiService.callApi(intakeDispatch, Endpoint.Intakes, "GET", { projectId, participantId }, null, null, () => setIntakeLoading(false));
	}, [apiService, participantId, projectId]);

	useEffect(() => {
		if (intakeState.entity) {
			setIntake(intakeState.entity);
		}
	}, [intakeState.entity]);

	useEffect(() => {
		if (intakeLoading || intakeState.isAdding || intakeState.isUpdating) {
			reduxDispatch(showLoader());
		} else if (!intakeLoading && !intakeState.isAdding && !intakeState.isUpdating) {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, intakeLoading, intakeState.isAdding, intakeState.isUpdating]);

	useEffect(() => {
		if ((!intakeState.isAdding && intakeState.addedEntity) || (!intakeState.isUpdating && intakeState.updatedEntity)) {
			if (closeOnSave) {
				onClose();
			} else {
				let newIntake: IIntake = intakeState.addedEntity;
				if (intakeState.updatedEntity) {
					newIntake = intakeState.updatedEntity;
				}
				setIntake(newIntake);
				setDataChanged(false);
			}
		}
	}, [closeOnSave, intakeState.addedEntity, intakeState.isAdding, intakeState.isUpdating, intakeState.updatedEntity, onClose]);

	function onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
		setIntake(handleChange(intake, event));
		setDataChanged(true);
	}

	function save(close: boolean): void {
		setCloseOnSave(close);
		let method: Method = "POST";
		if (intake.id) {
			method = "PUT";
		}
		apiService.callApi(intakeDispatch, Endpoint.Intakes, method, { projectId, participantId }, intake);
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			onClose();
		}
	}

	const readonly: boolean = !hasPermission(Permission.ProjectsUpdate, Permission.ProjectParticipantsUpdate);

	return (
		<>
			<Form>
				<Dialog width="90%" height="70%" title={t("intake")} onClose={() => handleClose()}>
					<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }} tabStyle={{ height: "100%" }} style={{ height: "100%" }}>
						<Tab reactKey="education" label={t("education")} className="h-100">
							<TextArea name="education" value={intake.education} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="workingExperiences" label={t("workingExperiences")} className="h-100">
							<TextArea name="workingExperiences" value={intake.workingExperiences} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="seniorityPreviousEmployment" label={t("seniorityPreviousEmployment")} className="h-100">
							<TextArea name="seniorityPreviousEmployment" value={intake.seniorityPreviousEmployment} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="functionPreviousEmployment" label={t("functionPreviousEmployment")} className="h-100">
							<TextArea name="functionPreviousEmployment" value={intake.functionPreviousEmployment} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="ambitions" label={t("ambitions")} className="h-100">
							<TextArea name="ambitions" value={intake.ambitions} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="preferedFutureFunction" label={t("preferedFutureFunction")} className="h-100">
							<TextArea name="preferedFutureFunction" value={intake.preferedFutureFunction} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="functionConditions" label={t("functionConditions")} className="h-100">
							<TextArea name="functionConditions" value={intake.functionConditions} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="environmentConditions" label={t("environmentConditions")} className="h-100">
							<TextArea name="environmentConditions" value={intake.environmentConditions} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="employmentConditions" label={t("employmentConditions")} className="h-100">
							<TextArea name="employmentConditions" value={intake.employmentConditions} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="thresholds" label={t("thresholds")} className="h-100">
							<TextArea name="thresholds" value={intake.thresholds} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
						<Tab reactKey="miscellaneous" label={t("miscellaneous")} className="h-100">
							<TextArea name="miscellaneous" value={intake.miscellaneous} onChange={onChange} disabled={readonly} className="h-100 w-100" />
						</Tab>
					</TabPanel>
					<DialogActionsBar>
						<StandardButton onClick={() => handleClose()} type="button">
							{readonly || !dataChanged ? t("close") : t("cancel")}
						</StandardButton>
						{!readonly && (
							<SubmitButton primary disabled={!dataChanged} onClick={() => save(false)}>
								{t("save")}
							</SubmitButton>
						)}
						{!readonly && (
							<SubmitButton primary disabled={!dataChanged} onClick={() => save(true)}>
								{t("saveAndClose")}
							</SubmitButton>
						)}
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save(true);
					}}
					onDecline={() => onClose()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
};

export default IntakeEditor;
