import React, { useEffect, useReducer, useRef, useState } from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { getInitialState } from "@selas/state-management";
import {
	checkBoxCell,
	customCell,
	EntityEditor,
	GridPanel,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	notify,
	StandardButton,
	Tab,
	TabPanel,
	TextArea,
	YesNoSwitch
} from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import { useTranslation } from "react-i18next";

import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialCompanyWebState } from "../../../state";
import { partnerReducer } from "../../../state/reducers";
import companyWebReducer from "../../../state/reducers/companyWebReducer";
import { Permission } from "../../../utils/enums";
import { IAddress, ICompanyWeb, IContact, IPartner } from "../../../utils/types/models";
import AddressEdit from "../../global/addressEdit";
import { heatCell } from "../../global/heat";
import ContactEditor from "./contact";

const PartnerEditor: React.FC<IAddEntityScreenProps<IPartner> | IEditEntityScreenProps<IPartner>> = (props: IAddEntityScreenProps<IPartner> | IEditEntityScreenProps<IPartner>) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [companyWebState, companyWebDispatch] = useReducer(companyWebReducer, initialCompanyWebState);

	const initialPartner: IPartner = {
		id: (props as IEditEntityScreenProps<IPartner>).recordId || 0,
		name: "",
		vatNumber: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		extra: "",
		active: true
	};

	const [partner, setPartner] = useState<IPartner>(initialPartner);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (partnerState.entity) {
			setPartner(partnerState.entity);
		}
	}, [partnerState.entity]);

	useEffect(() => {
		if (companyWebState.data) {
			const data: ICompanyWeb = companyWebState.data;
			const partnerRecord: IPartner = cloneDeep(partner);

			partnerRecord.name = data.name;
			partnerRecord.address.street = data.address.street;
			partnerRecord.address.number = data.address.number;
			partnerRecord.address.postalCode = data.address.postalCode;
			partnerRecord.address.municipality = data.address.municipality;
			partnerRecord.address.country = data.address.country;

			setPartner(partnerRecord);
			setDataChanged(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyWebState.data]);

	function onChange(event: InputChangeEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent): void {
		setPartner(handleChange(partner, event));
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newPartner: IPartner = { ...partner };
		newPartner.address = address;
		setPartner(newPartner);
		setDataChanged(true);
	}

	function onActionButtonClicked(close: boolean, record: IPartner): void {
		if (record && !close) {
			setPartner(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(partner.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(partner.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(partner.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(partner.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(partner.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(partner.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		return messages;
	}

	function getVatInfo(): void {
		const vatNumber: string = partner.vatNumber.replace(/[^0-9]/g, "");

		if (vatNumber.length > 8 && vatNumber.length < 11) {
			apiService.callApi(companyWebDispatch, Endpoint.CompanyWeb, "GET", { vatnumber: vatNumber });
		} else {
			reduxDispatch(notify(t("vatNumber"), t("vatNumberFormat", {}), "success"));
		}
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IPartner>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={partner}
			endpoint={Endpoint.Partners}
			entityState={partnerState}
			entityType={t("partner")}
			dispatch={partnerDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={partner.name}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="details" label={t("details")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")} *</span>
									<Input name="name" ref={firstField} value={partner.name} onChange={onChange} disabled={readonly} required />
								</label>
							</div>

							<div className="col d-flex">
								<div className="flex-grow-1" style={{ marginRight: "10px" }}>
									<label className="k-form-field">
										<span>{t("vatNumber")}</span>
										<Input name="vatNumber" value={partner.vatNumber} onChange={onChange} disabled={readonly} />
									</label>
								</div>
								<div className="d-flex justify-content-end align-items-end">
									<StandardButton onClick={() => getVatInfo()} type="button" primary disabled={!partner.vatNumber} iconClass="las la-search" />
								</div>
							</div>

							<div className="col">
								<label className="k-form-field">
									<span>{t("commissionPercentage")}</span>
									<NumericTextBox name="commissionPercentage" value={partner.commissionPercentage} onChange={onChange} min={0} format="n2" disabled={readonly} />
								</label>
							</div>
						</div>
						<AddressEdit address={partner.address} addressField="address" onChange={onChange} setAddress={setAddress} required numberRequired={true} readonly={readonly} />
						<label className="k-form-field">
							<span>{t("extra")}</span>
							<TextArea name="extra" value={partner.extra} onChange={onChange} disabled={readonly} />
						</label>
						{!props.hideActive && (
							<div className="k-form-field">
								<div>{t("active")}</div>
								<YesNoSwitch name="active" checked={partner.active} onChange={onChange} disabled={readonly} />
							</div>
						)}
					</div>
				</Tab>
				{partner.id && hasPermission(Permission.ContactsRead) && (
					<Tab reactKey="contacts" label={t("contacts")}>
						<div className="row">
							<GridPanel
								style={{ height: "500px" }}
								listEndpoint={Endpoint.PartnerContactsList}
								listUrlArguments={{ partnerId: partner.id }}
								endpoint={Endpoint.PartnerContacts}
								addScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsAdd), extraProps: { partnerId: partner.id, allowedToEditPartner: false } }}
								editScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsUpdate), extraProps: { partnerId: partner.id, allowedToEditPartner: false } }}
								delete={{ urlArguments: { partnerId: partner.id }, isAllowed: hasPermission(Permission.ContactsDelete) }}
								filter={{
									logic: "and",
									filters: [
										{
											field: "active",
											operator: "eq",
											value: true
										}
									]
								}}
								localLoader
							>
								<GridColumn field="fullName" title={t("name")} />
								<GridColumn field="partnerHeat" title={t("partnerHeat")} cell={customCell(heatCell<IContact>("partnerId"))} filter="numeric" />
								<GridColumn field="type.name" title={t("contactType")} />
								<GridColumn field="statute.name" title={t("statute")} />
								<GridColumn field="company.name" title={t("company")} />
								<GridColumn field="companyHeat" title={t("companyHeat")} cell={customCell(heatCell<IContact>("companyId"))} filter="numeric" />
								<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell())} />
							</GridPanel>
						</div>
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default PartnerEditor;
