import React, { useEffect, useReducer, useState } from "react";

import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState, getInitialSystemState, hideLoader, render, showLoader } from "@selas/state-management";
import { Confirm, EnumDropDownList } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import GroupEditor from "..";
import Endpoint from "../../../../../services/api/endpoint";
import { groupReducer, municipalityReducer, projectParticipantDistributeReducer } from "../../../../../state/reducers";
import { AgeClass, CommunicationLanguage, ProjectParticipantStatute } from "../../../../../utils/enums";
import { IGroup, IMunicipality, IProjectParticipant } from "../../../../../utils/types/models";
import { GroupCard } from "./groupCard";
import { ParticipantCard } from "./participantCard";

import style from "./style.module.scss";

interface IProps {
	projectId: number;
	onClose: () => void;
}

const DistributeParticipantsScreen: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	const defaultFilter: State = { skip: 0, take: 50 };

	const [state, dispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [groupsState, groupsDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [projectParticipantsState, projectParticipantsDispatch] = useReducer(projectParticipantDistributeReducer, getInitialState<IProjectParticipant>());

	const [displayFilter, setDisplayFilter] = useState<boolean>(false);
	const [filterState, setFilterState] = useState<State>(defaultFilter);

	const [municipalityState, municipalityDispatch] = useReducer(municipalityReducer, getInitialSystemState<IMunicipality>());

	const [municipality, setMunicipality] = useState<IMunicipality>(null);
	const [ageClass, setAgeClass] = useState<AgeClass>(null);
	const [statute, setStatute] = useState<ProjectParticipantStatute>(null);
	const [language, setLanguage] = useState<CommunicationLanguage>(null);

	useEffect(() => {
		apiService.callApi(groupsDispatch, Endpoint.GroupsList, "POST", { projectId: props.projectId }, defaultFilter);
		apiService.callApi(projectParticipantsDispatch, Endpoint.ProjectParticipantNotAssignedToGroup, "POST", { projectId: props.projectId }, filterState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiService, filterState, props.projectId]);

	useEffect(() => {
		if (!state.isUpdating && state.updatedEntity) {
			apiService.callApi(groupsDispatch, Endpoint.GroupsList, "POST", { projectId: props.projectId }, defaultFilter);
			apiService.callApi(projectParticipantsDispatch, Endpoint.ProjectParticipantNotAssignedToGroup, "POST", { projectId: props.projectId }, filterState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiService, filterState, props.projectId, state.isUpdating, state.updatedEntity]);

	useEffect(() => {
		if (groupsState.isListLoading || projectParticipantsState.isListLoading || state.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, groupsState.isListLoading, projectParticipantsState.isListLoading, state.isUpdating]);

	function createGroup(): void {
		const groupEditorKey: string = newKey("GroupEditor");
		reduxDispatch(
			render(groupEditorKey, GroupEditor, {
				recordId: 0,
				projectId: props.projectId,
				actionButtonClicked: (close: boolean) => {
					if (close) {
						reduxDispatch(derender(groupEditorKey));
						apiService.callApi(groupsDispatch, Endpoint.GroupsList, "POST", { projectId: props.projectId }, defaultFilter);
					}
				}
			})
		);
	}

	function emptyFilters(): void {
		setMunicipality(null);
		setAgeClass(null);
		setStatute(null);
		setLanguage(null);
	}

	function onDeclineFilter(): void {
		emptyFilters();
		setFilterState(defaultFilter);
		setDisplayFilter(false);
	}

	function onConfirmFilter(): void {
		if (!municipality && !ageClass && !statute && !language) {
			setFilterState(defaultFilter);
		} else {
			const request: State = {
				filter: {
					logic: "and",
					filters: []
				},
				skip: 0,
				take: 50
			};

			if (municipality) {
				request.filter.filters.push({ field: "participant.address.postalCode", operator: "eq", value: municipality.code });
			}
			if (ageClass) {
				request.filter.filters.push({ field: "ageClass", operator: "eq", value: ageClass });
			}
			if (statute) {
				request.filter.filters.push({ field: "statute", operator: "eq", value: statute });
			}
			if (language) {
				request.filter.filters.push({ field: "participant.communicationLanguage", operator: "eq", value: language });
			}

			setFilterState(request);
		}
		setDisplayFilter(false);
	}

	function municipalityItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, itemProps.dataItem.code + " " + itemProps.dataItem.name);
	}

	function onDrop(newGroup: IGroup): void {
		apiService.callApi(dispatch, Endpoint.Groups, "PUT", { projectId: props.projectId, id: newGroup.id }, newGroup);
	}

	return (
		<Dialog title={t("distributeParticipants")} width="50%" onClose={props.onClose}>
			<DndProvider backend={HTML5Backend}>
				<div className="row" style={{ verticalAlign: "middle" }}>
					<div className={"col " + style.header}>
						<div className="d-flex justify-content-center">
							<Button look="flat" iconClass="las la-plus" className={style.buttonStyle} title={t("add")} onClick={(event: React.MouseEvent<HTMLButtonElement>) => createGroup()} />
							<div className="flex-grow-1" />
							{t("groups")}
							<div className="flex-grow-1" />
						</div>
					</div>
					<div className={"col " + style.header}>
						<div className="d-flex justify-content-center">
							<div className="flex-grow-1" />
							{t("participants")}
							<div className="flex-grow-1" />
							<Button
								look="flat"
								iconClass="las la-filter"
								className={style.buttonStyle}
								title={t("chooseFilter")}
								onClick={(event: React.MouseEvent<HTMLButtonElement>) => setDisplayFilter(true)}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row" style={{ height: "95%" }}>
					<div className={"col " + style.groupContainer}>
						{groupsState.list.map((group: IGroup) => (
							<GroupCard key={"group_" + group.id} group={group} onDrop={onDrop} />
						))}
					</div>
					<div className={"col " + style.participantContainer}>
						{projectParticipantsState.list.map((participant: IProjectParticipant) => (
							<ParticipantCard key={"participant_" + participant.id} participant={participant} />
						))}
					</div>
				</div>
			</DndProvider>
			{displayFilter && (
				<Confirm title={t("chooseFilter")} onDecline={onDeclineFilter} onConfirm={onConfirmFilter}>
					<div className="k-form">
						<div className="row">
							<div className={"col " + style.filterRowStart}>
								<pre className={style.filter}>{t("postalCode")}</pre>
							</div>
							<div className={"col " + style.filterRowEnd}>
								<ComboBox
									name={"postalCode"}
									className="full-width-field hiddenTrigger"
									data={municipalityState.entities}
									dataItemKey="postalCode"
									textField="code"
									value={municipality}
									itemRender={municipalityItem}
									onClose={(event: ComboBoxCloseEvent) => setMunicipality(event.target.value)}
									onChange={(event: ComboBoxChangeEvent) => setMunicipality(event.value)}
									filterable
									onFilterChange={(event: ComboBoxFilterChangeEvent) =>
										apiService.callApi(municipalityDispatch, Endpoint.Municipalities, "GET", { search: event.filter.value, field: "postalCode" })
									}
								/>
							</div>
						</div>
						<div className="row" style={{ marginTop: "10px" }}>
							<div className={"col " + style.filterRowStart}>
								<pre className={style.filter}>{t("ageClass")}</pre>
							</div>
							<div className={"col " + style.filterRowEnd}>
								<EnumDropDownList name="ageClass" enum={AgeClass} value={ageClass} onChange={(event: DropDownListChangeEvent) => setAgeClass(event.value?.key)} />
							</div>
						</div>
						<div className="row" style={{ marginTop: "10px" }}>
							<div className={"col " + style.filterRowStart}>
								<pre className={style.filter}>{t("statute")}</pre>
							</div>
							<div className={"col " + style.filterRowEnd}>
								<EnumDropDownList name="statute" enum={ProjectParticipantStatute} value={statute} onChange={(event: DropDownListChangeEvent) => setStatute(event.value?.key)} />
							</div>
						</div>
						<div className="row" style={{ marginTop: "10px" }}>
							<div className={"col " + style.filterRowStart}>
								<pre className={style.filter}>{t("language")}</pre>
							</div>
							<div className={"col " + style.filterRowEnd}>
								<EnumDropDownList name="language" enum={CommunicationLanguage} value={language} onChange={(event: DropDownListChangeEvent) => setLanguage(event.value?.key)} />
							</div>
						</div>
						<div className="row" style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
							<Button
								look="flat"
								iconClass="las la-trash-alt"
								className={style.buttonStyle}
								title={t("delete")}
								onClick={(event: React.MouseEvent<HTMLButtonElement>) => emptyFilters()}
							/>
						</div>
					</div>
				</Confirm>
			)}
		</Dialog>
	);
};

export default DistributeParticipantsScreen;
