import React, { useCallback, useEffect, useState } from "react";

import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, IEntityState, render } from "@selas/state-management";
import { commandCell, Confirm, customCell, dateCell, enumFilterCell, Loader, translatedCell } from "@selas/ui-components";
import { newKey } from "@selas/utils";

import { map } from "lodash";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import style from "../../../../../assets/editor.module.scss";
import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { FollowUpTaskStatus, Permission } from "../../../../../utils/enums";
import { IFollowUpTask, ISessionFollowUpTask } from "../../../../../utils/types/models";
import { colorCell, colorCellClassName } from "../../../../global/colorCell";
import FollowUpTaskEditor, { getFollowUpTaskStatusColor } from "../../../followup/followUpTask";

import { IAction } from "@selas/models";
import { useTranslation } from "react-i18next";

interface IProps {
	projectId: number;
	sessionId: number;
	outlookMeetingId: string;
	sessionFollowUpTasksState: IEntityState<ISessionFollowUpTask>;
	sessionFollowUpTasksDispatch: React.Dispatch<IAction>;
	readOnly: boolean;
}

const SessionFollowUpTasks: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	// const [sessionFollowUpTasksState, sessionFollowUpTasksDispatch] = useReducer(sessionFollowUpTaskReducer, getInitialState<ISessionFollowUpTask>());
	const reduxDispatch: Dispatch = useDispatch();
	const [entities, setEntities] = useState<ISessionFollowUpTask[]>([]);
	const [gridState, setGridState] = useState<State>({
		skip: 0,
		take: 25
	});
	const apiService: ApiCommunicator = useApiService();

	const refreshGrid: () => void = useCallback(() => {
		apiService.callApi(props.sessionFollowUpTasksDispatch, Endpoint.SessionFollowUpTasksList, "POST", { projectId: props.projectId, sessionId: props.sessionId }, gridState);
	}, [apiService, gridState, props.projectId, props.sessionId, props.sessionFollowUpTasksDispatch]);

	useEffect(() => {
		refreshGrid();
	}, [refreshGrid]);

	useEffect(() => {
		if (
			(!props.sessionFollowUpTasksState.isDeleting && props.sessionFollowUpTasksState.deleteSuccess) ||
			(!props.sessionFollowUpTasksState.isUpdating && props.sessionFollowUpTasksState.updatedEntity)
		) {
			refreshGrid();
		}
	}, [
		props.sessionFollowUpTasksState.isDeleting,
		props.sessionFollowUpTasksState.deleteSuccess,
		props.sessionFollowUpTasksState.isUpdating,
		props.sessionFollowUpTasksState.updatedEntity,
		refreshGrid
	]);

	useEffect(() => {
		setEntities(
			map(props.sessionFollowUpTasksState.list, (item: ISessionFollowUpTask) => {
				return item;
			})
		);
	}, [props.sessionFollowUpTasksState.list]);

	function deleteEntity(id: number): void {
		const confirmKey: string = newKey("deleteItem");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm"),
				children: t("confirm_content", { action: t("remove") }),
				onConfirm: () => {
					apiService.callApi(props.sessionFollowUpTasksDispatch, Endpoint.SessionFollowUpTasks, "DELETE", { projectId: props.projectId, sessionId: props.sessionId, id });
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	function createSessionFollowUpTask(): void {
		const addSessionFollowUpTask: string = newKey("addSessionFollowUpTask");
		reduxDispatch(
			render(addSessionFollowUpTask, FollowUpTaskEditor, {
				actionButtonClicked: (close: boolean, record?: IFollowUpTask) => {
					if (close) {
						reduxDispatch(derender(addSessionFollowUpTask));
					}
					if (record) {
						refreshGrid();
					}
				},
				projectId: props.projectId,
				sessionId: props.sessionId,
				outlookMeetingId: props.outlookMeetingId
			})
		);
	}

	function editEntity(id: number): void {
		const editSessionFollowUpTask: string = newKey("editSessionFollowUpTask");
		reduxDispatch(
			render(editSessionFollowUpTask, FollowUpTaskEditor, {
				actionButtonClicked: (close: boolean, record?: IFollowUpTask) => {
					if (close) {
						reduxDispatch(derender(editSessionFollowUpTask));
					}
					if (record) {
						refreshGrid();
					}
				},
				recordId: id,
				readonly: false,
				projectId: props.projectId,
				sessionId: props.sessionId,
				outlookMeetingId: props.outlookMeetingId
			})
		);
	}

	return (
		<>
			{(props.sessionFollowUpTasksState.isListLoading || props.sessionFollowUpTasksState.isUpdating) && <Loader />}
			<Grid
				className={style.tabGrid}
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
				data={entities}
				total={props.sessionFollowUpTasksState.totalCount}
				onRowDoubleClick={(event: GridRowDoubleClickEvent) => {
					if (!props.readOnly) {
						return editEntity(event.dataItem.id);
					}
				}}
				{...gridState}
				filterable
				sortable
				scrollable="scrollable"
				style={{ overflowX: "auto" }}
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				resizable={true}
			>
				<GridToolbar>
					<div className="toolbarButtonContainer d-flex w-100 align-items-center">
						<button
							title="Create Session FollowUp task"
							className="k-button k-primary"
							onClick={createSessionFollowUpTask}
							hidden={!hasPermission(Permission.SessionTemplatesUpdate)}
							disabled={props.readOnly}
						>
							{t("add")}
						</button>
						<div className="flex-grow-1" />
						<i className="refreshButton las la-sync" onClick={refreshGrid} />
					</div>
				</GridToolbar>
				<GridColumn
					field="status"
					title={t("sessionFollowUpTaskStatus")}
					cell={customCell(colorCell(true, translatedCell(), getFollowUpTaskStatusColor), colorCellClassName)}
					filterCell={enumFilterCell(FollowUpTaskStatus)}
				/>
				<GridColumn field="creator.fullName" title={t("creator")} />
				<GridColumn field="assignee.fullName" title={t("taskAssignee")} />
				<GridColumn field="subject" title={t("subject")} />
				<GridColumn field="dueDate" title={t("dueDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
				<GridColumn
					title={t("actions")}
					width="140px"
					filterable={false}
					editable={false}
					sortable={false}
					groupable={false}
					headerClassName="notSortable"
					cell={customCell(
						commandCell([
							{
								tooltip: t("remove"),
								iconClass: "las la-times",
								idAction: deleteEntity,
								showCommand: () => hasPermission(Permission.SessionTemplatesUpdate) && !props.readOnly
							},
							{
								tooltip: t("edit"),
								iconClass: "las la-pencil-alt",
								idAction: editEntity,
								showCommand: () => hasPermission(Permission.SessionTemplatesUpdate) && !props.readOnly
							}
						])
					)}
				/>
			</Grid>
		</>
	);
};

export default SessionFollowUpTasks;
