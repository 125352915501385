import React from "react";

import { DatePicker, DatePickerChangeEvent, DateTimePicker, DateTimePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { handleChange } from "@selas/ui-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { SettingType } from "../../../utils/enums";
import { ISetting } from "../../../utils/types/models";

dayjs.extend(customParseFormat);

interface IProps {
	setting: ISetting;
	onChange: (setting: ISetting) => void;
	readOnly: boolean;
}

const SettingEditor: React.FC<IProps> = (props: IProps) => {
	function onChange(event: InputChangeEvent | DatePickerChangeEvent | DateTimePickerChangeEvent | NumericTextBoxChangeEvent): void {
		const newSetting: ISetting = handleChange(props.setting, event);
		props.onChange(newSetting);
	}

	function onTimePickerChange(event: TimePickerChangeEvent): void {
		const newSetting: ISetting = {
			...props.setting,
			value: dayjs(event.value).format("HH:mm:ss")
		};
		props.onChange(newSetting);
	}

	switch (props.setting.type) {
		case SettingType.Date:
			return <DatePicker name="value" value={new Date(props.setting.value)} onChange={onChange} weekNumber className="full-width-field" disabled={props.readOnly} />;
		case SettingType.DateTime:
			return <DateTimePicker name="value" value={new Date(props.setting.value)} onChange={onChange} weekNumber className="full-width-field" disabled={props.readOnly} />;
		case SettingType.Time:
			return <TimePicker name="value" value={dayjs(props.setting.value, "HH:mm:ss").toDate()} onChange={onTimePickerChange} className="full-width-field" disabled={props.readOnly} />;
		case SettingType.Integer:
			return <NumericTextBox name="value" value={parseInt(props.setting.value, 0)} onChange={onChange} format="n0" className="full-width-field" disabled={props.readOnly} />;
		case SettingType.Decimal:
			return <NumericTextBox name="value" value={parseFloat(props.setting.value)} onChange={onChange} className="full-width-field" disabled={props.readOnly} />;
		case SettingType.String:
		default:
			return <Input name="value" value={props.setting.value} onChange={onChange} className="full-width-field" disabled={props.readOnly} />;
	}
};

export default SettingEditor;
