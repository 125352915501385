import React, { Dispatch, ReactElement, useEffect, useReducer, useRef, useState } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent, ListItemProps, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { InputChangeEvent, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState } from "@selas/state-management";
import {
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	ManageableMultiSelect,
	SearchBox,
	setEntity,
	Tab,
	TabPanel,
	YesNoSwitch
} from "@selas/ui-components";
import { getDate } from "@selas/utils";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

import { isNull } from "lodash";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { companyReducer, contractTypeReducer, jobFunctionReducer, opportunityParticipantReducer, participantReducer, sectorReducer } from "../../../state/reducers";
import { CommunicationLanguage, OpportunityParticipantStatus, Permission } from "../../../utils/enums";
import { ICompany, IContractType, IJobFunction, IOpportunityParticipant, IOpportunityParticipantSector, IParticipant, ISector } from "../../../utils/types/models";
import { statusDropdownItemRender } from "../../global/statusDropdownItemRender";
import SectorEditor from "../catalogue/sector";
import CompanyEditor from "../customer/company";
import ParticipantEditor from "../customer/participant";
import ParticipantEditorContent from "../customer/participant/editorContent";
import DatePickerForDateOnly from "../datepicker/datePicker";
import ContractTypeEditor from "../personInfo/contractType";
import JobFunctionEditor from "../personInfo/jobFunction";
import { getOpportunityParticipantStatusColor } from "./grids/opportunityParticipants";

interface IProps {
	opportunityId: number;
}

const OpportunityParticipantEditor: React.FC<(IAddEntityScreenProps<IOpportunityParticipant> | IEditEntityScreenProps<IOpportunityParticipant>) & IProps> = (
	props: (IAddEntityScreenProps<IOpportunityParticipant> | IEditEntityScreenProps<IOpportunityParticipant>) & IProps
) => {
	const { t } = useTranslation();

	const initialOpportunityParticipant: IOpportunityParticipant = {
		id: (props as IEditEntityScreenProps<IOpportunityParticipant>).recordId || 0,
		participantId: null,
		participant: {
			id: 0,
			nationalRegistryNumber: "",
			firstName: "",
			lastName: "",
			fullName: "",
			address: {
				street: "",
				number: "",
				postalCode: "",
				municipality: "",
				province: "",
				country: "",
				fullAddress: ""
			},
			phoneNumber: "",
			mobilePhoneNumber: "",
			emailAddress: "",
			nationalityId: null,
			dateOfBirth: null,
			knownLanguages: [],
			highestDegreeId: null,
			communicationLanguage: CommunicationLanguage.Dutch,
			active: true,
			isInfoAndRefferalEmailAllowed: true
		},
		opportunityId: props.opportunityId,
		status: OpportunityParticipantStatus.New,
		sectors: [],
		putNameOnInvoice: false
	};
	const [opportunityParticipant, setOpportunityParticipant] = useState<IOpportunityParticipant>(initialOpportunityParticipant);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [opportunityParticipantState, opportunityParticipantDispatch] = useReducer(opportunityParticipantReducer, getInitialState<IOpportunityParticipant>());
	const [participantState, participantDispatch] = useReducer(participantReducer, getInitialState<IParticipant>());
	const [sectorState, sectorDispatch] = useReducer(sectorReducer, getInitialState<ISector>());
	const [contractTypeState, contractTypeDispatch] = useReducer(contractTypeReducer, getInitialState<IContractType>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [lastFunctionState, lastFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const [preferedFunctionState, preferedFunctionDispatch] = useReducer(jobFunctionReducer, getInitialState<IJobFunction>());
	const firstField: React.MutableRefObject<ComboBox> = useRef();
	const apiService: ApiCommunicator = useApiService();

	useEffect(() => {
		if (opportunityParticipantState.entity) {
			setOpportunityParticipant(opportunityParticipantState.entity);
		}
	}, [opportunityParticipantState.entity]);

	function onChange(
		event: InputChangeEvent | SwitchChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | NumericTextBoxChangeEvent | DatePickerChangeEvent
	): void {
		setOpportunityParticipant(handleChange(opportunityParticipant, event));
		setDataChanged(true);
	}

	function onMultiSelectChange(event: MultiSelectChangeEvent): void {
		const newOpportunityParticipant: IOpportunityParticipant = cloneDeep(opportunityParticipant);

		if (event.value) {
			if (event.target.name === "sectors") {
				newOpportunityParticipant.sectors = [];
				for (const sector of event.value) {
					newOpportunityParticipant.sectors.push({
						participantId: newOpportunityParticipant.id,
						sectorId: sector.id,
						sector
					});
				}
			}
			updateOpportunityParticipant(newOpportunityParticipant);
		}
	}

	function addOrUpdateSector(sector: ISector): void {
		const newOpportunityParticipant: IOpportunityParticipant = cloneDeep(opportunityParticipant);
		const existingOpportunityParticipantSector: IOpportunityParticipantSector = find(newOpportunityParticipant.sectors, { sectorId: sector.id });
		if (existingOpportunityParticipantSector) {
			existingOpportunityParticipantSector.sector = sector;
		} else {
			newOpportunityParticipant.sectors.push({
				participantId: newOpportunityParticipant.id,
				sectorId: sector.id,
				sector
			});
		}
		updateOpportunityParticipant(newOpportunityParticipant);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IOpportunityParticipant, idField: keyof IOpportunityParticipant): void {
		const newContact: IOpportunityParticipant = setEntity(opportunityParticipant, entity, field, idField);
		updateOpportunityParticipant(newContact);
	}

	function updateOpportunityParticipant(newOpportunityParticipant: IOpportunityParticipant): void {
		setOpportunityParticipant(newOpportunityParticipant);
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		switch (event.target.name) {
			case "participantId":
				dispatch = participantDispatch;
				filterEndpoint = Endpoint.Participants;
				break;
			case "contractTypeId":
				dispatch = contractTypeDispatch;
				filterEndpoint = Endpoint.ContractTypes;
				break;
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "lastFunctionId":
			case "preferedFutureFunctionId":
				dispatch = event.target.name === "lastFunctionId" ? lastFunctionDispatch : preferedFunctionDispatch;
				filterEndpoint = Endpoint.JobFunctions;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value });
	}

	function onMultiSelectFilterChange(event: MultiSelectFilterChangeEvent): void {
		apiService.callApi(sectorDispatch, Endpoint.Sectors, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!opportunityParticipant.participantId) {
			messages.push(t("fill_in_required_field", { field: t("participant").toLowerCase() }));
		}
		if (!isNull(opportunityParticipant.fireOrQuitDate) && opportunityParticipant.fireOrQuitDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("fireOrQuitDate").toLowerCase() }));
		}
		if (!isNull(opportunityParticipant.noticePeriodEndDate) && opportunityParticipant.noticePeriodEndDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("noticePeriodEndDate").toLowerCase() }));
		}
		if (!isNull(opportunityParticipant.employmentUnitEnrollDate) && opportunityParticipant.employmentUnitEnrollDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("employmentUnitEnrollDate").toLowerCase() }));
		}
		if (!isNull(opportunityParticipant.employmentUnitLeaveDate) && opportunityParticipant.employmentUnitLeaveDate < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("employmentUnitLeaveDate").toLowerCase() }));
		}

		return messages;
	}

	function onActionButtonClicked(close: boolean, record: IOpportunityParticipant): void {
		if (record && !close) {
			setOpportunityParticipant(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function renderOpportunityParticipantStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getOpportunityParticipantStatusColor);
	}

	const readonly: boolean = (props as IEditEntityScreenProps<IOpportunityParticipant>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={opportunityParticipant}
			endpoint={Endpoint.OpportunityParticipants}
			extraUrlParams={{ opportunityId: props.opportunityId }}
			entityState={opportunityParticipantState}
			entityType={t("opportunityParticipant")}
			dispatch={opportunityParticipantDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={opportunityParticipant.participant?.fullName}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("participant")}
									recordId={opportunityParticipant.participantId}
									addScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsAdd) }}
									editScreen={{ screen: ParticipantEditor, isAllowed: hasPermission(Permission.ParticipantsUpdate) }}
									setEntity={(participant: IParticipant) => setChild(participant, "participant", "participantId")}
									readOnly={readonly}
								>
									<SearchBox
										name="participantId"
										entities={participantState.entities}
										isLoading={participantState.areEntitiesLoading}
										entityId={opportunityParticipant.participantId}
										entity={opportunityParticipant.participant}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "participant", "participantId")}
										disabled={readonly}
										myRef={firstField}
									/>
								</ManageableField>
							</div>
						</div>
						<ParticipantEditorContent participant={opportunityParticipant.participant} readonly hideActive={false} />
					</div>
				</Tab>
				<Tab reactKey="opportunity" label={t("opportunity")}>
					<div className="k-form">
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("opportunityParticipantStatus")} *</span>
									<EnumDropDownList
										name="status"
										value={opportunityParticipant.status}
										onChange={onChange}
										enum={OpportunityParticipantStatus}
										itemRender={renderOpportunityParticipantStatus}
										required
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableMultiSelect
									fieldLabel={t("sectors")}
									addScreen={{ screen: SectorEditor, isAllowed: hasPermission(Permission.SectorsAdd) }}
									editScreen={{ screen: SectorEditor, isAllowed: hasPermission(Permission.SectorsUpdate) }}
									setEntity={addOrUpdateSector}
									name="sectors"
									data={sectorState.entities}
									textField="name"
									dataItemKey="id"
									value={map(opportunityParticipant.sectors, "sector")}
									loading={sectorState.areEntitiesLoading}
									onChange={onMultiSelectChange}
									filterable
									onFilterChange={onMultiSelectFilterChange}
									disabled={readonly}
									readOnly={readonly}
								/>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"fireOrQuitDate"}
										name="fireOrQuitDate"
										value={getDate(opportunityParticipant.fireOrQuitDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"noticePeriodEndDate"}
										name="noticePeriodEndDate"
										value={getDate(opportunityParticipant.noticePeriodEndDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("contractType")}
									recordId={opportunityParticipant.contractTypeId}
									addScreen={{ screen: ContractTypeEditor, isAllowed: hasPermission(Permission.ContractTypesAdd) }}
									editScreen={{ screen: ContractTypeEditor, isAllowed: hasPermission(Permission.ContractTypesUpdate) }}
									setEntity={(contractType: IContractType) => setChild(contractType, "contractType", "contractTypeId")}
									readOnly={readonly}
								>
									<SearchBox
										name="contractTypeId"
										entities={contractTypeState.entities}
										isLoading={contractTypeState.areEntitiesLoading}
										entityId={opportunityParticipant.contractTypeId}
										entity={opportunityParticipant.contractType}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "contractType", "contractTypeId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("company")}
									recordId={opportunityParticipant.companyId}
									addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
									editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
									setEntity={(company: ICompany) => setChild(company, "company", "companyId")}
									readOnly={readonly}
								>
									<SearchBox
										name="companyId"
										entities={companyState.entities}
										isLoading={companyState.areEntitiesLoading}
										entityId={opportunityParticipant.companyId}
										entity={opportunityParticipant.company}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "company", "companyId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("lastFunction")}
									recordId={opportunityParticipant.lastFunctionId}
									addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
									editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
									setEntity={(lastFunction: IJobFunction) => setChild(lastFunction, "lastFunction", "lastFunctionId")}
									readOnly={readonly}
								>
									<SearchBox
										name="lastFunctionId"
										entities={lastFunctionState.entities}
										isLoading={lastFunctionState.areEntitiesLoading}
										entityId={opportunityParticipant.lastFunctionId}
										entity={opportunityParticipant.lastFunction}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "lastFunction", "lastFunctionId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("preferedFutureFunction")}
									recordId={opportunityParticipant.preferedFutureFunctionId}
									addScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsAdd) }}
									editScreen={{ screen: JobFunctionEditor, isAllowed: hasPermission(Permission.JobFunctionsUpdate) }}
									setEntity={(jobFunction: IJobFunction) => setChild(jobFunction, "preferedFutureFunction", "preferedFutureFunctionId")}
									readOnly={readonly}
								>
									<SearchBox
										name="preferedFutureFunctionId"
										entities={preferedFunctionState.entities}
										isLoading={preferedFunctionState.areEntitiesLoading}
										entityId={opportunityParticipant.preferedFutureFunctionId}
										entity={opportunityParticipant.preferedFutureFunction}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(undefined, "preferedFutureFunction", "preferedFutureFunctionId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"employmentUnitEnrollDate"}
										name="employmentUnitEnrollDate"
										value={getDate(opportunityParticipant.employmentUnitEnrollDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"employmentUnitLeaveDate"}
										name="employmentUnitLeaveDate"
										value={getDate(opportunityParticipant.employmentUnitLeaveDate)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										format={"yyyy-MM-dd"}
										onChange={onChange}
										disabled={readonly}
									/>
								</div>
							</div>
						</div>
						<div className="k-form-field">
							<div>{t("putNameOnInvoice")}</div>
							<YesNoSwitch name="putNameOnInvoice" checked={opportunityParticipant.putNameOnInvoice} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</Tab>
			</TabPanel>
		</EntityEditor>
	);
};

export default OpportunityParticipantEditor;
