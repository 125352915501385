import React from "react";

import { StandardButton } from "@selas/ui-components";
import map from "lodash/map";

import "./style.scss";

interface IProps {
	reactKey: string;
	currentHeat: number;
	hoveringHeat?: {
		heat: number;
		setheat: (newHeat: number) => void;
	};
	changeHeat?: (newHeat: number) => void;
	readonly: boolean;
}

const HeatRow: React.FC<IProps> = (props: IProps) => {
	return (
		<div className="d-flex flex-row heatRow">
			{map([1, 2, 3], (heat: number) => (
				<i
					key={`${props.reactKey}_{${heat}}`}
					className={`las la-fire-alt${!props.readonly && props.changeHeat ? " heatable" : ""}${!props.readonly && props.hoveringHeat?.heat >= heat ? " newHeated" : ""}${
						props.currentHeat >= heat ? " heated" : ""
					}`}
					onMouseOver={!props.readonly && props.hoveringHeat ? () => props.hoveringHeat.setheat(heat) : undefined}
					onMouseLeave={!props.readonly && props.hoveringHeat ? () => props.hoveringHeat.setheat(null) : undefined}
					onClick={!props.readonly && props.changeHeat ? () => props.changeHeat(heat) : undefined}
				/>
			))}
			{!props.readonly && props.changeHeat && <StandardButton look="clear" onClick={() => props.changeHeat(null)} iconClass="las la-times" />}
		</div>
	);
};

export default HeatRow;
