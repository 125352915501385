import { Reducer } from "react";

import { IAction } from "@selas/models";
import { AxiosError, AxiosResponse } from "axios";

import { ICompanyWebState, initialCompanyWebState } from "..";
import Endpoint from "../../services/api/endpoint";

const companyWebReducer: Reducer<ICompanyWebState, IAction> = (state: ICompanyWebState = initialCompanyWebState, action: IAction): ICompanyWebState => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.CompanyWeb:
			state = {
				...state,
				isDownloading: true
			};
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.CompanyWeb: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				downloadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_GET_" + Endpoint.CompanyWeb: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };

			state = {
				...state,
				data: payload.response.data
			};

			break;
		}
		case "REQUEST_END_GET_" + Endpoint.CompanyWeb:
			state = {
				...state,
				isDownloading: false
			};
			break;
	}

	return state;
};

export default companyWebReducer;
