import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { customCell, dateCell, GridPanel } from "@selas/ui-components";
import { useTranslation } from "react-i18next";

import style from "../../../../../assets/editor.module.scss";
import Endpoint from "../../../../../services/api/endpoint";
import { hasPermission } from "../../../../../services/authentication";
import { Permission } from "../../../../../utils/enums";
import NoteEditor from "../../../followup/note";

interface IProps {
	sessionId: number;
	readOnly: boolean;
}

const SessionNotes: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.SessionNotesList}
			listUrlArguments={{ sessionId: props.sessionId }}
			endpoint={Endpoint.SessionNotes}
			addScreen={{
				screen: NoteEditor,
				isAllowed: !props.readOnly && hasPermission(Permission.SessionsUpdate),
				extraProps: { sessionId: props.sessionId }
			}}
			editScreen={{
				screen: NoteEditor,
				isAllowed: !props.readOnly && hasPermission(Permission.SessionsUpdate),
				extraProps: { sessionId: props.sessionId }
			}}
			delete={{ isAllowed: !props.readOnly && hasPermission(Permission.SessionsUpdate), urlArguments: { sessionId: props.sessionId } }}
			localLoader
		>
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="createdDate" title={t("createdDate")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="content" title={t("note")} />
		</GridPanel>
	);
};

export default SessionNotes;
