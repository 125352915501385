import React, { CSSProperties } from "react";

interface IProps {
	clickHandler: () => void;
	children: React.ReactNode;
	iconClass: string;
	iconStyle: CSSProperties;
	spanStyle: CSSProperties;
}

const BackOfficeButton: React.FC<IProps> = (props: IProps) => {
	return (
		<div className="col">
			<div className="largeButton d-flex flex-row align-items-center justify-content-around" onClick={props.clickHandler}>
				<span style={props.spanStyle}>{props.children}</span>
				<i className={props.iconClass} style={props.iconStyle} />
			</div>
		</div>
	);
};

export default BackOfficeButton;
