import React, { SetStateAction, useCallback, useEffect, useReducer, useState } from "react";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBoxCloseEvent, MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { RadioButtonProps, RadioGroup, RadioGroupChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, getInitialState } from "@selas/state-management";
import { Confirm, Form, handleChange, Loader, notify, StandardButton, SubmitButton } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import some from "lodash/some";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { initialBackOfficeTaskRequestState, initialOpportunityState, initialProjectState, initialSessionState } from "../../../../state";
import { backOfficeTaskRequestReducer, groupReducer, opportunityParticipantReducer, opportunityReducer, projectParticipantReducer, projectReducer, sessionReducer } from "../../../../state/reducers";
import { emailRegex } from "../../../../utils";
import { CommunicationLanguage } from "../../../../utils/enums";
import {
	IGroupProps,
	IOpportunityProps,
	IProjectParticipantProps,
	IProjectProps,
	ISessionProps,
	isGroupProps,
	isOpportunityProps,
	isProjectParticipantProps,
	isProjectProps,
	isSessionProps
} from "../../../../utils/props";
import {
	IBackOfficeTaskGroupRequest,
	IBackOfficeTaskOpportunityRequest,
	IBackOfficeTaskProjectParticipantRequest,
	IBackOfficeTaskProjectRequest,
	IBackOfficeTaskRequest,
	IBackOfficeTaskSessionRequest,
	IGroup,
	IOpportunity,
	IOpportunityParticipant,
	IProject,
	IProjectParticipant
} from "../../../../utils/types/models";
import CreateDocument, { IDocumentData, isDocumentData } from "./createDocument";
import SendEmail, { IEmailData } from "./sendEmail";

import "./requestBackOfficeTask.scss";

interface IProps {
	renderKey: string;
	taskId: number;
	onClose: (cancelled: boolean) => void;
}

type Props = IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps;

export type RequestBackOfficeTaskProps = IProps & Props;

export interface ITaskData {
	recipientType: "task" | "opportunity" | "participant" | "allParticipants" | "selectionOfParticipants";
	recievers: IProjectParticipant[];
	documentData: IDocumentData;
	emailData: IEmailData;
}

const initialTaskData: ITaskData = {
	recipientType: "task",
	recievers: [],
	documentData: {
		create: false,
		documentTemplateId: 0,
		requestedLanguage: CommunicationLanguage.Dutch
	},
	emailData: {
		send: false,
		customRecipient: false,
		to: [],
		opportunityAttachments: [],
		taskAttachments: [],
		projectParticipantAttachments: [],
		emailTemplateId: 0,
		selectedLanguage: CommunicationLanguage.Dutch,
		subjectNl: "",
		bodyNl: "",
		subjectFr: "",
		bodyFr: "",
		subjectDe: "",
		bodyDe: "",
		subjectEn: "",
		bodyEn: "",
		attachments: []
	}
};

function checkEmailLanguge(subject: string, body: string, language: CommunicationLanguage, errorMessages: string[]): void {
	if (isNullOrEmpty(subject)) {
		errorMessages.push(i18next.t("missingLanguage", { language: i18next.t(language), field: i18next.t("subject") }));
	}
	if (isNullOrEmpty(body)) {
		errorMessages.push(i18next.t("missingLanguage", { language: i18next.t(language), field: i18next.t("body") }));
	}
}

function checkDocumentLanguage(documentData: IDocumentData, language: CommunicationLanguage, errorMessages: string[], recipientType: string): void {
	let outputDocumentName: string;
	switch (language) {
		case CommunicationLanguage.Dutch:
			outputDocumentName = documentData.documentTemplate.documentNameNl;
			break;
		case CommunicationLanguage.French:
			outputDocumentName = documentData.documentTemplate.documentNameFr;
			break;
		case CommunicationLanguage.German:
			outputDocumentName = documentData.documentTemplate.documentNameDe;
			break;
		case CommunicationLanguage.English:
			outputDocumentName = documentData.documentTemplate.documentNameEn;
			break;
	}
	if (isNullOrEmpty(outputDocumentName)) {
		if (recipientType === "task" || recipientType === "opportunity") {
			errorMessages.push(i18next.t("missingLanguageSingle", { language: i18next.t(language), field: i18next.t("documentName") }));
		} else {
			errorMessages.push(i18next.t("missingLanguage", { language: i18next.t(language), field: i18next.t("documentName") }));
		}
	}
}

function checkEmailSend(taskData: ITaskData, projectParticipants: IProjectParticipant[], props: RequestBackOfficeTaskProps): string[] {
	const errorMessages: string[] = [];

	if (taskData.recipientType === "task" || taskData.recipientType === "opportunity" || taskData.emailData.customRecipient) {
		if (taskData.emailData.to.length <= 0) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("to").toLowerCase() }));
		}
		if (some(taskData.emailData.to, (email: string) => !email.match(emailRegex))) {
			errorMessages.push(i18next.t("fill_in_correct_format", { field: i18next.t("to").toLowerCase(), example: "email@test.com" }));
		}
	}
	if (taskData.emailData.cc && taskData.emailData.cc.length > 0 && some(taskData.emailData.cc, (email: string) => !email.match(emailRegex))) {
		errorMessages.push(i18next.t("fill_in_correct_format", { field: i18next.t("cc").toLowerCase(), example: "email@test.com" }));
	}
	if (taskData.emailData.bcc && taskData.emailData.bcc.length > 0 && some(taskData.emailData.bcc, (email: string) => !email.match(emailRegex))) {
		errorMessages.push(i18next.t("fill_in_correct_format", { field: i18next.t("bcc").toLowerCase(), example: "email@test.com" }));
	}
	if (taskData.recipientType === "task" || taskData.recipientType === "opportunity" || taskData.emailData.customRecipient) {
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.Dutch && isNullOrEmpty(taskData.emailData.subjectNl)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("subject").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.Dutch && isNullOrEmpty(taskData.emailData.bodyNl)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("body").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.French && isNullOrEmpty(taskData.emailData.subjectFr)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("subject").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.French && isNullOrEmpty(taskData.emailData.bodyFr)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("body").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.German && isNullOrEmpty(taskData.emailData.subjectDe)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("subject").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.German && isNullOrEmpty(taskData.emailData.bodyDe)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("body").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.English && isNullOrEmpty(taskData.emailData.subjectEn)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("subject").toLowerCase() }));
		}
		if (taskData.emailData.selectedLanguage === CommunicationLanguage.English && isNullOrEmpty(taskData.emailData.bodyEn)) {
			errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("body").toLowerCase() }));
		}
	} else {
		const recipients: IProjectParticipant[] = taskData.recipientType === "allParticipants" || taskData.recipientType === "participant" ? projectParticipants : taskData.recievers;

		if (isEmpty(recipients) && recipients.length <= 0) {
			if (taskData.recipientType === "selectionOfParticipants") {
				errorMessages.push(i18next.t("selectRecipients"));
			} else {
				errorMessages.push(i18next.t("noRecipients"));
			}
		}
		each(uniq(flatten(map(recipients, "participant.communicationLanguage"))), (language: CommunicationLanguage) => {
			let subject: string = "";
			let body: string = "";
			if (language === CommunicationLanguage.Dutch) {
				subject = taskData.emailData.subjectNl;
				body = taskData.emailData.bodyNl;
			} else if (language === CommunicationLanguage.French) {
				subject = taskData.emailData.subjectFr;
				body = taskData.emailData.bodyFr;
			} else if (language === CommunicationLanguage.German) {
				subject = taskData.emailData.subjectDe;
				body = taskData.emailData.bodyDe;
			} else if (language === CommunicationLanguage.English) {
				subject = taskData.emailData.subjectEn;
				body = taskData.emailData.bodyEn;
			}
			checkEmailLanguge(subject, body, language, errorMessages);
		});

		if (taskData.recipientType === "participant" && isNullOrEmpty(projectParticipants[0].participant.emailAddress)) {
			errorMessages.push(i18next.t("recipientEmailNotAvailable"));
		}
	}

	if (!taskData.documentData.create) {
		let totalAttachmentSize: number = 0;
		totalAttachmentSize += sumBy(taskData.emailData.taskAttachments, "content.size");
		if (isOpportunityProps(props)) {
			totalAttachmentSize += sumBy(taskData.emailData.opportunityAttachments, "content.size");
		} else if (isProjectParticipantProps(props)) {
			totalAttachmentSize += sumBy(taskData.emailData.projectParticipantAttachments, "content.size");
		}

		if (totalAttachmentSize > 15 * 1024 * 1024) {
			errorMessages.push(i18next.t("attachmentsTooLarge"));
		}
	}
	return errorMessages;
}

function checkDocumentCreation(taskData: ITaskData, projectParticipants: IProjectParticipant[]): string[] {
	const errorMessages: string[] = [];
	if (taskData.documentData.documentTemplate === undefined) {
		errorMessages.push(i18next.t("fill_in_required_field", { field: i18next.t("document").toLowerCase() }));
		return errorMessages;
	}
	if (taskData.recipientType === "task" || taskData.recipientType === "opportunity") {
		checkDocumentLanguage(taskData.documentData, taskData.documentData.requestedLanguage, errorMessages, taskData.recipientType);
	} else {
		const recipients: IProjectParticipant[] =
			taskData.recipientType === "allParticipants" || taskData.recipientType === "selectionOfParticipants" || taskData.recipientType === "participant" ? projectParticipants : taskData.recievers;
		if (isEmpty(recipients) && recipients.length <= 0) {
			if (taskData.recipientType === "selectionOfParticipants") {
				errorMessages.push(i18next.t("selectRecipients"));
			} else {
				errorMessages.push(i18next.t("noRecipients"));
			}
		}
		each(uniq(flatten(map(recipients, "participant.communicationLanguage"))), (language: CommunicationLanguage) => {
			checkDocumentLanguage(taskData.documentData, language, errorMessages, taskData.recipientType);
		});
	}

	return errorMessages;
}

const RequestMailAndOrDocument: React.FC<RequestBackOfficeTaskProps> = (props: RequestBackOfficeTaskProps) => {
	const { t } = useTranslation();
	const { onClose: propsOnClose } = props;
	const [selectedTab, setSelectedTab] = useState(0);
	const [taskData, setTaskData] = useState<ITaskData>(initialTaskData);
	const [projectParticipants, setProjectParticipants] = useState<IProjectParticipant[]>([]);
	const [groupState, groupDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
	const [projectParticipantState, projectParticipantDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());
	const [opportunityParticipantState, opportunityParticipantDispatch] = useReducer(opportunityParticipantReducer, getInitialState<IOpportunityParticipant>());
	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const [opportunityState, opportunityDispatch] = useReducer(opportunityReducer, initialOpportunityState);
	const [backOfficeTaskRequestState, backOfficeTaskRequestDispatch] = useReducer(backOfficeTaskRequestReducer, initialBackOfficeTaskRequestState);
	const [askSaveChange, setAskSaveChange] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (isGroupProps(props)) {
			apiService.callApi(groupDispatch, Endpoint.Groups, "GET", { projectId: props.projectId, id: props.groupId });
		} else if (isSessionProps(props)) {
			apiService.callApi(sessionDispatch, Endpoint.Sessions, "GET", { projectId: props.projectId, id: props.sessionId });
		} else if (isProjectParticipantProps(props)) {
			apiService.callApi(projectParticipantDispatch, Endpoint.ProjectParticipants, "GET", { projectId: props.projectId, id: props.projectParticipantId });
		} else if (isProjectProps(props)) {
			apiService.callApi(projectParticipantDispatch, Endpoint.ProjectParticipants, "GET", { projectId: props.projectId });
			apiService.callApi(projectDispatch, Endpoint.Projects, "GET", { id: props.projectId });
		} else if (isOpportunityProps(props)) {
			apiService.callApi(opportunityDispatch, Endpoint.Opportunities, "GET", { id: props.opportunityId });
			apiService.callApi(opportunityParticipantDispatch, Endpoint.OpportunityParticipants, "GET", { opportunityId: props.opportunityId });
		}
	}, [apiService, props]);

	useEffect(() => {
		let newProjectParticipants: IProjectParticipant[] = [];
		if (groupState.entity) {
			newProjectParticipants = map(groupState.entity.projectParticipants, "projectParticipant");
		} else if (sessionState.entity) {
			newProjectParticipants = map(sessionState.entity.sessionParticipants, "projectParticipant");
		} else if (projectParticipantState.entity) {
			newProjectParticipants = [projectParticipantState.entity];
		} else if (projectParticipantState.entities) {
			newProjectParticipants = projectParticipantState.entities;
		}
		setProjectParticipants(newProjectParticipants);
	}, [groupState.entity, sessionState.entity, projectParticipantState.entity, projectParticipantState.entities]);

	const fillInEmails: (entity: IProject | IOpportunity) => void = useCallback((entity: IProject | IOpportunity) => {
		updateTaskData((oldTaskData: ITaskData) => {
			const newTaskData: ITaskData = cloneDeep(oldTaskData);
			if (!oldTaskData.emailData.to || oldTaskData.emailData.to.length <= 0) {
				if (entity?.contact?.email && !newTaskData.emailData.to.find((e: string) => e === entity?.contact?.email)) {
					newTaskData.emailData.to.push(entity?.contact?.email);
				}
				if (entity?.companyContact?.email && !newTaskData.emailData.to.find((e: string) => e === entity?.companyContact?.email)) {
					newTaskData.emailData.to.push(entity?.companyContact?.email);
				}
				if (entity?.partnerContact?.email && !newTaskData.emailData.to.find((e: string) => e === entity?.partnerContact?.email)) {
					newTaskData.emailData.to.push(entity?.partnerContact?.email);
				}
			}
			return newTaskData;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (opportunityState.entity?.companyContact?.email || opportunityState.entity?.partnerContact?.email || opportunityState.entity?.contact?.email) {
			fillInEmails(opportunityState.entity);
		}
	}, [opportunityState.entity, fillInEmails]);

	useEffect(() => {
		if (projectState.entity?.companyContact?.email || projectState.entity?.partnerContact?.email || projectState.entity?.contact?.email) {
			fillInEmails(projectState.entity);
		}
	}, [projectState.entity, fillInEmails]);

	useEffect(() => {
		if (!backOfficeTaskRequestState.isRequesting && backOfficeTaskRequestState.success) {
			propsOnClose(false);
			reduxDispatch(derender(props.renderKey));
		}
	}, [backOfficeTaskRequestState.isRequesting, backOfficeTaskRequestState.success, props.renderKey, propsOnClose, reduxDispatch]);

	function onChange(event: SwitchChangeEvent | MultiSelectChangeEvent | ComboBoxCloseEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		const newTaskData: ITaskData = handleChange(taskData, event);
		updateTaskData(newTaskData);
	}

	function onRadioGroupChange(event: RadioGroupChangeEvent): void {
		const newTaskData: ITaskData = cloneDeep(taskData);
		newTaskData.recipientType = event.value;
		if (newTaskData.recipientType === "opportunity") {
			newTaskData.emailData.send = false;
		}
		updateTaskData(newTaskData);
	}

	function onChangeChildData(data: IDocumentData | IEmailData): void {
		const newTaskData: ITaskData = cloneDeep(taskData);
		if (isDocumentData(data)) {
			newTaskData.documentData = data;
		} else {
			newTaskData.emailData = data;
		}
		updateTaskData(newTaskData);
	}

	function updateTaskData(newTaskData: SetStateAction<ITaskData>): void {
		setTaskData(newTaskData);
		setDataChanged(true);
	}

	function onClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
			propsOnClose(false);
			reduxDispatch(derender(props.renderKey));
		} else {
			propsOnClose(true);
			reduxDispatch(derender(props.renderKey));
		}
	}

	function create(): void {
		const errorMessages: string[] = [];
		if (taskData.emailData.send) {
			errorMessages.push(...checkEmailSend(taskData, projectParticipants, props));
		}
		if (taskData.documentData.create) {
			errorMessages.push(...checkDocumentCreation(taskData, projectParticipants));
		}

		if (errorMessages.length > 0) {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(errorMessages, (message: string, index: number) => (
							<React.Fragment key={"entityEditor_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		} else {
			const backOfficeTaskRequest: IBackOfficeTaskRequest = {
				taskId: props.taskId,
				attachToTask: taskData.recipientType === "task",
				simpleEmail: false,
				emailData: taskData.emailData.send
					? {
							cc: taskData.emailData.cc,
							bcc: taskData.emailData.bcc,
							subjectNl: taskData.emailData.subjectNl,
							bodyNl: taskData.emailData.bodyNl,
							subjectFr: taskData.emailData.subjectFr,
							bodyFr: taskData.emailData.bodyFr,
							subjectDe: taskData.emailData.subjectDe,
							bodyDe: taskData.emailData.bodyDe,
							subjectEn: taskData.emailData.subjectEn,
							bodyEn: taskData.emailData.bodyEn
					  }
					: undefined,
				documentData: taskData.documentData.create
					? {
							templateId: taskData.documentData.documentTemplateId
					  }
					: undefined
			};
			if (taskData.emailData.send) {
				backOfficeTaskRequest.emailData.attachments = [];
				if (taskData.emailData.taskAttachments.length > 0) {
					backOfficeTaskRequest.emailData.attachments.push(...map(taskData.emailData.taskAttachments, "content"));
				}
				if (isOpportunityProps(props) && taskData.emailData.opportunityAttachments.length > 0) {
					backOfficeTaskRequest.emailData.attachments.push(...map(taskData.emailData.opportunityAttachments, "content"));
				}
				if (isProjectParticipantProps(props) && taskData.emailData.projectParticipantAttachments.length > 0) {
					backOfficeTaskRequest.emailData.attachments.push(...map(taskData.emailData.projectParticipantAttachments, "content"));
				}
				if (taskData.emailData.attachments.length > 0) {
					backOfficeTaskRequest.emailData.attachments.push(...taskData.emailData.attachments);
				}
			}
			if (isOpportunityProps(props)) {
				((backOfficeTaskRequest as unknown) as IBackOfficeTaskOpportunityRequest).opportunityId = props.opportunityId;
				if (backOfficeTaskRequest.emailData) {
					backOfficeTaskRequest.emailData.to = taskData.emailData.to;
					backOfficeTaskRequest.emailData.selectedLanguage = taskData.emailData.selectedLanguage;
				}
				if (backOfficeTaskRequest.documentData) {
					backOfficeTaskRequest.documentData.requestedLanguage = taskData.documentData.requestedLanguage;
					backOfficeTaskRequest.documentData.participantId = taskData.documentData.participant !== undefined ? taskData.documentData.participant.id : null;
				}
			} else if (isProjectProps(props)) {
				((backOfficeTaskRequest as unknown) as IBackOfficeTaskProjectRequest).projectId = props.projectId;
				if (isGroupProps(props)) {
					((backOfficeTaskRequest as unknown) as IBackOfficeTaskGroupRequest).groupId = props.groupId;
				} else if (isSessionProps(props)) {
					((backOfficeTaskRequest as unknown) as IBackOfficeTaskSessionRequest).sessionId = props.sessionId;
				} else if (isProjectParticipantProps(props)) {
					if (taskData.recipientType === "participant") {
						((backOfficeTaskRequest as unknown) as IBackOfficeTaskProjectRequest).projectParticpantIds = map(projectParticipants, "id");
					} else {
						((backOfficeTaskRequest as unknown) as IBackOfficeTaskProjectParticipantRequest).projectParticipantId = props.projectParticipantId;
					}
				}
				if (taskData.recipientType === "allParticipants") {
					((backOfficeTaskRequest as unknown) as IBackOfficeTaskProjectRequest).projectParticpantIds = map(projectParticipants, "id");
				} else if (taskData.recipientType === "selectionOfParticipants") {
					((backOfficeTaskRequest as unknown) as IBackOfficeTaskProjectRequest).projectParticpantIds = map(taskData.recievers, "id");
				} else if (taskData.recipientType !== "participant") {
					if (backOfficeTaskRequest.emailData) {
						backOfficeTaskRequest.emailData.to = taskData.emailData.to;
						backOfficeTaskRequest.emailData.selectedLanguage = taskData.emailData.selectedLanguage;
					}
					if (backOfficeTaskRequest.documentData) {
						backOfficeTaskRequest.documentData.requestedLanguage = taskData.documentData.requestedLanguage;
					}
				}
				if (
					(taskData.recipientType === "allParticipants" || taskData.recipientType === "selectionOfParticipants" || taskData.recipientType === "participant") &&
					taskData.emailData.customRecipient
				) {
					backOfficeTaskRequest.emailData.to = taskData.emailData.to;
				}
			}

			if (backOfficeTaskRequest) {
				apiService.callApi(backOfficeTaskRequestDispatch, Endpoint.BackofficeTaskRequestEmailAndOrDocument, "POST", null, backOfficeTaskRequest);
			}
		}
	}

	const forData: RadioButtonProps[] = [
		{
			label: t("task"),
			value: "task"
		}
	];

	if (isOpportunityProps(props)) {
		forData.push({ label: t("opportunity"), value: "opportunity" });
	} else if (isProjectParticipantProps(props)) {
		forData.push({ label: t("participant"), value: "participant" });
	} else if (!isOpportunityProps(props)) {
		forData.push({ label: t("allParticipants"), value: "allParticipants" }, { label: t("selectionOfParticipants"), value: "selectionOfParticipants" });
	}

	return (
		<>
			{backOfficeTaskRequestState.isRequesting && <Loader />}
			<Form>
				<Dialog width="70%" title={`${t("sendEmail")} ${t("and")}/${t("or")} ${t("createDocument").toLowerCase()}`} onClose={onClose}>
					<div className="k-form">
						<div className="k-form-field">
							<div>{t("for")}</div>
							<RadioGroup name="recipientType" data={forData} value={taskData.recipientType} onChange={onRadioGroupChange} layout="horizontal" />
						</div>
						{taskData.recipientType === "selectionOfParticipants" && (
							<div className="k-form-field">
								<span>{t("participants")} *</span>
								<MultiSelect
									name="recievers"
									loading={groupState.isEntityLoading || sessionState.isEntityLoading || projectParticipantState.areEntitiesLoading}
									data={projectParticipants}
									value={taskData.recievers}
									dataItemKey="id"
									textField="participant.fullName"
									autoClose={false}
									onChange={onChange}
								/>
							</div>
						)}
						<TabStrip selected={selectedTab} onSelect={(event: TabStripSelectEventArguments) => setSelectedTab(event.selected)} className="k-form-field" animation={false}>
							<TabStripTab title={t("email")}>
								<SendEmail
									taskId={props.taskId}
									opportunityId={isOpportunityProps(props) ? props.opportunityId : undefined}
									projectId={isProjectParticipantProps(props) ? props.projectId : undefined}
									projectParticipantId={isProjectParticipantProps(props) ? props.projectParticipantId : undefined}
									recipientType={taskData.recipientType}
									emailData={taskData.emailData}
									createDocument={taskData.documentData.create}
									recievers={taskData.recipientType === "allParticipants" ? projectParticipants : taskData.recievers}
									onChange={onChangeChildData}
								/>
							</TabStripTab>
							<TabStripTab title={t("document")}>
								<CreateDocument
									recipientType={taskData.recipientType}
									documentData={taskData.documentData}
									onChange={onChangeChildData}
									opportunityParticipants={opportunityParticipantState.entities}
								/>
							</TabStripTab>
						</TabStrip>
					</div>
					<DialogActionsBar>
						<StandardButton onClick={onClose}>{t("cancel")}</StandardButton>
						<SubmitButton primary onClick={create}>
							{t("create")}
						</SubmitButton>
					</DialogActionsBar>
				</Dialog>
			</Form>
			{askSaveChange && (
				<Confirm title={t("pending_changes")} onConfirm={() => onClose()} onDecline={() => setAskSaveChange(false)}>
					{t("ask_close")}
				</Confirm>
			)}
		</>
	);
};

export default RequestMailAndOrDocument;
