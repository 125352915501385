import React, { useCallback, useEffect, useReducer, useState } from "react";

import { State } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, render } from "@selas/state-management";
import { commandCell, Confirm, customCell, dateCell, enumFilterCell, enumMultiSelectFilterCell, IRoutedTabProps, Loader, translatedCell } from "@selas/ui-components";
import { cellContentsFunction } from "@selas/ui-components/dist/gridpanel/customCells/gridCells";
import { getDate, newKey } from "@selas/utils";
import find from "lodash/find";
import forEach from "lodash/forEach";
import map from "lodash/map";
import remove from "lodash/remove";
import some from "lodash/some";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialInvoiceServiceState } from "../../../state";
import invoiceReducer from "../../../state/reducers/invoiceServiceReducer";
import { Color, Permission, ProjectParticipantStatus, ProjectServiceStatus, ProjectStatus } from "../../../utils/enums";
import { ICompany, IInvoiceServiceBase, IProject, IProjectParticipantService, IProjectService } from "../../../utils/types/models";
import CompanyEditor from "../../editors/customer/company";
import ExcelUploadDialog from "../../editors/excelUploadDialog";
import invoiceEditor from "../../editors/invoicing/fillInvoiceDetails";
import ProjectEditor from "../../editors/project";
import ProjectParticipantServiceEditor from "../../editors/project/projectParticipantService";
import ProjectServiceEditor from "../../editors/project/projectService";
import { colorCell } from "../../global/colorCell";

export function getHoursColor(props: GridCellProps): Color {
	let balance: number = 0;
	const usedIndividualHours: number = props.dataItem.usedIndividualHours ? props.dataItem.usedIndividualHours : 0;
	const usedCollectiveHours: number = props.dataItem.usedCollectiveHours ? props.dataItem.usedCollectiveHours : 0;

	if (props.field === "individualHours") {
		if (props.dataItem.individualHours === undefined) {
			return Color.Green;
		}
		balance = props.dataItem.individualHours - usedIndividualHours;
	} else if (props.field === "collectiveHours") {
		if (props.dataItem.collectiveHours === undefined) {
			return Color.Green;
		}
		balance = props.dataItem.collectiveHours - usedCollectiveHours;
	}
	if (balance > 0) {
		return Color.Green;
	} else if (balance === 0) {
		return Color.Orange;
	}
	return Color.Red;
}

export function addFieldInfo(getContent?: cellContentsFunction): React.FC<GridCellProps> {
	return (props: GridCellProps) => {
		const { t } = useTranslation();

		let totalHours: number = 0;
		let usedHours: number = 0;
		if (props.field === "individualHours") {
			totalHours = props.dataItem.individualHours ? props.dataItem.individualHours : 0;
			usedHours = props.dataItem.usedIndividualHours ? props.dataItem.usedIndividualHours : 0;
		} else if (props.field === "collectiveHours") {
			totalHours = props.dataItem.collectiveHours ? props.dataItem.collectiveHours : 0;
			usedHours = props.dataItem.usedCollectiveHours ? props.dataItem.usedCollectiveHours : 0;
		}
		const content: string = `${t("totalHours")}: ${totalHours} ${t("usedHours")}: ${usedHours}`;

		return (
			<>
				{getContent(props)}
				&nbsp;
				{content}
			</>
		);
	};
}

interface IProps {
	projectId: number;
	projectServicesUpdatedOrDeleted?: () => void;
}

const Services: React.FC<IRoutedTabProps & IProps> = (props: IProps) => {
	const { projectServicesUpdatedOrDeleted } = props;
	const { t } = useTranslation();
	const reduxDispatch: Dispatch = useDispatch();
	const [gridState, setGridState] = useState<State>({
		filter: {
			logic: "and",
			filters: [
				{
					field: "status",
					operator: "in",
					value: [ProjectServiceStatus.Forecast, ProjectServiceStatus.ToBeInvoiced]
				}
			]
		},
		skip: 0,
		take: 25
	});
	const [invoiceServiceState, invoiceServiceDispatch] = useReducer(invoiceReducer, initialInvoiceServiceState);
	const apiService: ApiCommunicator = useApiService();
	const [selectedRows, setSelectedRows] = useState<IInvoiceServiceBase[]>([]);
	const [entities, setEntities] = useState<IInvoiceServiceBase[]>([]);
	let _export: ExcelExport;

	const refreshGrid: () => void = useCallback(() => {
		if (props.projectId !== 0) {
			apiService.callApi(invoiceServiceDispatch, Endpoint.invoiceServicesList, "POST", { projectId: props.projectId }, gridState);
		}
	}, [apiService, gridState, props.projectId]);

	useEffect(() => {
		refreshGrid();
	}, [refreshGrid]);

	useEffect(() => {
		if ((!invoiceServiceState.isDeleting && invoiceServiceState.deleteSuccess) || (!invoiceServiceState.isUpdating && invoiceServiceState.isUpdated)) {
			refreshGrid();
			if (projectServicesUpdatedOrDeleted) {
				projectServicesUpdatedOrDeleted();
			}
		}
	}, [invoiceServiceState.isDeleting, invoiceServiceState.deleteSuccess, invoiceServiceState.isUpdating, invoiceServiceState.isUpdated, refreshGrid, projectServicesUpdatedOrDeleted]);

	useEffect(() => {
		setEntities(
			map(invoiceServiceState.list, (item: IInvoiceServiceBase) => {
				// @ts-ignore
				item.selected = some(selectedRows, { id: item.id });
				return item;
			})
		);
	}, [invoiceServiceState.list, selectedRows]);

	function deleteEntity(id: number): void {
		const confirmKey: string = newKey("deleteItem");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm"),
				children: t("confirm_content", { action: t("remove") }),
				onConfirm: () => {
					apiService.callApi(invoiceServiceDispatch, Endpoint.invoiceServices, "DELETE", { id });
					reduxDispatch(derender(confirmKey));
				},
				onDecline: () => reduxDispatch(derender(confirmKey))
			})
		);
	}

	function setStatusToToBeInvoiced(id: number): void {
		apiService.callApi(invoiceServiceDispatch, Endpoint.invoiceServicesSetStatus, "PUT", { id });
	}

	function showCompany(service: IInvoiceServiceBase): void {
		const showCustomerKey: string = newKey("showCustomer");
		reduxDispatch(
			render(showCustomerKey, CompanyEditor, {
				recordId: service.companyId,
				readonly: !hasPermission(Permission.CompaniesUpdate),
				actionButtonClicked: (close: boolean, record: ICompany) => {
					if (close) {
						reduxDispatch(derender(showCustomerKey));
					}
					if (record) {
						refreshGrid();
					}
				}
			})
		);
	}

	function showProject(service: IInvoiceServiceBase): void {
		const showProjectKey: string = newKey("showProject");
		reduxDispatch(
			render(showProjectKey, ProjectEditor, {
				recordId: service.projectId,
				readonly: !hasPermission(Permission.ProjectsUpdate),
				actionButtonClicked: (close: boolean, record: IProject) => {
					if (close) {
						reduxDispatch(derender(showProjectKey));
					}
					if (record) {
						refreshGrid();
					}
				}
			})
		);
	}

	function selectionChange(event: GridSelectionChangeEvent): void {
		const newState: IInvoiceServiceBase[] = [...selectedRows];
		const foundItem: IInvoiceServiceBase = find(newState, { id: event.dataItem.id });
		if (foundItem) {
			remove(newState, foundItem);
		} else {
			const selectedRow: IInvoiceServiceBase = { ...event.dataItem, invoiceDate: getDate(event.dataItem.invoiceDate) };
			newState.push(selectedRow);
		}
		setSelectedRows(newState);
	}

	function headerSelectionChange(event: GridHeaderSelectionChangeEvent): void {
		// @ts-ignore
		if (event.syntheticEvent.target.checked) {
			setSelectedRows([...entities]);
		} else {
			setSelectedRows([]);
		}
	}

	function calculateAmount(): number {
		return sumBy(selectedRows, "actualAmount");
	}

	function exportExcel(): void {
		forEach(selectedRows, (row: IInvoiceServiceBase) => (row.status = ProjectServiceStatus.ToBeInvoiced));
		_export.save(selectedRows);

		const ids: number[] = [];
		selectedRows.forEach((row: IInvoiceServiceBase) => ids.push(row.id));
		apiService.callApi(invoiceServiceDispatch, Endpoint.invoiceServicesSetStatusList, "PUT", undefined, ids);
		setSelectedRows([]);
	}

	function importExcel(): void {
		const key: string = newKey("importService");
		reduxDispatch(
			render(key, ExcelUploadDialog, {
				excelType: "importInvoice",
				onClose: () => {
					reduxDispatch(derender(key));
					refreshGrid();
				}
			})
		);
	}

	function fillInvoiceDetails(): void {
		const key: string = newKey("invoiceDetails");
		reduxDispatch(
			render(key, invoiceEditor, {
				onClose: () => {
					reduxDispatch(derender(key));
				},
				onSave: (invoiceNumber: string, invoiceDate: Date) => {
					apiService.callApi(invoiceServiceDispatch, Endpoint.invoiceServicesSetInvoiceDetails, "PUT", null, {
						invoiceServiceIds: map(selectedRows, "id"),
						invoiceNumber,
						invoiceDate
					});
					reduxDispatch(derender(key));
					setSelectedRows([]);
				}
			})
		);
	}

	function showService(service: IInvoiceServiceBase): void {
		const showServiceKey: string = newKey("showService");
		const rightEditor: boolean = service.serviceType === "projectService";
		reduxDispatch(
			rightEditor
				? render(showServiceKey, ProjectServiceEditor, {
						recordId: service.id,
						projectId: service.projectId,
						readonly: !hasPermission(Permission.InvoicingAccess),
						actionButtonClicked: (close: boolean, record: IProjectService) => {
							if (close) {
								reduxDispatch(derender(showServiceKey));
							}
							if (record) {
								refreshGrid();
							}
						}
				  })
				: render(showServiceKey, ProjectParticipantServiceEditor, {
						recordId: service.id,
						projectId: service.projectId,
						participantId: service.projectParticipantId,
						readonly: !hasPermission(Permission.InvoicingAccess),
						actionButtonClicked: (close: boolean, record: IProjectParticipantService) => {
							if (close) {
								reduxDispatch(derender(showServiceKey));
							}
							if (record) {
								refreshGrid();
							}
						}
				  })
		);
	}

	return (
		<>
			{(invoiceServiceState.isListLoading || invoiceServiceState.isDeleting || invoiceServiceState.isUpdating) && <Loader />}
			<ExcelExport data={selectedRows} ref={(exporter: ExcelExport) => (_export = exporter)}>
				<ExcelExportColumn field="id" title="ID" />
				<ExcelExportColumn field="code" title={t("serviceCode")} />
				<ExcelExportColumn field="projectStatus" title={t("status")} />
				<ExcelExportColumn field="companyName" title={t("company")} />
				<ExcelExportColumn field="companyVatNumber" title={t("vatNumber")} width={150} footerCellOptions={{ wrap: true, textAlign: "center" }} />
				<ExcelExportColumn field="projectName" title={t("projectName")} />
				<ExcelExportColumn field="projectResponsible" title={t("projectResponsible")} />
				<ExcelExportColumn field="serviceType" title={t("projectOrParticipant")} />
				<ExcelExportColumn field="invoicingMoment" title={t("invoicingMoment")} />
				<ExcelExportColumn field="projectParticipantStatus" title={t("participantStatus")} />
				<ExcelExportColumn field="projectParticipant" title={t("participant")} />
				<ExcelExportColumn field="status" title={t("serviceStatus")} />
				<ExcelExportColumn field="actualAmount" title={t("cost")} cellOptions={{ format: "€#,##0.00" }} />
				<ExcelExportColumn field="invoiceNumber" title={t("invoicingNumber")} width={150} />
				<ExcelExportColumn field="invoiceDate" title={t("invoicingDate")} width={150} cellOptions={{ format: "dd/MM/yyyy" }} />
			</ExcelExport>
			<Grid
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
				data={entities}
				total={invoiceServiceState.totalCount}
				{...gridState}
				filterable
				sortable
				style={{ height: "450px" }}
				selectedField="selected"
				onSelectionChange={selectionChange}
				onHeaderSelectionChange={headerSelectionChange}
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				resizable={true}
			>
				<GridToolbar>
					<div className="toolbarButtonContainer d-flex w-100 align-items-center">
						<button title="Export Excel" className="k-button k-primary" onClick={exportExcel}>
							{t("excelExport")}
						</button>
						<button title="Import Excel" className="k-button k-primary" onClick={importExcel}>
							{t("excelImport")}
						</button>
						<button title="fill invoice details" className="k-button k-primary" onClick={fillInvoiceDetails} disabled={selectedRows.length === 0}>
							{t("invoicingDetails")}
						</button>
						<div className="flex-grow-1" />
						<div>
							<p style={{ fontSize: "160%" }}>
								Lijnen geselecteerd: {selectedRows.length} &emsp; Totaalbedrag: € {calculateAmount()}{" "}
							</p>
						</div>
						<div className="flex-grow-1" />
						<i className="refreshButton las la-sync" onClick={refreshGrid} />
					</div>
				</GridToolbar>
				<GridColumn field="selected" title=" " width="60px" filterable={false} />
				<GridColumn
					title={t("actions")}
					width="120px"
					filterable={false}
					editable={false}
					sortable={false}
					groupable={false}
					headerClassName="notSortable"
					cell={customCell(
						commandCell([
							{
								iconClass: "las la-receipt",
								tooltip: t("changeStatusToInvoice"),
								idAction: setStatusToToBeInvoiced,
								showCommand: (dataItem: IInvoiceServiceBase) => dataItem.status === ProjectServiceStatus.Forecast && hasPermission(Permission.ProjectsUpdate)
							},
							{
								iconClass: "las la-project-diagram",
								tooltip: t("projectShow"),
								recordAction: showProject,
								showCommand: () => hasPermission(Permission.ProjectsRead)
							},
							{
								iconClass: "las la-building",
								tooltip: t("customerShow"),
								recordAction: showCompany,
								showCommand: (dataItem: IInvoiceServiceBase) => dataItem.companyId !== undefined && hasPermission(Permission.CompaniesRead)
							},
							{
								tooltip: t("serviceShow"),
								iconClass: "las la-cogs",
								recordAction: showService
							},
							{
								tooltip: t("remove"),
								iconClass: "las la-times",
								idAction: deleteEntity
							}
						])
					)}
				/>
				<GridColumn field="companyName" title={t("company")} width="200px" />
				<GridColumn field="companyVatNumber" title={t("vatNumber")} width="200px" />
				<GridColumn field="code" title={t("serviceCode")} width="150px" />
				<GridColumn field="actualAmount" title={t("cost")} filter="numeric" format="{0:n2}" width="150px" />
				<GridColumn field="projectParticipant" title={t("participant")} width="150px" />
				<GridColumn field="individualHours" title={t("individualHours")} width="200px" cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} />
				<GridColumn field="collectiveHours" title={t("collectiveHours")} width="200px" cell={customCell(addFieldInfo(colorCell(false, translatedCell(), getHoursColor)))} />
				<GridColumn field="invoiceNumber" title={t("invoicingNumber")} width="150px" filter="numeric" />
				<GridColumn field="invoiceDate" title={t("invoicingDate")} cell={customCell(dateCell("yyyy-MM-dd"))} width="200px" filter="date" />
				<GridColumn field="projectParticipantResponsible" title={t("projectParticipantResponsible")} width="200px" />
				<GridColumn field="projectStatus" title={t("projectStatus")} filterCell={enumMultiSelectFilterCell(ProjectStatus)} width="200px" cell={customCell(translatedCell())} />
				<GridColumn field="projectName" title={t("projectName")} width="200px" />
				<GridColumn field="projectResponsible" title={t("projectResponsible")} width="200px" />
				<GridColumn field="serviceType" title={t("projectOrParticipant")} width="150px" cell={customCell(translatedCell())} />
				<GridColumn field="invoicingMoment" title={t("invoicingMoment")} width="200px" />
				<GridColumn field="projectParticipantStatus" title={t("participantStatus")} filterCell={enumFilterCell(ProjectParticipantStatus)} width="150px" cell={customCell(translatedCell())} />
				<GridColumn field="status" title={t("serviceStatus")} width="200px" filterCell={enumMultiSelectFilterCell(ProjectServiceStatus)} cell={customCell(translatedCell())} />
			</Grid>
		</>
	);
};

export default Services;
