import React from "react";

import { Card, CardBody } from "@progress/kendo-react-layout";
import cloneDeep from "lodash/cloneDeep";
import { DropTargetMonitor, useDrop } from "react-dnd";

import { IGroup } from "../../../../../utils/types/models";
import { DragItem } from "./participantCard";

import style from "./style.module.scss";

interface GroupCardProps {
	group: IGroup;
	onDrop: (newGroup: IGroup) => void;
}

export const GroupCard: React.FC<GroupCardProps> = (props: GroupCardProps) => {
	const [{ isOver }, drop] = useDrop({
		accept: "PARTICIPANT",
		drop(item: DragItem): void {
			const groupRecord: IGroup = cloneDeep(props.group);
			groupRecord.projectParticipants.push({ projectParticipantId: item.participant.id, groupId: groupRecord.id });
			props.onDrop(groupRecord);
		},
		collect: (monitor: DropTargetMonitor) => ({
			isOver: monitor.isOver()
		})
	});

	return (
		<div key={"card_group_" + props.group.id} ref={drop}>
			<Card className={style.group} style={{ backgroundColor: isOver ? "#ffdccc" : "" }}>
				<CardBody style={{ padding: "10px" }}>
					<div className="row">
						<div className="col" style={{ display: "flex", justifyContent: "flex-start" }}>
							<pre style={{ fontWeight: "bold" }} className={style.multiline}>
								{props.group.name}
							</pre>
						</div>
					</div>
					<pre className={style.multiline}>{props.group.description}</pre>
				</CardBody>
			</Card>
		</div>
	);
};
