import React, { ReactElement, useState } from "react";

import { DateRangePicker, DateRangePickerChangeEvent, SelectionRange } from "@progress/kendo-react-dateinputs";
import type { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import getDateRangeFilterCellCalendar from "./getDateRangeFilterCellCalendar";

import styles from "./dateRangeFilterCell.module.scss";

function dateRangeToString(range: SelectionRange): string {
	let result: string = "";
	if (!range.start) {
		result += "...";
	} else {
		result += dayjs(range.start).format("YYYY-MM-DD");
	}
	result += " - ";

	if (!range.end) {
		result += "...";
	} else {
		result += dayjs(range.end).format("YYYY-MM-DD");
	}

	return result;
}

function DateRangeFilterCell({ onChange, value }: GridFilterCellProps): ReactElement {
	const { t } = useTranslation();
	const [showDateRangePicker, setShowDateRangePicker] = useState(false);
	const inputRef = React.useRef<Input>(null);

	function handleChange(e: DateRangePickerChangeEvent): void {
		e.syntheticEvent.persist();
		const start: Date = e.value && e.value.start ? dayjs(e.value.start).startOf("day").toDate() : null;
		const end: Date = e.value && e.value.end ? dayjs(e.value.end).endOf("day").toDate() : null;
		onChange({
			value: [start, end],
			operator: "dateRange",
			syntheticEvent: e.syntheticEvent
		});
		setShowDateRangePicker(false);
	}

	function clearFilter(): void {
		onChange({
			value: "",
			operator: "",
			syntheticEvent: undefined
		});
		setShowDateRangePicker(false);
	}

	const selectionRange: SelectionRange = value ? { start: value[0], end: value[1] } : { start: null, end: null };
	const textualValue: string = value ? dateRangeToString(selectionRange) : "";

	return (
		<div className="k-filtercell">
			<div className="k-filtercell-wrapper">
				<DateRangePicker
					className="k-display-none"
					show={showDateRangePicker}
					value={selectionRange}
					onChange={handleChange}
					calendar={getDateRangeFilterCellCalendar(() => setShowDateRangePicker(false))}
					popupSettings={{ anchor: inputRef.current?.element }}
				/>
				<Input ref={inputRef} value={textualValue} title={textualValue} className={styles.input} readOnly onClick={() => setShowDateRangePicker(true)} />
				<div className="k-filtercell-operator">
					<button
						className="k-button k-button-icon k-clear-button-visible k-filtercell-operator"
						title={t("grid.filterClearButton")}
						type="button"
						disabled={!value}
						onClick={() => clearFilter()}
					>
						<span className="k-icon k-i-filter-clear" />
					</button>
				</div>
			</div>
		</div>
	);
}

export default DateRangeFilterCell;
