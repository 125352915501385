import React, { Dispatch, useEffect, useReducer } from "react";

import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction } from "@selas/models";
import { getInitialState, hideLoader, showLoader } from "@selas/state-management";
import { StandardButton } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import Endpoint from "../../services/api/endpoint";
import { opportunityParticipantReducer, projectParticipantReducer } from "../../state/reducers";
import { IApplicationState } from "../../store";
import { OpportunityParticipantStatus, ProjectParticipantStatus, ProjectParticipantStatute } from "../../utils/enums";
import { IOpportunityProps, IProjectProps, isOpportunityProps } from "../../utils/props";
import { IContact, IOpportunityParticipant, IParticipant, IProjectParticipant, IUser } from "../../utils/types/models";

interface IProps {
	contact: IContact;
	onContactCopied: () => void;
}

const CopyFromContactButton: React.FC<IProps & (IOpportunityProps | IProjectProps)> = (props: IProps & (IOpportunityProps | IProjectProps)) => {
	const { onContactCopied } = props;
	const { t } = useTranslation();
	const [opportunityParticipanState, opportunityParticipanDispatch] = useReducer(opportunityParticipantReducer, getInitialState<IOpportunityParticipant>());
	const [projectParticipantState, projectParticipantDispatch] = useReducer(projectParticipantReducer, getInitialState<IProjectParticipant>());
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const apiService: ApiCommunicator = useApiService();
	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (opportunityParticipanState.isAdding || projectParticipantState.isAdding) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
		return () => reduxDispatch(hideLoader());
	}, [opportunityParticipanState.isAdding, projectParticipantState.isAdding, reduxDispatch]);

	useEffect(() => {
		if (opportunityParticipanState.addedEntity || projectParticipantState.addedEntity) {
			onContactCopied();
		}
	}, [opportunityParticipanState.addedEntity, projectParticipantState.addedEntity, onContactCopied]);

	function createParticipant(): void {
		let projectOrOpportunityParticipant: IOpportunityParticipant | IProjectParticipant;
		let urlParameters: Record<string, unknown>;
		let dispatch: Dispatch<IAction>;
		let endpoint: Endpoint;
		const { firstName, lastName, fullName, companyId, jobFunctionId, address, dateOfBirth, genderId, language, phoneNumber, mobilePhoneNumber, email } = props.contact;
		const participant: IParticipant = {
			id: 0,
			nationalRegistryNumber: "",
			firstName,
			lastName,
			fullName,
			address,
			phoneNumber,
			mobilePhoneNumber,
			emailAddress: email,
			dateOfBirth,
			genderId,
			communicationLanguage: language,
			knownLanguages: [],
			active: true,
			isInfoAndRefferalEmailAllowed: true
		};
		if (isOpportunityProps(props)) {
			dispatch = opportunityParticipanDispatch;
			endpoint = Endpoint.OpportunityParticipants;
			urlParameters = { opportunityId: props.opportunityId, makeContact: true };
			projectOrOpportunityParticipant = {
				id: 0,
				participantId: 0,
				participant,
				opportunityId: props.opportunityId,
				companyId,
				lastFunctionId: jobFunctionId,
				status: OpportunityParticipantStatus.New,
				sectors: [],
				putNameOnInvoice: false
			};
		} else {
			dispatch = projectParticipantDispatch;
			endpoint = Endpoint.ProjectParticipants;
			urlParameters = { projectId: props.projectId, makeContact: true };
			projectOrOpportunityParticipant = {
				id: 0,
				participantId: 0,
				participant,
				responsibleUserId: currentUser.id,
				projectId: props.projectId,
				companyId,
				lastFunctionId: jobFunctionId,
				status: ProjectParticipantStatus.New,
				sectors: [],
				putNameOnInvoice: false,
				contractSent: false,
				contractSignedReceived: false,
				contractSentToGovernment: false,
				outplacementCertificate: false,
				reclamation: false,
				statute: ProjectParticipantStatute.Servant,
				projectServices: [],
				notes: [],
				emails: [],
				attachments: [],
				accessToTraP: false
			};
		}

		apiService.callApi(dispatch, endpoint, "POST", urlParameters, projectOrOpportunityParticipant);
	}

	return (
		<StandardButton primary onClick={createParticipant}>
			{t("createFromContact")}
		</StandardButton>
	);
};

export default CopyFromContactButton;
