import React, { ReactElement, useRef } from "react";

import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { derender, render } from "@selas/state-management";
import { customCell, dateCell, enumFilterCell, GridPanel, IGridPanelRef, StandardButton, translatedCell } from "@selas/ui-components";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { Color, Permission, ProjectServiceStatus } from "../../../../utils/enums";
import { isGridCellProps } from "../../../../utils/props";
import { colorCell, colorCellClassName } from "../../../global/colorCell";
import { statusDropdownItemRender } from "../../../global/statusDropdownItemRender";
import ImportFromInvoicngGroupDialogue from "../projectParticipant/importFromInvoicingGroup";
import ProjectServiceEditor from "../projectService";

import style from "../../../../assets/editor.module.scss";

interface IProps {
	projectId: number;
}

export function getProjectServiceStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case ProjectServiceStatus.Forecast:
		case ProjectServiceStatus.Invoiced:
			return Color.Green;
		case ProjectServiceStatus.ToBeInvoiced:
			return Color.Orange;
		case ProjectServiceStatus.DoNotInvoice:
			return Color.Red;
	}
}

export function renderProjectServiceStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
	return statusDropdownItemRender(li, itemProps, getProjectServiceStatusColor);
}

const ProjectServices: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const reduxDispatch: Dispatch = useDispatch();

	function importFromInvoicingGroup(): void {
		const importKey: string = newKey("import");
		reduxDispatch(
			render(importKey, ImportFromInvoicngGroupDialogue, {
				projectId: props.projectId,
				onClose: (refresh: boolean) => {
					if (refresh) {
						ref.current.refresh();
					}
					reduxDispatch(derender(importKey));
				}
			})
		);
	}

	let toolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.ProjectsUpdate)) {
		toolbarButtons = [
			<StandardButton key="importFromInvoicingGroupButton" onClick={importFromInvoicingGroup} primary>
				{t("importFromInvoicingGroup")}
			</StandardButton>
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.ProjectServicesList}
			listUrlArguments={{ projectId: props.projectId }}
			endpoint={Endpoint.ProjectServices}
			addScreen={{ screen: ProjectServiceEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			editScreen={{ screen: ProjectServiceEditor, isAllowed: hasPermission(Permission.ProjectsUpdate), extraProps: { projectId: props.projectId } }}
			delete={{ urlArguments: { projectId: props.projectId }, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			localLoader
			extraToolbarButtons={toolbarButtons}
			ref={ref}
		>
			<GridColumn
				field="status"
				title={t("projectServiceStatus")}
				cell={customCell(colorCell(true, translatedCell(), getProjectServiceStatusColor), colorCellClassName)}
				filterCell={enumFilterCell(ProjectServiceStatus)}
			/>
			<GridColumn field="code" title={t("serviceCode")} />
			<GridColumn field="type.name" title={t("serviceType")} />
			<GridColumn field="shortDescription" title={t("shortDescription")} />
			<GridColumn field="amount" title={t("amount")} filter="numeric" />
			<GridColumn field="invoiceNumber" title={t("invoicingNumber")} />
			<GridColumn field="invoiceDate" title={t("invoicingDate")} cell={customCell(dateCell("yyyy-MM-dd"))} />
		</GridPanel>
	);
};

export default ProjectServices;
