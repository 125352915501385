import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { render } from "@selas/state-management";
import { newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import { customCell, dateCell, GridPanel, StandardButton } from "@selas/ui-components";
import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { Permission } from "../../../../utils/enums";
import { IOpportunityProps } from "../../../../utils/props";
import RequestMail from "../../simpleEmailSend/requestMail";

import style from "../../../../assets/editor.module.scss";
import EmailEditor from "../../followup/email";

interface IProps {
	opportunityId: number;
}

const OpportunityEmails: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const reduxDispatch: ReduxDispatch = useDispatch();

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: IOpportunityProps = {
			opportunityId: props.opportunityId
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: () => {
					return;
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	let toolbarButtons: React.ReactElement[];
	if (hasPermission(Permission.OpportunitiesUpdate)) {
		toolbarButtons = [
			<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
				{t("sendEmail")}
			</StandardButton>
		];
	}

	return (
		<GridPanel
			className={style.tabGrid}
			listEndpoint={Endpoint.OpportunityEmailsList}
			listUrlArguments={{ opportunityId: props.opportunityId }}
			endpoint={Endpoint.OpportunityEmails}
			editScreen={{ screen: EmailEditor, isAllowed: true, extraProps: { opportunityId: props.opportunityId } }}
			delete={{ urlArguments: { opportunityId: props.opportunityId }, isAllowed: hasPermission(Permission.OpportunitiesUpdate) }}
			localLoader
			extraToolbarButtons={toolbarButtons}
			sort={[{ field: "createdDate", dir: "desc" }]}
		>
			<GridColumn field="createdDate" title={t("createdOn")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" />
			<GridColumn field="creator.fullName" title={t("creator")} />
			<GridColumn field="to" title={t("to")} />
			<GridColumn field="cc" title={t("cc")} />
			<GridColumn field="bcc" title={t("bcc")} />
			<GridColumn field="subject" title={t("subject")} />
			<GridColumn field="body" title={t("body")} />
		</GridPanel>
	);
};

export default OpportunityEmails;
