import React, { SetStateAction, useEffect, useReducer, useRef, useState } from "react";

import { DateTimePicker, DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import apiService from "@selas/api-communication/dist/apiService";
import { getInitialState } from "@selas/state-management";
import { Confirm, EnumDropDownList, Form, handleChange, Loader, notify, StandardButton, SubmitButton } from "@selas/ui-components";
import { isNullOrEmpty } from "@selas/utils";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../../services/api/endpoint";
import { initialSessionState } from "../../../../state";
import { groupReducer, sessionReducer } from "../../../../state/reducers";
import { PlanInterval } from "../../../../utils/enums";
import { IGroup } from "../../../../utils/types/models";

interface IProps {
	onClose: () => void;
	taskId: number;
	groupId: number;
	projectId: number;
}

interface IPlanData {
	taskId: number;
	name: string;
	begin: Date;
	end: Date;
	interval: PlanInterval;
	repetition: number;
}

const PlanSessions: React.FC<IProps> = ({ onClose, taskId, groupId, projectId }: IProps) => {
	const { t } = useTranslation();
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [askSaveChange, setAskSaveChange] = useState<boolean>(false);
	const initialPlanData: IPlanData = {
		taskId,
		name: "",
		begin: null,
		end: null,
		interval: null,
		repetition: null
	};
	const [planData, setPlanData] = useState<IPlanData>(initialPlanData);
	const [groupState, groupDispatch] = useReducer(groupReducer, getInitialState<IGroup>());
	const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
	const firstField: React.MutableRefObject<Input> = useRef();
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (firstField) {
			firstField.current.focus();
		}
	}, [firstField]);

	useEffect(() => {
		apiService.callApi(groupDispatch, Endpoint.Groups, "GET", { projectId, id: groupId });
	}, [groupId, projectId]);

	useEffect(() => {
		if (groupState.entity) {
			updatePlanData((oldPlanData: IPlanData) => ({ ...oldPlanData, name: groupState.entity.name }));
		}
	}, [groupState.entity]);

	useEffect(() => {
		if (!sessionState.isPlanning && sessionState.planningSuccess) {
			onClose();
		}
	}, [onClose, sessionState.isPlanning, sessionState.planningSuccess]);

	function planSessions(): void {
		const messages: string[] = [];
		if (isNullOrEmpty(planData.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!planData.begin) {
			messages.push(t("fill_in_required_field", { field: t("begin").toLowerCase() }));
		}
		if (!planData.end) {
			messages.push(t("fill_in_required_field", { field: t("end").toLowerCase() }));
		}
		if (planData.begin && planData.end && planData.end < planData.begin) {
			messages.push(t("fill_in_correct_planned_hours", { begin: t("begin").toLowerCase(), end: t("end").toLowerCase() }));
		}
		if (!planData.interval) {
			messages.push(t("fill_in_required_field", { field: t("interval").toLowerCase() }));
		}
		if (!planData.repetition) {
			messages.push(t("fill_in_required_field", { field: t("repetition").toLowerCase() }));
		}

		if (messages.length > 0) {
			reduxDispatch(
				notify(
					t("information"),
					<>
						{map(messages, (message: string, index: number) => (
							<React.Fragment key={"plannSession_error_" + index}>
								{message}
								<br />
							</React.Fragment>
						))}
					</>,
					"success"
				)
			);
		} else {
			apiService.callApi(sessionDispatch, Endpoint.PlanSessions, "POST", { projectId, groupId }, planData);
		}
	}

	function onChange(event: InputChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | DateTimePickerChangeEvent | NumericTextBoxChangeEvent): void {
		updatePlanData(handleChange(planData, event));
	}

	function updatePlanData(newPlanData: SetStateAction<IPlanData>): void {
		setPlanData(newPlanData);
		setDataChanged(true);
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			onClose();
		}
	}

	return (
		<Form>
			<Dialog title={t("planSessions")} width="70%" onClose={() => handleClose()} autoFocus>
				{(groupState.isEntityLoading || sessionState.isPlanning) && <Loader />}
				<div className="k-form">
					<div className="k-form-field">
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("name")} *</span>
									<Input name="name" ref={firstField} value={planData.name} onChange={onChange} required />
								</label>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("begin")} *</span>
									<DateTimePicker name="begin" value={planData.begin} onChange={onChange} format={"yyyy-MM-dd HH:mm"} required />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("end")} *</span>
									<DateTimePicker name="end" value={planData.end} onChange={onChange} format={"yyyy-MM-dd HH:mm"} required />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("interval")} *</span>
									<EnumDropDownList name="interval" value={planData.interval} onChange={onChange} enum={PlanInterval} required />
								</div>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("repetition")}</span>
									<NumericTextBox name="repetition" value={planData.repetition} format="n0" min={1} onChange={onChange} required />
								</label>
							</div>
						</div>
					</div>
				</div>
				<DialogActionsBar>
					<StandardButton onClick={() => handleClose()}>{t("cancel")}</StandardButton>
					<SubmitButton primary onClick={() => planSessions()}>
						{t("plan")}
					</SubmitButton>
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && dataChanged && (
				<Confirm title={t("confirmLeave")} onConfirm={() => onClose()} onDecline={() => setAskSaveChange(false)}>
					{t("ask_close")}
				</Confirm>
			)}
		</Form>
	);
};

export default PlanSessions;
