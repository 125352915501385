import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";

export interface IOpportunityProps {
	opportunityId: number;
}

export interface IProjectProps {
	projectId: number;
}

export interface IGroupProps extends IProjectProps {
	groupId: number;
}

export interface ISessionProps extends IProjectProps {
	sessionId: number;
	outlookMeetingId: string;
}

export interface ISessionTemplateProps {
	sessionTemplateId: number;
}

export interface IProjectParticipantProps extends IProjectProps {
	projectParticipantId: number;
}

export function isOpportunityProps(
	toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps
): toBeDetermined is IOpportunityProps {
	return (toBeDetermined as IOpportunityProps).opportunityId !== undefined;
}

export function isProjectProps(toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps): toBeDetermined is IProjectProps {
	return (toBeDetermined as IProjectProps).projectId !== undefined;
}

export function isGroupProps(toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps): toBeDetermined is IGroupProps {
	return (toBeDetermined as IGroupProps).groupId !== undefined;
}

export function isSessionProps(toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps): toBeDetermined is ISessionProps {
	return (toBeDetermined as ISessionProps).sessionId !== undefined;
}

export function isProjectParticipantProps(
	toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps
): toBeDetermined is IProjectParticipantProps {
	return (toBeDetermined as IProjectParticipantProps).projectParticipantId !== undefined;
}

export function isGridCellProps(toBeDetermined: GridCellProps | ListItemProps): toBeDetermined is GridCellProps {
	return (toBeDetermined as GridCellProps).columnsCount !== undefined;
}

export function isSessionTemplateProps(
	toBeDetermined: IOpportunityProps | IProjectProps | IGroupProps | ISessionProps | IProjectParticipantProps | ISessionTemplateProps
): toBeDetermined is ISessionTemplateProps {
	return (toBeDetermined as ISessionTemplateProps).sessionTemplateId !== undefined;
}
