import React, { Dispatch, ReactElement, useEffect, useReducer, useRef, useState } from "react";

import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { IAction, IEntity } from "@selas/models";
import { getInitialState, render } from "@selas/state-management";
import {
	EntityEditor,
	EnumDropDownList,
	handleChange,
	IAddEntityScreenProps,
	IEditEntityScreenProps,
	ManageableField,
	SearchBox,
	setEntity,
	StandardButton,
	Tab,
	TabPanel,
	TextArea,
	YesNoSwitch
} from "@selas/ui-components";
import { getDate, isNullOrEmpty, newKey } from "@selas/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import { isNull } from "lodash";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialOpportunityState, initialUserState } from "../../../state";
import { companyContactReducer, companyReducer, contactReducer, opportunityReducer, partnerContactReducer, partnerReducer, serviceTypeReducer, userReducer } from "../../../state/reducers";
import opportunityOriginReducer from "../../../state/reducers/opportunityOriginReducer";
import { IApplicationState } from "../../../store";
import { Permission } from "../../../utils/enums";
import OpportunityStatus from "../../../utils/enums/opportunityStatus";
import { IOpportunityProps } from "../../../utils/props";
import { ICompany, IContact, IOpportunity, IOrigin, IPartner, IServiceType, IUser } from "../../../utils/types/models";
import { statusDropdownItemRender } from "../../global/statusDropdownItemRender";
import { getOpportunityStatusColor } from "../../pages/work/opportunities";
import ServiceTypeEditor from "../catalogue/serviceType";
import CompanyEditor from "../customer/company";
import ContactEditor from "../customer/contact";
import PartnerEditor from "../customer/partner";
import DatePickerForDateOnly from "../datepicker/datePicker";
import OpportunityOriginEditor from "../masterdata/opportunityOrigin";
import RequestMail from "../simpleEmailSend/requestMail";
import OpportunityAttachments from "./grids/opportunityAttachments";
import OpportunityEmails from "./grids/opportunityEmails";
import OpportunityFollowUpTasks from "./grids/opportunityFollowUpTasks";
import OpportunityNotes from "./grids/opportunityNotes";
import OpportunityParticipants from "./grids/opportunityParticipants";
import OpportunityServices from "./grids/opportunityServices";

const OpportunityEditor: React.FC<IAddEntityScreenProps<IOpportunity> | IEditEntityScreenProps<IOpportunity>> = (props: IAddEntityScreenProps<IOpportunity> | IEditEntityScreenProps<IOpportunity>) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const initialOpportunity: IOpportunity = {
		id: (props as IEditEntityScreenProps<IOpportunity>).recordId || 0,
		typeId: null,
		responsibleUserId: currentUser.id,
		responsibleUser: currentUser,
		title: "",
		description: "",
		status: OpportunityStatus.New,
		chanceOfSucces: 0,
		forecastCost: 0,
		partnerId: 0,
		cost: 0,
		publicTender: false
	};
	const [opportunity, setOpportunity] = useState<IOpportunity>(initialOpportunity);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [opportunityState, opportunityDispatch] = useReducer(opportunityReducer, initialOpportunityState);
	const [contactState, contactDispatch] = useReducer(contactReducer, getInitialState<IContact>());
	const [companyContactState, companyContactDispatch] = useReducer(companyContactReducer, getInitialState<IContact>());
	const [partnerContactState, partnerContactDispatch] = useReducer(partnerContactReducer, getInitialState<IContact>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialState<ICompany>());
	const [partnerState, partnerDispatch] = useReducer(partnerReducer, getInitialState<IPartner>());
	const [originState, originDispatch] = useReducer(opportunityOriginReducer, getInitialState<IOrigin>());
	const [serviceTypeState, serviceTypeDispatch] = useReducer(serviceTypeReducer, getInitialState<IServiceType>());
	const [userState, userDispatch] = useReducer(userReducer, initialUserState);
	const firstField: React.MutableRefObject<ComboBox> = useRef();
	const apiService: ApiCommunicator = useApiService();

	const readonly: boolean = (props as IEditEntityScreenProps<IOpportunity>).readonly;

	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (opportunityState.entity) {
			setOpportunity(opportunityState.entity);
		}
	}, [opportunityState.entity]);

	useEffect(() => {
		apiService.callApi(originDispatch, Endpoint.Origins, "GET");
	}, [apiService]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let dispatch: Dispatch<IAction>;
		let filterEndpoint: Endpoint;
		let extraUrlParameters: Record<string, unknown>;
		switch (event.target.name) {
			case "contactId":
				dispatch = contactDispatch;
				filterEndpoint = Endpoint.Contacts;
				break;
			case "companyId":
				dispatch = companyDispatch;
				filterEndpoint = Endpoint.Companies;
				break;
			case "partnerId":
				dispatch = partnerDispatch;
				filterEndpoint = Endpoint.Partners;
				break;
			case "responsibleUserId":
				dispatch = userDispatch;
				filterEndpoint = Endpoint.Users;
				break;
			case "typeId":
				dispatch = serviceTypeDispatch;
				filterEndpoint = Endpoint.ServiceTypes;
				break;
			case "companyContactId":
				dispatch = companyContactDispatch;
				extraUrlParameters = { companyId: opportunity.companyId };
				filterEndpoint = Endpoint.CompanyContacts;
				break;
			case "partnerContactId":
				dispatch = partnerContactDispatch;
				extraUrlParameters = { partnerId: opportunity.partnerId };
				filterEndpoint = Endpoint.PartnerContacts;
				break;
		}
		apiService.callApi(dispatch, filterEndpoint, "GET", { search: event.filter.value, ...extraUrlParameters });
	}

	function onChange(
		event: ComboBoxCloseEvent | InputChangeEvent | SwitchChangeEvent | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | DatePickerChangeEvent | NumericTextBoxChangeEvent
	): void {
		const newOpportunity: IOpportunity = handleChange(opportunity, event);
		// @ts-ignore
		if ("props" in event.target && event.target.props.name === "originId") {
			newOpportunity.chanceOfSucces = newOpportunity.origin.chanceOfSucces;
		} else if (event.target.name === "forecastCost") {
			newOpportunity.cost = event.target.value;
		}
		updateOpportunity(newOpportunity);
	}

	function updateOpportunity(newOpportunity: IOpportunity): void {
		setOpportunity(newOpportunity);
		setDataChanged(true);
	}

	function onActionButtonClicked(close: boolean, record: IOpportunity): void {
		if (record && !close) {
			setOpportunity(record);
			setDataChanged(false);
		}
		props.actionButtonClicked(close, record);
	}

	function setChild<T extends IEntity>(entity: T, field: keyof IOpportunity, idField: keyof IOpportunity): void {
		let newOpportunity: IOpportunity = setEntity(opportunity, entity, field, idField);
		if (field === "company" && !entity) {
			newOpportunity = setEntity(newOpportunity, null, "companyContact", "companyContactId");
		}
		if (field === "partner" && !entity) {
			newOpportunity = setEntity(newOpportunity, null, "partnerContact", "partnerContactId");
		}

		updateOpportunity(newOpportunity);
	}

	function renderOpportunityStatus(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return statusDropdownItemRender(li, itemProps, getOpportunityStatusColor);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!opportunity.responsibleUserId) {
			messages.push(t("fill_in_required_field", { field: t("opportunityResponsible").toLowerCase() }));
		}
		if (!opportunity.partnerId) {
			messages.push(t("fill_in_required_field", { field: t("partner").toLowerCase() }));
		}
		if (!opportunity.typeId) {
			messages.push(t("fill_in_required_field", { field: t("serviceType").toLowerCase() }));
		}
		if (isNullOrEmpty(opportunity.title)) {
			messages.push(t("fill_in_required_field", { field: t("title").toLowerCase() }));
		}
		if (opportunity.forecastCost === 0 || !opportunity.forecastCost) {
			messages.push(t("fill_in_required_field", { field: t("forecastCost").toLowerCase() }));
		}
		if (opportunity.status === OpportunityStatus.Rejected && isNullOrEmpty(opportunity.rejectionReason)) {
			messages.push(t("fill_in_required_field", { field: t("rejectionReason").toLowerCase() }));
		}
		if (!isNull(opportunity.letterOfIntent) && opportunity.letterOfIntent < new Date(1900, 1, 1, 0, 0, 0, 0)) {
			messages.push(t("fill_in_correct_date", { field: t("letterOfIntent").toLowerCase() }));
		}

		return messages;
	}

	function requestBackOfficeTask(): void {
		const renderKey: string = newKey("sendEmails");
		const requestBackOfficeTaskProps: IOpportunityProps = {
			opportunityId: opportunity.id
		};

		reduxDispatch(
			render(renderKey, RequestMail, {
				renderKey,
				onClose: (cancelled: boolean) => {
					if (!cancelled) {
						// console.log("email");
					}
				},
				...requestBackOfficeTaskProps
			})
		);
	}

	return (
		<EntityEditor
			width="70%"
			record={opportunity}
			endpoint={Endpoint.Opportunities}
			entityState={opportunityState}
			entityType={t("opportunity")}
			dispatch={opportunityDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={opportunity.title}
			actionButtonClicked={onActionButtonClicked}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="general" label={t("general")}>
					<div className="k-form">
						{opportunity.id > 0 && (
							<div className="k-form-field" style={{ padding: "5px 0px 20px 0px" }}>
								<StandardButton key="sendEmail" onClick={requestBackOfficeTask} primary>
									{t("sendEmail")}
								</StandardButton>
							</div>
						)}
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={`${t("participant")}`}
									recordId={opportunity.contactId}
									addScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsAdd) }}
									editScreen={{ screen: ContactEditor, isAllowed: hasPermission(Permission.ContactsUpdate) }}
									setEntity={(contact: IContact) => setChild(contact, "contact", "contactId")}
									readOnly={readonly}
								>
									<SearchBox
										name="contactId"
										entities={contactState.entities}
										isLoading={contactState.areEntitiesLoading}
										entityId={opportunity.contactId}
										entity={opportunity.contact}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "contact", "contactId")}
										disabled={readonly}
										myRef={firstField}
									/>
								</ManageableField>
								<div className="contactInformation">
									{opportunity.contact && (
										<>
											{opportunity.contact.address.street} {opportunity.contact.address.number}
											<br />
											{opportunity.contact.address.postalCode} {opportunity.contact.address.municipality}
											<br />
											{!isNullOrEmpty(opportunity.contact.phoneNumber) && (
												<>
													{opportunity.contact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(opportunity.contact.email) && <a href={"mailto:" + opportunity.contact.email}>{opportunity.contact.email}</a>}
										</>
									)}
								</div>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("company")}
									recordId={opportunity.companyId}
									addScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesAdd) }}
									editScreen={{ screen: CompanyEditor, isAllowed: hasPermission(Permission.CompaniesUpdate) }}
									setEntity={(company: ICompany) => setChild(company, "company", "companyId")}
									readOnly={readonly}
								>
									<SearchBox
										name="companyId"
										entities={companyState.entities}
										isLoading={companyState.areEntitiesLoading}
										entityId={opportunity.companyId}
										entity={opportunity.company}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "company", "companyId")}
										disabled={readonly}
									/>
								</ManageableField>
								<div className="contactInformation">
									{opportunity.company && (
										<>
											{!isNullOrEmpty(opportunity.company.vatNumber) && (
												<>
													{opportunity.company.vatNumber}
													<br />
												</>
											)}
											{opportunity.company.address.street} {opportunity.company.address.number}
											<br />
											{opportunity.company.address.postalCode} {opportunity.company.address.municipality}
										</>
									)}
								</div>
							</div>
							<div className="col">
								<div className="companyContact">
									<div className="k-form-field">
										<span>{t("companyContact")}</span>
										<SearchBox
											name="companyContactId"
											entities={companyContactState.entities}
											isLoading={companyContactState.areEntitiesLoading}
											entityId={opportunity.companyContactId}
											entity={opportunity.companyContact}
											textField="fullName"
											onFilterChange={onFilterChange}
											onClose={onChange}
											onClear={() => setChild(null, "companyContact", "companyContactId")}
											disabled={readonly || opportunity.company === undefined || !hasPermission(Permission.ContactsRead, Permission.ContactsUpdate)}
										/>
									</div>
								</div>
								<div className="contactInformation">
									{opportunity.companyContact && (
										<>
											{!isNullOrEmpty(opportunity.companyContact.phoneNumber) && (
												<>
													{t("phoneNumber")}: {opportunity.companyContact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(opportunity.companyContact.mobilePhoneNumber) && (
												<>
													{t("mobilePhoneNumber")}: {opportunity.companyContact.mobilePhoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(opportunity.companyContact.email) && <a href={"mailto:" + opportunity.companyContact.email}>{opportunity.companyContact.email}</a>}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="row justify-content-end">
							<div className="col-4">
								<ManageableField
									fieldLabel={t("partner") + " *"}
									recordId={opportunity.partnerId}
									addScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
									editScreen={{ screen: PartnerEditor, isAllowed: hasPermission(Permission.PartnersAdd) }}
									setEntity={(partner: IPartner) => setChild(partner, "partner", "partnerId")}
									readOnly={readonly}
								>
									<SearchBox
										name="partnerId"
										entities={partnerState.entities}
										isLoading={partnerState.areEntitiesLoading}
										entityId={opportunity.partnerId}
										entity={opportunity.partner}
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "partner", "partnerId")}
										disabled={readonly}
										required
									/>
								</ManageableField>
								<div className="contactInformation">
									{opportunity.partner && (
										<div>
											{!isNullOrEmpty(opportunity.partner.vatNumber) && (
												<>
													{opportunity.partner.vatNumber}
													<br />
												</>
											)}
											{opportunity.partner.address.street} {opportunity.partner.address.number}
											<br />
											{opportunity.partner.address.postalCode} {opportunity.partner.address.municipality}
										</div>
									)}
								</div>
							</div>
							<div className="col-4">
								<div className="partnerContact">
									<>
										<div className="k-form-field">
											<span>{t("partnerContact")}</span>
											<SearchBox
												name="partnerContactId"
												entities={partnerContactState.entities}
												isLoading={partnerContactState.areEntitiesLoading}
												entityId={opportunity.partnerContactId}
												entity={opportunity.partnerContact}
												textField="fullName"
												onFilterChange={onFilterChange}
												onClose={onChange}
												onClear={() => setChild(null, "partnerContact", "partnerContactId")}
												disabled={readonly || opportunity.partner === undefined || !hasPermission(Permission.ContactsRead, Permission.ContactsUpdate)}
											/>
										</div>
									</>
								</div>
								<div className="contactInformation">
									{opportunity.partnerContact && (
										<>
											{!isNullOrEmpty(opportunity.partnerContact.phoneNumber) && (
												<>
													{t("phoneNumber")}: {opportunity.partnerContact.phoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(opportunity.partnerContact.mobilePhoneNumber) && (
												<>
													{t("mobilePhoneNumber")}: {opportunity.partnerContact.mobilePhoneNumber}
													<br />
												</>
											)}
											{!isNullOrEmpty(opportunity.partnerContact.email) && <a href={"mailto:" + opportunity.partnerContact.email}>{opportunity.partnerContact.email}</a>}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="k-form-field">
									<span>{t("opportunityResponsible")} *</span>
									<SearchBox
										name="responsibleUserId"
										entities={userState.entities}
										isLoading={userState.areEntitiesLoading}
										entityId={opportunity.responsibleUserId}
										entity={opportunity.responsibleUser}
										required
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "responsibleUser", "responsibleUserId")}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>{t("opportunityStatus")} *</span>
								</div>
								<EnumDropDownList name="status" value={opportunity.status} enum={OpportunityStatus} itemRender={renderOpportunityStatus} onChange={onChange} required />
							</div>
						</div>
						{opportunity.status === OpportunityStatus.Rejected && (
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("rejectionReason")} *</span>
										<Input name="rejectionReason" value={opportunity.rejectionReason} required onChange={onChange} disabled={readonly} />
									</label>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("origin")}
									recordId={opportunity.originId}
									addScreen={{ screen: OpportunityOriginEditor, isAllowed: hasPermission(Permission.OpportunityOriginsAdd) }}
									editScreen={{ screen: OpportunityOriginEditor, isAllowed: hasPermission(Permission.OpportunityOriginsUpdate) }}
									setEntity={(origin: IOrigin) => setChild(origin, "origin", "originId")}
									readOnly={readonly}
								>
									<DropDownList
										name="originId"
										loading={originState.areEntitiesLoading}
										data={originState.entities}
										value={opportunity.origin}
										dataItemKey="id"
										textField="name"
										onChange={onChange}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("forecastCost")} *</span>
									<NumericTextBox name="forecastCost" value={opportunity.forecastCost} format="n2" min={0} onChange={onChange} required disabled={readonly} />
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("cost")}</span>
									<NumericTextBox name="cost" value={opportunity.cost} format="n2" min={0} onChange={onChange} disabled={readonly} />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								{opportunity.origin && (
									<label className="k-form-field">
										<span>{t("chanceOfSucces")} *</span>
										<NumericTextBox
											name="chanceOfSucces"
											defaultValue={opportunity.origin.chanceOfSucces}
											value={opportunity.chanceOfSucces}
											onChange={onChange}
											required
											disabled={readonly}
											format="p"
											min={0}
											max={1}
											step={0.1}
										/>
									</label>
								)}
							</div>
							<div className="col">
								<div className="k-form-field">
									<DatePickerForDateOnly
										label={"letterOfIntent"}
										name="letterOfIntent"
										value={getDate(opportunity.letterOfIntent)}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										onChange={onChange}
										disabled={readonly}
										format={"yyyy-MM-dd"}
									/>
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<div>{t("publicTender")}</div>
									<YesNoSwitch name="publicTender" checked={opportunity.publicTender} onChange={onChange} disabled={readonly} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("title")} *</span>
									<Input name="title" value={opportunity.title} onChange={onChange} required />
								</label>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={`${t("serviceType")} *`}
									recordId={opportunity.typeId}
									addScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesAdd) }}
									editScreen={{ screen: ServiceTypeEditor, isAllowed: hasPermission(Permission.ServiceTypesUpdate) }}
									setEntity={(type: IServiceType) => setChild(type, "type", "typeId")}
									readOnly={readonly}
								>
									<SearchBox
										name="typeId"
										entities={serviceTypeState.entities}
										isLoading={serviceTypeState.areEntitiesLoading}
										entityId={opportunity.typeId}
										entity={opportunity.type}
										required
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChange}
										onClear={() => setChild(null, "type", "typeId")}
										disabled={readonly}
									/>
								</ManageableField>
							</div>
						</div>
						<label className="k-form-field">
							<span>{t("description")}</span>
							<TextArea name="description" value={opportunity.description} onChange={onChange} style={{ height: "150px" }} />
						</label>
					</div>
				</Tab>
				{opportunity.id && (
					<Tab reactKey="tasks" label={t("tasks")}>
						<OpportunityFollowUpTasks opportunityId={opportunity.id} />
					</Tab>
				)}
				{opportunity.id && (
					<Tab reactKey="services" label={t("services")}>
						<OpportunityServices opportunityId={opportunity.id} />
					</Tab>
				)}
				{opportunity.id && (
					<Tab reactKey="participants" label={t("participants")}>
						<OpportunityParticipants opportunityId={opportunity.id} contact={opportunity.contact} />
					</Tab>
				)}
				{opportunity.id > 0 && (
					<Tab reactKey="notes" label={t("notes")}>
						<OpportunityNotes opportunityId={opportunity.id} />
					</Tab>
				)}
				{opportunity.id && (
					<Tab reactKey="emails" label={t("emails")}>
						<OpportunityEmails opportunityId={opportunity.id} />
					</Tab>
				)}
				{opportunity.id && (
					<Tab reactKey="attachments" label={t("attachments")}>
						<OpportunityAttachments opportunityId={opportunity.id} />
					</Tab>
				)}
			</TabPanel>
		</EntityEditor>
	);
};

export default OpportunityEditor;
