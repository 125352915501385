import { Reducer } from "react";

import { IAction } from "@selas/models";
import { createEntityReducer, getInitialState, IEntityState } from "@selas/state-management";
import { AxiosError, AxiosResponse } from "axios";

import Endpoint from "../../services/api/endpoint";
import { IOpportunityService } from "../../utils/types/models";

const opportunityServiceReducer: Reducer<IEntityState<IOpportunityService>, IAction> = (state: IEntityState<IOpportunityService> = getInitialState<IOpportunityService>(), action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.OpportunityServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: true,
				addedEntity: null,
				addError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.OpportunityServicesImportFromInvoicingGroup: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			state = {
				...state,
				addedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_POST_" + Endpoint.OpportunityServicesImportFromInvoicingGroup: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				addError: payload.error
			};
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.OpportunityServicesImportFromInvoicingGroup:
			state = {
				...state,
				isAdding: false
			};
			break;
		case "REQUEST_PUT_" + Endpoint.OpportunityServicesUpdatePriority:
			state = {
				...state,
				isUpdating: true,
				updatedEntity: null,
				updateError: null
			};
			break;
		case "REQUEST_SUCCESS_PUT_" + Endpoint.OpportunityServicesUpdatePriority: {
			const payload: { response: AxiosResponse<IOpportunityService> } = action.payload as { response: AxiosResponse<IOpportunityService> };
			state = {
				...state,
				updatedEntity: payload.response.data
			};
			break;
		}
		case "REQUEST_FAILURE_PUT_" + Endpoint.OpportunityServicesUpdatePriority: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				updateError: payload.error
			};

			break;
		}
		case "REQUEST_END_PUT_" + Endpoint.OpportunityServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false
			};

			break;
		case "CLEAR_PUT_" + Endpoint.OpportunityServicesUpdatePriority:
			state = {
				...state,
				isUpdating: false,
				updatedEntity: null,
				updateError: null
			};
			break;
	}
	return state;
};

export default createEntityReducer<IOpportunityService>(Endpoint.OpportunityServices, Endpoint.OpportunityServicesList, opportunityServiceReducer);
