import { AccountInfo } from "@azure/msal-browser";
import { IAction } from "@selas/models";

import { authContext } from "../../services/authentication";

const SET_LOGIN_SUCCESS: string = "SET_LOGIN_SUCCESS";
const SET_LOGIN_ERROR: string = "SET_LOGIN_ERROR";
const SET_LOGOUT_SUCCESS: string = "SET_LOGOUT_SUCCESS";
const SET_CURRENTLANGUAGE: string = "SET_CURRENTLANGUAGE";

function setLoginError(errorDesc: string, error: string): IAction {
	return {
		type: SET_LOGIN_ERROR,
		errorDesc,
		error
	};
}

function checkLoggedInUser(): IAction {
	const account: AccountInfo = authContext().getAllAccounts()[0];
	if (account) {
		return userLoggedIn(account);
	} else {
		return userLoggedOut();
	}
}

function userLoggedIn(account: AccountInfo): IAction {
	return {
		type: SET_LOGIN_SUCCESS,
		account
	};
}

function userLoggedOut(): IAction {
	return {
		type: SET_LOGOUT_SUCCESS
	};
}

function setCurrentLanguage(language: string): IAction {
	return {
		type: SET_CURRENTLANGUAGE,
		language
	};
}

export { checkLoggedInUser, setCurrentLanguage, setLoginError, userLoggedIn, userLoggedOut };
