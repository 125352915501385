import { Chip } from "@progress/kendo-react-buttons";
import React from "react";

import { useTranslation } from "react-i18next";

interface IProps {
	present: boolean;
	onChangePresent?: (newValue: boolean) => void;
}

const PresentStatusChip: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	function onClick(): void {
		switch (props.present) {
			case null:
			case undefined:
				props.onChangePresent(true);
				break;
			case true:
				props.onChangePresent(false);
				break;
			case false:
				props.onChangePresent(null);
				break;
		}
	}

	function presentChipProps(): { text: string; type: string } {
		switch (props.present) {
			case null:
			case undefined:
				return { text: t("toBeDetirmined"), type: "none" };
			case true:
				return { text: t("present"), type: "success" };
			case false:
				return { text: t("absent"), type: "error" };
		}
	}

	return <Chip onClick={onClick} {...presentChipProps()} style={{ cursor: props.onChangePresent ? undefined : "default" }} />;
};

export default PresentStatusChip;
