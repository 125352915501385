enum ProjectParticipantStatute {
	Worker = "Worker",

	Servant = "Servant",

	Executive = "Executive",

	Management = "Management"
}

export default ProjectParticipantStatute;
